import { Users, User, UsersCreate } from "../components";
import roles from "./roles";

export default [
  {
    path: "/dashboard/auth/users",
    name: "dashboard-auth-users",
    component: Users,
    meta: {
      title: "USERS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/auth/users/:id",
    name: "dashboard-auth-user",
    component: User,
    meta: {
      title: "USER",
      needsAuth: true,
      verified: true,
      guest: false,
      roles: ["manager", "moderator", "admin", "superadmin"],
    },
  },
  {
    path: "/dashboard/auth/users/create/:id?",
    name: "dashboard-auth-users-create",
    component: UsersCreate,
    meta: {
      title: "USERS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
];
