import { Attempts, AttemptsGetOne } from "../components";
import roles from "./roles";

export default [
  {
    path: "/dashboard/prep/attempts",
    name: "dashboard-prep-attempts",
    component: Attempts,
    meta: {
      title: "ATTEMPTS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/attempts/:id",
    name: "dashboard-prep-attempts-get",
    component: AttemptsGetOne,
    meta: {
      title: "Attempt Details",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
];
