import { Questions, QuestionsCreate } from "../components";
import roles from './roles';

export default [
  {
    path: "/dashboard/prep/questions",
    name: "dashboard-prep-questions",
    component: Questions,
    meta: {
      title: "QUESTIONS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/questions/create/:id?",
    name: "dashboard-prep-questions-create",
    component: QuestionsCreate,
    meta: {
      title: "CREATE-QUESTIONS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
]