<template>
  <layout-center>
    <v-card class="mb-1">
      <v-card-title>
        <v-btn
          icon
          @click="
            if ($route.query.parent_id) {
              $router.push({
                name: 'dashboard-prep-exam',
                params: {
                  id: $route.query.parent_id,
                },
              });
            } else {
              $router.push({
                name: 'dashboard-prep-exams',
              });
            }
          "
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        {{
          isHindi ? (item.title_hi ? item.title_hi : item.title) : item.title
        }}
      </v-card-title>
    </v-card>
    <v-card class="mx-auto">
      <list-with-avatar
        idPrefix="XM"
        :items="items"
        @clicked="
          ({ clickedItem }) => {
            $router.push({
              name: 'dashboard-prep-exams-mocktests',
              params: { id: clickedItem.id },
              query: { parent_id: $route.params.id },
            });
          }
        "
        @update="
          ({ clickedItem }) => {
            $router.push({
              name: 'dashboard-prep-exams-create',
              params: { id: clickedItem.id },
              query: { parent_id: $route.params.id },
            });
          }
        "
        @toggleActive="
          ({ clickedItem }) => {
            $store.dispatch('dashboard/prep/ExamsToggleActive', {
              item: clickedItem,
              query: { parent_id: $route.params.id },
            });
          }
        "
      />
      <fab-button
        v-if="allowedModeratorAndUp"
        :icon="'mdi-plus'"
        color="primary"
        @clicked="
          $router.push({
            name: 'dashboard-prep-exams-create',
            query: { parent_id: $route.params.id },
          })
        "
      />
    </v-card>
    <v-card
      class="mt-2"
      v-if="item && item.__meta__ && item.__meta__.total_mocktests > 0"
    >
      <v-card-text class="primary text-center justify-center">
        You Should remove mock tests from here
      </v-card-text>
      <exams-mocktests />
    </v-card>
    <v-pagination
      v-if="pagination.lastPage"
      v-model="pagination.page"
      :length="pagination.lastPage"
    ></v-pagination>
  </layout-center>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import FabButton from "@/components/FabButton.vue";
import ListWithAvatar from "../../modules/ListWithAvatar.vue";
import LayoutCenter from "@/layouts/LayoutCenter.vue";
import ExamsMocktests from "./ExamsMocktests.vue";

export default {
  name: "dashboard-prep-exams",
  components: {
    FabButton,
    ListWithAvatar,
    LayoutCenter,
    ExamsMocktests,
  },
  data() {
    return {
      item: {},
      items: [],
      pagination: {
        lastPage: null,
        page: null,
        perPage: null,
        total: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
  },
  watch: {},
  methods: {
    getItems() {
      return axios("/prep/exams/where/parent_id/" + this.$route.params.id).then(
        (res) => {
          this.items = res.data.data;
        }
      );
    },
    getItem() {
      return axios("/prep/exams/" + this.$route.params.id).then((res) => {
        this.item = res.data;
      });
    },
  },
  created() {
    this.getItem().then((res) => {
      this.getItems();
    });
  },
};
</script>
