<template>
  <v-row dense v-if="authUser && authUser.id" class="mx-0 px-0">
    <v-col cols="12">
      <v-list-item class="mx-0 px-0">
        <v-progress-linear
          v-if="!authUser"
          indeterminate
          color="yellow darken-2"
        ></v-progress-linear>
        <v-list-item-avatar v-if="authUser">
          <v-avatar size="64" class="rounded-circle elevation-1 d-inline-block">
            <drawer-avatar-img inputId="profile-bar" style="cursor: pointer" />
          </v-avatar>
        </v-list-item-avatar>

        <v-list-item-content v-if="authUser">
          <v-list-item-title class="title d-flex">
            {{ authUser.display_name }}
            <v-spacer></v-spacer>
          </v-list-item-title>
          <v-list-item-subtitle>
            vikarn.com/{{ authUser.username }}
            <v-chip
              v-if="!hideChange"
              label
              class="ma-0 ml-1 pa-0 px-1"
              x-small
              @click.stop="$router.push({ name: 'update-profile' })"
              >change
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-content v-if="!authUser">
          <v-list-item-title>Guest</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action
          @click="
            () => {
              $router.push({
                name: 'products',
              });
            }
          "
        >
          <!-- <v-btn icon @click.stop="$router.push({ name: 'profile' })">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn> -->
          <div>🌕{{ authUser.wallet ? authUser.wallet.coins : "0" }}</div>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import DrawerAvatarImg from "@/components/DrawerAvatarImg";

import localforage from "@/plugins/localforage";
// import bus from "@/bus";

export default {
  name: "profile-bar",
  components: { DrawerAvatarImg },
  props: ["hideChange"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    items() {
      let items = this.$store.getters["app/exams/mocktests"];
      return items.filter((el) => el.elapsed && el.elapsed > 0);
    },
  },
  methods: {},
  created() {},
};
</script>

<style>
</style>