import { Tags, TagsCreate, TagsQuestions } from "../components";
import roles from "./roles";

export default [
  {
    path: "/dashboard/prep/tags",
    name: "dashboard-prep-tags",
    component: Tags,
    meta: {
      title: "TAGS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/tags/create/:id?",
    name: "dashboard-prep-tags-create",
    component: TagsCreate,
    meta: {
      title: "CREATE-TAGS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/tags/:id/questions",
    name: "dashboard-prep-tags-questions",
    component: TagsQuestions,
    meta: {
      title: "TAgS-QUESTIONS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
];
