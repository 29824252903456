import { Exams, Exam, ExamsCreate, ExamsMocktests } from "../components";
import roles from "./roles";

export default [
  {
    path: "/dashboard/prep/exams/",
    name: "dashboard-prep-exams",
    component: Exams,
    meta: {
      title: "EXAMS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/exams/create/:id?",
    name: "dashboard-prep-exams-create",
    component: ExamsCreate,
    meta: {
      title: "CREATE-EXAMS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/exams/:id",
    name: "dashboard-prep-exam",
    component: Exam,
    meta: {
      title: "EXAM",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },

  {
    path: "/dashboard/prep/exams/:id/mocktests",
    name: "dashboard-prep-exams-mocktests",
    component: ExamsMocktests,
    meta: {
      title: "EXAMS-MOCKTESTS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
];
