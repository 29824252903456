<template>
  <v-row justify="center">
    <v-col class="col-6">
      <h2>{{ $lang("DURATION") }}</h2>
    </v-col>
    <v-col class="col-2">
      <v-text-field
        type="number"
        v-model="durationHours"
        :label="$lang('HOURS')"
      />
    </v-col>
    <v-col class="col-2">
      <v-text-field
        type="number"
        v-model="durationMinutes"
        :label="$lang('MINUTES')"
      />
    </v-col>
    <v-col class="col-2">
      <v-text-field
        type="number"
        v-model="durationSeconds"
        :label="$lang('SECONDS')"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "selector-time",
  props: ["seconds"],
  data() {
    return {
      durationHours: 0,
      durationMinutes: 0,
      durationSeconds: 0,
    };
  },
  watch: {
    durationHours(_hours) {
      let hours = parseInt(_hours) ? parseInt(_hours) : 0;
      let mins = parseInt(this.durationMinutes)
        ? parseInt(this.durationMinutes)
        : 0;
      let secs = parseInt(this.durationSeconds)
        ? parseInt(this.durationSeconds)
        : 0;
      //console.log('hms', [hours,mins,secs])
      this.changed(hours * 3600 + mins * 60 + secs);
    },
    durationMinutes(_mins) {
      let hours = parseInt(this.durationHours)
        ? parseInt(this.durationHours)
        : 0;
      let mins = parseInt(_mins) ? parseInt(_mins) : 0;
      let secs = parseInt(this.durationSeconds)
        ? parseInt(this.durationSeconds)
        : 0;
      //console.log('hms', [hours,mins,secs])
      this.changed(hours * 3600 + mins * 60 + secs);
    },
    durationSeconds(_secs) {
      let hours = parseInt(this.durationHours)
        ? parseInt(this.durationHours)
        : 0;
      let mins = parseInt(this.durationMinutes)
        ? parseInt(this.durationMinutes)
        : 0;
      let secs = parseInt(_secs) ? parseInt(_secs) : 0;
      //console.log('hms', [hours,mins,secs])
      this.changed(hours * 3600 + mins * 60 + secs);
    },
    seconds(val) {
      this.updateTimeDuration(val);
    },
  },
  methods: {
    changed(duration) {
      this.$emit("changed", duration);
    },
    updateTimeDuration(val) {
      if (!val) return;
      let hms = val.toString().convertToTime();
      this.durationHours = hms.hours;
      this.durationMinutes = hms.minutes;
      this.durationSeconds = hms.seconds;
    },
  },
  created() {
    this.updateTimeDuration(this.seconds);
  },
};
</script>