import { axios } from "@/plugins/axios.js";
import { tryTidyHtml } from "@/helpers/utils.html.js";
import { pushHistory, pullHistory } from "./history.js";
// import { isEmpty } from "lodash";
// import localforage from "localforage";
// import bus from "@/bus";
// import { locale } from "core-js";
export const onKeyUp = async ({ state }, e) => {
  // console.log("onKeyUp", e.evt);
};

export const undoTextChange = async ({ state }, { rowIndex, colField }) => {
  pullHistory({ state, rowIndex, colField });
};

export const pushTextIntoHistory = async (
  { state },
  { rowIndex, colField, value }
) => {
  pushHistory({
    state,
    rowIndex,
    colField,
    value,
  });
};

export const moveRowItem = async ({ commit }, { rowIndex, indexOffset }) => {
  commit("MOVE_ROW_ITEM", { rowIndex, indexOffset });
};

export const addRowAtIndex = async ({ commit }, { atIndex, clickedIndex }) => {
  commit("ADD_ROW_AT_INDEX", { atIndex, clickedIndex });
};
export const addRow = async ({ commit }) => {
  commit("ADD_ROW");
};
export const duplicateRow = async ({ commit }, rowIndex) => {
  commit("DUPLICATE_ROW", rowIndex);
};

export const cellUpdated = async ({ state, dispatch, commit }, e) => {
  commit("UPDATE_FIELDS_BY_CUSTOM_FIELDS", e.row);
  dispatch("modified", { row: e.row });
  console.log("cellUpdated", e);
  pushHistory({
    state,
    rowIndex: e.rowIndex,
    colField: e.column.field,
    value: e.oldValue,
  });
  // valid html check works but value might contains math symbols which
  // could render value as invalid html;
  // check if valid html, if not revert back to old value
  // const validHtml = tryTidyHtml(e.value, true);
  // tryTidyHtml(e.value)
};
export const modified = async ({ commit }, { row }) => {
  commit("MODIFIED", { row });
};
export const rowSelected = async ({ state }, e) => {
  // console.log("rowSelected", e.rowIndex, e);
  if (!e || !e.rowData || !state.rows) {
    return;
  }
  state.selectedRowIndex = e.rowIndex;
  state.selectedColIndex = e.colIndex;
  state.selectedRowField = e.colData.field;

  return Promise.resolve(state.rows[state.selectedRowIndex]);
};
export const updateSelectedCell = async ({ state }, val) => {
  if (!state.rows || state.selectedRowIndex < 0 || !state.selectedRowField) {
    return;
  }
  state.rows[state.selectedRowIndex][state.selectedRowField] = val;
};

export const onContextMenu = ({ state, dispatch }, e) => {
  // rowIndex in cellUpdate is wrong.
  // rowIndex in selectedRow is correct.
  // dont use onContextMenu event's rowIndex
  console.log("onContextMenu", e);
  return Promise.resolve(true);
};

export const publish = ({ state, dispatch }) => {
  // if (this.colViewMode != "all") {
  //   this.colViewMode = "all";
  //   return;
  // }
  if (
    !confirm(
      `Are you sure? \n\nThis Double check if there is any error in data.`
    )
  ) {
    return Promise.reject("CANCELED_BY_USER");
  }

  const segment = state.segment;
  const modifiedRows = state.rows.filter((row) => row.modified);

  return axios
    .post(`prep/segments/${segment.id}/questions`, {
      questions: modifiedRows,
    })
    .then((res) => {
      dispatch("fetchRows", { segment_id: segment.id });
    })
    .finally(() => {});
};

export const fetchTags = ({ dispatch }) => {
  return axios.get(`prep/tags/where/ident/questions`).then((res) => {
    const tags = res.data.data;
    dispatch("setTags", tags);
  });
};

export const fetchRows = ({ dispatch }, { segment_id }) => {
  return axios.get(`prep/segments/${segment_id}/questions`).then((res) => {
    const segment = res.data;
    const rows = res.data.questions;
    delete segment.questions;
    dispatch("setSegment", segment);
    dispatch("setRows", rows);
    dispatch("fetchTags");
    return rows;
  });
};

export const removeRow = ({ commit }, rowIndex) => {
  commit("REMOVE_ROW", rowIndex);
};

export const undoRemoveRow = ({ commit }) => {
  commit("UNDO_REMOVE_ROW");
};

export const setSegment = ({ commit }, segment) => {
  commit("SET_SEGMENT", segment);
};
export const setRows = ({ commit }, rows) => {
  commit("SET_ROWS", rows);
};
export const setTags = ({ commit }, tags) => {
  commit("SET_TAGS", tags);
};
export const setColViewMode = ({ commit }, val) => {
  commit("SET_COL_VIEW_MODE", val);
};

export const refresh = ({ commit }) => {
  commit("REFRESH");
};
