import localforage from 'localforage'
import { isEmpty } from 'lodash'

export const SET_TOKEN = (state, token) => {
    if (isEmpty(token)) {
        localforage.removeItem('authtoken')
        return
    }
    localforage.setItem('authtoken', token)
}

export const SET_AUTHENTICATED = (state, trueOrFalse) => {
    state.authenticated = trueOrFalse
}

export const SET_USER = (state, user) => {
    if (user && user.profile) {
        let profile = user.profile;
        let display_name = user.username
        if (profile.first_name) display_name = profile.first_name;
        if (profile.first_name && profile.last_name) display_name += " " + profile.last_name;
        profile.display_name = display_name.ucWords();
        user.profile = profile;
    }
    state.user = user
}

// export const SET_ME = (state, data) => {
//     state.me = data
// }

export const RESET_AUTH_STATE = state => {
    state.authenticated = false,
        state.user = null;
};