import auth from "./auth/locales";
import home from "./home/locales";
import profile from "./profile/locales";
import exams from "./exams/locales";
import notes from "./notes/locales";
import doubts from "./doubts/locales";
import analytics from "./analytics/locales";
import dashboard from "./dashboard/locales";
import errors from "./errors/locales";

export default {
  en: {
    ...auth.en,
    ...home.en,
    ...profile.en,
    ...exams.en,
    ...notes.en,
    ...doubts.en,
    ...analytics.en,
    ...errors.en,
    ...dashboard.en,
  },
  hi: {
    ...auth.hi,
    ...home.hi,
    ...profile.hi,
    ...exams.hi,
    ...notes.hi,
    ...doubts.hi,
    ...analytics.hi,
    ...errors.hi,
    ...dashboard.hi,
  },
};
