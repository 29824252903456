export default {
  "DASHBOARD:TESTS:TITLE-TEXT": "Tests.",

  "NEW-EXAM": "New Exam",
  "NEW-QUESTION": "New Question",
  "NEW-MOCKTEST": "New Mocktest",
  "NEW-SUBJECT": "New Subject",
  "NEW-TAG": "New Tag",

  "UPDATE-EXAM": "Update Exam",
  "UPDATE-QUESTION": "Update Question",
  "UPDATE-MOCKTEST": "Update Mocktest",
  "UPDATE-SUBJECT": "Update Subject",
  "UPDATE-TAG": "Update Tag",
  ATTEMPTS: "Attempts",
};
