import Vue from "vue";
import EasyTranslator from "easy-translator-vue";
import { locales as appLocales } from "@/app/index";

import en from "./en";
import hi from "./hi";

if (!localStorage.getItem("currLocale")) {
  localStorage.setItem("currLocale", JSON.stringify("en"));
}

export const setLocale = (locale) => {
  localStorage.setItem("currLocale", JSON.stringify(locale));
};

export const getLocale = () => {
  if (!localStorage.getItem("currLocale")) {
    localStorage.setItem("currLocale", JSON.stringify("en"));
  }
  return JSON.parse(localStorage.getItem("currLocale"));
};

// vue moment language
if (localStorage.getItem("currLocale")) {
  //if(JSON.parse(localStorage.getItem("currLocale")) == "hi")
  //require("moment/locale/hi");
}

export const locales = Vue.use(EasyTranslator, {
  locale: JSON.parse(localStorage.getItem("currLocale")),
  fallbackLocale: "en",
  locales: {
    en: {
      ...appLocales.en,
      ...en,
    },
    hi: {
      ...appLocales.hi,
      ...hi,
    },
  },
});
