import localforage from "localforage";
import { isEmpty } from "lodash";

export const RESET_STATE = (state) => {
  state.exams = [];
  state.mocktest = {};
};
export const SET_MOCKTEST = (state, data) => {
  state.mocktest = data;
};

export const RESET_MOCKTEST_ELAPSED = (state) => {
  state.activeMocktests = [];
  localforage.setItem("activeMocktests", state.activeMocktests);
};

export const RESTORE_MOCKTEST_ELAPSED = (state, data) => {
  state.activeMocktests = data;
};

export const SET_MOCKTEST_ELAPSED = (state, { id, elapsed }) => {
  if (!state.activeMocktests) {
    state.activeMocktests = [];
  }
  if (elapsed < 1) {
    //state.activeMocktests = state.activeMocktests.filter((el) => el.id == id);
    //return;
  }

  if (state.activeMocktests.find((el) => el.id == id)) {
    state.activeMocktests.find((el) => el.id == id).elapsed = elapsed;
  } else {
    state.activeMocktests.push({
      id,
      elapsed,
    });
  }
  localforage.setItem("activeMocktests", state.activeMocktests);
};

export const ADD_MOCKTESTS = (state, mocktest) => {
  if (!state.mocktests) {
    state.mocktests = [];
  }
  let item = state.mocktests.find((el) => el.id == mocktest.id);

  if (item) {
    item = mocktest;
  } else {
    state.mocktests.push(mocktest);
  }
  localforage.setItem("mocktests", state.mocktests);
};

export const UPDATE_SELECTED_TEST_LANGUAGE = async (state, value) => {
  state.selectedTestLanguage = value;
};

export const UPDATE_MOCKTESTS = async (state, mocktest) => {
  if (!state.mocktests) {
    state.mocktests = [];
  }
  if (mocktest && mocktest.id) {
    let item = state.mocktests.find((el) => el.id == mocktest.id);
    if (!item) {
      state.mocktests.push(mocktest);
    }
    state.mocktests = state.mocktests.map((el) => {
      if (el.id == mocktest.id) {
        el = mocktest;
      }
      return el;
    });
  }

  await localforage.setItem("mocktests", state.mocktests);
};

export const REMOVE_MOCKTESTS = (state, id) => {
  if (!state.mocktests) {
    state.mocktests = [];
  }
  state.mocktests = state.mocktests.filter((el) => el.id != id);
  localforage.setItem("mocktests", state.mocktests);
};

export const RESET_MOCKTESTS = (state) => {
  state.mocktests = [];
  localforage.setItem("mocktests", state.mocktests);
};
export const RESTORE_MOCKTESTS = async (state, data) => {
  state.mocktests = data;
  await localforage.setItem("mocktests", state.mocktests);
  console.log("persisted data", state);
};

export const SET_ATTEMPT = (state, data) => {
  state.attempt = data;
};

export const SET_EXAMS = (state, data) => {
  state.exams = data;
};
