<template>
  <layout-center>
    <v-card class="mx-auto">
      <v-card-title>
        <v-btn icon @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <v-spacer></v-spacer>
        <h2>
          {{
            $store.getters.getCurrLocale == "hi"
              ? mocktest.title_hi
                ? mocktest.title_hi
                : mocktest.title
              : mocktest.title
          }}
        </h2>
      </v-card-title>
      <v-divider></v-divider>
      <list-with-avatar
        :items="items"
        @toggleActive="
          ({ clickedItem }) => {
            $store.dispatch('dashboard/prep/SegmentsToggleActive', {
              item: clickedItem,
            });
          }
        "
        @update="
          ({ clickedItem }) => {
            $router.push({
              name: 'dashboard-prep-segments-create',
              params: { id: clickedItem.id },
              query: {
                mocktest_id: mocktest.id,
              },
            });
          }
        "
        @clicked="
          ({ clickedItem }) => {
            $router.push({
              name: 'dashboard-grid-segment-questions',
              params: { id: clickedItem.id },
            });
          }
        "
      >
        <template v-slot:listItems="{ item }">
          <v-list-item
            @click="
              $router.push({
                name: 'dashboard-grid-segment-questions',
                params: { id: item.id },
              })
            "
          >
            <v-icon>mdi-table</v-icon>
            Question Grid
          </v-list-item>
          <v-list-item
            @click="
              $router.push({
                name: 'dashboard-prep-segments-questions',
                params: { id: item.id },
              })
            "
          >
            <v-icon>mdi-view-list</v-icon>
            Question List
          </v-list-item>
        </template>
      </list-with-avatar>
      <fab-button
        :icon="'mdi-plus'"
        color="primary"
        @clicked="
          $router.push({
            name: 'dashboard-prep-segments-create',
            query: {
              mocktest_id: mocktest.id,
            },
          })
        "
      />
    </v-card>
  </layout-center>
</template>

<script>
import { axios } from "@/plugins/axios";
import { mapGetters } from "vuex";
import FabButton from "@/components/FabButton.vue";
import ListWithAvatar from "../../modules/ListWithAvatar.vue";
import LayoutCenter from "@/layouts/LayoutCenter.vue";

export default {
  name: "dashboard-prep-mocktests-segments",
  components: {
    FabButton,
    ListWithAvatar,
    LayoutCenter,
  },
  data() {
    return {
      items: [],
      mocktest: {},
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
  },
  watch: {},
  methods: {},
  created() {
    let id = this.$route.params.id;
    this.$store
      .dispatch("dashboard/prep/MocktestsGetOne", { id })
      .then((result) => {
        console.log(result);
        this.mocktest = result;
        axios.get(`prep/mocktests/${this.mocktest.id}/segments`).then((res) => {
          this.items = res.data.data;
        });
      });
  },
};
</script>
