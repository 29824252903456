import { Analytics } from "../components";

export default [
  {
    path: "/analytics",
    name: "analytics",
    component: Analytics,
    meta: {
      title: "Analytics",
      needsAuth: true,
      verified: false,
      guest: false,
    },
  },
];
