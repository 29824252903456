var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-center',[_c('form',[(_vm.item)?_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$lang(_vm.item.id ? "UPDATE" : "NEW") + " " + _vm.$lang("USER"))+" ")]),_c('v-spacer'),(_vm.item && _vm.item.id)?_c('avatar-uploader',{attrs:{"item":_vm.item,"dispatch":"dashboard/auth/UsersUpdateAvatar"}}):_vm._e()],1),_c('v-divider'),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$lang('USERNAME'),"hint":_vm.$lang('USERNAME-HINT'),"type":"text","error-messages":_vm.errors && _vm.errors.username ? _vm.errors.username : []},model:{value:(_vm.item.username),callback:function ($$v) {_vm.$set(_vm.item, "username", $$v)},expression:"item.username"}}),_c('v-text-field',{attrs:{"label":_vm.$lang('EMAIL'),"hint":_vm.$lang('EMAIL-HINT'),"type":"email","error-messages":_vm.errors && _vm.errors.email ? _vm.errors.email : []},model:{value:(_vm.item.email),callback:function ($$v) {_vm.$set(_vm.item, "email", $$v)},expression:"item.email"}}),(!_vm.item.id)?_c('v-text-field',{attrs:{"label":_vm.$lang('PASSWORD'),"hint":_vm.$lang('PASSWORD-HINT'),"type":"password","error-messages":_vm.errors && _vm.errors.password ? _vm.errors.password : []},model:{value:(_vm.item.password),callback:function ($$v) {_vm.$set(_vm.item, "password", $$v)},expression:"item.password"}}):_vm._e(),(_vm.item.id)?_c('selector-role',{attrs:{"select":_vm.item.role},on:{"selected":(role) => {
              if (role == _vm.item.role) return;
              _vm.$store.dispatch('dashboard/auth/usersAssignRole', {
                item: _vm.item,
                role,
              });
            }}}):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('div',{staticClass:"text-xs-center"},[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.doSubmit}},[_vm._v(_vm._s(_vm.$lang(_vm.item.id ? "UPDATE" : "CREATE")))]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({ name: 'dashboard-auth-users' })}}},[_vm._v(_vm._s(_vm.$lang("CANCEL")))])],1),_c('v-spacer'),(_vm.item.id)?_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.remove()}}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$lang("REMOVE"))+" ")],1):_vm._e()],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }