<template>
  <div v-if="loading">
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        {{ $lang("LOADING") }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-progress-linear
        color="orange accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-card>
  </div>
  <v-row v-else>
    <profile-bar :showChange="false" />
    <v-col v-if="rank && rank.total > 1" cols="12" md="6">
      <v-card dark>
        <v-img height="200px" :src="meta ? meta.backgrounds.card_1 : ''">
          <v-card-title>
            <v-icon class="mr-2">mdi-shield-star-outline</v-icon>
            {{ $lang("RANK") }}
          </v-card-title>
          <v-card-text>
            <span class="display-2">
              {{ rank.obtain
              }}<small style="vertical-align: super">{{
                rank.obtain.toString().ordinalSuffix()
              }}</small>
            </span>
          </v-card-text>
          <v-card-text class="pt-3">
            <span
              >You have obtained {{ rank.obtain
              }}<small style="vertical-align: super">{{
                rank.obtain.toString().ordinalSuffix()
              }}</small>
              place in total {{ rank.total }} participants.</span
            >
          </v-card-text>
        </v-img>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card dark>
        <v-img height="200px" :src="meta ? meta.backgrounds.card_2 : ''">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-icon class="mr-2">mdi-progress-check</v-icon>
                <span class="title">{{ $lang("SCORE") }}</span>
                <div>
                  <span class="display-1">{{ attempt.points_obtain }}</span>
                  <span class="headline">/</span>
                  <span class="headline">{{ attempt.points_total }}</span>
                </div>
              </v-col>
              <v-col cols="6" style="text-align: right">
                <v-icon class="mr-2">mdi-bullseye-arrow</v-icon>
                <span class="title">{{ $lang("ACCURACY") }}</span>
                <div class="display-1">{{ attempt.accuracy }}%</div>
              </v-col>
              <v-col cols="6">
                <v-icon class="mr-2">mdi-speedometer</v-icon>
                <span class="title">{{ $lang("SPEED") }}</span>
                <div class="display-1">
                  {{ attempt.questions_per_minute
                  }}<span class="title">Q / Min</span>
                </div>
              </v-col>
              <v-col cols="6" style="text-align: right">
                <v-icon class="mr-2">mdi-clock</v-icon>
                <span class="title">{{ $lang("DURATION") }}</span>
                <div class="display-1">
                  {{ attempt.duration.toString().fancyTime() }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-img>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card
        :color="
          attempt.questions_correct > attempt.questions_incorrect
            ? 'green'
            : 'red'
        "
        dark
      >
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
              <span class="title">{{ $lang("CORRECT") }}</span>
              <div class="display-1">{{ attempt.questions_correct }}</div>
            </v-col>
            <v-col cols="6" style="text-align: right">
              <v-icon class="mr-2">mdi-close-circle-outline</v-icon>
              <span class="title">{{ $lang("INCORRECT") }}</span>
              <div class="display-1">{{ attempt.questions_incorrect }}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card color="orange" dark>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-icon class="mr-2">mdi-format-list-checks</v-icon>
              <span class="title">{{ $lang("ATTEMPTED") }}</span>
              <div class="display-1">{{ attempt.questions_attempted }}</div>
            </v-col>
            <v-col cols="6" style="text-align: right">
              <v-icon class="mr-2">mdi-transit-skip</v-icon>
              <span class="title">{{ $lang("SKIPPED") }}</span>
              <div class="display-1">{{ attempt.questions_unattempted }}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="attempt.id && attempt.data">
      <v-expansion-panels>
        <v-expansion-panel
          v-for="segment in attempt.data.segments"
          :key="segment.id"
        >
          <v-expansion-panel-header>
            <v-card-actions class="pa-0 ma-0">
              <translate-exam-button class="mr-2" />
              <v-switch
                v-model="showAnswers"
                @click.stop=""
                inset
                :label="$lang('Answers')"
              ></v-switch>
            </v-card-actions>
            <v-spacer></v-spacer>
            {{
              attempt.data.segments.length > 1
                ? segment.title
                : attempt.data.title
            }}
            ({{ segment.questions.length }})
          </v-expansion-panel-header>
          <v-expansion-panel-content style="margin: 0 -1.5rem">
            <div v-for="(qtn, idx) in segment.questions" :key="qtn.id">
              <result-card
                :showAnswers="showAnswers"
                :question="qtn"
                :idx="idx"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import bus from "@/bus";
import { axios } from "@/plugins/axios";
import ResultCard from "./ResultCard";
import TranslateExamButton from "../modules/TranslateExamButton";
import ProfileBar from "@/app/profile/modules/ProfileBar";

export default {
  name: "attempt-result",
  components: {
    ResultCard,
    TranslateExamButton,
    ProfileBar,
  },
  data() {
    return {
      item: {},
      loading: true,
      meta: null,
      rank: null,
      showAnswers: true,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      mocktest: "app/exams/mocktest",
      attempt: "app/exams/attempt",
    }),
  },
  methods: {
    fetchMeta() {
      return axios.get("/globals/meta/attempt/result/").then((res) => {
        this.meta = res.data.data;
      });
    },
    fetchRanks() {
      return axios
        .get(
          `/prep/attempts/rank/${this.attempt.prep_mocktest_id}/${this.attempt.id}`
        )
        .then((res) => {
          this.rank = res.data;
          console.log(res.data);
        });
    },
    check() {
      if (!this.attempt || !this.attempt.id) {
        this.$router.replace({
          name: "exams-mocktest",
          params: {
            id: this.$route.params.id,
          },
        });
      } else {
        this.loading = false;
        this.fetchRanks();
      }
    },
  },
  watch: {
    attempt(val) {
      if (val) {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchMeta();
    setTimeout(() => {
      this.check();
    }, 500);
  },
};
</script>

