import { Doubts } from "../components";

export default [{
    path: "/doubts",
    name: "doubts",
    component: Doubts,
    meta: {
        title: "DOUBTS",
        needsAuth: true,
        verified: true,
        roles: [
            "student",
            "teacher",
            "manager",
            "moderator",
            "admin",
            "superadmin",
        ],
        guest: false,
    },
}, ];