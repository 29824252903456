import { Profile } from "../components";

export default [
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      title: "PROFILE",
      needsAuth: true,
      verified: true,
      guest: false,
    },
  },
];
