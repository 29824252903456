<template>
  <layout-center>
    <form>
      <v-card v-if="item">
        <v-card-title>
          <div class="title">
            {{ $lang(item.id ? "UPDATE" : "NEW") + " " + $lang("MOCKTEST") }}
          </div>
          <v-spacer></v-spacer>

          <banner-uploader
            v-if="item && item.id"
            :item="item"
            dispatch="dashboard/prep/MocktestsUpdateBanner"
          />
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-select
            v-model="item.prep_subject_id"
            :items="subjects"
            item-text="title"
            item-value="id"
            clearable
            :label="$lang('SUBJECT')"
            :error-messages="
              errors && errors.prep_subject_id ? errors.prep_subject_id : []
            "
          ></v-select>
        </v-card-text>
        <v-divider></v-divider>

        <common-create-fields :item="item" />

        <v-card-text>
          <selector-vcoin :item="item" />

          <selector-points :item="item" />

          <selector-time
            :seconds="item.duration"
            @changed="(val) => (this.item.duration = val)"
          />
          <v-divider class="mb-4"></v-divider>

          <selector-exams v-if="item.id" :item="item" />
        </v-card-text>
        <v-divider></v-divider>

        <selector-tags v-if="item.id" ident="mocktests" :item="item" />

        <selector-buyers v-if="authUser && ['admin','superadmin'].includes(authUser.role) && item.id" :item="item" />

        <v-card-actions>
          <v-btn
            icon
            @click="
              $router.push({
                name: query_exam_id
                  ? 'dashboard-prep-exams-mocktests'
                  : 'dashboard-prep-mocktests',
                params: { id: query_exam_id },
              })
            "
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn outlined @click="doSubmit(false)">{{
            $lang(item.id ? "UPDATE" : "CREATE")
          }}</v-btn>
          <v-btn v-if="item.id" class="mr-1" outlined @click="doSubmit(true)">{{
            $lang("SAVEANDNEW")
          }}</v-btn>

          <v-spacer></v-spacer>
          <v-btn v-if="item.id" color="red" outlined @click="remove()">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </layout-center>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutCenter from "@/layouts/LayoutCenter.vue";
import BannerUploader from "@/components/BannerUploader.vue";
import SelectorExams from "../../modules/SelectorExams.vue";
import SelectorTime from "../../modules/SelectorTime.vue";
import SelectorPoints from "../../modules/SelectorPoints.vue";
import SelectorVcoin from "../../modules/SelectorVcoin.vue";
import CommonCreateFields from "../../modules/CommonCreateFields";
import SelectorTags from "../../modules/SelectorTags";
import SelectorBuyers from "../../modules/SelectorBuyers";

export default {
  name: "dashboard-prep-mocktests-create",
  components: {
    LayoutCenter,
    BannerUploader,
    SelectorExams,
    SelectorTime,
    SelectorPoints,
    SelectorVcoin,
    CommonCreateFields,
    SelectorTags,
    SelectorBuyers,
  },
  data() {
    return {
      item: {
        id: null,
        title: "",
        description: "",
        language: null,
      },
      subjects: [],
      query_exam_id: null,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      authUser: "auth/user"
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    remove() {
      this.$store
        .dispatch("dashboard/prep/MocktestsRemove", {
          item: this.item,
          context: this,
        })
        .then(() => {
          this.$router.replace({
            name: this.query_exam_id
              ? "dashboard-prep-exams-mocktests"
              : "dashboard-prep-mocktests",
            params: { id: this.query_exam_id },
          });
        });
    },
    fetchSubjects() {
      this.$store.dispatch("dashboard/prep/SubjectsGetAll", {}).then((res) => {
        // fill subjects array
        this.subjects = res.data;
      });
    },
    fetchItem(id) {
      this.item.id = this.$route.params.id;
      this.$store
        .dispatch("dashboard/prep/MocktestsGetOne", {
          id: this.item.id,
          context: this,
        })
        .catch(() => {
          this.$router.replace({ name: "dashboard-prep-mocktests" });
        });
    },
    doSubmit(isNew) {
      if (this.$route.params.id) {
        this.$store
          .dispatch("dashboard/prep/MocktestsUpdate", {
            item: this.item,
            context: this,
          })
          .then(() => {
            let conf = {};

            if (isNew) {
              console.log("isNew", isNew);
              console.log("this.query_exam_id", this.query_exam_id);
              conf.name = "dashboard-prep-mocktests-create";
              conf.query = { exam_id: this.query_exam_id };
            } else {
              if (this.query_exam_id) {
                conf.name = "dashboard-prep-exams-mocktests";
              } else {
                conf.name = "dashboard-prep-mocktests";
              }
              conf.params = { id: this.query_exam_id };
            }
            this.$router.replace(conf);
          });
      } else {
        if (this.query_exam_id) {
          this.item.exam_id = this.query_exam_id;
        }
        this.$store
          .dispatch("dashboard/prep/MocktestsCreate", {
            item: this.item,
            context: this,
          })
          .then((data) => {
            this.$router.replace({
              name: "dashboard-prep-mocktests-create",
              params: { id: data.id },
              query: { exam_id: this.query_exam_id },
            });
          });
      }
    },
  },
  watch: {
    "$route.params.id"(id) {
      this.fetchItem(id);
      this.fetchSubjects();
    },
    "$route.query.exam_id"(_id) {
      _id = parseInt(_id) ? parseInt(_id) : null;
      this.query_exam_id = _id;
    },
  },
  created() {
    this.fetchSubjects();
    if (this.$route.params.id) {
      this.fetchItem(this.$route.params.id);
    }

    if (this.$route.query.exam_id) {
      let _id = parseInt(this.$route.query.exam_id)
        ? parseInt(this.$route.query.exam_id)
        : null;
      this.query_exam_id = _id;
    }
  },
};
</script>
