import {
  Exams,
  ExamsChildren,
  Exam,
  Mocktest,
  MocktestPreview,
  AttemptResult,
  AttemptResultNew,
} from "../components";
let roles = [
  // "student",
  "teacher",
  "manager",
  "moderator",
  "admin",
  "superadmin",
];
export default [
  {
    path: "/exams",
    name: "exams",
    component: Exams,
    meta: {
      title: "EXAMS",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/exams/:id",
    name: "exams-children",
    component: ExamsChildren,
    meta: {
      title: "EXAMS",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/exams/:id/details",
    name: "exam",
    component: Exam,
    meta: {
      title: "EXAMS",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/exams/mocktest-preview/:id",
    name: "exams-mocktest-preview",
    component: MocktestPreview,
    meta: {
      title: "EXAMS",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/exams/mocktest/:id",
    name: "exams-mocktest",
    component: Mocktest,
    meta: {
      title: "EXAMS",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/exams/attempt/result/:id",
    name: "exams-attempt-result",
    component: AttemptResultNew,
    meta: {
      title: "ATTEMPTRESULT",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
];
