<template>
  <div class="countdown">
    <div class="digit" v-if="hms.hours">{{ hms.hours | twoDigits }}:</div>
    <div class="digit">{{ hms.minutes | twoDigits }}:</div>
    <div class="digit">{{ hms.seconds | twoDigits }}</div>
  </div>
</template>
<script>
export default {
  name: "display-timer",
  props: ["duration", "startTime", "start", "reset"],
  emits: ["started", "stopped", "tick", "finished", "reset"],
  data() {
    return {
      timer: null,
      time: null,
      elapsed: 0,
    };
  },
  computed: {
    hms() {
      return this.time.toString().convertToTime();
    },
  },
  watch: {
    reset() {
      this.onReset();
    },
    start(val) {
      if (val) this.onStart();
      else this.onStop();
    },
    duration(val) {
      this.time = val;
    },
    startTime(val) {
      this.elapsed = val;
    },
    time(val) {
      if (val == null) return;

      if (val < 0) {
        this.onStop();
        this.$emit("finished", {});
      }
    },
  },
  methods: {
    onStart() {
      this.timer = setInterval(() => {
        this.elapsed++;
        this.time = this.duration - this.elapsed;
        this.$emit("tick", this.duration - this.time);
      }, 1000);
      this.$emit("started", {});
    },
    onStop() {
      if (!this.timer) return;
      clearInterval(this.timer);
      this.timer = null;
      this.$emit("stopped", this.elapsed);
    },
    onReset() {
      this.onStop();
      this.time = this.duration - this.startTime;
      this.elapsed = this.startTime;
      this.$emit("reset", {});
    },
  },
  created() {
    this.time = this.duration;
    this.elapsed = this.startTime;
    if (this.start) {
      this.onStart();
    }
  },
  filters: {
    twoDigits(value) {
      if (value < 0) {
        return "00";
      }
      if (value.toString().length <= 1) {
        return `0${value}`;
      }
      return value;
    },
  },
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400|Roboto:100);

.countdown {
  display: flex;
  justify-content: center;
}

.digit {
  display: flex;
  flex-direction: column;
  text-align: center;
}
</style>