var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"id":"vuetip"}},[(_vm.item)?_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$lang(_vm.item.id ? "UPDATE" : "NEW") + " " + _vm.$lang("QUESTION"))+" ")]),_c('v-spacer'),_c('v-card-actions',[(_vm.$route.params.id && _vm.item.id)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.handleCopy}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showLatexEditor = !_vm.showLatexEditor}}},[_c('v-icon',[_vm._v("mdi-math-compass")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showPreview = !_vm.showPreview}}},[_c('v-icon',[_vm._v("mdi-monitor-cellphone")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":() => {
              _vm.variant = _vm.variant == 'none' ? 'extended' : 'none';
            }}},[(_vm.variant == 'none')?_c('v-icon',[_vm._v("mdi-format-list-text")]):_c('v-icon',[_vm._v("mdi-form-textarea")])],1)],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-tabs',{attrs:{"color":"orange","grow":""}},[_c('v-tab',[_vm._v("English")]),_c('v-tab',[_vm._v("हिन्दी")]),_c('v-tab-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('vue-tip',{attrs:{"variant":_vm.variant,"initial":_vm.item.title,"errors":_vm.errors && _vm.errors.title ? _vm.errors.title : [],"placeholder":_vm.$lang('TITLE')},on:{"changed":(val) => {
                        _vm.item.title = val;
                      }}}),_c('vue-tip',{attrs:{"variant":_vm.variant,"color":"primary","dark":"","initial":_vm.item.description,"errors":_vm.errors && _vm.errors.description ? _vm.errors.description : [],"placeholder":_vm.$lang('DESCRIPTION')},on:{"changed":(val) => {
                        _vm.item.description = val;
                      }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('vue-tip',{attrs:{"variant":_vm.variant,"initial":_vm.item.option_a,"errors":_vm.errors && _vm.errors.option_a ? _vm.errors.option_a : [],"placeholder":_vm.$lang('OPTIONA')},on:{"changed":(val) => {
                        _vm.item.option_a = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('vue-tip',{attrs:{"variant":_vm.variant,"initial":_vm.item.option_b,"errors":_vm.errors && _vm.errors.option_b ? _vm.errors.option_b : [],"placeholder":_vm.$lang('OPTIONB')},on:{"changed":(val) => {
                        _vm.item.option_b = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('vue-tip',{attrs:{"variant":_vm.variant,"initial":_vm.item.option_c,"errors":_vm.errors && _vm.errors.option_c ? _vm.errors.option_c : [],"placeholder":_vm.$lang('OPTIONC')},on:{"changed":(val) => {
                        _vm.item.option_c = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('vue-tip',{attrs:{"variant":_vm.variant,"initial":_vm.item.option_d,"errors":_vm.errors && _vm.errors.option_d ? _vm.errors.option_d : [],"placeholder":_vm.$lang('OPTIOND')},on:{"changed":(val) => {
                        _vm.item.option_d = val;
                      }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('vue-tip',{attrs:{"variant":_vm.variant,"color":"green","initial":_vm.item.explanation,"errors":_vm.errors && _vm.errors.explanation ? _vm.errors.explanation : [],"placeholder":_vm.$lang('explanation')},on:{"changed":(val) => {
                        _vm.item.explanation = val;
                      }}})],1)],1)],1),_c('v-tab-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('vue-tip',{attrs:{"variant":_vm.variant,"color":"orange","dark":"","initial":_vm.item.title_hi,"errors":_vm.errors && _vm.errors.title_hi ? _vm.errors.title_hi : [],"placeholder":_vm.$lang('TITLE')},on:{"changed":(val) => {
                        _vm.item.title_hi = val;
                      }}}),_c('vue-tip',{attrs:{"variant":_vm.variant,"color":"primary","dark":"","initial":_vm.item.description_hi,"errors":_vm.errors && _vm.errors.description_hi
                        ? _vm.errors.description_hi
                        : [],"placeholder":_vm.$lang('DESCRIPTION')},on:{"changed":(val) => {
                        _vm.item.description_hi = val;
                      }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('vue-tip',{attrs:{"variant":_vm.variant,"initial":_vm.item.option_a_hi,"errors":_vm.errors && _vm.errors.option_a_hi ? _vm.errors.option_a_hi : [],"placeholder":_vm.$lang('OPTIONA')},on:{"changed":(val) => {
                        _vm.item.option_a_hi = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('vue-tip',{attrs:{"variant":_vm.variant,"initial":_vm.item.option_b_hi,"errors":_vm.errors && _vm.errors.option_b_hi ? _vm.errors.option_b_hi : [],"placeholder":_vm.$lang('OPTIONB')},on:{"changed":(val) => {
                        _vm.item.option_b_hi = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('vue-tip',{attrs:{"variant":_vm.variant,"initial":_vm.item.option_c_hi,"errors":_vm.errors && _vm.errors.option_c_hi ? _vm.errors.option_c_hi : [],"placeholder":_vm.$lang('OPTIONC')},on:{"changed":(val) => {
                        _vm.item.option_c_hi = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('vue-tip',{attrs:{"variant":_vm.variant,"initial":_vm.item.option_d_hi,"errors":_vm.errors && _vm.errors.option_d_hi ? _vm.errors.option_d_hi : [],"placeholder":_vm.$lang('OPTIOND')},on:{"changed":(val) => {
                        _vm.item.option_d_hi = val;
                      }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('vue-tip',{attrs:{"variant":_vm.variant,"color":"green","initial":_vm.item.explanation_hi,"errors":_vm.errors && _vm.errors.explanation_hi
                        ? _vm.errors.explanation_hi
                        : [],"placeholder":_vm.$lang('explanation')},on:{"changed":(val) => {
                        _vm.item.explanation_hi = val;
                      }}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{attrs:{"label":`Has LaTeX maths?`},model:{value:(_vm.item.is_latex),callback:function ($$v) {_vm.$set(_vm.item, "is_latex", $$v)},expression:"item.is_latex"}}),_c('v-checkbox',{attrs:{"label":`Has Options?`},model:{value:(_vm.item.has_options),callback:function ($$v) {_vm.$set(_vm.item, "has_options", $$v)},expression:"item.has_options"}}),_c('v-select',{attrs:{"items":_vm.options,"item-text":"value","item-value":"key","clearable":"","label":_vm.$lang('CORRECT-OPTION'),"error-messages":_vm.errors && _vm.errors.correct_option ? _vm.errors.correct_option : []},model:{value:(_vm.item.correct_option),callback:function ($$v) {_vm.$set(_vm.item, "correct_option", $$v)},expression:"item.correct_option"}}),_c('v-select',{attrs:{"items":_vm.subjects,"item-text":"title","item-value":"id","clearable":"","label":_vm.$lang('SUBJECT'),"error-messages":_vm.errors && _vm.errors.prep_subject_id ? _vm.errors.prep_subject_id : []},model:{value:(_vm.item.prep_subject_id),callback:function ($$v) {_vm.$set(_vm.item, "prep_subject_id", $$v)},expression:"item.prep_subject_id"}}),_c('selector-language',{attrs:{"select":_vm.item.language},on:{"selected":(abbr) => (_vm.item.language = abbr)}}),(_vm.item.id)?_c('selector-mocktest-segments',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.item.id)?_c('selector-tags',{attrs:{"ident":"questions","item":_vm.item}}):_vm._e(),_c('v-divider'),(
              _vm.item.explanation_video && _vm.item.explanation_video.length == 11
            )?_c('question-explanation-video',{staticClass:"mt-4",attrs:{"videoId":_vm.item.explanation_video}}):_vm._e(),_c('v-text-field',{attrs:{"loading":_vm.loading,"disabled":_vm.loading,"label":"Youtube Video ID","type":"text","prefix":"/watch?v="},model:{value:(_vm.item.explanation_video),callback:function ($$v) {_vm.$set(_vm.item, "explanation_video", $$v)},expression:"item.explanation_video"}}),_c('v-divider')],1)],1)],1),_c('v-card-actions',[_c('div',[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.doSubmit(false)}}},[_vm._v(_vm._s(_vm.$lang(_vm.item.id ? "UPDATE" : "CREATE")))]),(_vm.item.id)?_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.doSubmit(true)}}},[_vm._v(_vm._s(_vm.$lang("SAVEANDNEW")))]):_vm._e(),_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.$router.push({
              name: _vm.query_segment_id
                ? 'dashboard-prep-segments-questions'
                : _vm.query_tag_id
                ? 'dashboard-prep-tags-questions'
                : 'dashboard-prep-questions',
              params: {
                id: _vm.query_segment_id
                  ? _vm.query_segment_id
                  : _vm.query_tag_id
                  ? _vm.query_tag_id
                  : 0,
              },
            })}}},[_vm._v(_vm._s(_vm.$lang("CANCEL")))])],1),_c('v-spacer'),(_vm.item.id)?_c('v-btn',{attrs:{"small":"","color":"red","outlined":""},on:{"click":function($event){return _vm.remove()}}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$lang("REMOVE"))+" ")],1):_vm._e()],1),_c('question-preview-dialog',{attrs:{"show":_vm.showPreview,"item":_vm.item},on:{"closed":(val) => (_vm.showPreview = val)}}),_c('latex-editor-dialog',{attrs:{"show":_vm.showLatexEditor,"item":_vm.item},on:{"closed":(val) => (_vm.showLatexEditor = val)}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }