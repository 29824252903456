import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: { dark: true },
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
});

export default vuetify;
