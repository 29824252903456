<template>
  <v-layout justify-center align-center>
    <v-row dense>
      <v-col v-for="(item, i) in items" :key="i" cols="12">
        <v-card>
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline" v-text="item.title"></v-card-title>

              <v-card-subtitle>
                {{ item.description.stripTags() }}
              </v-card-subtitle>

              <v-card-actions>
                <v-btn class="ml-2 mt-5" outlined rounded small>
                  {{ item.language }}
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3" size="125" tile>
              <v-img :src="item.banner_square"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { axios } from "@/plugins/axios";

export default {
  name: "tests",
  components: {},
  data() {
    return {
      items: [],
    };
  },
  computed: {},
  methods: {
    fetchItems() {
      axios
        .get("prep/notes/public")
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.fetchItems();
  },
};
</script>

