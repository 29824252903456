<template>
  <layout-center v-if="items">
    <v-card class="mx-auto">
      <v-card-title>
        <v-btn icon @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <v-spacer></v-spacer>
        <h2>
          {{
            $store.getters.getCurrLocale == "hi"
              ? item.title_hi
                ? item.title_hi
                : item.title
              : item.title
          }}
        </h2>
      </v-card-title>
      <v-divider></v-divider>
      <list-item-details
        v-if="item.questions && item.questions.length > 0"
        :items="item.questions"
        @toggleActive="
          ({ clickedItem }) => {
            $store.dispatch('dashboard/prep/QuestionsToggleActive', {
              item: clickedItem,
            });
          }
        "
        @update="
          ({ clickedItem }) => {
            $router.push({
              name: 'dashboard-prep-questions-create',
              params: { id: clickedItem.id },
              query: {
                segment_id: item.id,
              },
            });
          }
        "
        @clicked="
          ({ clickedItem }) => {
            $router.push({
              name: 'dashboard-prep-questions-create',
              params: { id: clickedItem.id },
              query: {
                tag_id: item.id,
                parent_page: $route.query.page,
              },
            });
          }
        "
      />
      <v-col cols="12">
        <query-page-redirect :pagination="pagination" />
      </v-col>
      <fab-button
        :icon="'mdi-plus'"
        color="primary"
        @clicked="
          $router.push({
            name: 'dashboard-prep-questions-create',
            query: {
              segment_id: item.id,
            },
          })
        "
      />
    </v-card>
  </layout-center>
</template>

<script>
import { axios } from "@/plugins/axios";
import { mapGetters } from "vuex";
import FabButton from "@/components/FabButton.vue";
import ListItemDetails from "../../modules/ListItemDetails.vue";
import LayoutCenter from "@/layouts/LayoutCenter.vue";
import bus from "@/bus";
import QueryPageRedirect from "@/components/QueryPageRedirect.vue";

export default {
  name: "dashboard-prep-tags-questions",
  components: {
    FabButton,
    ListItemDetails,
    LayoutCenter,
    QueryPageRedirect,
  },
  data() {
    return {
      item: {},
      items: [],
      pagination: {
        lastPage: null,
        page: 1,
        perPage: null,
        total: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
  },
  watch: {},
  methods: {
    fetchItem() {
      bus.$emit("showWait");
      return axios
        .get(`prep/tags/${this.$route.params.id}`)
        .then((res) => {
          this.item = res.data;
        })
        .then(() => {
          this.fetchItems(this.$route.query.page);
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    fetchItems(page = 1) {
      bus.$emit("showWait");
      if (parseInt(page) < 1) {
        return;
      }
      return axios
        .get(`prep/tags/${this.$route.params.id}/questions?page=${page}`)
        .then((res) => {
          this.items = res.data.data;
          this.item.questions = res.data.data;
          this.pagination = {
            lastPage: res.data.lastPage,
            page: res.data.page,
            perPage: res.data.perPage,
            total: res.data.total,
          };
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    if (
      parseInt(this.$route.query.page) &&
      parseInt(this.$route.query.page) > 0
    ) {
      this.fetchItem();
    }
  },
  updated() {
    bus.$emit("highlightElement");
  },
};
</script>
