<template>
  <layout-center>
    <form>
      <v-card v-if="item">
        <v-card-title>
          <v-btn
            icon
            @click="
              if ($route.query.parent_id) {
                $router.push({
                  name: 'dashboard-prep-exam',
                  params: {
                    id: $route.query.parent_id,
                  },
                });
              } else {
                $router.push({
                  name: 'dashboard-prep-exams',
                });
              }
            "
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <div class="title">
            {{ $lang(item.id ? "UPDATE" : "NEW") + " " + $lang("EXAM") }}
          </div>
          <v-spacer></v-spacer>

          <banner-uploader
            v-if="item && item.id"
            :item="item"
            dispatch="dashboard/prep/ExamsUpdateBanner"
          />
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-select
            v-model="item.parent_id"
            :items="examParents"
            item-text="title"
            item-value="id"
            clearable
            :label="$lang('Parent Exam Group').ucWords()"
            :error-messages="errors && errors.parent_id ? errors.parent_id : []"
          ></v-select>
        </v-card-text>
        <v-divider></v-divider>
        {{ item.parent_id }}
        <v-divider></v-divider>

        <common-create-fields :item="item" />

        <v-divider></v-divider>

        <selector-tags v-if="item.id" ident="exams" :item="item" />

        <v-divider></v-divider>

        <v-card-actions>
          <div class="text-xs-center">
            <v-btn outlined @click="doSubmit">{{
              $lang(item.id ? "UPDATE" : "CREATE")
            }}</v-btn>
            <v-btn
              text
              @click="$router.push({ name: 'dashboard-prep-exams' })"
              >{{ $lang("CANCEL") }}</v-btn
            >
          </div>
          <v-spacer></v-spacer>
          <v-btn v-if="item.id" color="red" outlined @click="remove()">
            <v-icon>mdi-delete</v-icon> {{ $lang("REMOVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </layout-center>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutCenter from "@/layouts/LayoutCenter.vue";
import BannerUploader from "@/components/BannerUploader.vue";
import CommonCreateFields from "../../modules/CommonCreateFields.vue";
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import SelectorTags from "../../modules/SelectorTags.vue";

export default {
  name: "dashboard-prep-exams-create",
  components: {
    LayoutCenter,
    BannerUploader,
    CommonCreateFields,
    SelectorTags,
  },
  data() {
    return {
      item: {
        id: null,
        title: "",
        description: "",
        language: null,
      },
      examParents: [],
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    remove() {
      this.$store
        .dispatch("dashboard/prep/ExamsRemove", {
          item: this.item,
          context: this,
        })
        .then(() => {
          this.$router.replace({ name: "dashboard-prep-exams" });
        });
    },

    fetchExamParents() {
      return axios.get(`prep/exams`).then((res) => {
        let exams = res.data.data;
        // exams = exams.filter((el) => !el.parent_id || el.parent_id == 0);
        this.examParents = exams;

        if (
          this.$route.query.parent_id &&
          parseInt(this.$route.query.parent_id) > 0
        ) {
          this.item.parent_id = this.examParents.find(
            (el) => el.id == this.$route.query.parent_id
          ).id;
        }
      });
    },
    fetchItem(id) {
      this.item.id = this.$route.params.id;
      this.$store
        .dispatch("dashboard/prep/ExamsGetOne", {
          id: this.item.id,
          context: this,
        })
        .then(() => {
          //
        })
        .catch(() => {
          this.$router.replace({ name: "dashboard-prep-exams" });
        });
    },
    doSubmit() {
      if (this.$route.params.id) {
        this.$store.dispatch("dashboard/prep/ExamsUpdate", {
          item: this.item,
          context: this,
        });
      } else {
        this.$store
          .dispatch("dashboard/prep/ExamsCreate", {
            item: this.item,
            context: this,
          })
          .then((data) => {
            this.$router.replace({
              name: "dashboard-prep-exams-create",
              params: { id: data.id },
              query: { parent_id: this.item.parent_id },
            });
          });
      }
    },
  },
  watch: {
    "$route.params.id"(id) {
      this.fetchItem(id);

      this.fetchExamParents();
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchItem(this.$route.params.id);
    }
    this.fetchExamParents();
  },
};
</script>
