<template>
  <v-card v-if="items && items.length > 0" class="mt-4 mx-auto pt-2">
    <v-card-title class="pt-2">
      <div>{{ title ? title : "Activity Logs - Hourly" }}</div>
      <v-spacer></v-spacer>
      {{ items.length }}
    </v-card-title>
    <v-divider class=""></v-divider>
    <v-sheet
      elevation="0"
      class="v-sheet--offset mx-auto"
      max-width="calc(100% - 20px)"
    >
      <StatsSpark
        v-if="items && items.length > 0"
        :items="items"
        :limit="limit ? limit : 7"
        :format="format ? format : 'HH'"
        :label_format="label_format ? label_format : 'hhA'"
        :field="field ? field : 'created_at'"
        :sort_callback="sort_callback ? sort_callback : handleSort"
      />
    </v-sheet>

    <v-card-text class="pt-2" v-if="lastRecord">
      <v-divider class="my-2"></v-divider>
      <v-icon class="mr-2" small> mdi-clock </v-icon>
      <span class="text-caption grey--text font-weight-light"
        >last activity
        <u
          v-if="lastRecord.user_id"
          @click.stop="
            $router.push({
              name: 'dashboard-auth-user',
              params: { id: lastRecord.user_id },
            })
          "
          >{{ lastRecord.user.display_name }}</u
        >
        {{ lastRecord.ago }}
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
import { axios } from "@/plugins/axios";
import { mapGetters } from "vuex";
import StatsSpark from "./StatsSpark";
import moment from "@/plugins/moment";

export default {
  name: "home",
  props: ["format", "field", "title", "limit", "label_format", "sort_callback"],
  components: {
    StatsSpark,
  },
  data() {
    return {
      items: [],
      lastRecord: null,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
  },
  watch: {},
  methods: {
    handleSort(grouped) {
      grouped.sort((a, b) => {
        return parseInt(a.key) < parseInt(b.key)
          ? -1
          : parseInt(b.key) < parseInt(a.key)
          ? 1
          : 0;
      });
      return grouped;
    },
    fetchAllitems() {
      return axios
        .get("logs/select/?orderby=id|desc", {
          params: {
            select: ["id", "created_at", "user_id"],
            select_user: ["display_name"],
          },
        })
        .then((res) => {
          this.items = res.data.data;

          let last = this.items[0];
          last.ago = moment(
            last[this.field ? this.field : "created_at"]
          ).fromNow();
          this.lastRecord = last;
        });
    },
  },
  created() {
    this.fetchAllitems();
  },
};
</script>

<style>
</style>