import Vue from 'vue'

import _Login from './Login'
import _Register from './Register'
import _VerifyEmail from './VerifyEmail'
import _UpdateEmail from './UpdateEmail'
import _UpdatePassword from './UpdatePassword'
import _ForgotPassword from './ForgotPassword'
import _UpdateProfile from './UpdateProfile'

export const Login = Vue.component('login', _Login);
export const Register = Vue.component('register', _Register);
export const VerifyEmail = Vue.component('verify-email', _VerifyEmail);
export const UpdateEmail = Vue.component('update-email', _UpdateEmail);
export const UpdatePassword = Vue.component('update-password', _UpdatePassword);
export const ForgotPassword = Vue.component('forgot-password', _ForgotPassword);
export const UpdateProfile = Vue.component('forgot-password', _UpdateProfile);
