<template>
  <v-dialog
    :fullscreen="fullscreen"
    scrollable
    persistent
    v-model="dialog"
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card elevation="4" tile>
      <v-card-title class="px-2 py-2 indigo accent-4 white--text">
        {{ $lang("Preview") }} ({{ locale }})
        <v-spacer></v-spacer>
        <v-btn small class="ml-1" icon dark @click="fullscreen = !fullscreen">
          <v-icon v-if="fullscreen">mdi-fullscreen-exit</v-icon>
          <v-icon v-else>mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn small class="ml-1" icon dark @click="toggleLocale">
          <v-icon>mdi-translate</v-icon>
        </v-btn>
        <v-btn small class="ml-1" icon dark @click="dialog = !dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <question-card
        touchless
        @click.stop="() => {}"
        :locale="locale"
        :question="item"
        :idx="-1"
        :currentQuestionId="0"
        :withAnswer="true"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import QuestionCard from "@/components/QuestionCard";

export default {
  name: "question-preview-dialog",
  props: ["item", "show"],
  components: {
    QuestionCard,
  },
  data() {
    return {
      dialog: null,
      locale: null,
      fullscreen: false,
    };
  },
  watch: {
    show(val) {
      this.dialog = val;
      this.locale = this.$store.getters.getCurrLocale;
    },
    dialog(val) {
      this.$emit("closed", val);
    },
  },
  created() {
    this.locale = this.$store.getters.getCurrLocale;
    this.dialog = this.show;
  },
  methods: {
    toggleLocale() {
      if (this.locale == "hi") {
        this.locale = "en";
      } else {
        this.locale = "hi";
      }
    },
  },
};
</script>

<style>
</style>