<template>
  <!-- <v-img :src="url" v-if="url" /> -->
  <v-card>
    <v-card-text v-if="!url">
      <form enctype="multipart/form-data" @submit.prevent="doSubmit()">
        <v-file-input
          :loading="loading"
          name="file"
          type="file"
          label="Click to choose an image"
          id="upload-image-file"
        />
        <v-btn dark color="primary" :loading="loading" type="submit" block>
          Upload
        </v-btn>
      </form>
    </v-card-text>
  </v-card>
</template>

<script>
/**
 * Example of a custom Image selector
 * Key is to emit a select-file event when a file needs to be added
 */
import bus from "@/bus";
import { axios } from "@/plugins/axios";
import { VImg, VBtn } from "vuetify/lib";

export default {
  name: "VueTipFileSelector",
  data() {
    return {
      dialog: false,
      loading: false,
      url: null,
    };
  },
  methods: {
    doSubmit() {
      console.log("route", this.$route);
      let files = document.querySelector("#upload-image-file");
      if (!files.files[0]) {
        this.$store.dispatch(
          "setAlert",
          { type: "error", message: "PLEASE_CHOOSE_AN_IMAGE" },
          { root: true }
        );

        return;
      }

      this.loading = true;
      bus.$emit("showWait");
      var formData = new FormData();
      formData.append("file", files.files[0]);
      formData.append("title", "no-title");

      return axios
        .post(`media/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.url = response.data.url;
          this.$emit("select-file", {
            src: response.data.url,
            alt: "Image-Title",
          });
          this.$emit("uploaded", response.data.url);
          return Promise.resolve(response.data);
        })
        .catch((err) => {
          this.url = null;
          return Promise.reject(error.response);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
  },
};
</script>

<style scoped>
.selectable {
  cursor: pointer;
}
</style>