<template>
  <v-layout justify-center align-center>
    <v-flex md8 lg6>
      <form>
        <v-card>
          <v-card-title>
            <div class="headline">
              {{ $lang("AUTH:VERIFY_EMAIL:CARD_TITLE-TEXT") }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              prepend-icon="mdi-verified_user"
              :label="$lang('AUTH:VERIFY_EMAIL:INPUT_TEXT_SALT-LABEL')"
              :hint="$lang('AUTH:VERIFY_EMAIL:INPUT_TEXT_SALT-HINT')"
              v-model="salt"
              :error-messages="errors && errors.salt ? errors.salt : []"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <div class="text-xs-center">
              <v-btn outlined @click="doVerification">{{
                $lang("AUTH:VERIFY_EMAIL:BUTTON_SUBMIT-TEXT")
              }}</v-btn>
              <v-btn text @click="resendCode">{{
                $lang("AUTH:VERIFY_EMAIL:BUTTON_SEND_AGAIN-TEXT")
              }}</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </form>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { isEmpty } from "lodash";

export default {
  name: "verify-email",
  data() {
    return {
      salt: null,
      wait: false,
    };
  },
  mounted() {
    // redirect if already activated
    if (this.auth.user && this.auth.user.active) {
      this.$store.dispatch(
        "setAlert",
        { type: "warning", message: "You are already activated." },
        { root: true }
      );
      this.$router.replace({ name: "home" });
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    ...mapActions({
      verifyEmail: "auth/verifyEmail",
      resentEmailVerificationCode: "auth/resentEmailVerificationCode",
    }),
    resendCode() {
      if (!this.auth || !this.auth.user || this.auth.user.active) return;
      this.resentEmailVerificationCode({ user: this.auth.user, context: this });
    },
    afterSuccess() {
      localforage
        .getItem("intended")
        .then((intended) => {
          if (isEmpty(intended)) {
            this.$router.replace({ name: "home" });
            return;
          }
          localforage.removeItem("intended");
          this.$router.replace(intended);
        })
        .catch(() => {
          this.$router.replace({ name: "home" });
        });
    },
    doVerification() {
      if (!this.salt || this.salt.trim().length < 1) {
        this.$store.dispatch(
          "setAlert",
          { type: "error", message: "Verification Code can not be empty." },
          { root: true }
        );
        return;
      }
      this.wait = true;
      this.verifyEmail(this.salt.trim())
        .then(() => {
          this.wait = false;
          // this.$router.replace({ name: "home" });
          this.afterSuccess();
        })
        .catch((e) => {
          this.wait = false;
        });
    },
  },
};
</script>


