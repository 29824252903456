export default [
  {
    path: "/dashboard/feeds",
    name: "dashboard-feeds",
    component: () => import("../components/Feeds"),
    meta: {
      title: "FEEDS",
      needsAuth: true,
      verified: true,
      roles: ["manager", "admin", "superadmin"],
      guest: false,
    },
  },
  {
    path: "/dashboard/feeds/create/:id?",
    name: "dashboard-feeds-create",
    component: () => import("../components/FeedsCreate"),
    meta: {
      title: "FEEDS_CREATE",
      needsAuth: true,
      verified: true,
      roles: ["manager", "admin", "superadmin"],
      guest: false,
    },
  },
];
