var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-center',[_c('form',[(_vm.item)?_c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){if (_vm.$route.query.parent_id) {
              _vm.$router.push({
                name: 'dashboard-prep-exam',
                params: {
                  id: _vm.$route.query.parent_id,
                },
              });
            } else {
              _vm.$router.push({
                name: 'dashboard-prep-exams',
              });
            }}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$lang(_vm.item.id ? "UPDATE" : "NEW") + " " + _vm.$lang("EXAM"))+" ")]),_c('v-spacer'),(_vm.item && _vm.item.id)?_c('banner-uploader',{attrs:{"item":_vm.item,"dispatch":"dashboard/prep/ExamsUpdateBanner"}}):_vm._e()],1),_c('v-divider'),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.examParents,"item-text":"title","item-value":"id","clearable":"","label":_vm.$lang('Parent Exam Group').ucWords(),"error-messages":_vm.errors && _vm.errors.parent_id ? _vm.errors.parent_id : []},model:{value:(_vm.item.parent_id),callback:function ($$v) {_vm.$set(_vm.item, "parent_id", $$v)},expression:"item.parent_id"}})],1),_c('v-divider'),_vm._v(" "+_vm._s(_vm.item.parent_id)+" "),_c('v-divider'),_c('common-create-fields',{attrs:{"item":_vm.item}}),_c('v-divider'),(_vm.item.id)?_c('selector-tags',{attrs:{"ident":"exams","item":_vm.item}}):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('div',{staticClass:"text-xs-center"},[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.doSubmit}},[_vm._v(_vm._s(_vm.$lang(_vm.item.id ? "UPDATE" : "CREATE")))]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({ name: 'dashboard-prep-exams' })}}},[_vm._v(_vm._s(_vm.$lang("CANCEL")))])],1),_c('v-spacer'),(_vm.item.id)?_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.remove()}}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$lang("REMOVE"))+" ")],1):_vm._e()],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }