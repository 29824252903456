var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-center',[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_c('h2',[_vm._v(" "+_vm._s(_vm.$store.getters.getCurrLocale == "hi" ? _vm.item.title_hi ? _vm.item.title_hi : _vm.item.title : _vm.item.title)+" ")])],1),_c('v-divider'),_c('list-item-details',{attrs:{"items":_vm.item.questions,"idPrefix":"QE"},on:{"toggleActive":({ clickedItem }) => {
          _vm.$store.dispatch('dashboard/prep/QuestionsToggleActive', {
            item: clickedItem,
          });
        },"update":({ clickedItem }) => {
          _vm.$router.push({
            name: 'dashboard-prep-questions-create',
            params: { id: clickedItem.id },
            query: {
              segment_id: _vm.item.id,
            },
          });
        },"remove":({ clickedItem }) => {
          _vm.handleRemove(clickedItem);
        },"clicked":({ clickedItem }) => {
          _vm.$router.push({
            name: 'dashboard-prep-questions-create',
            params: { id: clickedItem.id },
            query: {
              segment_id: _vm.item.id,
            },
          });
        }}}),_c('fab-button',{attrs:{"icon":'mdi-plus',"color":"primary"},on:{"clicked":function($event){return _vm.$router.push({
          name: 'dashboard-prep-questions-create',
          query: {
            segment_id: _vm.item.id,
          },
        })}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }