var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items.length > 0)?_c('v-list',{attrs:{"subheader":"","two-line":""}},_vm._l((_vm.items),function(item){return _c('v-list-item',{directives:[{name:"long-press",rawName:"v-long-press",value:(
      () => {
        _vm.$emit('update', { clickedItem: item });
      }
    ),expression:"\n      () => {\n        $emit('update', { clickedItem: item });\n      }\n    "}],key:item.id,on:{"click":function($event){$event.stopPropagation();return _vm.$emit('clicked', { clickedItem: item })}}},[(item.banner_square)?_c('v-list-item-avatar',[_c('v-avatar',[_c('v-img',{attrs:{"src":item.banner_square,"alt":"Avatar"}})],1)],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_c('v-btn',{attrs:{"id":'highlight-' + item.id,"icon":"","color":item.active ? 'green' : 'red'},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toggleActive', { clickedItem: item })}}},[_c('v-icon',[_vm._v(_vm._s(item.active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline"))])],1),(item.children && item.children.length > 0)?_c('v-icon',{attrs:{"color":"orange accent-4"}},[_vm._v("mdi-clover")]):_vm._e(),_vm._v(" "+_vm._s(_vm.isHindi ? (item.title_hi ? item.title_hi : item.title) : item.title)+" ")],1),_c('v-list-item-subtitle',[(_vm.idPrefix && item.id)?_c('v-chip',{staticClass:"mr-1 black accent-4 white--text",attrs:{"label":"","dark":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return (() => {
              _vm.copyToClipboard(item);
            }).apply(null, arguments)}}},[_vm._v("#"+_vm._s(_vm.idPrefix)+_vm._s(item.id))]):_vm._e(),(item.subject)?_c('v-chip',{attrs:{"color":"cyan darken-4 mr-1","dark":"","label":"","x-small":""}},[_vm._v(_vm._s(item.subject.title))]):_vm._e(),(item.ident)?_c('v-chip',{attrs:{"color":"indigo darken-4 mr-1","dark":"","label":"","x-small":""}},[_vm._v(_vm._s(item.ident))]):_vm._e(),(item.user)?_c('v-chip',{attrs:{"label":"","x-small":""}},[_vm._v(_vm._s(item.user.username))]):_vm._e(),(item.__meta__ && item.__meta__.total_questions)?_c('v-chip',{staticClass:"blue ml-1",attrs:{"label":"","x-small":""}},[_vm._v(" "+_vm._s(item.__meta__.total_questions)+" "+_vm._s(_vm.$lang("qstn"))+" ")]):_vm._e(),(item.__meta__ && item.__meta__.total_exams)?_c('v-chip',{staticClass:"pink ml-1",attrs:{"label":"","x-small":""}},[_vm._v(" "+_vm._s(item.__meta__.total_exams)+" "+_vm._s(_vm.$lang("exams"))+" ")]):_vm._e(),(item.__meta__ && item.__meta__.total_mocktests)?_c('v-chip',{staticClass:"green ml-1",attrs:{"label":"","x-small":""}},[_vm._v(" "+_vm._s(item.__meta__.total_mocktests)+" "+_vm._s(_vm.$lang("mocks"))+" ")]):_vm._e()],1)],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_vm._l((_vm.menu),function(mItem){return _c('v-list-item',{key:mItem.id,on:{"click":function($event){$event.stopPropagation();return mItem.to(item)}}},[_c('v-icon',[_vm._v(_vm._s(mItem.icon(item)))]),_vm._v(" "+_vm._s(mItem.title)+" ")],1)}),_vm._t("listItems",null,{"item":item})],2)],1)],1)],1)}),1):_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(_vm._s(_vm.$lang("NOITEMS")))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }