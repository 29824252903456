import { Notes } from "../components";

export default [
  {
    path: "/notes",
    name: "notes",
    component: Notes,
    meta: {
      title: "NOTES",
      needsAuth: true,
      verified: true,
      roles: [
        "student",
        "teacher",
        "manager",
        "moderator",
        "admin",
        "superadmin",
      ],
      guest: false,
    },
  },
];
