<template>
  <v-bottom-navigation grow app>
    <v-btn
      :disabled="!item.user_option"
      color="primary"
      @click="
        () => {
          review = false;
          item.review = false;
          $emit('next');
        }
      "
    >
      <span>{{ $lang("SAVE") }}</span>
      <v-icon small>mdi-send</v-icon>
    </v-btn>
    <v-btn
      :color="review ? 'orange' : ''"
      @click="
        () => {
          review = !review;
          item.review = review;
          if (review) $emit('next');
        }
      "
    >
      <span>{{ $lang(review ? "UNMARK" : "MARKANDNEXT") }}</span>
      <v-icon small>mdi-check</v-icon>
    </v-btn>

    <v-btn
      :disabled="!item.user_option"
      @click="item.user_option = null"
      color="red"
    >
      <span>{{ $lang("Clear") }}</span>
      <v-icon small>mdi-autorenew</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "mocktest-attempt-card-actions",
  props: ["item"],
  data() {
    return {
      review: false,
    };
  },
  watch: {
    item(val) {
      if (!val.review) {
        val.review = false;
      }
      this.review = val.review;
    },
    review(val) {
      this.item.review = val;
    },
  },
};
</script>
