import localforage from "@/plugins/localforage";
import vuetify from "@/plugins/vuetify";
import store from "@/store";
import router from "@/router";
import bus from "@/bus";

// persist current locale
localforage.getItem("currLocale").then((val) => {
  store.dispatch("setCurrLocale", val);
});

// persisting theme
localforage.getItem("isThemeDark").then((val) => {
  store.dispatch("setThemeDark", val);
  vuetify.framework.theme.dark = val;
});

// persisting authentication
store
  .dispatch("auth/setToken")
  .then(() => {
    store
      .dispatch("auth/fetchUser")
      .then((res) => {})
      .catch((err) => {
        if (err == "NOT_FOUND") {
          store.dispatch("auth/clearAuth");
          router.replace({ name: "login" });
        }
      });
  })
  .catch((err) => {
    // no auth token
    store.dispatch("auth/clearAuth");
  });
