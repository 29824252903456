import state from "./state";
import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

import exams from "../exams/store";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    exams,
  },
};
