var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-center',[_c('form',[_c('v-card',{staticClass:"mb-2"},[_c('v-card-title',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$lang("GENERATE") + " " + _vm.$lang("MOCKTEST"))+" ")]),_c('v-spacer')],1)],1),_c('v-card',{staticClass:"mb-2"},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$lang('TITLE'),"hint":_vm.$lang('TITLE-HINT'),"type":"text"},model:{value:(_vm.schema.title),callback:function ($$v) {_vm.$set(_vm.schema, "title", $$v)},expression:"schema.title"}}),_c('vue-tip',{attrs:{"variant":"none","initial":_vm.schema.description,"placeholder":_vm.$lang('DESCRIPTION')},on:{"changed":(val) => {
              _vm.schema.description = val;
            }}}),_c('selector-points',{attrs:{"item":_vm.schema}}),_c('selector-language',{attrs:{"select":_vm.schema.language},on:{"selected":(abbr) => (_vm.schema.language = abbr)}}),_c('v-checkbox',{staticClass:"mb-3",attrs:{"label":`Shuffle questions for whole mocktest.`},model:{value:(_vm.schema.shuffle_questions),callback:function ($$v) {_vm.$set(_vm.schema, "shuffle_questions", $$v)},expression:"schema.shuffle_questions"}}),_c('selector-time',{attrs:{"seconds":_vm.schema.duration},on:{"changed":(val) => (this.schema.duration = val)}})],1),_c('v-divider'),_c('v-card-text',[_c('exams-list',{attrs:{"initial":_vm.schema.exam_id},on:{"selected":(data) => {
              _vm.exams = data;
            }}})],1),_c('v-divider'),_c('counter',{attrs:{"value":_vm.segment_count,"label":"Segments"},on:{"changed":(val) => (_vm.segment_count = val)}})],1),_vm._l((parseInt(_vm.segment_count)),function(val,idx){return _c('v-card',{key:idx,staticClass:"mb-2"},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$lang('SEGMENT') + ' ' + val + ' ' + _vm.$lang('TITLE'),"type":"text"},model:{value:(_vm.schema.segments[idx].title),callback:function ($$v) {_vm.$set(_vm.schema.segments[idx], "title", $$v)},expression:"schema.segments[idx].title"}}),_c('vue-tip',{attrs:{"variant":"none","initial":_vm.schema.segments[idx].description,"placeholder":_vm.$lang('DESCRIPTION')},on:{"changed":(val) => {
              _vm.schema.segments[idx].description = val;
            }}}),_c('v-checkbox',{staticClass:"mb-3",attrs:{"label":`Shuffle questions for this segment.`},model:{value:(_vm.schema.segments[idx].shuffle_questions),callback:function ($$v) {_vm.$set(_vm.schema.segments[idx], "shuffle_questions", $$v)},expression:"schema.segments[idx].shuffle_questions"}}),_c('selector-time',{attrs:{"seconds":_vm.schema.segments[idx].duration},on:{"changed":(val) => (_vm.schema.segments[idx].duration = val)}})],1),_c('v-card-text',[(_vm.tags)?_c('add-segment-tags',{attrs:{"tags":_vm.tags},on:{"selected":(tag) => {
              _vm.addTagToSegment(idx, tag);
            }}}):_vm._e()],1),_c('v-card-text',_vm._l((_vm.schema.segments[idx].tags),function(tag,i){return _c('v-card',{key:i,staticClass:"mb-2",attrs:{"outlined":"","dark":""}},[_c('v-card-actions',[_c('v-btn',{staticClass:"mr-2 red",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.removeTagfromSegment(idx, tag)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v(" "+_vm._s(tag.title)+" "),_c('v-spacer'),(tag.shuffle_questions)?_c('v-icon',{staticClass:"green--text mr-1"},[_vm._v("mdi-shuffle-variant")]):_vm._e(),_vm._v(" "+_vm._s(tag.count)+"/ "+_vm._s(tag.total_questions)+" ")],1)],1)}),1)],1)}),_c('v-card',{staticClass:"mb-2"},[_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.doSubmit(false)}}},[_vm._v(_vm._s(_vm.$lang("GENERATE")))]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({
              name: 'dashboard-prep-mocktests',
            })}}},[_vm._v(_vm._s(_vm.$lang("CANCEL"))+" ")])],1)],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }