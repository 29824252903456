<template>
  <layout-center>
    <v-card class="mx-auto">
      <list-with-avatar
        idPrefix="MT"
        :items="items"
        @toggleActive="
          ({ clickedItem }) => {
            $store.dispatch('dashboard/prep/MocktestsToggleActive', {
              item: clickedItem,
            });
          }
        "
        @update="
          ({ clickedItem }) => {
            $router.push({
              name: 'dashboard-prep-mocktests-create',
              params: { id: clickedItem.id },
            });
          }
        "
        @clicked="
          ({ clickedItem }) => {
            $router.push({
              name: 'dashboard-prep-mocktests-segments',
              params: { id: clickedItem.id },
            });
          }
        "
      />
      <fab-button
        :icon="'mdi-plus'"
        color="primary"
        @clicked="$router.push({ name: 'dashboard-prep-mocktests-create' })"
      />
    </v-card>
  </layout-center>
</template>

<script>
import { mapGetters } from "vuex";
import FabButton from "@/components/FabButton.vue";
import ListWithAvatar from "../../modules/ListWithAvatar.vue";
import LayoutCenter from "@/layouts/LayoutCenter.vue";

export default {
  name: "dashboard-prep-mocktests",
  components: {
    FabButton,
    ListWithAvatar,
    LayoutCenter,
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
  },
  watch: {},
  methods: {},
  created() {
    this.$store.dispatch("dashboard/prep/MocktestsGetAll", { context: this });
  },
};
</script>
