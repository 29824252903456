import {
  Mocktests,
  MocktestsCreate,
  MocktestsGenerate,
  MocktestsSegments,
} from "../components";
import roles from "./roles";

export default [
  {
    path: "/dashboard/prep/mocktests",
    name: "dashboard-prep-mocktests",
    component: Mocktests,
    meta: {
      title: "MOCK-TESTS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/mocktests/create/:id?",
    name: "dashboard-prep-mocktests-create",
    component: MocktestsCreate,
    meta: {
      title: "CREATE-MOCKTESTS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/mocktests/generate",
    name: "dashboard-prep-mocktests-generate",
    component: MocktestsGenerate,
    meta: {
      title: "GENERATE-MOCKTESTS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/mocktests/:id/segments",
    name: "dashboard-prep-mocktests-segments",
    component: MocktestsSegments,
    meta: {
      title: "CREATE-MOCKTESTS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
];
