import { Notes, NotesCreate } from "../components";
import roles from './roles';

export default [
  {
    path: "/dashboard/prep/notes",
    name: "dashboard-prep-notes",
    component: Notes,
    meta: {
      title: "NOTES",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/notes/create/:id?",
    name: "dashboard-prep-notes-create",
    component: NotesCreate,
    meta: {
      title: "CREATE-NOTES",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
]