<template>
  <v-layout justify-center align-center>
    <v-flex md8 lg6>
      <v-card>
        <v-card-title>
          <div class="title">{{ $lang("DOUBTS") }}</div>
        </v-card-title>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "doubts",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
};
</script>


