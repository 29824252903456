<template >
  <v-btn icon color="">
    <v-icon>mdi-dots-vertical</v-icon>
    <span v-if="title">{{ title }}</span>
  </v-btn>
</template>

<script>
export default {
  name: "context-button",
  data() {
    return {
      to: null,
      icon: "mdi-gear",
      title: null
    }
  },
  created() {},
  methods: {
  }
};
</script>