import {
  Segments,
  SegmentsCreate,
  SegmentsQuestions,
  SegmentsQuestionsGrid,
  SegmentsQuestionsGrid2,
} from "../components";
import roles from "./roles";

export default [
  {
    path: "/dashboard/prep/segments",
    name: "dashboard-prep-segments",
    component: Segments,
    meta: {
      title: "SEGMENTS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/segments/create/:id?",
    name: "dashboard-prep-segments-create",
    component: SegmentsCreate,
    meta: {
      title: "CREATE-SEGMENT",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/segments/:id/questions",
    name: "dashboard-prep-segments-questions",
    component: SegmentsQuestions,
    meta: {
      title: "SEGMENTS-QUESTIONS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/segments/:id/questions/grid",
    name: "dashboard-prep-segments-questions-grid",
    component: SegmentsQuestionsGrid,
    meta: {
      title: "SEGMENTS-QUESTIONS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/segments/:id/questions/grid2",
    name: "dashboard-prep-segments-questions-grid2",
    component: SegmentsQuestionsGrid2,
    meta: {
      title: "SEGMENTS-QUESTIONS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
];
