export default {
  rowschema: {
    modified: false,
    uid: null,
    sn: null,
    title: "",
    title_hi: "",
    tags: "",
    is_latex: false,
    active: false,
    has_options: true,
  },

  colViewMode: "all",
  colViewModeItems: [
    { value: "all", text: "All" },
    { value: "hindi", text: "Hindi" },
    { value: "english", text: "English" },
    { value: "hindi-english", text: "Hindi English" },
    { value: "english-hindi", text: "English Hindi" },
    { value: "common", text: "Common Only" },
  ],
  tags: [],
  historyRows: [],
  rows: [],
  segment: {},
  selectedRowIndex: -1,
  selectedColIndex: -1,
  selectedRowField: "",
  richTextFields: [
    "title",
    "description",
    "explanation",
    "option_a",
    "option_c",
    "option_b",
    "option_d",

    "title_hi",
    "description_hi",
    "explanation_hi",
    "option_a_hi",
    "option_c_hi",
    "option_b_hi",
    "option_d_hi",
  ],
};
