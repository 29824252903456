<template>
  <v-expansion-panels v-if="items && items.length > 0" focusable>
    <v-expansion-panel class="mb-1">
      <v-expansion-panel-header
        expand-icon=""
        class="headline indigo accent-4 white--text"
      >
        <v-card-title class="pa-0 ma-0" dark>
          <div>
            {{ $lang("MYATTEMPTS") }}
            <div class="caption blue--text text--accent-1">
              {{
                $store.getters.getCurrLocale == "hi"
                  ? "इसे आपकी मेहनत का परिणाम कह सकते हैं।"
                  : "This would be the result of your hard work."
              }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-chip color="white indigo--text">{{ items.length }}</v-chip>
        </v-card-title>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list dense style="margin: 0 -1.5rem">
          <v-list-item
            @click="
              $router.push({
                name: 'exams-attempt-result',
                params: { id: item.data.id },
              })
            "
            v-for="(item, i) in items"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{
                  $store.getters.getCurrLocale == "hi"
                    ? item.data.title_hi
                      ? item.data.title_hi
                      : item.data.title
                    : item.data.title
                }}
              </v-list-item-title>
              <div>
                <span class="caption grey--text">{{
                  item.created_at | formatDateOnly
                }}</span>
                |
                <span class="caption grey--text mr-1">
                  {{ item.questions_correct }} ({{ item.questions_attempted }})
                  /
                  {{ item.questions_total }}
                </span>
              </div>
            </v-list-item-content>
            <v-list-item-action-text>
              {{ item.points_obtain }}/{{ item.points_total }}
            </v-list-item-action-text>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";

export default {
  name: "my-attempts",
  data() {
    return {
      items: [],
    };
  },
  methods: {
    fetchItems(page = 1) {
      bus.$emit("showWait");

      return axios
        .get("prep/attempts/attempted?withData=true&page=" + page)
        .then((res) => {
          this.items = res.data.data;
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    this.fetchItems();
  },
};
</script>

<style>
</style>