import Vue from "vue";

// Vue editable grid component and styles
import VueEditableGrid from "./VueEditableGrid.common.js";
import "./VueEditableGrid.css";
// import "./grid.css";

// register component in the Vue app
Vue.component("vue-editable-grid", VueEditableGrid);

export default VueEditableGrid;
