export default [
  {
    path: "/dashboard/grid/segments/:id/questions",
    name: "dashboard-grid-segment-questions",
    component: () => import("../components/Grid"),
    meta: {
      title: "GRID-QUESTIONS",
      needsAuth: true,
      verified: true,
      roles: ["admin", "superadmin", "manager", "moderator", "teacher"],
      guest: false,
    },
  },
];
