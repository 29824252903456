<template>
  <v-expansion-panels v-if="items && items.length > 0" focusable>
    <v-expansion-panel class="mb-1">
      <v-expansion-panel-header
        class="headline orange accent-4 white--text"
        expand-icon=""
      >
        <v-card-title tile class="pa-0 ma-0" dark>
          <div>
            {{ $lang("CONTINUEATTEMPTS") }}
            <div class="caption orange--text text--accent-1">
              {{
                $store.getters.getCurrLocale == "hi"
                  ? "आप वहीं से आगे बढ़ सकते हैं जहां से आपने उन्हें छोड़ा था।"
                  : "You may continue from where you left them."
              }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-chip color="white orange--text">{{ items.length }}</v-chip>
        </v-card-title>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list dense style="margin: 0 -1.5rem">
          <v-list-item
            @click="
              $router.push({
                name: 'exams-mocktest-preview',
                params: { id: item.id },
              })
            "
            v-for="(item, i) in items"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{
                  $store.getters.getCurrLocale == "hi"
                    ? item.title_hi
                      ? item.title_hi
                      : item.title
                    : item.title
                }}
              </v-list-item-title>
              <div>
                <span class="caption grey--text mr-1">{{
                  item.saved_at | formatDateOnly
                }}</span>
                |
                <span
                  class="caption grey--text ml-1"
                  v-if="item.elapsed && item.duration"
                  >{{ item.elapsed.toString().fancyTime() }} /
                  {{ item.duration.toString().fancyTime() }}</span
                >
              </div>
            </v-list-item-content>
            <v-list-item-action-text>
              0/{{ item.points_total }}
            </v-list-item-action-text>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { axios } from "@/plugins/axios";
import localforage from "@/plugins/localforage";
import bus from "@/bus";

export default {
  name: "continue-attempts",
  data() {
    return {};
  },
  computed: {
    items() {
      let items = this.$store.getters["app/exams/mocktests"];
      return items.filter((el) => el.elapsed && el.elapsed > 0);
    },
  },
  methods: {
    fetchItems(page = 1) {
      bus.$emit("showWait");
    },
  },
  created() {
    this.fetchItems();
  },
};
</script>

<style>
</style>