<template>
  <v-layout justify-center align-center>
    <v-flex md8 lg6>
      <form @submit.prevent="submit()">
        <v-card>
          <v-card-title>
            <div class="title">{{ $lang("AUTH:LOGIN:CARD_TITLE-TEXT") }}</div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              prepend-icon="mdi-email"
              :label="$lang('AUTH:LOGIN:INPUT_TEXT_EMAIL-LABEL')"
              :hint="$lang('AUTH:LOGIN:INPUT_TEXT_EMAIL-HINT')"
              v-model="user.email"
              type="email"
              :error-messages="errors && errors.email ? errors.email : []"
            ></v-text-field>

            <v-text-field
              prepend-icon="mdi-lock"
              :label="$lang('AUTH:LOGIN:INPUT_TEXT_PASSWORD-LABEL')"
              :hint="$lang('AUTH:LOGIN:INPUT_TEXT_PASSWORD-HINT')"
              v-model="user.password"
              type="password"
              :error-messages="errors && errors.password ? errors.password : []"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-layout column>
              <v-flex>
                <v-btn outlined type="submit">{{
                  $lang("AUTH:LOGIN:BUTTON_SUBMIT-TEXT")
                }}</v-btn>
                <v-btn text @click="$router.push({ name: 'register' })">{{
                  $lang("AUTH:LOGIN:BUTTON_REGISTER-TEXT")
                }}</v-btn>
              </v-flex>
              <v-flex class="text-xs-right">
                <v-divider class="mt-2 mb-2"></v-divider>
                <v-btn
                  text
                  small
                  @click="$router.push({ name: 'forgot-password' })"
                  >{{ $lang("AUTH:LOGIN:BUTTON_FORGOT_PASSWORD-TEXT") }}</v-btn
                >
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </form>
    </v-flex>
  </v-layout>
</template>

<script>
import bus from "@/bus";
import { isEmpty } from "lodash";
import { mapActions } from "vuex";
import localforage from "localforage";

export default {
  name: "register",
  components: {},
  data() {
    return {
      user: { email: "", password: "" },
      showSettings: false,
    };
  },
  computed: {
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    submit() {
      this.login({ user: this.user, context: this })
        .then(() => {
          this.afterSuccess();
        })
        .catch(() => {});
    },
    afterSuccess() {
      localforage
        .getItem("intended")
        .then((intended) => {
          if (isEmpty(intended)) {
            this.$router.replace({ name: "home" });
            return;
          }

          localforage.removeItem("intended");
          this.$router.replace(intended);
        })
        .catch(() => {
          this.$router.replace({ name: "home" });
        });
    },
  },
  created() {
    bus.$on("contextButtonClicked", () => {
      this.showSettings = !this.showSettings;
    });
  },
};
</script>


