import { Products } from "../components";

export default [
  {
    path: "/dashboard/products",
    name: "dashboard-products",
    component: Products,
    meta: {
      title: "PRODUCTS",
      needsAuth: true,
      verified: true,
      roles: ["admin", "superadmin"],
      guest: false,
    },
  },
];
