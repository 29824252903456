import exams from "./exams.routes";
import mocktests from "./mocktests.routes";
import questions from "./questions.routes";
import subjects from "./subjects.routes";
import tags from "./tags.routes";
import notes from "./notes.routes";
import jobs from "./jobs.routes";
import segments from "./segments.routes";
import attempts from "./attempts.routes";
import reports from "./reports.routes";

export default [
  ...exams,
  ...mocktests,
  ...questions,
  ...subjects,
  ...tags,
  ...notes,
  ...jobs,
  ...segments,
  ...attempts,
  ...reports,
];
