import { axios } from "@/plugins/axios";
import store from "@/store";
export const selectOptionsCorrectOptions = [
  { value: "--", text: "--" },
  { value: "A", text: "A" },
  { value: "B", text: "B" },
  { value: "C", text: "C" },
  { value: "D", text: "D" },
];

const selectOptionsToggle = [
  { value: "✔️", text: "✔️" },
  { value: "❌", text: "❌" },
];

const selectOptionsLang = [
  { value: "", text: "Bilingual" },
  { value: "en", text: "English" },
  { value: "hi", text: "Hindi" },
];

export let subjects = [];
let selectOptionsSubjects = [];
const fetchSubjects = () => {
  return axios.get(`prep/subjects`).then((res) => {
    subjects = res.data.data;
    selectOptionsSubjects = subjects.map((el) => {
      return {
        value: el.title,
        text: el.title,
        // id: el.id,
      };
    });
  });
};

// ..........................................

export const toggles = {
  1: "✔️",
  true: "✔️",
  0: "❌",
  false: "❌",
  null: "❌",
  "": "❌",
};

export const flags = {
  1: "★",
  true: "★",
  0: "",
  false: "",
  null: "",
  "": "",
};

export const options = {
  0: "--",
  false: "--",
  null: "--",
  "--": "--",
  "": "--",
  1: "A",
  2: "B",
  3: "C",
  4: "D",
};

export const ColumnDefs = async ({ colViewMode = "all" }) => {
  await fetchSubjects();

  const colsEnglish = [
    {
      sortable: false,
      filter: true,
      richText: true,
      field: "title",
      headerName: "Question",
      editable: true,
      // maxlength: 300,
      className: "grid-text",
      size: "300px", //size: "1fr",
    },
    {
      sortable: false,
      filter: true,
      richText: true,
      field: "description",
      headerName: "Description",
      editable: true,
      // maxlength: 300,
      className: "grid-text",
      size: "200px", //size: "1fr",
    },
    {
      sortable: false,
      // filter: true,
      richText: true,
      field: "option_a",
      headerName: "Option A",
      editable: true,
      // maxlength: 300,
      className: "grid-text",
      size: "100px", //size: ".4fr",
    },
    {
      sortable: false,
      // filter: true,
      richText: true,
      field: "option_b",
      headerName: "Option B",
      editable: true,
      // maxlength: 300,
      className: "grid-text",
      size: "100px", //size: ".4fr",
    },
    {
      sortable: false,
      // filter: true,
      richText: true,
      field: "option_c",
      headerName: "Option C",
      editable: true,
      // maxlength: 300,
      className: "grid-text",
      size: "100px", //size: ".4fr",
    },
    {
      sortable: false,
      // filter: true,
      richText: true,
      field: "option_d",
      headerName: "Option D",
      editable: true,
      // maxlength: 300,
      className: "grid-text",
      size: "100px", //size: ".4fr",
    },
    {
      sortable: false,
      // filter: true,
      richText: true,
      field: "explanation",
      headerName: "Explanation",
      editable: true,
      // maxlength: 300,
      className: "grid-text",
      size: "200px", //size: ".4fr",
    },
  ];

  const colsHindi = [
    {
      sortable: false,
      filter: true,
      richText: true,
      field: "title_hi",
      headerName: "प्रश्न",
      editable: true,
      // maxlength: 300,
      className: "grid-text",
      size: "300px", //size: "1fr",
    },
    {
      sortable: false,
      filter: true,
      richText: true,
      field: "description_hi",
      headerName: "विवरण",
      editable: true,
      // maxlength: 300,
      className: "grid-text",
      size: "200px", //size: "1fr",
    },
    {
      sortable: false,
      // filter: true,
      richText: true,
      field: "option_a_hi",
      headerName: "विकल्प A",
      editable: true,
      // maxlength: 300,
      className: "grid-text",
      size: "100px", //size: ".4fr",
    },
    {
      sortable: false,
      // filter: true,
      richText: true,
      field: "option_b_hi",
      headerName: "विकल्प B",
      editable: true,
      // maxlength: 300,
      className: "grid-text",
      size: "100px", //size: ".4fr",
    },
    {
      sortable: false,
      // filter: true,
      richText: true,
      field: "option_c_hi",
      headerName: "विकल्प C",
      editable: true,
      // maxlength: 300,
      className: "grid-text",
      size: "100px", //size: ".4fr",
    },
    {
      sortable: false,
      // filter: true,
      richText: true,
      field: "option_d_hi",
      headerName: "विकल्प D",
      editable: true,
      // maxlength: 300,
      className: "grid-text",
      size: "100px", //size: ".4fr",
    },

    {
      sortable: false,
      // filter: true,
      richText: true,
      field: "explanation_hi",
      headerName: "व्याख्या",
      editable: true,
      // maxlength: 300,
      className: "grid-text",
      size: "200px", //size: ".4fr",
    },
  ];

  const colsCommon = [
    {
      sortable: false,
      // filter: true,
      field: "toggle_active",
      headerName: "👁",
      editable: true,
      className: "grid-select",
      size: "50px", //size: ".1fr",
      type: "select",
      selectOptions: selectOptionsToggle,
    },
    {
      sortable: false,
      // filter: true,
      field: "toggle_has_options",
      headerName: "⊙",
      editable: true,
      className: "grid-select",
      size: "50px", //size: ".1fr",
      type: "select",
      selectOptions: selectOptionsToggle,
    },

    {
      sortable: false,
      // filter: true,
      field: "language",
      headerName: "Lang",
      editable: true,
      // maxlength: 300,
      className: "grid-select",
      size: "70px", //size: ".1fr",
      type: "select",
      selectOptions: selectOptionsLang,
    },
  ];

  const colCorrectOption = {
    sortable: false,
    // filter: true,
    field: "select_correct_option",
    headerName: "✅",
    editable: true,
    // maxlength: 300,
    className: "grid-select",
    size: "50px", //size: ".1fr",
    type: "select",
    selectOptions: selectOptionsCorrectOptions,
  };

  const colsCommonAll = [
    {
      sortable: false,
      // filter: true,
      field: "toggle_is_latex",
      headerName: "∑",
      editable: true,
      className: "grid-select",
      size: "50px", //size: ".1fr",
      type: "select",
      selectOptions: selectOptionsToggle,
    },
    {
      sortable: false,
      // filter: true,
      field: "select_prep_subject_id",
      headerName: "Subject",
      editable: true,
      // maxlength: 300,
      className: "grid-subject",
      size: "120px", //size: ".1fr",
      type: "select",
      selectOptions: selectOptionsSubjects,
    },
    {
      sortable: false,
      // filter: true,
      field: "tags",
      headerName: "Tags",
      editable: false,
      // maxlength: 300,
      className: "grid-tags",
      size: "100px", //size: ".1fr",
      type: "text",
    },
  ];

  let defs = [];
  defs.push(
    {
      sortable: false,
      filter: false,
      field: "toggle_modified",
      headerName: "★",
      type: "text",
      editable: false,
      className: "grid-modified",
      size: "50px",
    },
    {
      sortable: false,
      filter: false,
      field: "sn",
      headerName: "#",
      type: "numeric",
      editable: false,
      className: "grid-sn",
      size: "50px",
    }
  );

  switch (colViewMode) {
    case "common":
      defs.push(...colsCommon);
      break;
    case "hindi":
      defs.push(...colsHindi);
      break;
    case "english":
      defs.push(...colsEnglish);
      break;
    case "hindi-english":
      defs.push(...colsHindi);
      defs.push(...colsEnglish);
      break;
    case "english-hindi":
      defs.push(...colsEnglish);
      defs.push(...colsHindi);
      break;
    default:
      defs.push(...colsCommon);
      defs.push(...colsEnglish);
      defs.push(...colsHindi);
      break;
  }

  switch (colViewMode) {
    case "common":
      defs.splice(
        defs.findIndex((el) => el.field === "language"),
        0,
        ...colsCommonAll
      );
      break;
    case "hindi":
      defs.splice(
        defs.findIndex((el) => el.field === "title_hi"),
        0,
        ...colsCommonAll
      );
      defs.splice(
        defs.findIndex((el) => el.field === "explanation_hi"),
        0,
        colCorrectOption
      );
      break;
    case "english":
      defs.splice(
        defs.findIndex((el) => el.field === "title"),
        0,
        ...colsCommonAll
      );
      defs.splice(
        defs.findIndex((el) => el.field === "explanation"),
        0,
        colCorrectOption
      );
      break;
    case "hindi-english":
      defs.splice(
        defs.findIndex((el) => el.field === "title_hi"),
        0,
        ...colsCommonAll
      );
      defs.splice(
        defs.findIndex((el) => el.field === "explanation_hi"),
        0,
        colCorrectOption
      );
      break;
    case "english-hindi":
      defs.splice(
        defs.findIndex((el) => el.field === "title"),
        0,
        ...colsCommonAll
      );
      defs.splice(
        defs.findIndex((el) => el.field === "explanation"),
        0,
        colCorrectOption
      );
      break;
    default:
      defs.splice(
        defs.findIndex((el) => el.field === "language"),
        0,
        ...colsCommonAll
      );
      defs.splice(
        defs.findIndex((el) => el.field === "explanation"),
        0,
        colCorrectOption
      );
      break;
  }

  return defs;
};
