import _axios from "axios";
import localforage from "localforage";
import { isEmpty } from "lodash";
import store from "@/store";

export const axios = _axios.create({
  baseURL:
    window.location && window.location.host == "localhost:8080"
      ? "http://localhost:2030" //"https://api.vikarn.com" //"http://localhost:2030"
      : "https://api.vikarn.com",
  headers: {
    "Content-Type": "application/json",
    Accept: "Token",
  },
  params: {
    locale: JSON.parse(localStorage.getItem("currLocale")),
  },
});

// set auth token if there is a token in localstorage
axios.interceptors.request.use(
  function(config) {
    return localforage
      .getItem("authtoken")
      .then((authtoken) => {
        // perhaps here is the place where we can ask api if the token is valid or not
        // sync token, it valid set it or remove it otherwise
        config.headers.common["Authorization"] = "Bearer " + authtoken;
        return config;
      })
      .catch((err) => {
        console.log(err);
        return config;
      });
  },
  function(error) {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(successRes) {
    if (successRes.data && successRes.data.message) {
      store.dispatch(
        "apiAlert",
        { message: successRes.data.message, code: successRes.status },
        { root: true }
      );
    }
    // clear form errors everytime a successful api request pass through
    store.dispatch("setFormErrors", { errors: [] }, { root: true });
    return successRes;
  },
  function(error) {
    if (error.response && error.response.data && error.response.data.message) {
      store.dispatch(
        "apiAlert",
        { message: error.response.data.message, code: error.response.status },
        { root: true }
      );
    }

    if (error.response && error.response.data && error.response.data.errors) {
      store.dispatch(
        "setFormErrors",
        { errors: error.response.data.errors },
        { root: true }
      );
    } else {
      // clear form errors if there are no errors
      store.dispatch("setFormErrors", { errors: [] }, { root: true });
    }
    return Promise.reject(error);
  }
);

// set token after login success or remove if expired
export const setHttpToken = (token) => {
  return axios.interceptors.request.use(
    function(config) {
      if (isEmpty(token)) {
        config.headers.common["Authorization"] = null;
      } else {
        config.headers.common["Authorization"] = "Bearer " + token;
      }
      return Promise.resolve(config);
    },
    function(error) {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
