<template>
  <layout-center>
    <form>
      <v-card class="mb-2">
        <v-card-title>
          <div class="title">
            {{ $lang("GENERATE") + " " + $lang("MOCKTEST") }}
          </div>
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>
      <v-card class="mb-2">
        <v-card-text>
          <v-text-field
            :label="$lang('TITLE')"
            :hint="$lang('TITLE-HINT')"
            v-model="schema.title"
            type="text"
          ></v-text-field>

          <vue-tip
            variant="none"
            :initial="schema.description"
            :placeholder="$lang('DESCRIPTION')"
            @changed="
              (val) => {
                schema.description = val;
              }
            "
          />
          <selector-points :item="schema" />
          <selector-language
            :select="schema.language"
            @selected="(abbr) => (schema.language = abbr)"
          />
          <v-checkbox
            v-model="schema.shuffle_questions"
            :label="`Shuffle questions for whole mocktest.`"
            class="mb-3"
          ></v-checkbox>
          <selector-time
            :seconds="schema.duration"
            @changed="(val) => (this.schema.duration = val)"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <exams-list
            :initial="schema.exam_id"
            @selected="
              (data) => {
                exams = data;
              }
            "
          />
        </v-card-text>
        <v-divider></v-divider>
        <counter
          :value="segment_count"
          label="Segments"
          @changed="(val) => (segment_count = val)"
        />
      </v-card>
      <v-card
        class="mb-2"
        v-for="(val, idx) in parseInt(segment_count)"
        :key="idx"
      >
        <v-card-text>
          <v-text-field
            :label="$lang('SEGMENT') + ' ' + val + ' ' + $lang('TITLE')"
            v-model="schema.segments[idx].title"
            type="text"
          ></v-text-field>
          <vue-tip
            variant="none"
            :initial="schema.segments[idx].description"
            :placeholder="$lang('DESCRIPTION')"
            @changed="
              (val) => {
                schema.segments[idx].description = val;
              }
            "
          />
          <v-checkbox
            v-model="schema.segments[idx].shuffle_questions"
            :label="`Shuffle questions for this segment.`"
            class="mb-3"
          ></v-checkbox>
          <selector-time
            :seconds="schema.segments[idx].duration"
            @changed="(val) => (schema.segments[idx].duration = val)"
          />
        </v-card-text>
        <v-card-text>
          <add-segment-tags
            v-if="tags"
            @selected="
              (tag) => {
                addTagToSegment(idx, tag);
              }
            "
            :tags="tags"
          />
        </v-card-text>
        <v-card-text>
          <v-card
            outlined
            class="mb-2"
            dark
            v-for="(tag, i) in schema.segments[idx].tags"
            :key="i"
          >
            <v-card-actions>
              <v-btn
                @click="removeTagfromSegment(idx, tag)"
                icon
                x-small
                class="mr-2 red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>

              {{ tag.title }}
              <v-spacer></v-spacer>
              <v-icon class="green--text mr-1" v-if="tag.shuffle_questions"
                >mdi-shuffle-variant</v-icon
              >
              {{ tag.count }}/
              {{ tag.total_questions }}
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>

      <v-card class="mb-2">
        <v-card-actions>
          <v-btn outlined @click="doSubmit(false)">{{
            $lang("GENERATE")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              $router.push({
                name: 'dashboard-prep-mocktests',
              })
            "
            >{{ $lang("CANCEL") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </layout-center>
</template>

<script>
import bus from "@/bus";
import { axios } from "@/plugins/axios";
import { mapGetters } from "vuex";
import VueTip from "@/components/VueTip";
import LayoutCenter from "@/layouts/LayoutCenter.vue";
import SelectorLanguage from "../../modules/SelectorLanguage.vue";
import SelectorTime from "../../modules/SelectorTime.vue";
import SelectorPoints from "../../modules/SelectorPoints.vue";
import Counter from "./Generate/Counter";
import AddSegmentTags from "./Generate/AddSegmentTags.vue";
import ExamsList from "./Generate/ExamsList.vue";
// import SelectorExams from "../modules/SelectorExams.vue";
// <selector-exams v-if="item.id" :item="item" />
export default {
  name: "dashboard-prep-mocktests-generate",
  components: {
    VueTip,
    LayoutCenter,
    SelectorLanguage,
    // SelectorExams,
    SelectorTime,
    SelectorPoints,
    Counter,
    AddSegmentTags,
    ExamsList,
  },
  data() {
    return {
      exams: [],
      tags: [],
      segment_count: 0,
      schema: {
        title: null,
        description: null,
        exam_id: null,
        duration: 0,
        points_correct: 2,
        points_incorrect: -0.5,
        points_unattempted: 0,
        segments: [
          {
            index: 0,
            title: "Segment 1",
            tags: [],
            duration: 300,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    log: console.log,
    removeTagfromSegment(idx, tag) {
      this.schema.segments[idx].tags = this.schema.segments[idx].tags.filter(
        (el) => el.id != tag.id
      );
    },
    addTagToSegment(idx, tag) {
      if (this.schema.segments[idx].tags.find((el) => el.id == tag.id)) {
        return;
      }
      this.schema.segments[idx].tags.push(tag);
    },
    doSubmit() {
      bus.$emit("showWait");
      return axios
        .post("prep/generate/mocktest", { schema: this.schema })
        .then((res) => {
          let mocktest = res.data.mocktest;
          this.$router.push({
            name: "dashboard-prep-mocktests-segments",
            params: {
              id: mocktest.id,
            },
          });
        })
        .catch((err) => {})
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    fetchTags() {
      bus.$emit("showWait");
      return axios
        .get("prep/tags?orderby=title|asc")
        .then((res) => {
          this.tags = res.data.data;
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    generateTagSkeleton(count = 1, idx) {
      let segment = this.schema.segments[idx];
      segment.tag_count = count;

      if (segment.tag_count < segment.tags.length) {
        segment.tags = segment.tags.slice(0, segment.tag_count);
      }

      for (let i = 0; i < segment.tag_count; i++) {
        if (!segment.tags.find((el) => el.index == i)) {
          segment.tags.push({
            index: i,
          });
        }
      }
      this.schema.segments[idx] = segment;
    },
    generateSegmentSkeleton(count = 1) {
      if (count < this.schema.segments.length) {
        this.schema.segments = this.schema.segments.slice(0, count);
      }
      for (let i = 0; i < count; i++) {
        if (!this.schema.segments.find((el) => el.index == i)) {
          this.schema.segments.push({
            title: null,
            description: null,
            index: i,
            tags: [],
            duration: 300,
          });
        }
      }
    },
  },
  watch: {
    exams(val) {
      console.log(val);
      if (!val) {
        val = [];
      }
      let ids = val.map((el) => el.id);
      this.schema.exam_id = ids;
    },
    segment_count(val) {
      this.generateSegmentSkeleton(val);
    },
  },
  created() {
    this.fetchTags();
    this.generateSegmentSkeleton(this.segment_count);
  },
};
</script>
