import Vue from "vue";

import _Users from "./Users.vue";
import _User from "./User.vue";
import _UsersCreate from "./UsersCreate.vue";

/* ################ ################ */

export const Users = Vue.component("dashboard-auth-users", _Users);
export const User = Vue.component("dashboard-auth-users", _User);
export const UsersCreate = Vue.component(
  "dashboard-auth-users-create",
  _UsersCreate
);
