import { Payments, TopupWallet } from "../components";

export default [
  {
    path: "/dashboard/topup/wallet",
    name: "dashboard-topup-wallet",
    component: TopupWallet,
    meta: {
      title: "Topup Wallet",
      needsAuth: true,
      verified: true,
      roles: ["admin", "superadmin"],
      guest: false,
    },
  },
  {
    path: "/dashboard/payments",
    name: "dashboard-payments",
    component: Payments,
    meta: {
      title: "ORDERS",
      needsAuth: true,
      verified: true,
      roles: ["admin", "superadmin"],
      guest: false,
    },
  },
];
