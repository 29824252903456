<template>
  <div v-if="items && items.length > 0">
    <v-sparkline
      v-if="grouped && grouped.length > 0"
      class="mt-2"
      :labels="labels"
      :value="values"
      :gradient="gradient"
      :smooth="radius || false"
      :padding="padding"
      :line-width="width"
      :stroke-linecap="lineCap"
      :gradient-direction="gradientDirection"
      :fill="fill"
      :type="inner_type"
      :auto-line-width="!range_total || range_total > 14 ? false : true"
      auto-draw
      :auto-draw-duration="1"
    >
      <template v-slot:label="item">
        {{
          show_value
            ? !range_total || range_total > 14
              ? ""
              : item.value.split("|")[1] + " |"
            : ""
        }}
        {{ !range_total || range_total > 14 ? "" : item.value.split("|")[0] }}
      </template>
    </v-sparkline>

    <v-range-slider
      v-if="range_value"
      :min="0"
      :max="all_grouped_length"
      v-model="range_value"
      step="1"
      hint="Minimum and Maximum Range."
      tick
    >
      <template v-slot:prepend>
        <v-icon class="mr-1" @click="toggleType">{{
          inner_type == "bar" ? "mdi-chart-line" : "mdi-chart-bar"
        }}</v-icon>
      </template>
      <template v-slot:append>
        <v-icon class="mr-l" @click="show_value = !show_value">{{
          show_value ? "mdi-eye" : "mdi-eye-off"
        }}</v-icon>
      </template>
    </v-range-slider>
    <v-card-actions class="ma-0 pa-0"
      >Avg Selection: {{ value_average }}
      <v-spacer></v-spacer>
      Avg Overall:
      {{ value_average_all }}</v-card-actions
    >
  </div>
</template>
<script>
const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"],
];
import moment from "@/plugins/moment";
export default {
  props: [
    "items",
    "limit",
    "type",
    "format",
    "field",
    "label_format",
    "sort_callback",
  ],
  data() {
    return {
      width: 2,
      radius: 3,
      padding: 18,
      lineCap: "round",
      gradient: gradients[5],
      value: [],
      gradientDirection: "top",
      gradients,
      fill: false,
      autoLineWidth: true,

      date_format: "DD MMM YYYY",
      filter_field: "created_at",
      private_range_value: null,
      private_range_old: null,
      inner_type: "trend",
      show_value: false,
    };
  },
  watch: {
    limit() {
      this.range_value[0] = this.all_grouped_length - this.limit;
      this.range_value[1] = this.all_grouped_length;
    },
    format() {
      if (this.format) {
        this.date_format = this.format;
      }
    },
    field() {
      if (this.field) {
        this.filter_field = this.field;
      }
    },
    items() {},
    type() {
      if (this.type) {
        this.inner_type = this.type;
      }
    },
  },
  computed: {
    range_value: {
      get() {
        let val = [];
        if (this.private_range_value && this.private_range_value.length == 2) {
          val[0] = this.private_range_value[0];
          val[1] = this.private_range_value[1];
        } else {
          val[0] = this.all_grouped_length - this.limit;
          val[1] = this.all_grouped_length;
        }
        return val;
      },
      set(val) {
        this.private_range_value = val;
      },
    },
    grouped_length() {
      return this.grouped.length;
    },
    all_grouped_length() {
      return this.all_grouped.length;
    },

    all_values() {
      return this.all_grouped.map((gp) => {
        return gp.val ? gp.val.length : 0;
      });
    },
    all_grouped() {
      let grouped = [];
      console.log("this.date_format", this.date_format);
      this.items.forEach((el) => {
        const filter_field = el[this.filter_field];
        const key = moment(filter_field).format(this.date_format);
        if (grouped.find((grp) => grp.key == key)) {
          grouped.find((grp) => grp.key == key).val.push(el.id);
        } else {
          grouped.push({ key, val: [el.id], key_raw: filter_field });
        }
      });

      if (this.sort_callback) {
        grouped = this.sort_callback(grouped);
      } else {
        grouped.reverse();
      }

      return grouped;
    },
    all_labels() {
      let labels = [];
      this.all_grouped.map(({ key, val, key_raw }) => {
        let frmt = this.label_format ? this.label_format : "DD MMM";
        let lbl = moment(key_raw).format(frmt);
        labels.push(lbl + "|" + val.length);
      });
      return labels;
    },

    value_average_all() {
      return Math.round(
        this.all_values.reduce((p, c) => p + c, 0) / this.all_values.length
      );
    },
    value_average() {
      return Math.round(
        this.values.reduce((p, c) => p + c, 0) / this.values.length
      );
    },

    grouped() {
      return this.all_grouped.slice(this.range_value[0], this.range_value[1]);
    },
    values() {
      return this.all_values.slice(this.range_value[0], this.range_value[1]);
    },
    labels() {
      return this.all_labels.slice(this.range_value[0], this.range_value[1]);
    },
    range_total() {
      return this.range_value[1] - this.range_value[0];
    },
  },
  methods: {
    toggleType() {
      if (this.inner_type == "bar") {
        this.inner_type = "trend";
      } else {
        this.inner_type = "bar";
      }
    },
    generateValue() {},
  },

  created() {
    if (this.field) {
      this.filter_field = this.field;
    }
    if (this.type) {
      this.inner_type = this.type;
    }
    if (this.format) {
      this.date_format = this.format;
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.limit && this.all_grouped_length) {
        this.range_value[0] = this.all_grouped_length - this.limit;
        this.range_value[1] = this.all_grouped_length;
      }
      // console.log("this.limit", this.limit);
      // console.log("this.all_grouped_length", this.all_grouped_length);
      // console.log(this.range_value[0], this.range_value[1]);
    }, 1000);
  },
};
</script>

<style>
/* .v-sheet--offset {
  top: -12px;
  position: relative;
} */
</style>