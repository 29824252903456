<template>
  <div id="uploader" v-if="show">
    <div ref="area">
      <img v-if="data" :src="data" />
      <img v-if="url && !data" :src="url" />
      <span v-if="!data && !url">CLick to Upload/ paste here</span>
      <span v-if="!data && url">Uploaded to server</span>
      <span v-if="data && !url">Click Upload Button</span>
    </div>
    <input
      name="upload-input"
      type="file"
      id="upload-input"
      style="display: none"
      @change="updatePreview"
    />
    <v-btn @click="doUpload" style="z-index: 1; position: fixed">
      <v-icon>mdi-upload</v-icon>
      {{ $lang("Upload") }}
    </v-btn>
  </div>
</template>

<script>
import UploadBase64 from "../helpers/UploadBase64.js";
export default {
  props: ["identity"],
  data() {
    return {
      loading: false,
      data: null,
      url: null,
      show: true,
    };
  },
  components: {},
  mounted() {
    this.$refs.area.onpaste = this.onPaste;
    this.$refs.area.onclick = this.onClick;
  },
  created() {
    this.show = true;
  },
  methods: {
    blobToDataURL(blob, callback) {
      if (!blob) return;

      var a = new FileReader();
      a.onload = function (e) {
        callback(e.target.result);
      };
      a.readAsDataURL(blob);
    },
    openUpload() {
      document.getElementById("upload-input").click();
    },
    onClick() {
      if (this.data || this.url) {
        this.url = null;
        this.data = null;
      } else {
        this.openUpload();
      }
    },
    updatePreview(e) {
      var files = e.target.files;
      if (files.length === 0) {
        console.log("no file selected");
      }

      this.blobToDataURL(files[0], (res) => {
        console.log(res);
        this.data = res;
      });
    },
    onPaste(e) {
      let file = e.clipboardData.items[0].getAsFile();
      if (file) {
        e.preventDefault();
        e.stopPropagation();
        this.blobToDataURL(file, (res) => {
          this.url = null;
          this.data = res;
        });
      }
    },

    doUpload() {
      if (!this.data) {
        console.log("Choose an image to upload.");
        return;
      }
      this.loading = true;
      return UploadBase64(this.data, "media/upload", {
        identity: this.identity ? this.identity : "upload-area",
      })
        .then((res) => {
          this.$emit("select-file", {
            src: res.url,
            alt: "",
          });
          this.url = res.url;
          this.data = null;
          this.show = false;
        })
        .catch((err) => {
          console.error(err);
          this.url = null;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
#uploader {
  margin: 0 auto;
  cursor: pointer;
  width: 300px;
  height: 200px;
  overflow: hidden;
  background: grey;
  border: 1px solid darkgrey;
}
#uploader div {
  width: 100%;
  height: 60%;
}
#uploader:hover {
  background: darkgrey;
}
#uploader div img {
  width: 100%;
  margin: auto;
}
#uploader div span {
  width: 100%;
  margin: 0 auto;
}
</style>