<template>
  <v-select
    v-model="role"
    :items="roles"
    item-text="title"
    item-value="abbr"
    :label="$lang('ROLE')"
    clearable
  />
</template>

<script>
export default {
  name: "selector-role",
  props: ["select"],
  data() {
    return {
      roles: [
        { abbr: "member", title: "Member" },
        { abbr: "admin", title: "Admin" },
        { abbr: "manager", title: "Manager" },
        { abbr: "moderator", title: "Moderator" },
        { abbr: "teacher", title: "Teacher" },
        { abbr: "parent", title: "Parent" },
        { abbr: "student", title: "Student" },
      ],
      role: {},
    };
  },
  watch: {
    role(abbr) {
      console.log("abbr", abbr);
      this.$emit("selected", abbr);
    },
    select(abbr) {
      let lang = this.roles.filter((el) => el.abbr == abbr);
      if (lang.length > 0) {
        this.role = lang[0].abbr;
      }
    },
  },
  created() {
    let lang = this.roles.filter((el) => el.abbr == this.select);
    if (lang.length > 0) {
      this.role = lang[0].abbr;
    }
  },
};
</script>
