<template>
  <v-row justify="center">
    <v-col class="col-6">
      <h2>{{ $lang("POINTS") }}</h2>
    </v-col>
    <v-col class="col-2">
      <v-text-field
        type="number"
        v-model="item.points_correct"
        :label="$lang('CORRECT')"
      />
    </v-col>
    <v-col class="col-2">
      <v-text-field
        type="number"
        v-model="item.points_incorrect"
        :label="$lang('INCORRECT')"
      />
    </v-col>
    <v-col class="col-2">
      <v-text-field
        type="number"
        v-model="item.points_unattempted"
        :label="$lang('UNATTEMPTED')"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "selector-points",
  props: ["item"],
  data() {
    return {};
  },
  watch: {},
  methods: {},
  created() {
    if (this.item) {
      if (!this.item.points_correct) {
        this.item.points_correct = 1;
      }
      if (!this.item.points_incorrect) {
        this.item.points_incorrect = 0;
      }
      if (!this.item.points_unattempted) {
        this.item.points_unattempted = 0;
      }
    }
  },
};
</script>