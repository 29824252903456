import Vue from "vue";
import Router from "vue-router";
import beforeEach from "./beforeEach";
import { routes } from "@/app/index";
import patchRouterMethod from "./patchRouterMethod";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: `/`,
  scrollBehavior: () => ({ y: 0 }),
  routes: routes,
});

router.beforeEach(beforeEach);

patchRouterMethod(router, "push");
patchRouterMethod(router, "replace");

export default router;
