<template>
  <v-row>
    <v-col cols="6" md="4" v-for="(val, key) in item" :key="key">
      <v-card class="justify-center align-center">
        <v-card-text class="overline">
          {{ $lang(key) }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          {{ val }}
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";
export default {
  data() {
    return {
      item: null,
    };
  },
  methods: {
    fetchItem() {
      bus.$emit("showWait");
      return axios
        .get(`/prep/reports/${this.$route.params.id}`)
        .then((res) => {
          this.item = res.data;
          this.item.data = "check method fetchItem";
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    this.fetchItem();
  },
};
</script>

<style>
</style>