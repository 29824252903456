export default [
  {
    path: "/dashboard/coupons",
    name: "dashboard-coupons",
    component: () => import("../components/Coupons"),
    meta: {
      title: "COUPONS",
      needsAuth: true,
      verified: true,
      roles: ["admin", "superadmin"],
      guest: false,
    },
  },
  {
    path: "/dashboard/coupons/create/:id?",
    name: "dashboard-coupons-create",
    component: () => import("../components/CouponsCreate"),
    meta: {
      title: "CREATECOUPONS",
      needsAuth: true,
      verified: true,
      roles: ["admin", "superadmin"],
      guest: false,
    },
  },
];
