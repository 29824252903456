export default {
  "DASHBOARD:TESTS:TITLE-TEXT": "Tests.",
  "NEW-EXAM": "नया इग्ज़ैम",
  "NEW-QUESTION": "नया सवाल",
  "NEW-MOCKTEST": "नया मॉक-टेस्ट",
  "NEW-SUBJECT": "नया विषय",
  "NEW-TAG": "नया टैग",

  "UPDATE-EXAM": "अपडेट इग्ज़ैम",
  "UPDATE-QUESTION": "अपडेट सवाल",
  "UPDATE-MOCKTEST": "अपडेट मॉक-टेस्ट",
  "UPDATE-SUBJECT": "अपडेट विषय",
  "UPDATE-TAG": "अपडेट टैग",
  ATTEMPTS: "प्रयास",
};
