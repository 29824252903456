<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-card style="height: 100%" class="d-flex justify-center align-center">
        <v-card-text>
          <notify-all />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="8">
      <search-card @clicked="(term) => getItems(term)" />
    </v-col>

    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="pagination.total"
        :loading="loading"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:item.role="{ item }">
          <v-chip outlined label x-small :color="getColor(item.role)" dark>
            {{ item.role.toUpperCase() }}
          </v-chip>
        </template>

        <template v-slot:item.referee_uid="{ item }">
          <v-chip outlined label x-small dark>
            {{ item.referee_uid ? item.referee_uid.toUpperCase() : "N/A" }}
          </v-chip>
        </template>

        <template v-slot:item.wallet="{ item }">
          <span>
            {{ item.wallet ? "🌕" + item.wallet.vcoin : "" }}
          </span>
          <span>{{ item.wallet ? "🌚" + item.wallet.gcoin : "" }} </span>
          <top-up-wallet
            v-if="
              authUser &&
              ['admin', 'superadmin'].includes(authUser.role.toLowerCase())
            "
            :user="item"
          />
        </template>

        <template v-slot:item.actions="{ item }">
          <notify-user :user="item" />
          <v-btn
            class="mx-1"
            small
            icon
            :color="item.banned ? 'red' : ''"
            @click="
              $store.dispatch('dashboard/auth/usersToggleBan', {
                item,
              })
            "
          >
            <v-icon>{{ item.banned ? "mdi-lock" : "mdi-lock-open" }}</v-icon>
          </v-btn>
          <v-btn
            class="mx-1"
            small
            icon
            @click="
              $router.push({
                name: 'dashboard-auth-users-create',
                params: { id: item.id },
              })
            "
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class="mx-1"
            small
            icon
            @click="
              $store.dispatch('dashboard/auth/usersRemove', {
                item,
              })
            "
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.email="{ item }">
          {{ item.display_name }}
          <br v-if="item.mobile" />
          {{ item.mobile }}
          <br v-if="item.email" />
          {{ item.email }}
        </template>

        <template v-slot:item.last_logged_in="{ item }">
          <span v-if="item.last_logged_in">
            {{ item.last_logged_in | formatDate }}
          </span>
          <span v-else>{{ "--" }}</span>
          <!-- <span v-else>{{ item.created_at | formatDate }}</span> -->
        </template>

        <template v-slot:item.verified="{ item }">
          <v-btn
            @click.stop="toggleVerify(item)"
            icon
            :color="item.verified ? 'green' : 'red'"
          >
            <v-icon>{{
              item.verified
                ? "mdi-checkbox-marked"
                : "mdi-checkbox-blank-outline"
            }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12">
      <v-pagination
        v-if="pagination.lastPage > 1"
        v-model="pagination.page"
        :length="pagination.lastPage"
      ></v-pagination>
    </v-col>

    <fab-button
      :icon="'mdi-plus'"
      color="primary"
      @clicked="$router.push({ name: 'dashboard-auth-users-create' })"
    />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import FabButton from "@/components/FabButton.vue";
import SearchCard from "../../prep/modules/SearchCard";
import TopUpWallet from "../modules/TopUpWallet.vue";
import NotifyUser from "../modules/NotifyUser.vue";
import NotifyAll from "../modules/NotifyAll.vue";
export default {
  name: "dashboard-auth-users",
  components: {
    FabButton,
    SearchCard,
    NotifyAll,
    NotifyUser,
    TopUpWallet,
  },
  data() {
    return {
      items: [],
      options: {},
      where: "",
      loading: false,
      headers: [
        { text: "Verifed", value: "verified", sortable: true },
        { text: "Ref", value: "referee_uid", sortable: true },
        { text: "Wallet", value: "wallet", sortable: false },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
        },
        { text: "Role", value: "role" },
        { text: "Last Login", value: "last_logged_in", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pagination: {
        lastPage: null,
        page: 1,
        perPage: null,
        total: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      authUser: "auth/user",
    }),
  },
  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
    "pagination.page"(page) {
      if (page > 0) {
        this.getItems();
      }
    },
  },
  methods: {
    toggleVerify(item) {
      return this.$store.dispatch("dashboard/auth/usersToggleVerify", {
        item,
      });
    },
    getColor(role) {
      switch (role) {
        case "student":
          return "blue";
          break;

        case "teacher":
          return "orange";
          break;

        case "admin" || "superadmin":
          return "green";
          break;

        default:
          return "muted";
          break;
      }
    },
    clicked(clickedItem) {
      this.$router.push({
        name: "dashboard-auth-users-create",
        params: { id: clickedItem.id },
      });
    },
    getItems(term = "") {
      this.where = term;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const ascDesc = sortDesc && sortDesc[0] ? "desc" : "asc";
      this.loading = true;
      return this.$store
        .dispatch("dashboard/auth/getUsers", {
          context: this,
          page: this.pagination.page,
          perPage: this.pagination.perPage,
          orderby: sortBy && sortBy.length > 0 ? `${sortBy[0]}|${ascDesc}` : ``,
          where: this.where,
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getItems();
  },
};
</script>
