<template>
  <v-card-text>
    <v-text-field
      label="English Title"
      v-model="item.title"
      type="text"
      :error-messages="errors && errors.title ? errors.title : []"
    ></v-text-field>

    <v-text-field
      label="हिन्दी शीर्षक"
      v-model="item.title_hi"
      type="text"
      :error-messages="errors && errors.title_hi ? errors.title_hi : []"
    ></v-text-field>

    <vue-tip
      :variant="useVariant"
      :initial="item.description"
      @changed="
        (val) => {
          item.description = val;
        }
      "
      placeholder="English Description"
    />
    <vue-tip
      :variant="useVariant"
      :initial="item.description_hi"
      @changed="
        (val) => {
          item.description_hi = val;
        }
      "
      placeholder="हिन्दी विविरण"
    />
    <selector-language
      :select="item.language"
      @selected="(abbr) => (item.language = abbr)"
    />
  </v-card-text>
</template>

<script>
import SelectorLanguage from "./SelectorLanguage";
import VueTip from "@/components/VueTip";

export default {
  props: ["item", "variant"],
  components: {
    VueTip,
    SelectorLanguage,
  },
  computed: {
    useVariant() {
      return this.variant ? this.variant : "minimal";
    },
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  created() {},
};
</script>

<style>
</style>