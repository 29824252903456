<template>
  <v-app-bar dense v-if="visible" app>
    <v-app-bar-nav-icon
      @click.stop="$emit('toggle-drawer')"
    ></v-app-bar-nav-icon>

    <v-toolbar-title>{{
      $lang(pageTitle ? pageTitle : "APP_TITLE")
    }}</v-toolbar-title>
    <v-spacer></v-spacer>

    <!-- right buttons -->
    <context-button></context-button>
  </v-app-bar>
</template>

<script>
import ContextButton from "@/components/ContextButton";
import bus from "@/bus";

export default {
  props: ["pageTitle"],
  components: {
    ContextButton,
  },
  data() {
    return {
      visible: true,
    };
  },
  watch: {
    pageTitle(val) {
      document.title = this.$lang(val) + " | " + this.$lang("APP_TITLE");
    },
  },
  beforeCreated() {
    bus.$on("hideAppBar", () => {
      this.visible = false;
    });
    bus.$on("showAppBar", () => {
      this.visible = true;
    });
  },
};
</script>

<style>
</style>