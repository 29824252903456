<template>
  <div
    class="d-flex justify-center align-center"
    style="height: calc(100vh - 200px)"
  >
    <span>Check back later.</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import moment from "@/plugins/moment";

export default {
  name: "dashboard-topup-wallet",
  components: {},
  data() {
    return {
      user: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  created() {
    // bus.$emit("hideWait");
  },
};
</script>

<style>
</style>