import Vue from "vue";

import _Exams from "./Exams/Exams.vue";
import _Exam from "./Exams/Exam.vue";
import _ExamsCreate from "./Exams/ExamsCreate.vue";
import _ExamsMocktests from "./Exams/ExamsMocktests.vue";

import _Mocktests from "./Mocktests/Mocktests.vue";
import _MocktestsCreate from "./Mocktests/MocktestsCreate.vue";
import _MocktestsSegments from "./Mocktests/MocktestsSegments.vue";
import _MocktestsGenerate from "./Mocktests/MocktestsGenerate.vue";
import _SegmentsQuestionsGrid from "./Segments/Grid/QuestionsGrid.vue";
import _SegmentsQuestionsGrid2 from "./Segments/Grid2/QuestionsGrid.vue";

import _Subjects from "./Subjects/Subjects.vue";
import _SubjectsCreate from "./Subjects/SubjectsCreate.vue";

import _Tags from "./Tags/Tags.vue";
import _TagsCreate from "./Tags/TagsCreate.vue";
import _TagsQuestions from "./Tags/TagsQuestions";

import _Questions from "./Questions/Questions.vue";
import _QuestionsCreate from "./Questions/QuestionsCreate.vue";

import _Notes from "./Notes/Notes.vue";
import _NotesCreate from "./Notes/NotesCreate.vue";

import _Jobs from "./Jobs/Jobs.vue";
import _JobsCreate from "./Jobs/JobsCreate.vue";

import _Segments from "./Segments/Segments.vue";
import _SegmentsCreate from "./Segments/SegmentsCreate.vue";
import _SegmentsQuestions from "./Segments/SegmentsQuestions.vue";

import _Attempts from "./Attempts/Attempts.vue";
import _AttemptsGetOne from "./Attempts/AttemptsGetOne.vue";

import _Reports from "./Reports/Reports.vue";
import _ReportsGetOne from "./Reports/ReportsGetOne.vue";

/* ################ ################ */

export const Exams = Vue.component("dashboard-prep-exams", _Exams);
export const Exam = Vue.component("dashboard-prep-exam", _Exam);
export const ExamsCreate = Vue.component(
  "dashboard-prep-exams-create",
  _ExamsCreate
);
export const ExamsMocktests = Vue.component(
  "dashboard-prep-exams-mocktests",
  _ExamsMocktests
);

export const Mocktests = Vue.component("dashboard-prep-mocktests", _Mocktests);
export const MocktestsCreate = Vue.component(
  "dashboard-prep-mocktests-create",
  _MocktestsCreate
);
export const MocktestsSegments = Vue.component(
  "dashboard-prep-mocktests-segments",
  _MocktestsSegments
);

export const SegmentsQuestionsGrid = Vue.component(
  "dashboard-prep-segments-questions",
  _SegmentsQuestionsGrid
);
export const SegmentsQuestionsGrid2 = Vue.component(
  "dashboard-prep-segments-questions",
  _SegmentsQuestionsGrid2
);

export const MocktestsGenerate = Vue.component(
  "dashboard-prep-mocktests-generate",
  _MocktestsGenerate
);

export const Subjects = Vue.component("dashboard-prep-subjects", _Subjects);
export const SubjectsCreate = Vue.component(
  "dashboard-prep-subjects-create",
  _SubjectsCreate
);

export const Questions = Vue.component("dashboard-prep-questions", _Questions);
export const QuestionsCreate = Vue.component(
  "dashboard-prep-questions-create",
  _QuestionsCreate
);

export const Tags = Vue.component("dashboard-prep-tags", _Tags);
export const TagsCreate = Vue.component(
  "dashboard-prep-tags-create",
  _TagsCreate
);
export const TagsQuestions = Vue.component(
  "dashboard-prep-tags-questions",
  _TagsQuestions
);

export const Notes = Vue.component("dashboard-prep-notes", _Notes);
export const NotesCreate = Vue.component(
  "dashboard-prep-notes-create",
  _NotesCreate
);

export const Jobs = Vue.component("dashboard-prep-jobs", _Jobs);
export const JobsCreate = Vue.component(
  "dashboard-prep-jobs-create",
  _JobsCreate
);

export const Segments = Vue.component("dashboard-prep-segments", _Segments);
export const SegmentsCreate = Vue.component(
  "dashboard-prep-segments-create",
  _SegmentsCreate
);
export const SegmentsQuestions = Vue.component(
  "dashboard-prep-segments-questions",
  _SegmentsQuestions
);

export const Attempts = Vue.component("dashboard-prep-attempts", _Attempts);
export const AttemptsGetOne = Vue.component(
  "dashboard-prep-attempts-get",
  _AttemptsGetOne
);

export const Reports = Vue.component("dashboard-prep-reports", _Reports);
export const ReportsGetOne = Vue.component(
  "dashboard-prep-reports-get",
  _ReportsGetOne
);
