<template>
  <div
    v-if="$store.getters['app/exams/selectedTestLanguage'] == 'en'"
    class="question"
  >
    <v-card flat class="mb-1">
      <v-card-title>
        <report-question-dialog
          withAnswer="true"
          v-if="question.id"
          class="btn-pos mr-2"
          :question_id="question.id"
        />
        <div class="border-lr">
          <div class="circle">{{ idx + 1 }}.</div>
          <latex-html :html="question.title" />
        </div>
      </v-card-title>
    </v-card>
    <v-card flat>
      <v-card-text
        v-if="question.description && question.description.length > 8"
      >
        <latex-html :html="question.description" />
      </v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-row class="optn-row">
            <v-col cols="2">
              <v-chip :class="getColor(question, '1')">A</v-chip>
            </v-col>
            <v-col cols="10">
              <latex-html :html="question.option_a" />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6">
          <v-row class="optn-row">
            <v-col cols="2">
              <v-chip :class="getColor(question, '2')">B</v-chip>
            </v-col>
            <v-col cols="10">
              <latex-html :html="question.option_b" />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6">
          <v-row class="optn-row">
            <v-col cols="2">
              <v-chip :class="getColor(question, '3')">C</v-chip>
            </v-col>
            <v-col cols="10">
              <latex-html :html="question.option_c" />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6">
          <v-row class="optn-row">
            <v-col cols="2">
              <v-chip :class="getColor(question, '4')">D</v-chip>
            </v-col>
            <v-col cols="10">
              <latex-html :html="question.option_d" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-text
        v-if="
          showAnswers && question.explanation && question.explanation.length > 8
        "
      >
        <latex-html :html="question.explanation" />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text
        v-if="
          showAnswers &&
          question.explanation_video &&
          question.explanation_video.length == 11
        "
      >
        <question-explanation-video
          :videoId="question.explanation_video"
          class="mt-4"
        />
      </v-card-text>
    </v-card>
  </div>

  <div v-else class="question">
    <v-card flat class="mb-1">
      <v-card-title>
        <report-question-dialog
          withAnswer="true"
          v-if="question.id"
          class="btn-pos mr-2"
          :question_id="question.id"
        />
        <div class="border-lr">
          <div class="circle">{{ idx + 1 }}.</div>
          <latex-html :html="question.title_hi" />
        </div>
      </v-card-title>
    </v-card>
    <v-card flat>
      <v-card-text
        v-if="question.description_hi && question.description_hi.length > 8"
      >
        <latex-html :html="question.description_hi" />
      </v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-row class="optn-row">
            <v-col cols="2">
              <v-chip :class="getColor(question, '1')">A</v-chip>
            </v-col>
            <v-col cols="10">
              <latex-html :html="question.option_a_hi" />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6">
          <v-row class="optn-row">
            <v-col cols="2">
              <v-chip :class="getColor(question, '2')">B</v-chip>
            </v-col>
            <v-col cols="10">
              <latex-html :html="question.option_b_hi" />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6">
          <v-row class="optn-row">
            <v-col cols="2">
              <v-chip :class="getColor(question, '3')">C</v-chip>
            </v-col>
            <v-col cols="10">
              <latex-html :html="question.option_c_hi" />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6">
          <v-row class="optn-row">
            <v-col cols="2">
              <v-chip :class="getColor(question, '4')">D</v-chip>
            </v-col>
            <v-col cols="10">
              <latex-html :html="question.option_d_hi" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-text
        v-if="
          showAnswers &&
          question.explanation_hi &&
          question.explanation_hi.length > 8
        "
      >
        <latex-html :html="question.explanation_hi" />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text
        v-if="
          showAnswers &&
          question.explanation_video &&
          question.explanation_video.length == 11
        "
      >
        <question-explanation-video
          :videoId="question.explanation_video"
          class="mt-4"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LatexHtml from "@/components/LatexHtml";
import ReportQuestionDialog from "@/components/ReportQuestionDialog";
import QuestionExplanationVideo from "@/components/QuestionExplanationVideo";
export default {
  name: "result-card",
  props: ["question", "idx", "showAnswers"],
  components: {
    LatexHtml,
    ReportQuestionDialog,
    QuestionExplanationVideo,
  },
  watch: {
    showAnswers(val) {},
  },
  methods: {
    getColor(qtn, numberStr) {
      let cls = " ";
      if (!this.showAnswers) {
        return cls;
      }
      if (qtn.user_option == numberStr) {
        if (qtn.user_option != qtn.correct_option) {
          cls += "red";
        } else {
          cls += "green";
        }
      } else {
        if (qtn.correct_option == numberStr) {
          cls += "blue";
        }
      }

      return cls;
    },
  },
};
</script>

<style scoped>
.circle {
  float: left;
  margin-right: 0.5em;
  color: orange;
}
.optn-row {
  border-top: 1px solid;
  margin: 5px;
}
.question {
  margin-bottom: 2rem;
  padding: 0.5rem 0.5rem;
  padding-bottom: 1.2rem;
  border-radius: 0.4rem;
  border: 1px solid gray;
}
.question table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

.question td,
.question th {
  border: 1px solid;
  padding: 8px;
}

.question th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}
.border-lr {
  padding-left: 10px;
  border-left: 5px solid orange;
}

.border-bt {
  padding: 10px 0;
  border-bottom: 2px solid orange;
}
.btn-pos {
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
}
</style>
