<template>
  <v-select
    v-model="abbr"
    :items="languages"
    item-text="title"
    item-value="abbr"
    :label="$lang('LANGUAGE')"
    clearable
  />
</template>

<script>
export default {
  name: "selector-language",
  props: ["select"],
  data() {
    return {
      languages: [
        { abbr: "", title: "Bilingual (हिन्दी + English)" },
        { abbr: "en", title: "Only English" },
        { abbr: "hi", title: "सिर्फ हिन्दी" },
      ],
      abbr: null,
    };
  },
  watch: {
    abbr(val) {
      console.log("selected language abbr", val);
      this.$emit("selected", val);
    },
    select(abbr) {
      let lang = this.languages.find((el) => el.abbr == abbr);

      if (lang) {
        this.abbr = lang.abbr;
      } else {
        this.abbr = this.languages[0].abbr;
      }
    },
  },
  created() {
    let lang = this.languages.find((el) => el.abbr == this.select);
    if (lang) {
      this.abbr = lang.abbr;
    } else {
      this.abbr = this.languages[0].abbr;
    }
  },
};
</script>
