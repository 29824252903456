export default {
  "ROOT:FULLSCREEN_WAIT:ALERT_MSG-TEXT": "कृपया प्रतीक्षा करें...",
  "ROOT:FULLSCREEN_WAIT:ALERT_MSG-TEXT|LOGOUT": "लॉगआउट हो रहे हैं..",

  "ROOT:DRAWER:TOOLBAR_GUEST_TITLE-TEXT": "स्वागत है!",
  "ROOT:DRAWER:BUTTON_HOME-TEXT": "होम",
  "ROOT:DRAWER:BUTTON_GROUPS-TEXT": "ग्रुप्स",
  "ROOT:DRAWER:BUTTON_EXPENSES-TEXT": "एक्स्पेंसेस",
  "ROOT:DRAWER:BUTTON_PROFILE-TEXT": "प्रोफाइल",
  "ROOT:DRAWER:BUTTON_LOGIN-TEXT": "लॉग इन",
  "ROOT:DRAWER:BUTTON_UPDATE_EMAIL-TEXT": "ईमेल बदलें",
  "ROOT:DRAWER:BUTTON_UPDATE_PROFILE-TEXT": "अपडेट प्रोफाइल",
  "ROOT:DRAWER:BUTTON_UPDATE_PASSWORD-TEXT": "पासवर्ड बदलें",
  "ROOT:DRAWER:BUTTON_LOGOUT-TEXT": "लॉगआउट",
  "ROOT:DRAWER:BUTTON_REGISTER-TEXT": "रजिस्टर",

  "ROOT:VERIFY_STATUS:MESSAGE-TEXT":
    "ईमेल वेरीफाई करने के लिए यहाँ क्लिक करें.",

  "ROOT:BOTTOM_NAV:BUTTON_HOME-TEXT": "होम",
  "ROOT:BOTTOM_NAV:BUTTON_GROUPS-TEXT": "ग्रुप्स",
  "ROOT:BOTTOM_NAV:BUTTON_EXPENSES-TEXT": "एक्स्पेंसेस",
  APP_TITLE: "विकर्ण",
  INR: "₹ %s/-",
  SAVE: "सेव करें",
  MENU: "मेनू",
  ACCOUNT: "अकाउंट",
  SETTINGS: "सेटिंग्स",
  USER_PREFS: "यूजर प्रेफरेंसेस",
  GENERAL: "सामान्य",
  DARK_THEME: "डार्क थीम",
  LIGHT_THEME: "लाइट थीम",
  ON: "ऑन",
  OFF: "ऑफ",
  SOMETHING_WENT_WRONG: "कुछ गड़बड़ हो गई!",
  NO_MORE_ITEMS: "बस! इतने ही हैं.",
  NOT_CONNECTED: "आप इन्टरनेट से नहीं जुड़े हैं.",

  WELCOME: "स्वागत है",
  HOME: "होम",
  GROUP: "ग्रुप",
  GROUPS: "ग्रुप्स",
  EXPENSE: "एक्स्पेंस",
  EXPENSES: "एक्स्पेंसेस",
  TEAMS: "दल",
  TEAM: "दल",
  MEMBER: "सदस्य",
  MEMBERS: "सदस्य",
  TOTAL: "कुल",
  SUBTOTAL: "कुल योग",

  I_OWE: "मुझे देने होंगे %s /- रुपये.",
  I_NILL: "मेरा लेन-देन बराबर है.",
  I_GET: "मुझे मिलेंगे %s /- रुपये.",
  I_SPENT: "मैंने %s /- रुपये खर्च किये.",
  OWE: "देने हैं %s /- रुपये.",
  NILL: "लेन-देन बराबर.",
  GET: "लेने हैं %s /- रुपये.",
  SPENT: "खर्च किये %s /- रुपये.",

  CONFIRM_DELETE:
    "क्या आप वाकई <b style='color:red'>%s</b> को हटाना चाहते हैं?",
  YES: "जी हां",
  NO: "नहीं",
  OK: "ठीक है",
  CANCEL: "रद्द करें",
  REMOVE: "हटायें",
  UPDATE: "अपडेट",
  CREATE: "नया जोड़ें",
  GENERATE: "जेनरैट",
  NEW: "नया",
  NEXT: "अगला",
  PREVIOUS: "पिछला",
  PAGE: "पेज",
  DESCRIPTION: "विविरण",
  SUBJECT: "विषय",
  "CORRECT-OPTION": "सही ऑप्शन",
  OPTIONA: "ऑप्शन (अ)",
  OPTIONB: "ऑप्शन (ब)",
  OPTIONC: "ऑप्शन (स)",
  OPTIOND: "ऑप्शन (द)",
  TITLE: "शीर्षक",
  EXAMS: "इग्ज़ैमस्",
  EXAM: "इग्ज़ैम",
  QUESTIONS: "प्रश्न",
  QUESTION: "प्रश्न",
  TAGS: "टैग्स",
  TAG: "टैग",
  SUBJECTS: "विषय",
  SUBJECT: "विषय",
  NOTES: "नोट्स",
  NOTE: "नोट",
  MOCKTESTS: "मॉक-टेस्टस",
  MOCKTEST: "मॉक-टेस्ट",
  SEGMENTS: "सेगमेंट्स",
  SEGMENT: "सेगमेंट",
  DURATION: "समय अवधि",
  EXPLANATION: "स्पष्टीकरण",
  NOITEMS: "लिस्ट खाली है",
  SEGMENTED: "सेगमेंट की समय अवधि का उपयोग करें?",
  ATTEMPT: "प्रयास करें",
  START: "प्रारंभ करें",
  "TOTAL-QUESTIONS": "कुल प्रश्न",
  MARKANDNEXT: "मार्क और अगला",
  UNMARK: "अन-मार्क",
  CLEAR: "क्लियर",
  SUBMIT: "सबमिट",
  SAVEANDNEW: "सेव और नया",
  ACCEPTANDSTART: "स्वीकार करें और शुरू करें",
  CONFIRMLEAVE: "टेस्ट पूरा नहीं हुआ? बीच मे छोड़ कर जाना चाहते हैं?",
  USERS: "यूजर्स",
  AUTH: "ऑथराइज़",
  PREPARE: "तैयारी",
  "GENERATE-MOCKTEST": "नया मॉकटेस्ट बनाएं",
  REPORT: "रिपोर्ट",
  REPORTS: "रेपोर्ट्स",
  JOB: "नौकरी",
  JOBS: "नौकरियाँ",
};
