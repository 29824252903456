<template>
  <v-row>
    <v-col cols="1">
      <v-radio :disabled="isDisabledd" :value="value"></v-radio>
    </v-col>
    <v-col cols="11">
      <latex-html :hasClass="hasClass" :isLatex="isLatex" :html="html" />
    </v-col>
  </v-row>
</template>

<script>
import LatexHtml from "@/components/LatexHtml";
export default {
  props: ["value", "html", "isDisabledd", "isLatex", "hasClass"],
  name: "radio-html",
  components: {
    LatexHtml,
  },
};
</script>

<style>
img {
  max-width: 50%;
}
</style>