<template>
  <v-bottom-navigation
    dense
    :value="currentParentPageIndex"
    :background-color="color"
    :dark="currentParentPageIndex == 2"
    :input-value="visible"
    app
    grow
  >
    <v-btn v-for="item in menu_user" :key="item.icon" @click="item.to">
      <span>{{ item.text }}</span>
      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";
import bus from "@/bus";
export default {
  data() {
    return {
      visible: null,
      menu_user: [
        {
          icon: "mdi-account-box",
          text: this.$lang("PROFILE"),
          to: () => this.routeTo("profile"),
        },
        {
          icon: "mdi-home",
          text: this.$lang("HOME"),
          to: () => this.routeTo("home"),
        },
        {
          icon: "mdi-book",
          text: this.$lang("EXAMS"),
          to: () => this.routeTo("exams"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      user: "auth/user",
    }),
    color() {
      switch (this.currentParentPage) {
        case "profile":
          return "green darken-4 white--text";
        case "home":
          return "purple accent-4 white--text";
        case "exams":
          return "orange accent-4 white--text";
        default:
          return "";
      }
    },
    currentParentPageIndex() {
      switch (this.currentParentPage) {
        case "profile":
          return 0;
        case "home":
          return 1;
        case "exams":
          return 2;
        default:
          return undefined;
      }
    },
    currentParentPage() {
      let paths = this.$route.path.split("/");
      if (isEmpty(paths[1])) {
        return "home";
      }
      return paths[1];
    },
  },
  methods: {
    routeTo(to) {
      this.$router.push({ name: to });
    },
  },
  mounted() {
    this.visible = this.user ? true : false;
  },
  watch: {
    user(value) {
      this.visible = value ? true : false;
    },
  },
  beforeCreate() {
    bus.$on("hideBottomNav", () => {
      this.visible = false;
    });
    bus.$on("showBottomNav", () => {
      this.visible = true;
    });
  },
  created() {},
};
</script>

<style>
</style>