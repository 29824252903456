<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn block color="orange darken-2" dark v-bind="attrs" v-on="on">
          <v-icon left>mdi-plus</v-icon>
          {{ $lang("tag") }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="mb-2 headline orange darken-4 white--text">
          {{ $lang("tags") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="text-center">
          <v-autocomplete
            v-model="selected"
            :items="items"
            dense
            filled
            item-text="title"
            item-value="id"
            :label="$lang('TAG')"
            clearable
          >
            <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              {{ data.item.title }} - {{ data.item.__meta__.total_questions }}
            </template>
            <template slot="item" slot-scope="data">
              <!-- HTML that describe how select should render items when the select is open -->
              <span>
                {{ data.item.title }}
                <br />
                <span class="caption" style="color: grey">{{
                  data.item.title_hi
                }}</span>
              </span>
              <v-spacer></v-spacer>
              <v-chip color="black" dark small>
                {{ data.item.__meta__.total_questions }}
              </v-chip>
            </template>
          </v-autocomplete>
          <!-- <v-chip
            @click="() => {}"
            small
            class="ma-1"
            :style="'background-color:' + randomColor()"
            v-for="tag in items"
            :key="tag.id"
            dark
          >
            {{ tag.title }}
          </v-chip> -->
          <counter
            :value="count"
            label="Questions"
            @changed="(val) => (count = val)"
          />

          <v-checkbox
            v-model="shuffle_questions"
            :label="`Shuffle questions for this tag.`"
            class="my-3"
          ></v-checkbox>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!this.selected || this.count < 1"
            block
            outlined
            color="orange darken-3"
            dark
            @click="generateTag()"
          >
            {{ $lang("Attach") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import { getRandomColorLuminance } from "@/helpers/utils";
import Counter from "./Counter";
export default {
  components: { Counter },
  name: "add-segment-tags",
  props: ["tags"],
  data() {
    return {
      selected: null,
      shuffle_questions: false,
      count: 5,
      items: [],
      dialog: false,
    };
  },
  watch: {
    tags(val) {
      this.items = val;
    },
    selected() {},
  },
  methods: {
    generateTag() {
      if (!this.selected || this.count < 1) {
        return;
      }
      this.$emit("selected", {
        id: this.selected,
        count: this.count,
        shuffle_questions: this.shuffle_questions,
        total_questions: this.items.find((el) => el.id == this.selected)
          .__meta__.total_questions,
        title: this.items.find((el) => el.id == this.selected).title,
      });
      this.selected = null;
      this.dialog = false;
    },
    randomColor() {
      return getRandomColorLuminance(-0.5);
    },
  },
  created() {
    this.items = this.tags;
    // if (this.items && this.items.length > 0) {
    //   for (let i = 0; i < this.tags.length; i++) {
    //     const tag = this.tags[i];
    //     this.items[i].title =
    //       tag.title + " (" + tag.__meta__.total_questions + ")";
    //   }
    // }
  },
};
</script>