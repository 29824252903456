import Vue from "vue";

import _Exams from "./Exams";
import _ExamsChildren from "./ExamsChildren";
import _Exam from "./Exam";
import _Mocktest from "./Mocktest";
import _MocktestPreview from "./MocktestPreview";
import _AttemptResult from "./AttemptResult";
import _AttemptResultNew from "./Attempt/Result";

export const Exams = Vue.component("exams", _Exams);
export const ExamsChildren = Vue.component("exams-children", _ExamsChildren);
export const Exam = Vue.component("exam", _Exam);

export const MocktestPreview = Vue.component(
  "mocktest-preview",
  _MocktestPreview
);
export const Mocktest = Vue.component("mocktest", _Mocktest);

export const AttemptResult = Vue.component("attempt-result", _AttemptResult);
export const AttemptResultNew = Vue.component(
  "attempt-result-new",
  _AttemptResultNew
);
