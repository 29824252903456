<template>
  <v-row justify="center">
    <v-col class="col-6">
      <selector-exams-autocomplete
        :itemExams="itemExams"
        @selected="handleSelect"
      />
    </v-col>
    <v-col class="col-6" v-if="itemExams">
      <v-chip
        class="ma-2"
        close
        color="orange"
        label
        small
        outlined
        v-for="exm in itemExams"
        @click:close="detachItemExam(exm.id)"
        :key="exm.id"
      >
        {{ exm.title }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
import { axios } from "@/plugins/axios";
import SelectorExamsAutocomplete from "./SelectorExamsAutocomplete";
import bus from "@/bus";

export default {
  name: "selector-exams",
  props: ["item"],
  components: {
    SelectorExamsAutocomplete,
  },
  data() {
    return {
      itemExams: [],
    };
  },
  watch: {
    item(val) {
      this.fetchItemExams(val.id);
    },
  },
  methods: {
    handleSelect(id) {
      this.attachItemExam(id);
    },
    fetchItemExams(item_id) {
      item_id = parseInt(item_id);
      if (isNaN(item_id)) return;
      return axios.get(`prep/mocktests/${item_id}/exams`).then((res) => {
        this.itemExams = res.data.data;
      });
    },
    attachItemExam(exam_id) {
      exam_id = parseInt(exam_id);
      if (isNaN(exam_id)) return;
      bus.$emit("showWait");
      return axios
        .post(`/prep/mocktests/attach/${this.item.id}/exams`, {
          foreign_ids: [exam_id],
        })
        .then(() => {
          this.fetchItemExams(this.item.id);
        })
        .finally(() => {
          this.exam = {};
          bus.$emit("hideWait");
        });
    },
    detachItemExam(exam_id) {
      exam_id = parseInt(exam_id);
      if (isNaN(exam_id)) return;
      bus.$emit("showWait");
      axios
        .post(`/prep/mocktests/detach/${this.item.id}/exams`, {
          foreign_ids: [exam_id],
        })
        .then(() => {
          this.fetchItemExams(this.item.id);
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    this.fetchItemExams(this.item.id);
  },
};
</script>