<template>
  <div class="mr-1 top-sticky-brf">
    <display-timer-counter
      :start="isStart"
      :startTime="parseInt(question.elapsed) ? parseInt(question.elapsed) : 0"
      @tick="(_elapsed) => (question.elapsed = _elapsed)"
    />
  </div>
</template>

<script>
import DisplayTimerCounter from "@/components/DisplayTimerCounter";

export default {
  name: "question-card-timer",
  props: ["question", "currentQuestionId"],
  components: {
    DisplayTimerCounter,
  },
  data() {
    return {
      started: false,
    };
  },
  watch: {
    currentQuestionId() {
      // console.log("currentQuestionId", this.currentQuestionId);
    },
  },
  computed: {
    isStart() {
      // let attempted = this.question && this.question.user_option ? false : true;
      if (
        this.currentQuestionId &&
        this.currentQuestionId == this.question.id
      ) {
        //return attempted;
        return true;
      }
      return false;
    },
  },
};
</script>

<style >
.top-sticky-brf {
  position: absolute;
  top: 0rem;
  right: -0.2rem;
  background-color: yellow;
  color: #000;
  font-size: 0.8rem;
  line-height: 1rem;
  padding: 1px;
  border-radius: 0rem 0rem 0rem 0rem;
}
</style>