<template>
  <form id="vuetip">
    <v-card v-if="item">
      <v-card-title>
        <div class="title">
          {{ $lang(item.id ? "UPDATE" : "NEW") + " " + $lang("QUESTION") }}
        </div>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn v-if="$route.params.id && item.id" icon @click="handleCopy">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
          <v-btn icon @click="showLatexEditor = !showLatexEditor">
            <v-icon>mdi-math-compass</v-icon>
          </v-btn>
          <v-btn icon @click="showPreview = !showPreview">
            <v-icon>mdi-monitor-cellphone</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="
              () => {
                variant = variant == 'none' ? 'extended' : 'none';
              }
            "
          >
            <v-icon v-if="variant == 'none'">mdi-format-list-text</v-icon>
            <v-icon v-else>mdi-form-textarea</v-icon>
          </v-btn>
          <!-- <media-image-upload-dialog @uploaded="(url) => {}" /> -->
        </v-card-actions>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-tabs color="orange" grow>
              <v-tab>English</v-tab>
              <v-tab>हिन्दी</v-tab>

              <v-tab-item>
                <v-row>
                  <v-col cols="12">
                    <vue-tip
                      :variant="variant"
                      :initial="item.title"
                      :errors="errors && errors.title ? errors.title : []"
                      :placeholder="$lang('TITLE')"
                      @changed="
                        (val) => {
                          item.title = val;
                        }
                      "
                    />
                    <vue-tip
                      :variant="variant"
                      color="primary"
                      dark
                      :initial="item.description"
                      :errors="
                        errors && errors.description ? errors.description : []
                      "
                      :placeholder="$lang('DESCRIPTION')"
                      @changed="
                        (val) => {
                          item.description = val;
                        }
                      "
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <vue-tip
                      :variant="variant"
                      :initial="item.option_a"
                      :errors="errors && errors.option_a ? errors.option_a : []"
                      :placeholder="$lang('OPTIONA')"
                      @changed="
                        (val) => {
                          item.option_a = val;
                        }
                      "
                    />
                  </v-col>
                  <v-col cols="6">
                    <vue-tip
                      :variant="variant"
                      :initial="item.option_b"
                      :errors="errors && errors.option_b ? errors.option_b : []"
                      :placeholder="$lang('OPTIONB')"
                      @changed="
                        (val) => {
                          item.option_b = val;
                        }
                      "
                    />
                  </v-col>
                  <v-col cols="6">
                    <vue-tip
                      :variant="variant"
                      :initial="item.option_c"
                      :errors="errors && errors.option_c ? errors.option_c : []"
                      :placeholder="$lang('OPTIONC')"
                      @changed="
                        (val) => {
                          item.option_c = val;
                        }
                      "
                    />
                  </v-col>
                  <v-col cols="6">
                    <vue-tip
                      :variant="variant"
                      :initial="item.option_d"
                      :errors="errors && errors.option_d ? errors.option_d : []"
                      :placeholder="$lang('OPTIOND')"
                      @changed="
                        (val) => {
                          item.option_d = val;
                        }
                      "
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <vue-tip
                      :variant="variant"
                      color="green"
                      :initial="item.explanation"
                      :errors="
                        errors && errors.explanation ? errors.explanation : []
                      "
                      :placeholder="$lang('explanation')"
                      @changed="
                        (val) => {
                          item.explanation = val;
                        }
                      "
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col cols="12">
                    <vue-tip
                      :variant="variant"
                      color="orange"
                      dark
                      :initial="item.title_hi"
                      :errors="errors && errors.title_hi ? errors.title_hi : []"
                      :placeholder="$lang('TITLE')"
                      @changed="
                        (val) => {
                          item.title_hi = val;
                        }
                      "
                    />
                    <vue-tip
                      :variant="variant"
                      color="primary"
                      dark
                      :initial="item.description_hi"
                      :errors="
                        errors && errors.description_hi
                          ? errors.description_hi
                          : []
                      "
                      :placeholder="$lang('DESCRIPTION')"
                      @changed="
                        (val) => {
                          item.description_hi = val;
                        }
                      "
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <vue-tip
                      :variant="variant"
                      :initial="item.option_a_hi"
                      :errors="
                        errors && errors.option_a_hi ? errors.option_a_hi : []
                      "
                      :placeholder="$lang('OPTIONA')"
                      @changed="
                        (val) => {
                          item.option_a_hi = val;
                        }
                      "
                    />
                  </v-col>
                  <v-col cols="6">
                    <vue-tip
                      :variant="variant"
                      :initial="item.option_b_hi"
                      :errors="
                        errors && errors.option_b_hi ? errors.option_b_hi : []
                      "
                      :placeholder="$lang('OPTIONB')"
                      @changed="
                        (val) => {
                          item.option_b_hi = val;
                        }
                      "
                    />
                  </v-col>
                  <v-col cols="6">
                    <vue-tip
                      :variant="variant"
                      :initial="item.option_c_hi"
                      :errors="
                        errors && errors.option_c_hi ? errors.option_c_hi : []
                      "
                      :placeholder="$lang('OPTIONC')"
                      @changed="
                        (val) => {
                          item.option_c_hi = val;
                        }
                      "
                    />
                  </v-col>
                  <v-col cols="6">
                    <vue-tip
                      :variant="variant"
                      :initial="item.option_d_hi"
                      :errors="
                        errors && errors.option_d_hi ? errors.option_d_hi : []
                      "
                      :placeholder="$lang('OPTIOND')"
                      @changed="
                        (val) => {
                          item.option_d_hi = val;
                        }
                      "
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <vue-tip
                      :variant="variant"
                      color="green"
                      :initial="item.explanation_hi"
                      :errors="
                        errors && errors.explanation_hi
                          ? errors.explanation_hi
                          : []
                      "
                      :placeholder="$lang('explanation')"
                      @changed="
                        (val) => {
                          item.explanation_hi = val;
                        }
                      "
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-col>

          <v-col cols="12" md="6">
            <v-checkbox
              v-model="item.is_latex"
              :label="`Has LaTeX maths?`"
            ></v-checkbox>
            <v-checkbox
              v-model="item.has_options"
              :label="`Has Options?`"
            ></v-checkbox>
            <v-select
              v-model="item.correct_option"
              :items="options"
              item-text="value"
              item-value="key"
              clearable
              :label="$lang('CORRECT-OPTION')"
              :error-messages="
                errors && errors.correct_option ? errors.correct_option : []
              "
            ></v-select>

            <v-select
              v-model="item.prep_subject_id"
              :items="subjects"
              item-text="title"
              item-value="id"
              clearable
              :label="$lang('SUBJECT')"
              :error-messages="
                errors && errors.prep_subject_id ? errors.prep_subject_id : []
              "
            ></v-select>

            <selector-language
              :select="item.language"
              @selected="(abbr) => (item.language = abbr)"
            />

            <selector-mocktest-segments v-if="item.id" :item="item" />
            <selector-tags v-if="item.id" ident="questions" :item="item" />

            <v-divider></v-divider>
            <question-explanation-video
              v-if="
                item.explanation_video && item.explanation_video.length == 11
              "
              :videoId="item.explanation_video"
              class="mt-4"
            />
            <v-text-field
              :loading="loading"
              :disabled="loading"
              label="Youtube Video ID"
              v-model="item.explanation_video"
              type="text"
              prefix="/watch?v="
            ></v-text-field>
            <v-divider></v-divider>
          </v-col>
          <!-- right side end -->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div>
          <v-btn small class="mr-1" outlined @click="doSubmit(false)">{{
            $lang(item.id ? "UPDATE" : "CREATE")
          }}</v-btn>
          <v-btn
            small
            v-if="item.id"
            class="mr-1"
            outlined
            @click="doSubmit(true)"
            >{{ $lang("SAVEANDNEW") }}</v-btn
          >
          <v-btn
            small
            text
            @click="
              $router.push({
                name: query_segment_id
                  ? 'dashboard-prep-segments-questions'
                  : query_tag_id
                  ? 'dashboard-prep-tags-questions'
                  : 'dashboard-prep-questions',
                params: {
                  id: query_segment_id
                    ? query_segment_id
                    : query_tag_id
                    ? query_tag_id
                    : 0,
                },
              })
            "
            >{{ $lang("CANCEL") }}</v-btn
          >
        </div>
        <v-spacer></v-spacer>
        <v-btn small v-if="item.id" color="red" outlined @click="remove()">
          <v-icon>mdi-delete</v-icon> {{ $lang("REMOVE") }}
        </v-btn>
      </v-card-actions>
      <question-preview-dialog
        :show="showPreview"
        @closed="(val) => (showPreview = val)"
        :item="item"
      />
      <latex-editor-dialog
        :show="showLatexEditor"
        @closed="(val) => (showLatexEditor = val)"
        :item="item"
      />
    </v-card>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import VueTip from "@/components/VueTip";
import SelectorMocktestSegments from "../../modules/SelectorMocktestSegments";
import SelectorTags from "../../modules/SelectorTags";
import SelectorLanguage from "../../modules/SelectorLanguage.vue";
import bus from "@/bus";
import { axios } from "@/plugins/axios";
import QuestionExplanationVideo from "@/components/QuestionExplanationVideo";
import QuestionPreviewDialog from "./QuestionPreviewDialog";
import LatexEditorDialog from "./LatexEditorDialog";

import {
  youTubeIdFromLink,
  validateURL,
  validateYouTubeUrl,
} from "@/helpers/utils";
// import MediaImageUploadDialog from "@/components/MediaImageUploadDialog";

export default {
  name: "dashboard-prep-questions-create",
  components: {
    VueTip,
    SelectorMocktestSegments,
    SelectorTags,
    SelectorLanguage,
    QuestionExplanationVideo,
    // MediaImageUploadDialog,
    QuestionPreviewDialog,
    LatexEditorDialog,
  },
  data() {
    return {
      loading: false,
      variant: "none",
      item: {
        id: null,
        title: "",
        description: "",
        language: null,
      },
      query_tag_id: null,
      query_segment_id: null,
      options: [
        { key: "1", value: this.$lang("OPTIONA") },
        { key: "2", value: this.$lang("OPTIONB") },
        { key: "3", value: this.$lang("OPTIONC") },
        { key: "4", value: this.$lang("OPTIOND") },
      ],
      subjects: [],
      showPreview: null,
      showLatexEditor: null,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    if (this.$route.query.parent_page) {
      to.query.page = this.$route.query.parent_page;
      to.query.back_from_id = this.$route.params.id;
    }
    console.log("to", to);
    next();
  },
  methods: {
    attachSegment(segment_id) {
      bus.$emit("showWait");
      segment_id = parseInt(segment_id);
      console.log("segment_id", segment_id);
      axios
        .post(`/prep/questions/attach/${this.item.id}/segments`, {
          foreign_ids: [segment_id],
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    remove() {
      this.$store
        .dispatch("dashboard/prep/QuestionsRemove", {
          item: this.item,
          context: this,
        })
        .then(() => {
          this.$router.replace({
            name: this.query_segment_id
              ? "dashboard-prep-segments-questions"
              : this.query_tag_id
              ? "dashboard-prep-tags-questions"
              : "dashboard-prep-questions",
            params: {
              id: this.query_segment_id
                ? this.query_segment_id
                : this.query_tag_id
                ? this.query_tag_id
                : 0,
            },
          });
        });
    },
    fetchSubjects() {
      this.$store.dispatch("dashboard/prep/SubjectsGetAll", {}).then((res) => {
        // fill subjects array
        this.subjects = res.data;
      });
    },
    handleCopy() {
      if (!this.$route.params.id) {
        return;
      }
      if (
        !confirm(
          "You are about to copy:\n\n" +
            (this.item.title ? this.item.title : this.item.id) +
            "\n Only table fields will be copied, relational links will be ignored." +
            "\nClick OK to copy."
        )
      ) {
        return Promise.reject("CANCELED_BY_USER");
      }
      this.loading = true;
      bus.$emit("showWait", "Creating copy...");
      return axios
        .post(`/prep/questions/copy/${this.item.id}`)
        .then((res) => {
          return this.$router.push({
            name: "dashboard-prep-questions-create",
            params: { id: res.data.id },
          });
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
    fetchItem(id) {
      this.item.id = this.$route.params.id;
      this.$store
        .dispatch("dashboard/prep/QuestionsGetOne", {
          id: this.item.id,
          context: this,
        })
        .then(() => {
          this.fetchSubjects();
        })
        .catch((err) => {
          console.log(err);
          this.$router.replace({ name: "dashboard-prep-questions" });
        });
    },
    doSubmit(isNew) {
      if (this.$route.params.id) {
        this.$store
          .dispatch("dashboard/prep/QuestionsUpdate", {
            item: this.item,
            context: this,
          })
          .then(() => {
            let conf = {};

            if (isNew) {
              conf.name = "dashboard-prep-questions-create";
              conf.query = {
                segment_id: this.query_segment_id,
                tag_id: this.query_tag_id,
              };
            } else {
              if (this.query_segment_id) {
                conf.name = "dashboard-prep-segments-questions";
              } else if (this.query_tag_id) {
                conf.name = "dashboard-prep-tags-questions";
              } else {
                conf.name = "dashboard-prep-questions";
              }
              conf.params = {
                id: this.query_segment_id
                  ? this.query_segment_id
                  : this.query_tag_id
                  ? this.query_tag_id
                  : 0,
              };
            }

            this.$router.replace(conf);
          });
      } else {
        if (this.query_segment_id) {
          this.item.segment_id = this.query_segment_id;
        }
        this.$store
          .dispatch("dashboard/prep/QuestionsCreate", {
            item: this.item,
            context: this,
          })
          .then((data) => {
            this.$router.replace({
              name: "dashboard-prep-questions-create",
              params: { id: data.id },
              query: {
                segment_id: this.query_segment_id,
                tag_id: this.query_tag_id,
              },
            });
          });
      }
    },
  },
  watch: {
    "item.explanation_video"(val) {
      if (validateYouTubeUrl(val)) {
        this.loading = true;
        let id = youTubeIdFromLink(val);
        console.log("youTubeIdFromLink ID", id);
        setTimeout(() => {
          this.item.explanation_video = id;
          this.loading = false;
        }, 600);
      }
      this.item.explanation_video = val;
    },

    "$route.params.id"(id) {
      this.fetchItem(id);
    },
    "$route.query.segment_id"(seg_id) {
      seg_id = parseInt(seg_id) ? parseInt(seg_id) : null;
      this.query_segment_id = seg_id;
    },
    "$route.query.tag_id"(tag_id) {
      tag_id = parseInt(tag_id) ? parseInt(tag_id) : null;
      this.query_tag_id = tag_id;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchItem(this.$route.params.id);
    }

    if (this.$route.query.segment_id) {
      let seg_id = parseInt(this.$route.query.segment_id)
        ? parseInt(this.$route.query.segment_id)
        : null;
      this.query_segment_id = seg_id;
    }
    if (this.$route.query.tag_id) {
      let seg_id = parseInt(this.$route.query.tag_id)
        ? parseInt(this.$route.query.tag_id)
        : null;
      this.query_tag_id = seg_id;
    }

    this.fetchSubjects();
  },
};
</script>

<style>
#vuetip img {
  max-width: 95%;
}
</style>