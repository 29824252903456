<template>
  <span></span>
</template>

<script>
import { scrollToTargetAdjusted } from "@/helpers/utils";
import bus from "@/bus";

export default {
  name: "HightlightElement",
  created() {
    bus.$on("highlightElement", () => {
      if (this.$route.hash) {
        const el = document.querySelector(this.$route.hash);
        if (el) {
          scrollToTargetAdjusted(el);
          if (el && el.parentElement) {
            if (el.parentElement.parentElement) {
              el.parentElement.parentElement.style.boxShadow =
                "0px 0px 10px #ffff00";
            }
          }
        }
      }
    });
  },
  // updated() {
  //   this.$nextTick(() => {
  //     if (this.$route.hash) {
  //       const el = document.querySelector(this.$route.hash);
  //       el && scrollToTargetAdjusted(el);
  //       if (el && el.parentElement) {
  //         if (el.parentElement.parentElement) {
  //           el.parentElement.parentElement.style.boxShadow =
  //             "0px 0px 10px #ffff00";
  //         }
  //       }
  //     }
  //   });
  // },
};
</script>

<style>
</style>