var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('search-card',{on:{"clicked":(term) => _vm.getItems(term, this.$route.query.page)}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.pagination.total,"loading":_vm.loading,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.user_id",fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","outlined":""},on:{"click":function($event){$event.stopPropagation();if (
              !_vm.authUser ||
              !['admin', 'superadmin'].includes(_vm.authUser.role)
            ) {
              return;
            }
            _vm.$router.push({
              name: 'dashboard-auth-user',
              params: { id: item.user_id },
            });}}},[_vm._v(" "+_vm._s(item.user.display_name)+" ")])]}},{key:"item.questions_correct",fn:function({ item }){return [_vm._v(" "+_vm._s(`(${item.questions_correct})${item.questions_attempted}/${item.questions_total}`)+" ")]}},{key:"item.points_obtain",fn:function({ item }){return [_vm._v(" "+_vm._s(`${item.points_obtain}/${item.points_total}`)+" ")]}},{key:"item.accuracy",fn:function({ item }){return [_vm._v(" "+_vm._s(`${item.accuracy}%`)+" ")]}},{key:"item.prep_mocktest_id",fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
              name: 'dashboard-prep-mocktests-segments',
              params: { id: item.mocktest.id },
            })}}},[_vm._v(_vm._s(item.mocktest.title))])]}},{key:"item.active",fn:function({ item }){return [_c('v-btn',{attrs:{"id":'highlight-' + item.id,"icon":"","color":item.active ? 'green' : 'red'},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('dashboard/prep/AttemptsToggleActive', {
              item,
            })}}},[_c('v-icon',[_vm._v(_vm._s(item.active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline"))])],1)]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$router.push({
              name: 'dashboard-prep-attempts-get',
              params: { id: item.id },
              query: { parent_page: _vm.$route.query.page },
            })}}},[_c('v-icon',[_vm._v("mdi-file")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":""},on:{"click":function($event){_vm.$store
              .dispatch('dashboard/prep/AttemptsRemove', {
                item,
              })
              .then(() => {
                _vm.items = _vm.items.filter((el) => {
                  return el.id != item.id;
                });
              })}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('query-page-redirect',{attrs:{"pagination":_vm.pagination}})],1),_c('fab-button',{attrs:{"icon":'mdi-plus',"color":"primary"},on:{"clicked":function($event){return _vm.$router.push({ name: 'dashboard-prep-attempts-create' })}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }