<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card-title class="mb-0 pb-0 justify-center">
          {{ isHindi ? "कोई परीक्षा चुनिये" : "CHOOSE AN EXAM" }}
        </v-card-title>
        <v-card-text class="mt-0 pt-0 text-center">
          {{
            isHindi
              ? "कृपया नीचे से कोई भी परीक्षा श्रेणी चुनें।"
              : "Please choose any exam category from below."
          }}
        </v-card-text>
      </v-col>
    </v-row>
    <v-row dense class="text-center justify-center">
      <v-col v-for="item in items" :key="item.id" cols="6" md="4">
        <v-card
          @click="$router.push({ name: 'exam', params: { id: item.id } })"
          outlined
          elevation="0"
          style="overflow: hidden"
          height="150"
          class="d-flex flex-column justify-space-around"
        >
          <v-card-text class="ma-0 pa-0 pt-1 text-center">
            <v-badge
              top
              right
              dot
              :color="item.active ? 'green' : 'red'"
              offset-x="30"
              offset-y="-20"
            ></v-badge>
            <v-avatar size="70" class="justify-self-cent">
              <v-img
                :src="
                  item.banner_square
                    ? item.banner_square
                    : 'https://images.unsplash.com/photo-1456513080510-7bf3a84b82f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&h=400&q=80'
                "
              />
            </v-avatar>
          </v-card-text>
          <v-card-text class="ma-0 pa-0 pt-1 text-center">
            <div
              :class="'subtitle-1 ' + (isDark ? 'white--text' : 'black--text')"
            >
              {{
                isHindi
                  ? item.title_hi
                    ? item.title_hi
                    : item.title
                  : item.title
              }}
            </div>
            <div class="caption">
              <span class="mr-1" v-for="child in item.children" :key="child.id">
                {{
                  isHindi
                    ? child.title_hi
                      ? child.title_hi
                      : child.title
                    : child.title
                }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";
export default {
  name: "exams",
  components: {},
  data() {
    return {
      items: [],
    };
  },
  computed: {},
  methods: {
    fetchItems() {
      bus.$emit("showWait");
      axios
        .get("prep/exams")
        .then((res) => {
          let items = res.data.data.filter(
            (el) => !el.parent_id || el.parent_id == ""
            // el.children &&
            // el.children.length > 0
          );

          this.items = items;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    this.fetchItems();
  },
};
</script>

<style>
.textShortDesc {
  /*Here we have determined the max number of lines.*/
  display: block; /* or inline-block */
  -o-text-overflow: ellipsis; /* Opera < 11*/
  text-overflow: ellipsis; /* IE, Safari (WebKit), Opera >= 11, FF > 6 */
  word-wrap: break-word;
  overflow: hidden;
  max-height: 1.2em; /*max-height/line-height=rowCount */
  line-height: 1.5em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>