export default [
  {
    path: "/dashboard/quotes",
    name: "dashboard-quotes",
    component: () => import("../components/Quotes"),
    meta: {
      title: "QUOTES",
      needsAuth: true,
      verified: true,
      roles: ["teacher", "moderator", "manager", "admin", "superadmin"],
      guest: false,
    },
  },
  {
    path: "/dashboard/quotes/create/:id?",
    name: "dashboard-quotes-create",
    component: () => import("../components/QuotesCreate"),
    meta: {
      title: "QUOTES_CREATE",
      needsAuth: true,
      verified: true,
      roles: ["teacher", "moderator", "manager", "admin", "superadmin"],
      guest: false,
    },
  },
];
