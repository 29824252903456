<template>
  <v-row justify="center">
    <v-col class="col-6">
      <v-select
        v-model="mocktest"
        :items="mocktests"
        item-text="title"
        item-value="id"
        :label="$lang('MOCKTEST')"
        clearable
      />
    </v-col>
    <v-col class="col-6">
      <v-select
        v-model="segment"
        :items="segments"
        item-text="title"
        item-value="id"
        :label="$lang('SEGMENT')"
        clearable
      />
    </v-col>
    <v-col class="col-12" v-if="mocktests && itemSegments">
      <v-chip
        class="ma-2"
        close
        color="orange"
        label
        small
        outlined
        @click:close="detachItemSegment(seg)"
        v-for="seg in itemSegments"
        :key="seg.id"
      >
        ({{ mocktests.find((el) => el.id == seg.prep_mocktest_id).title }})
        {{ seg.title }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";

export default {
  name: "selector-mocktest-segments",
  props: ["item"],
  data() {
    return {
      mocktests: [],
      mocktest: {},
      segments: [],
      segment: {},
      itemSegments: [],
    };
  },
  watch: {
    item(val) {
      this.fetchItemSegments(val.id);
    },
    mocktest(id) {
      this.fetchSegments(id);
    },
    segment(id) {
      id = parseInt(id);
      if (isNaN(id)) return;
      //console.log("selected segment id ", id);
      this.$emit("selected", id);
      this.attachItemSegment(id);
      this.fetchItemSegments(this.item.id);
    },
  },
  methods: {
    fetchMocktests() {
      this.$store.dispatch("dashboard/prep/MocktestsGetAll", {}).then((res) => {
        this.mocktests = res.data;
        this.fetchItemSegments(this.item.id);
      });
    },
    fetchItemSegments(item_id) {
      if (isNaN(parseInt(item_id))) return;
      return axios.get(`prep/questions/${item_id}/segments`).then((res) => {
        this.itemSegments = res.data.data;
      });
    },
    attachItemSegment(segment_id) {
      segment_id = parseInt(segment_id);
      if (isNaN(segment_id)) return;
      bus.$emit("showWait");
      return axios
        .post(`/prep/questions/attach/${this.item.id}/segments`, {
          foreign_ids: [segment_id],
        })
        .then(() => {
          this.fetchItemSegments(this.item.id);
        })
        .finally(() => {
          this.segment = {};
          bus.$emit("hideWait");
        });
    },
    detachItemSegment(segment) {
      if (!segment) return;
      let segment_id = segment.id;
      if (
        !confirm(
          `Are you sure to remove this question from segment ${segment.title}?`
        )
      ) {
        return;
      }
      segment_id = parseInt(segment_id);
      if (isNaN(segment_id)) return;
      bus.$emit("showWait");
      axios
        .post(`/prep/questions/detach/${this.item.id}/segments`, {
          foreign_ids: [segment_id],
        })
        .then(() => {
          this.fetchItemSegments(this.item.id);
        })
        .finally(() => {
          this.segment = {};
          bus.$emit("hideWait");
        });
    },
    fetchSegments(id) {
      if (isNaN(parseInt(id))) return;
      axios.get(`prep/mocktests/${id}/segments`).then((res) => {
        this.segments = res.data.data;
        this.segment = {}; //res.data.data[0]
      });
    },
  },
  created() {
    this.fetchMocktests();
  },
};
</script>