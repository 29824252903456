<template>
  <v-btn
    :loading="loading_btn"
    class="indigo"
    dark
    @click.stop="toggleTestLanguage()"
    icon
  >
    <v-icon>mdi-translate</v-icon>
  </v-btn>
</template>

<script>
import bus from "@/bus";

export default {
  name: "translate-exam-button",
  data() {
    return {
      loading_btn: false,
    };
  },
  methods: {
    toggleTestLanguage() {
      this.loading_btn = true;
      bus.$emit("showWait", "Translating...");
      let lang = this.$store.getters["app/exams/selectedTestLanguage"];
      lang = lang == "en" ? "hi" : "en";
      this.$store.dispatch("app/exams/UpdateSelectedTestLanguage", lang);
      setTimeout(() => {
        bus.$emit("hideWait");
        this.loading_btn = false;
      }, 500);
    },
  },
};
</script>

<style>
</style>