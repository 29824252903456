export default [
  {
    path: "/dashboard/logs",
    name: "dashboard-logs",
    component: () => import("../components/Logs"),
    meta: {
      title: "LOGS",
      needsAuth: true,
      verified: true,
      roles: ["admin", "superadmin"],
      guest: false,
    },
  },
  {
    path: "/dashboard/logs/user/:user_id",
    name: "dashboard-user-logs",
    component: () => import("../components/UserLogs"),
    meta: {
      title: "USER-LOGS",
      needsAuth: true,
      verified: true,
      roles: ["admin", "superadmin"],
      guest: false,
    },
  },
];
