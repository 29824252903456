export default {
  EXAMS: "परीक्षा",
  EXAM: "परीक्षा",
  ATTEMPTRESULT: "टेस्ट रिजल्ट",
  HASTESTS: "कुल %s टेस्ट्स",
  DIRECTIONS: "निर्देश",
  SUBMITCONFIRMTITLE: "क्या आप तैयार हैं?",
  SUBMITCONFIRMTEXT:
    "एक बार इस टेस्ट को सबमिट करने के बाद आप दोबारा यह टेस्ट नहीं दे सकते हैं।",
  SHOWANALYSIS: "विश्लेषण देखें",
};
