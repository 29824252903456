var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-center',[_c('v-card',{staticClass:"mx-auto"},[_c('list-with-avatar',{attrs:{"items":_vm.items},on:{"toggleActive":({ clickedItem }) => {
          _vm.$store.dispatch('dashboard/prep/NotesToggleActive', {
            item: clickedItem,
          });
        },"update":({ clickedItem }) => {
          _vm.$router.push({
            name: 'dashboard-prep-notes-create',
            params: { id: clickedItem.id },
          });
        },"clicked":({ clickedItem }) => {
          _vm.$router.push({
            name: 'dashboard-prep-notes-create',
            params: { id: clickedItem.id },
          });
        }}}),_c('fab-button',{attrs:{"icon":'mdi-plus',"color":"primary"},on:{"clicked":function($event){return _vm.$router.push({ name: 'dashboard-prep-notes-create' })}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }