import { axios, setHttpToken } from "@/plugins/axios.js";
import { isEmpty } from "lodash";
import localforage from "localforage";
import bus from "@/bus";
import { locale } from "core-js";
import store from "@/store";

function dispatchErrorAlert(error) {
  store.dispatch("apiAlert", {
    message:
      error.response.data && error.response.data.message
        ? error.response.data.message
        : error.response.status,
    code: error.response.status,
  });
}

export const getAll = ({ dispatch }, { uri, context }) => {
  bus.$emit("showWait");
  return axios
    .get(`prep/${uri}`)
    .then((response) => {
      if (context) {
        context.pagination = {
          lastPage: response.data.lastPage,
          page: response.data.page,
          perPage: response.data.perPage,
          total: response.data.total,
        };
        context.items = response.data.data;
      }
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const getOne = ({ dispatch }, { uri, id, context }) => {
  bus.$emit("showWait");
  return axios
    .get(`prep/${uri}/${id}`)
    .then((response) => {
      if (context) context.item = response.data;
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const toggler = ({ dispatch }, { uri, item }) => {
  bus.$emit("showWait");
  return axios
    .get(`prep/${uri}/toggle/${item.id}`)
    .then((response) => {
      bus.$emit("hideWait");
      item.active = !item.active;
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const togglerFeatured = ({ dispatch }, { uri, item }) => {
  bus.$emit("showWait");
  return axios
    .get(`prep/${uri}/featured/${item.id}`)
    .then((response) => {
      bus.$emit("hideWait");
      item.featured = !item.featured;
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const update = ({ dispatch }, { uri, item }) => {
  bus.$emit("showWait");
  return axios
    .post(`prep/${uri}/${item.id}`, item)
    .then((response) => {
      bus.$emit("hideWait");
      return Promise.resolve(response.data.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const create = ({ dispatch }, { uri, item }) => {
  bus.$emit("showWait");
  return axios
    .post(`prep/${uri}`, item)
    .then((response) => {
      bus.$emit("hideWait");
      return Promise.resolve(response.data.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const remove = ({ dispatch }, { uri, item }) => {
  if (
    !confirm(`Are you sure to delete ${item.title ? item.title : item.id}?`)
  ) {
    return Promise.reject("CANCELED_BY_USER");
  }
  bus.$emit("showWait");
  return axios
    .delete(`prep/${uri}/${item.id}`)
    .then((response) => {
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};
export const updateBanner = (
  { dispatch },
  { uri, files, kind, item, context }
) => {
  if (!files.files[0]) {
    dispatch(
      "setAlert",
      { type: "error", message: "PLEASE_CHOOSE_AN_IMAGE" },
      { root: true }
    );
    return;
  }

  bus.$emit("showWait");

  var formData = new FormData();
  formData.append("banner", files.files[0]);
  formData.append("provider", "local");

  return axios
    .post(`prep/${uri}/banner/${item.id}/${kind}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      context.imagePreview = response.data.url;
      context.item.banner_square = response.data.url;
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(err);
      return Promise.reject(error.response);
    });
};

export const updateBannerSquare = ({ dispatch }, { uri, files, context }) => {
  return dispatch("updateBanner", {
    item: context.item,
    files,
    context,
    uri,
    kind: "square",
  });
};

export const ExamsUpdateBanner = ({ dispatch }, { context, files }) => {
  return dispatch("updateBannerSquare", { files, context, uri: "exams" });
};
export const SubjectsUpdateBanner = ({ dispatch }, { context, files }) => {
  return dispatch("updateBannerSquare", { files, context, uri: "subjects" });
};
export const MocktestsUpdateBanner = ({ dispatch }, { context, files }) => {
  return dispatch("updateBannerSquare", { files, context, uri: "mocktests" });
};
export const NotesUpdateBanner = ({ dispatch }, { context, files }) => {
  return dispatch("updateBannerSquare", { files, context, uri: "notes" });
};
export const JobsUpdateBanner = ({ dispatch }, { context, files }) => {
  return dispatch("updateBannerSquare", { files, context, uri: "jobs" });
};
export const SegmentsUpdateBanner = ({ dispatch }, { context, files }) => {
  return dispatch("updateBannerSquare", { files, context, uri: "segments" });
};

export const ExamsUpdate = ({ dispatch }, { item, context }) => {
  return dispatch("update", { context, item, uri: "exams" });
};
export const QuestionsUpdate = ({ dispatch }, { item, context }) => {
  return dispatch("update", { context, item, uri: "questions" });
};
export const MocktestsUpdate = ({ dispatch }, { item, context }) => {
  return dispatch("update", { context, item, uri: "mocktests" });
};
export const TagsUpdate = ({ dispatch }, { item, context }) => {
  return dispatch("update", { context, item, uri: "tags" });
};
export const SubjectsUpdate = ({ dispatch }, { item, context }) => {
  return dispatch("update", { context, item, uri: "subjects" });
};
export const NotesUpdate = ({ dispatch }, { item, context }) => {
  return dispatch("update", { context, item, uri: "notes" });
};
export const JobsUpdate = ({ dispatch }, { item, context }) => {
  return dispatch("update", { context, item, uri: "jobs" });
};
export const SegmentsUpdate = ({ dispatch }, { item, context }) => {
  return dispatch("update", { context, item, uri: "segments" });
};

export const ExamsCreate = ({ dispatch }, { item, context }) => {
  return dispatch("create", { context, item, uri: "exams" });
};
export const QuestionsCreate = ({ dispatch }, { item, context }) => {
  return dispatch("create", { context, item, uri: "questions" });
};
export const MocktestsCreate = ({ dispatch }, { item, context }) => {
  return dispatch("create", { context, item, uri: "mocktests" });
};
export const TagsCreate = ({ dispatch }, { item, context }) => {
  return dispatch("create", { context, item, uri: "tags" });
};
export const SubjectsCreate = ({ dispatch }, { item, context }) => {
  return dispatch("create", { context, item, uri: "subjects" });
};
export const NotesCreate = ({ dispatch }, { item, context }) => {
  return dispatch("create", { context, item, uri: "notes" });
};
export const JobsCreate = ({ dispatch }, { item, context }) => {
  return dispatch("create", { context, item, uri: "jobs" });
};
export const SegmentsCreate = ({ dispatch }, { item, context }) => {
  return dispatch("create", { context, item, uri: "segments" });
};

export const ExamsRemove = ({ dispatch }, { item, context }) => {
  return dispatch("remove", { context, item, uri: "exams" });
};
export const QuestionsRemove = ({ dispatch }, { item, context }) => {
  return dispatch("remove", { context, item, uri: "questions" });
};
export const MocktestsRemove = ({ dispatch }, { item, context }) => {
  return dispatch("remove", { context, item, uri: "mocktests" });
};
export const TagsRemove = ({ dispatch }, { item, context }) => {
  return dispatch("remove", { context, item, uri: "tags" });
};
export const SubjectsRemove = ({ dispatch }, { item, context }) => {
  return dispatch("remove", { context, item, uri: "subjects" });
};
export const NotesRemove = ({ dispatch }, { item, context }) => {
  return dispatch("remove", { context, item, uri: "notes" });
};
export const JobsRemove = ({ dispatch }, { item, context }) => {
  return dispatch("remove", { context, item, uri: "jobs" });
};
export const SegmentsRemove = ({ dispatch }, { item, context }) => {
  return dispatch("remove", { context, item, uri: "segments" });
};
export const AttemptsRemove = ({ dispatch }, { item, context }) => {
  return dispatch("remove", { context, item, uri: "attempts" });
};
export const ReportsRemove = ({ dispatch }, { item, context }) => {
  return dispatch("remove", { context, item, uri: "reports" });
};

export const ExamsToggleFeatured = ({ dispatch }, { item, context }) => {
  return dispatch("togglerFeatured", { context, item, uri: "exams" });
};

export const ExamsToggleActive = ({ dispatch }, { item, context }) => {
  return dispatch("toggler", { context, item, uri: "exams" });
};
export const QuestionsToggleActive = ({ dispatch }, { item, context }) => {
  return dispatch("toggler", { context, item, uri: "questions" });
};
export const MocktestsToggleActive = ({ dispatch }, { item, context }) => {
  return dispatch("toggler", { context, item, uri: "mocktests" });
};
export const TagsToggleActive = ({ dispatch }, { item, context }) => {
  return dispatch("toggler", { context, item, uri: "tags" });
};
export const SubjectsToggleActive = ({ dispatch }, { item, context }) => {
  return dispatch("toggler", { context, item, uri: "subjects" });
};
export const NotesToggleActive = ({ dispatch }, { item, context }) => {
  return dispatch("toggler", { context, item, uri: "notes" });
};
export const JobsToggleActive = ({ dispatch }, { item, context }) => {
  return dispatch("toggler", { context, item, uri: "jobs" });
};
export const SegmentsToggleActive = ({ dispatch }, { item, context }) => {
  return dispatch("toggler", { context, item, uri: "segments" });
};
export const AttemptsToggleActive = ({ dispatch }, { item, context }) => {
  return dispatch("toggler", { context, item, uri: "attempts" });
};
export const ReportsToggleActive = ({ dispatch }, { item, context }) => {
  return dispatch("toggler", { context, item, uri: "reports" });
};

export const QuestionsGetAll = ({ dispatch }, { context }) => {
  return dispatch("getAll", { context, uri: "questions" });
};
export const TagsGetAll = ({ dispatch }, { context }) => {
  return dispatch("getAll", { context, uri: "tags" });
};
export const MocktestsGetAll = ({ dispatch }, { context }) => {
  return dispatch("getAll", { context, uri: "mocktests" });
};
export const SubjectsGetAll = ({ dispatch }, { context }) => {
  return dispatch("getAll", { context, uri: "subjects" });
};
export const ExamsGetAll = ({ dispatch }, { context }) => {
  return dispatch("getAll", { context, uri: "exams" });
};
export const NotesGetAll = ({ dispatch }, { context }) => {
  return dispatch("getAll", { context, uri: "notes" });
};
export const JobsGetAll = ({ dispatch }, { context }) => {
  return dispatch("getAll", { context, uri: "jobs" });
};
export const SegmentsGetAll = ({ dispatch }, { context }) => {
  return dispatch("getAll", { context, uri: "segments" });
};

export const QuestionsGetOne = ({ dispatch }, { context, id }) => {
  return dispatch("getOne", { context, id, uri: "questions" });
};
export const TagsGetOne = ({ dispatch }, { context, id }) => {
  return dispatch("getOne", { context, id, uri: "tags" });
};
export const MocktestsGetOne = ({ dispatch }, { context, id }) => {
  return dispatch("getOne", { context, id, uri: "mocktests" });
};
export const SubjectsGetOne = ({ dispatch }, { context, id }) => {
  return dispatch("getOne", { context, id, uri: "subjects" });
};
export const ExamsGetOne = ({ dispatch }, { context, id }) => {
  return dispatch("getOne", { context, id, uri: "exams" });
};
export const NotesGetOne = ({ dispatch }, { context, id }) => {
  return dispatch("getOne", { context, id, uri: "notes" });
};
export const JobsGetOne = ({ dispatch }, { context, id }) => {
  return dispatch("getOne", { context, id, uri: "jobs" });
};
export const SegmentsGetOne = ({ dispatch }, { context, id }) => {
  return dispatch("getOne", { context, id, uri: "segments" });
};

export const resetState = ({ commit }) => {
  commit("SET_AUTHENTICATED", false);
  commit("SET_USER", null);
  commit("SET_TOKEN", null);
};
