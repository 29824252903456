<template>
  <layout-center>
    <form>
      <v-card v-if="item">
        <v-card-title>
          <div class="title">
            {{ $lang(item.id ? "UPDATE" : "NEW") + " " + $lang("USER") }}
          </div>
          <v-spacer></v-spacer>

          <avatar-uploader
            v-if="item && item.id"
            :item="item"
            dispatch="dashboard/auth/UsersUpdateAvatar"
          />
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            :label="$lang('USERNAME')"
            :hint="$lang('USERNAME-HINT')"
            v-model="item.username"
            type="text"
            :error-messages="errors && errors.username ? errors.username : []"
          ></v-text-field>

          <v-text-field
            :label="$lang('EMAIL')"
            :hint="$lang('EMAIL-HINT')"
            v-model="item.email"
            type="email"
            :error-messages="errors && errors.email ? errors.email : []"
          ></v-text-field>

          <v-text-field
            v-if="!item.id"
            :label="$lang('PASSWORD')"
            :hint="$lang('PASSWORD-HINT')"
            v-model="item.password"
            type="password"
            :error-messages="errors && errors.password ? errors.password : []"
          ></v-text-field>
          <selector-role
            v-if="item.id"
            :select="item.role"
            @selected="
              (role) => {
                if (role == item.role) return;
                $store.dispatch('dashboard/auth/usersAssignRole', {
                  item,
                  role,
                });
              }
            "
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <div class="text-xs-center">
            <v-btn outlined @click="doSubmit">{{
              $lang(item.id ? "UPDATE" : "CREATE")
            }}</v-btn>
            <v-btn
              text
              @click="$router.push({ name: 'dashboard-auth-users' })"
              >{{ $lang("CANCEL") }}</v-btn
            >
          </div>
          <v-spacer></v-spacer>
          <v-btn v-if="item.id" color="red" outlined @click="remove()">
            <v-icon>mdi-delete</v-icon> {{ $lang("REMOVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </layout-center>
</template>

<script>
import { mapGetters } from "vuex";
// import VueTip from "@/components/VueTip";
import LayoutCenter from "@/layouts/LayoutCenter.vue";
import AvatarUploader from "../modules/AvatarUploader";
import SelectorRole from "../modules/SelectorRole.vue";

export default {
  name: "dashboard-auth-users-create",
  components: {
    // VueTip,
    LayoutCenter,
    AvatarUploader,
    SelectorRole,
  },
  data() {
    return {
      item: {
        id: null,
        username: "",
        email: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    remove() {
      this.$store
        .dispatch("dashboard/auth/usersRemove", {
          item: this.item,
          context: this,
        })
        .then(() => {
          this.$router.replace({ name: "dashboard-auth-users" });
        });
    },
    fetchItem(id) {
      this.item.id = id;
      this.$store
        .dispatch("dashboard/auth/usersGetOne", {
          id: this.item.id,
          context: this,
        })
        .catch(() => {
          this.$router.replace({ name: "dashboard-auth-users" });
        });
    },
    doSubmit() {
      if (this.$route.params.id) {
        this.$store.dispatch("dashboard/auth/usersUpdate", {
          item: this.item,
          context: this,
        });
      } else {
        this.$store
          .dispatch("dashboard/auth/usersCreate", {
            item: this.item,
            context: this,
          })
          .then((data) => {
            this.$router.replace({
              name: "dashboard-auth-users",
            });
          });
      }
    },
  },
  watch: {
    "$route.params.id"(id) {
      this.fetchItem(id);
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchItem(this.$route.params.id);
    }
  },
};
</script>
