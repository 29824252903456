var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$store.getters['app/exams/selectedTestLanguage'] == 'en')?_c('div',{staticClass:"question"},[_c('v-card',{staticClass:"mb-1",attrs:{"flat":""}},[_c('v-card-title',[(_vm.question.id)?_c('report-question-dialog',{staticClass:"btn-pos mr-2",attrs:{"withAnswer":"true","question_id":_vm.question.id}}):_vm._e(),_c('div',{staticClass:"border-lr"},[_c('div',{staticClass:"circle"},[_vm._v(_vm._s(_vm.idx + 1)+".")]),_c('latex-html',{attrs:{"html":_vm.question.title}})],1)],1)],1),_c('v-card',{attrs:{"flat":""}},[(_vm.question.description && _vm.question.description.length > 8)?_c('v-card-text',[_c('latex-html',{attrs:{"html":_vm.question.description}})],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"optn-row"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-chip',{class:_vm.getColor(_vm.question, '1')},[_vm._v("A")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('latex-html',{attrs:{"html":_vm.question.option_a}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"optn-row"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-chip',{class:_vm.getColor(_vm.question, '2')},[_vm._v("B")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('latex-html',{attrs:{"html":_vm.question.option_b}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"optn-row"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-chip',{class:_vm.getColor(_vm.question, '3')},[_vm._v("C")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('latex-html',{attrs:{"html":_vm.question.option_c}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"optn-row"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-chip',{class:_vm.getColor(_vm.question, '4')},[_vm._v("D")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('latex-html',{attrs:{"html":_vm.question.option_d}})],1)],1)],1)],1),_c('v-divider'),(
        _vm.showAnswers && _vm.question.explanation && _vm.question.explanation.length > 8
      )?_c('v-card-text',[_c('latex-html',{attrs:{"html":_vm.question.explanation}})],1):_vm._e(),_c('v-divider'),(
        _vm.showAnswers &&
        _vm.question.explanation_video &&
        _vm.question.explanation_video.length == 11
      )?_c('v-card-text',[_c('question-explanation-video',{staticClass:"mt-4",attrs:{"videoId":_vm.question.explanation_video}})],1):_vm._e()],1)],1):_c('div',{staticClass:"question"},[_c('v-card',{staticClass:"mb-1",attrs:{"flat":""}},[_c('v-card-title',[(_vm.question.id)?_c('report-question-dialog',{staticClass:"btn-pos mr-2",attrs:{"withAnswer":"true","question_id":_vm.question.id}}):_vm._e(),_c('div',{staticClass:"border-lr"},[_c('div',{staticClass:"circle"},[_vm._v(_vm._s(_vm.idx + 1)+".")]),_c('latex-html',{attrs:{"html":_vm.question.title_hi}})],1)],1)],1),_c('v-card',{attrs:{"flat":""}},[(_vm.question.description_hi && _vm.question.description_hi.length > 8)?_c('v-card-text',[_c('latex-html',{attrs:{"html":_vm.question.description_hi}})],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"optn-row"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-chip',{class:_vm.getColor(_vm.question, '1')},[_vm._v("A")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('latex-html',{attrs:{"html":_vm.question.option_a_hi}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"optn-row"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-chip',{class:_vm.getColor(_vm.question, '2')},[_vm._v("B")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('latex-html',{attrs:{"html":_vm.question.option_b_hi}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"optn-row"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-chip',{class:_vm.getColor(_vm.question, '3')},[_vm._v("C")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('latex-html',{attrs:{"html":_vm.question.option_c_hi}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"optn-row"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-chip',{class:_vm.getColor(_vm.question, '4')},[_vm._v("D")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('latex-html',{attrs:{"html":_vm.question.option_d_hi}})],1)],1)],1)],1),_c('v-divider'),(
        _vm.showAnswers &&
        _vm.question.explanation_hi &&
        _vm.question.explanation_hi.length > 8
      )?_c('v-card-text',[_c('latex-html',{attrs:{"html":_vm.question.explanation_hi}})],1):_vm._e(),_c('v-divider'),(
        _vm.showAnswers &&
        _vm.question.explanation_video &&
        _vm.question.explanation_video.length == 11
      )?_c('v-card-text',[_c('question-explanation-video',{staticClass:"mt-4",attrs:{"videoId":_vm.question.explanation_video}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }