<template>
  <v-card>
    <v-card-text class="my-4">
      <v-btn
        color="green"
        :block="!itemTags || itemTags.length < 1"
        dark
        @click="showNewTagDialog = true"
      >
        <v-icon>mdi-plus</v-icon>
        Tags
      </v-btn>
      <v-chip
        class="ma-2"
        close
        color="green"
        label
        small
        outlined
        v-for="tg in itemTags"
        @click:close="detachItemTag(tg.id)"
        :key="tg.id"
      >
        {{ tg.title }}
      </v-chip>
    </v-card-text>
    <NewTagDialog
      :show="showNewTagDialog"
      :ident="ident"
      :tags="tags"
      @closed="
        (closed) => {
          showNewTagDialog = !closed;
        }
      "
      @created="(tag) => tagCreated(tag)"
    />
  </v-card>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import NewTagDialog from "./NewTagDialog";
export default {
  name: "selector-tags",
  props: ["item", "ident"],
  components: {
    NewTagDialog,
  },
  data() {
    return {
      tags: [],
      allTags: [],
      tag: {},
      itemTags: [],
      showNewTagDialog: null,
    };
  },
  watch: {
    item(val) {
      this.fetchItemTags(val.id);
    },
    itemTags() {
      this.filterTags();
    },
    tag(id) {
      id = parseInt(id);
      if (isNaN(id)) return;
      console.log("id", id);
      this.attachItemTag(id);
    },
  },
  methods: {
    filterTags() {
      this.tags = this.allTags.filter((el) => {
        return !this.itemTags.find((_el) => _el.id == el.id);
      });
    },
    tagCreated(tag) {
      this.attachItemTag(tag.id);
      this.fetchTags();
      this.filterTags();
    },
    attachItemTag(tag_id) {
      tag_id = parseInt(tag_id);
      if (isNaN(tag_id)) return;
      bus.$emit("showWait");
      return axios
        .post(`prep/${this.ident}/attach/${this.item.id}/tags`, {
          foreign_ids: [tag_id],
        })
        .then(() => {
          this.fetchItemTags(this.item.id);
        })
        .finally(() => {
          this.tag = {};
          bus.$emit("hideWait");
        });
    },
    detachItemTag(tag_id) {
      tag_id = parseInt(tag_id);
      if (isNaN(tag_id)) return;
      bus.$emit("showWait");
      return axios
        .post(`prep/${this.ident}/detach/${this.item.id}/tags`, {
          foreign_ids: [tag_id],
        })
        .then(() => {
          this.fetchItemTags(this.item.id);
        })
        .finally(() => {
          this.tag = {};
          bus.$emit("hideWait");
        });
    },
    fetchItemTags(id) {
      if (isNaN(parseInt(id))) return;
      bus.$emit("showWait");
      return axios
        .get(`prep/${this.ident}/${id}/tags`)
        .then((res) => {
          this.itemTags = res.data.data;
        })
        .then(() => {
          this.filterTags();
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    fetchTags() {
      bus.$emit("showWait");
      return axios
        .get(`prep/tags/where/ident/${this.ident}`)
        .then((res) => {
          this.allTags = res.data.data;
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    this.fetchTags().then(() => {
      this.fetchItemTags(this.item.id);
    });
  },
};
</script>