var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items && _vm.items.length > 0)?_c('v-list',{attrs:{"subheader":"","two-line":""}},_vm._l((_vm.items),function(item){return _c('v-list-item',{directives:[{name:"long-press",rawName:"v-long-press",value:(
        () => {
          _vm.$emit('update', { clickedItem: item });
        }
      ),expression:"\n        () => {\n          $emit('update', { clickedItem: item });\n        }\n      "}],key:item.id,on:{"click":function($event){$event.stopPropagation();return _vm.$emit('clicked', { clickedItem: item })}}},[_c('v-list-item-content',[_c('div',[_c('v-btn',{attrs:{"id":'highlight-' + item.id,"icon":"","color":item.active ? 'green' : 'red'},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toggleActive', { clickedItem: item })}}},[_c('v-icon',[_vm._v(_vm._s(item.active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline"))])],1),_vm._v(" "+_vm._s(_vm.$store.getters.getCurrLocale == "hi" ? item.title_hi ? item.title_hi : item.title : item.title)+" ")],1),_c('v-list-item-subtitle',[(item.user)?_c('v-chip',{attrs:{"label":"","x-small":""}},[_vm._v(_vm._s(item.user.username))]):_vm._e(),(_vm.idPrefix && item.id)?_c('v-chip',{staticClass:"mr-1 black accent-4 white--text",attrs:{"label":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return (() => {
                _vm.copyToClipboard(`#QE${item.id} -- ${item.title} ${
                  item.description ? '\r\n' + item.description : ''
                }
(A). ${item.option_a}
(B). ${item.option_b}
(C). ${item.option_c}
(D). ${item.option_d}

#QE${item.id} -- ${item.title_hi} ${
                  item.description_hi ? '\r\n' + item.description_hi : ''
                }
(अ). ${item.option_a_hi}
(ब). ${item.option_b_hi}
(स). ${item.option_c_hi}
(द). ${item.option_d_hi}


`);
              }).apply(null, arguments)}}},[_vm._v("Copy ")]):_vm._e(),(_vm.idPrefix && item.id)?_c('v-chip',{staticClass:"mr-1 black accent-4 white--text",attrs:{"label":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return (() => {
                _vm.copyToClipboard('#' + _vm.idPrefix + ' - Explanation / स्पष्टीकरण');
              }).apply(null, arguments)}}},[_vm._v("#"+_vm._s(_vm.idPrefix)+_vm._s(item.id))]):_vm._e(),(item.subject)?_c('v-chip',{attrs:{"label":"","x-small":""}},[_vm._v(_vm._s(item.subject.title))]):_vm._e()],1),_c('div',{staticStyle:{"border-bottom":"1px dashed","margin-top":"5px"}})],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.menu),function(mItem){return _c('v-list-item',{key:mItem.id,on:{"click":function($event){return mItem.to(item)}}},[_c('v-icon',[_vm._v(_vm._s(mItem.icon(item)))]),_vm._v(" "+_vm._s(mItem.title)+" ")],1)}),1)],1)],1)],1)}),1):_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(_vm._s(_vm.$lang("NOITEMS")))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }