<template>
  <div v-if="variant == 'none'" style="border-bottom: 0px solid gray">
    <v-textarea
      :label="placeholder"
      filled
      auto-grow
      :rows="minRows ? minRows : 2"
      v-model="content"
      :error-messages="errors"
      :tabindex="tabindex"
      ref="etEditor"
    ></v-textarea>
  </div>
  <div v-else style="border-bottom: 2px solid gray" class="my-0">
    <froala
      v-if="variant == 'froala'"
      id="edit"
      :tag="'textarea'"
      :config="froalaConfig"
      v-model="content"
    ></froala>
    <!-- Use the component in the right place of the template -->
    <tiptap-vuetify
      v-else
      v-model="content"
      :extensions="
        variant && variant != null ? extensions[variant] : extensions['default']
      "
      :placeholder="placeholder"
      :card-props="{ elevation: 0 }"
      :toolbar-attributes="{ color: color ? color : 'muted', dark: dark }"
      :error-messages="errors"
      :tabindex="tabindex"
      @init="onInit"
    />
  </div>
</template>

<script>
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Image,
  Heading,
  Bold,
  Italic,
  Strike,
  TodoList,
  TodoItem,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import VueTipFileSelector from "@/components/VueTipFileSelector";
import UploadArea from "@/components/UploadArea";
export default {
  props: [
    "initial",
    "tabindex",
    "variant",
    "errors",
    "placeholder",
    "color",
    "dark",
    "minRows",
    "identity",
  ],
  name: "vue-tip",
  // specify TiptapVuetify component in "components"
  components: { TiptapVuetify },
  data: () => ({
    froalaConfig: {
      events: {
        initialized: function () {
          console.log("initialized");
        },
        focus: function (e, editor) {
          console.log("EVENT FOCUS", editor.selection.get());
        },
      },
    },
    content: "",
    // declare extensions you want to use
    extensions: {
      none: [],
      minimal: [
        Bold,
        Italic,
        Underline,
        Link,
        [
          Heading,
          {
            options: {
              levels: [1],
            },
          },
        ],
        HardBreak,
      ],
      extended: [
        Bold,
        Italic,
        Underline,
        [
          Heading,
          {
            options: {
              levels: [2],
            },
          },
        ],
        ListItem,
        BulletList,
        Link,
        [
          Image,
          {
            options: {
              imageSources: [{ component: UploadArea, name: "Paste Area" }],
            },
          },
        ],
        Table,
        TableCell,
        TableHeader,
        TableRow,
        HorizontalRule,
        HardBreak,
      ],
      default: [
        Bold,
        Italic,
        Underline,
        Strike,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Blockquote,
        ListItem,
        BulletList,
        OrderedList,
        Link,
        [
          Image,
          {
            options: {
              imageSources: [{ component: UploadArea, name: "Paste Area" }],
            },
          },
        ],
        Code,
        HorizontalRule,
        Paragraph,
        Table,
        TableCell,
        TableHeader,
        TableRow,
        History,
        HardBreak,
      ],
    },
    editor: null,
  }),
  created() {
    this.content = this.initial;
  },
  watch: {
    initial(val) {
      this.content = val;
    },
    content(val) {
      this.$emit("changed", val);
    },
  },
  methods: {
    onInit({ editor }) {
      this.editor = editor;
      this.$emit("onInit", editor);
    },
  },
  mounted() {
    if (this.editor) {
      setTimeout(() => {
        this.editor.focus("end");
      }, 50);
    }
  },
};
</script>