export const rows = (state) => {
  return state.rows;
};
export const segment = (state) => {
  return state.segment;
};

export const tags = (state) => {
  return state.tags;
};

export const selectedRow = (state) => {
  return state.rows[state.selectedRowIndex];
};

export const richTextFields = (state) => {
  return state.richTextFields;
};

export const selectedCell = (state) => {
  if (state.selectedRowIndex < 0 || !state.selectedRowField) {
    return "";
  }

  return state.rows[state.selectedRowIndex][state.selectedRowField];
};

export const historyRows = (state) => {
  return state.historyRows;
};

export const colViewModeItems = (state) => {
  return state.colViewModeItems;
};

export const colViewMode = (state) => {
  return state.colViewMode;
};

export const columnDefs = (state) => {
  return state.columnDefs;
};
