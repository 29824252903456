<template>
  <v-layout justify-center align-center>
    <v-flex md8 lg6>
      <slot></slot>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  name: "layout-center",
};
</script>