import Vue from "vue";

import _Home from "./Home";
export const Home = Vue.component("home", _Home);

import _Pdf from "./Pdf";
export const Pdf = Vue.component("pdf", _Pdf);

import _PdfSingle from "./PdfSingle";
export const PdfSingle = Vue.component("pdf-single", _PdfSingle);

import _OneQuestionPerPage from "./OneQuestionPerPage";
export const OneQuestionPerPage = Vue.component(
  "one-question-per-page",
  _OneQuestionPerPage
);
