import { Jobs, JobsCreate } from "../components";
import roles from "./roles";

export default [
  {
    path: "/dashboard/prep/jobs",
    name: "dashboard-prep-jobs",
    component: Jobs,
    meta: {
      title: "JOBS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/jobs/create/:id?",
    name: "dashboard-prep-jobs-create",
    component: JobsCreate,
    meta: {
      title: "CREATE-JOBS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
];
