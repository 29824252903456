<template>
  <v-card>
    <v-card-text class="my-4">
      <v-btn
        color="orange darken-3"
        :block="!itemBuyers || itemBuyers.length < 1"
        dark
        @click="showNewBuyerDialog = true"
      >
        <v-icon>mdi-plus</v-icon>
        Student
      </v-btn>
      <v-chip
        class="ma-2"
        close
        color="orange"
        label
        small
        outlined
        v-for="byr in itemBuyers"
        @click:close="detachItemBuyer(byr.id)"
        :key="byr.id"
      >
        {{ byr.username }}
      </v-chip>
    </v-card-text>
    <NewBuyerDialog
      :show="showNewBuyerDialog"
      :ident="ident"
      :buyers="buyers"
      @closed="
        (closed) => {
          showNewBuyerDialog = !closed;
        }
      "
      @created="(buyer) => buyerCreated(buyer)"
    />
  </v-card>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import NewBuyerDialog from "./NewBuyerDialog";
export default {
  name: "selector-buyers",
  props: ["item", "ident"],
  components: {
    NewBuyerDialog,
  },
  data() {
    return {
      buyers: [],
      allBuyers: [],
      buyer: {},
      itemBuyers: [],
      showNewBuyerDialog: null,
    };
  },
  watch: {
    item(val) {
      this.fetchItemBuyers(val.id);
    },
    itemBuyers() {
      this.filterBuyers();
    },
    buyer(id) {
      id = parseInt(id);
      if (isNaN(id)) return;
      console.log("id", id);
      this.attachItemBuyer(id);
    },
  },
  methods: {
    filterBuyers() {
      this.buyers = this.allBuyers.filter((el) => {
        return !this.itemBuyers.find((_el) => _el.id == el.id);
      });
    },
    buyerCreated(buyer) {
      this.attachItemBuyer(buyer.id);
      this.fetchBuyers();
      this.filterBuyers();
    },
    attachItemBuyer(buyer_id) {
      buyer_id = parseInt(buyer_id);
      if (isNaN(buyer_id)) return;
      bus.$emit("showWait");
      return axios
        .post(`prep/mocktests/attach/${this.item.id}/buyers`, {
          foreign_ids: [buyer_id],
        })
        .then(() => {
          this.fetchItemBuyers(this.item.id);
        })
        .finally(() => {
          this.buyer = {};
          bus.$emit("hideWait");
        });
    },
    detachItemBuyer(buyer_id) {
      buyer_id = parseInt(buyer_id);
      if (isNaN(buyer_id)) return;
      bus.$emit("showWait");
      return axios
        .post(`prep/mocktests/detach/${this.item.id}/buyers`, {
          foreign_ids: [buyer_id],
        })
        .then(() => {
          this.fetchItemBuyers(this.item.id);
        })
        .finally(() => {
          this.buyer = {};
          bus.$emit("hideWait");
        });
    },
    fetchItemBuyers(id) {
      if (isNaN(parseInt(id))) return;
      bus.$emit("showWait");
      return axios
        .get(`prep/mocktests/${id}/buyers`)
        .then((res) => {
          this.itemBuyers = res.data.data;
        })
        .then(() => {
          this.filterBuyers();
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    fetchBuyers() {
      bus.$emit("showWait");
      return axios
        .get(`auth/users`)
        .then((res) => {
          this.allBuyers = res.data;
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    this.fetchBuyers().then(() => {
      this.fetchItemBuyers(this.item.id);
    });
  },
};
</script>