<template>
    <form enctype="multipart/form-data" @submit.prevent="doUpload()">
        <div class="image-box">
          <v-avatar  >
              <img
                v-if="auth.user.profile.avatar"
                :src="imagePreview ? imagePreview : auth.user.profile.avatar"
                alt="Avatar"
                @click="openUpload"
              >
              <v-icon v-else large @click="openUpload">mdi-face</v-icon>
          </v-avatar>
        </div>
        <input
            name="file"
            type="file"
            id="file"
            @change="updatePreview"
            style="display:none;" >
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'drawer-avatar-img',
    data () {
      return {
        imagePreview: null,
      }
    },
    computed: {
      ...mapGetters({
        auth:"auth/auth"
      }),
    },
    
    methods: {
      ...mapActions({
          updateAvatar : 'auth/updateAvatar'
      }),
      openUpload () {
        document.getElementById('file').click()
      },
      updatePreview (e) {

        var reader, files = e.target.files
        if (files.length === 0) {
          console.log('Empty')
        }
        reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result
        }
        if(files[0])
          reader.readAsDataURL(files[0])
          this.doUpload();
      },

      doUpload(){
        var file = document.querySelector('#file');
        this.updateAvatar({ file: file, context : this });
      }

    }
}
</script>
<style scoped>
img {
  -webkit-transition: -webkit-transform 1s; /* Safari */
  transition: transform 1s;
}

img:hover {
  -webkit-transform: rotate(360deg); /* Safari */
  transform: rotate(360deg);
  cursor: pointer;
}
</style>
