export default {
  EXAMS: "Exams",
  ATTEMPTRESULT: "Test Result",
  HASTESTS: "Total %s tests",
  DIRECTIONS: "Directions",
  SUBMITCONFIRMTITLE: "Are you ready?",
  SUBMITCONFIRMTEXT: "Once you submit this test, you can not attempt it again.",
  SHOWANALYSIS: "Show Analysis",
  POINTSOBTAINED: "Points Obtained",
  UNLOCK: "Unlock for 🌕 %s vcoins",
};
