<template>
  <layout-center>
    <v-card class="mx-auto">
      <v-card-title>
        <v-btn icon @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <v-spacer></v-spacer>
        <h2>
          {{
            $store.getters.getCurrLocale == "hi"
              ? item.title_hi
                ? item.title_hi
                : item.title
              : item.title
          }}
        </h2>
      </v-card-title>
      <v-divider></v-divider>
      <list-item-details
        :items="item.questions"
        idPrefix="QE"
        @toggleActive="
          ({ clickedItem }) => {
            $store.dispatch('dashboard/prep/QuestionsToggleActive', {
              item: clickedItem,
            });
          }
        "
        @update="
          ({ clickedItem }) => {
            $router.push({
              name: 'dashboard-prep-questions-create',
              params: { id: clickedItem.id },
              query: {
                segment_id: item.id,
              },
            });
          }
        "
        @remove="
          ({ clickedItem }) => {
            handleRemove(clickedItem);
          }
        "
        @clicked="
          ({ clickedItem }) => {
            $router.push({
              name: 'dashboard-prep-questions-create',
              params: { id: clickedItem.id },
              query: {
                segment_id: item.id,
              },
            });
          }
        "
      />
      <fab-button
        :icon="'mdi-plus'"
        color="primary"
        @clicked="
          $router.push({
            name: 'dashboard-prep-questions-create',
            query: {
              segment_id: item.id,
            },
          })
        "
      />
    </v-card>
  </layout-center>
</template>

<script>
import { axios } from "@/plugins/axios";
import { mapGetters } from "vuex";
import FabButton from "@/components/FabButton.vue";
import ListItemDetails from "../../modules/ListItemDetails.vue";
import LayoutCenter from "@/layouts/LayoutCenter.vue";
import bus from "@/bus";
export default {
  name: "dashboard-prep-segments-questions",
  components: {
    FabButton,
    ListItemDetails,
    LayoutCenter,
  },
  data() {
    return {
      item: {},
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
  },
  watch: {},
  methods: {
    handleRemove(clickedItem) {
      console.log("remove question from this segment");
      // console.log("question.id", clickedItem.id);
      // console.log("segment id", this.item.id);
      //dettach question from segment
      return this.detachQuestion(clickedItem);
    },
    detachQuestion(question) {
      if (!question || !question.id) {
        return;
      }

      if (
        !confirm(
          `Are you sure to remove '${question.title}' from segment ${this.item.title}?`
        )
      ) {
        return;
      }

      bus.$emit("showWait");
      console.log("this.item.id", this.item.id);
      console.log("question.id", question.id);

      return axios
        .post(`/prep/questions/detach/${question.id}/segments`, {
          foreign_ids: [this.item.id],
        })
        .then(() => {
          return this.fetchItemWithQuestions();
        })
        .finally(() => {
          this.segment = {};
          bus.$emit("hideWait");
        });
    },
    fetchItemWithQuestions() {
      let id = this.$route.params.id;
      return axios.get(`prep/segments/${id}/questions`).then((res) => {
        this.item = res.data;
      });
    },
  },
  created() {
    this.fetchItemWithQuestions();
  },
};
</script>
