<template>
  <div class="bg-page-break">
    <div class="d-flex" v-if="mocktest && mocktest.id">
      <v-btn class="mt-4" @click="isLangHindi = !isLangHindi">{{
        isLangHindi ? "hi" : "en"
      }}</v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        prefix="offset"
        placeholder="Exmaple: 1-100,5-110,7-100"
        v-model="bleedStr"
      />

      <v-spacer></v-spacer>
      <v-text-field
        style="width: 50px"
        type="number"
        prefix="Font Size"
        placeholder="Size in pixels"
        v-model="fontSize"
      />

      <v-spacer></v-spacer>
      <v-btn class="mt-4" @click="generate(mocktest)">Export PDF</v-btn>
    </div>
    <hr />

    <div
      class="page watermark"
      ref="page"
      :style="`width: ${pageDimen.width}px;font-size:${fontSize}px;`"
    >
      <header :style="`height: ${headerHeight}px;`">
        <h1 class="page-title">
          {{ isLangHindi ? mocktest.title_hi : mocktest.title }}
        </h1>
      </header>
      <div ref="questionsBlock" class="questions-block">
        <div
          class="question-block"
          :style="`margin-bottom: ${
            bleed.find((el) => el.idx == i)
              ? bleed.find((el) => el.idx == i).offset
              : 0
          }px;`"
          v-for="(qstn, i) in mocktest.questions"
          :key="i"
        >
          <div class="question-title">
            <span class="bullet">{{ i + 1 }}.</span>
            <div>
              <latex-html
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.title_hi : qstn.title"
              />
              <latex-html
                v-if="qstn.description_hi || qstn.description"
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.description_hi : qstn.description"
              />
            </div>
          </div>
          <div class="options" v-if="qstn.has_options">
            <div class="option">
              <span class="bullet">A.</span>
              <latex-html
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.option_a_hi : qstn.option_a"
              />
            </div>
            <div class="option">
              <span class="bullet">B.</span>
              <latex-html
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.option_b_hi : qstn.option_b"
              />
            </div>
            <div class="option">
              <span class="bullet">C.</span>
              <latex-html
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.option_c_hi : qstn.option_c"
              />
            </div>
            <div class="option">
              <span class="bullet">D.</span>
              <latex-html
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.option_d_hi : qstn.option_d"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
// import mocktest from "@/helpers/testmodel";
import LatexHtml from "@/components/LatexHtml";
import muktaFont from "./Mukta-Regular-normal-base64";
// import './Mukta-Regular.ttf';
import html2PDF from "jspdf-html2canvas";
import { axios } from "@/plugins/axios";
import logobase64 from "./logo.base64";
export default {
  components: { LatexHtml },
  data() {
    return {
      pageDimen: {
        width: 595.28,
        height: 841.89,
      },
      headerHeight: 60,
      father: { children: [{ clientHeight: 0 }] },
      bleedStr: "",
      bleed: [],
      mocktest: {},
      hidePageBreak: false,
      fontSize: 9,
      isLangHindi: false,
    };
  },
  computed: {},
  methods: {
    stripHtml(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },

    fetchMocktest() {
      let mocktestId = this.$route.params.mocktest_id;
      if (!mocktestId) {
        return;
      }
      //https://api.vikarn.com/prep/mocktests/411/questions
      return axios
        .get("prep/mocktests/" + mocktestId + "/questions")
        .then((res) => {
          this.mocktest = res.data.data;
          this.father = this.$refs.questionsBlock;
          setTimeout(() => {}, 200);
        });
    },
    async generate(mocktest) {
      let elm = this.$refs.page;

      html2PDF(elm, {
        jsPDF: {
          unit: "px",
          format: "a4",
        },
        watermark({ pdf, pageNumber, totalPageNumber }) {
          // pdf: jsPDF instance
          if (pageNumber == 1) {
            pdf.setFontSize(10);
            pdf.setTextColor("#009");
            var text = "https://vikarn.com/mt/" + mocktest.id;
            pdf.text(text, pdf.internal.pageSize.width / 2, 40, {
              align: "center",
            });
            pdf.setFontSize(10);
          }
          pdf.setTextColor("#999");
          pdf.text(
            pdf.internal.pageSize.width - 50,
            pdf.internal.pageSize.height - 15,
            `Page: ${pageNumber}/${totalPageNumber}`
          );

          pdf.setTextColor("#999");
          pdf.text(30, pdf.internal.pageSize.height - 15, `vikarn.com`);
          pdf.link(30, pdf.internal.pageSize.height - 20, 30, 25, {
            url: "https://vikarn.com/mt/" + mocktest.id,
          });

          pdf.addImage(
            logobase64,
            "PNG",
            pdf.internal.pageSize.width / 2 - 150,
            pdf.internal.pageSize.height / 2 - 100,
            300,
            200,
            undefined,
            true
          );
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: false,
          useCORS: true,
        },
        imageQuality: 1,
        imageType: "image/jpeg",
        margin: {
          top: 30,
          right: 0,
          bottom: 30,
          left: -10,
        },
        output: this.mocktest.title + ".pdf",
        init: function (pdf) {
          pdf.addFileToVFS("Mukta-Regular-normal.ttf", muktaFont);
          pdf.addFont("Mukta-Regular-normal.ttf", "Mukta-Regular", "normal");
          pdf.setFont("Mukta-Regular");
          pdf.setFontSize(10);
          // pdf.setTextColor("#00a");
        },
        success: function (pdf) {
          window.open(pdf.output("bloburl"));
          // pdf.save(this.output);
        },
      });
    },
  },
  watch: {
    bleedStr(val) {
      if (!val) return;
      let strArr = val.split(",");
      strArr = strArr.map((el) => {
        el = el.trim();
        el = el.split("-");
        el = el.map((ee) => ee.trim());
        el[0] = el[0] - 1;
        el[1] = parseInt(el[1]);
        return { idx: el[0], offset: el[1] };
      });
      this.bleed = strArr;
    },
    "$refs.page"(val) {
      if (this.$refs.page) {
        this.father = this.$refs.page;
      }
    },
  },
  mounted() {
    this.fetchMocktest();
    if (this.$refs.page) {
      this.father = this.$refs.questionsBlock;
    }
  },
};
</script>

<style scoped>
.watermark {
  background-image: url("./vikarn.text.logo.png");
  background-size: clamp(100px, 30vw, 200px);
  /* background-attachment: fixed; */
  background-repeat: repeat;
  background-position: 50% 50%;
}

.bg-page-break {
  background-image: repeating-linear-gradient(
    to bottom,
    rgb(255, 255, 255) 0 745px,
    rgb(230, 0, 0) 745px 746px
  );
  background-position: 0px 74px;
  background-attachment: repeat-y;
}
.fixed-top {
  position: fixed;
  top: 55px;
  background-color: #fff;
  width: 100%;
  padding: 0px 30px;
  border-bottom: 1px solid #111;
  z-index: 2;
}
.overlay {
  display: block;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 5px;
}
.page {
  color: #000;
  font-family: "Mukta-Regular", sans-serif;
  line-height: 1.8;
  margin: 0px auto;
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid red; */
  /* font-size: 9px; */
}
header {
  flex: 1 0 100%;
  display: flex;
  margin: 0px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.page-title {
  /* position: relative;
  top: -20px; */
}
.questions-block {
  flex: 1 0 100%;
  padding: 0px 30px 0px 30px;
  display: flex;
  flex-wrap: wrap;
}
.question-block {
  flex: 1 0 50%;
  max-width: calc(50%);
  margin: 0px;
  padding: 10px;
  /* border: 1px solid #111; */
}
.question-block > .question-title {
  display: flex;
}
.bullet {
  font-weight: bold;
  margin-right: 5px;
}
.options {
  display: flex;
  margin-left: 10px;
  margin-top: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.option {
  flex: 1 0 50%;
  margin: 0px;
  padding: 0px;
  display: flex;
}
</style>
