import store from '@/store'
import { 
  Login,
  Register,
  VerifyEmail,
  UpdateEmail,
  UpdatePassword,
  ForgotPassword,
  UpdateProfile
} from '../components'

export default [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "AUTH:LOGIN:TITLE-TEXT",
      needsAuth: false,
      verified: false,
      guest: true
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: {
      beforeRouteEnter(to,from,next) {
        store.dispatch('auth/logout').then(() => {
          next({ name: "login" });
        }).catch(()=>{
          next({ name: "login" });
        })
      }
    }
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      title: "AUTH:REGISTER:TITLE-TEXT",
      needsAuth: false,
      verified: false,
      guest: true
    }
  },
  {
    path: "/forgot/password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      title: "AUTH:FORGOT_PASSWORD:TITLE-TEXT",
      needsAuth: false,
      verified: false,
      guest: true
    }
  },
  {
    path: "/verify/email",
    name: "verify-email",
    component: VerifyEmail,
    meta: {
      title: "AUTH:VERIFY_EMAIL:TITLE-TEXT",
      needsAuth: true,
      verified: false,
      guest: false
    }
  },
  {
    path: "/update/email",
    name: "update-email",
    component: UpdateEmail,
    meta: {
      title: "AUTH:UPDATE_EMAIL:TITLE-TEXT",
      needsAuth: true,
      verified: false,
      guest: false
    }
  },
  {
    path: "/update/password",
    name: "update-password",
    component: UpdatePassword,
    meta: {
      title: "AUTH:UPDATE_PASSWORD:TITLE-TEXT",
      needsAuth: true,
      verified: false,
      guest: false
    }
  },
  {
    path: "/update/profile",
    name: "update-profile",
    component: UpdateProfile,
    meta: {
      title: "AUTH:UPDATE_PROFILE:TITLE-TEXT",
      needsAuth: true,
      verified: true,
      guest: false
    }
  }
];