export const exams = (state) => {
  return state.exams;
};

export const mocktest = (state) => {
  return state.mocktest;
};

export const mocktests = (state) => {
  return state.mocktests;
};

export const getMocktestsById = (state) => (id) => {
  return state.mocktests.find((el) => el.id === id);
};

export const selectedTestLanguage = (state) => {
  return state.selectedTestLanguage;
};

export const activeMocktests = (state) => {
  return state.activeMocktests;
};

export const attempt = (state) => {
  return state.attempt;
};
