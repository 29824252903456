<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="headline green white--text mb-4">
        {{ $lang("SUBMITCONFIRMTITLE") }}
      </v-card-title>
      <v-card-text>
        {{ $lang("SUBMITCONFIRMTEXT") }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        {{ $lang("total") + " " + $lang("questions") }}
        <v-spacer></v-spacer>
        {{ countQuestions }}
        {{ $lang("qstn") }}
      </v-card-actions>
      <v-card-actions>
        {{ $lang("attempted") }}
        <v-spacer></v-spacer>
        {{ countAttempted }}
        {{ $lang("qstn") }}
      </v-card-actions>
      <v-card-actions>
        {{ $lang("skipped") }}
        <v-spacer></v-spacer>
        {{ countSkipped }}
        {{ $lang("qstn") }}
      </v-card-actions>
      <v-card-actions>
        {{ $lang("MarkedForReview") }}
        <v-spacer></v-spacer>
        {{ countMarkedForReview }}
        {{ $lang("qstn") }}
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn outlined text @click="handleCancel()">
          {{ $lang("cancel") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          color="green darken-3"
          dark
          @click="handleSubmit()"
        >
          {{ $lang("submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "attempt-confirm-submit",
  props: ["mocktest", "show"],
  data() {
    return {
      dialog: false,
      countMarkedForReview: 0,
      countSkipped: 0,
      countAttempted: 0,
      countQuestions: 0,
      loading: false,
    };
  },
  watch: {
    show(val) {
      this.dialog = val;
      this.loading = false;
      this.calculateQuestions();
    },
    mocktest() {
      this.calculateQuestions();
    },
  },
  computed: {},
  methods: {
    calculateQuestions() {
      this.countMarkedForReview = 0;
      this.countAttempted = 0;
      this.countSkipped = 0;
      this.countQuestions = 0;
      this.mocktest.segments.forEach((seg) => {
        seg.questions.forEach((qtn) => {
          if (qtn.review && qtn.review == true) {
            this.countMarkedForReview++;
          }
          if (qtn.user_option) {
            this.countAttempted++;
          } else {
            this.countSkipped++;
          }
          this.countQuestions++;
        });
      });
    },

    handleSubmit() {
      this.dialog = false;
      this.calculateQuestions();
      this.$emit("clicked", true);
    },
    handleCancel() {
      this.dialog = false;
      this.calculateQuestions();
      this.$emit("clicked", false);
    },
  },
  created() {
    this.dialog = this.show;
    this.calculateQuestions();
  },
};
</script>

<style>
</style>