<template>
  <v-row>
    <v-col cols="12" class="text-center">
      <youtube
        :player-vars="{ autoplay: 0 }"
        player-height="200"
        player-width="100%"
        :video-id="videoId"
        @ready="() => {}"
        @playing="() => {}"
      ></youtube>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "question-explanation-video",
  props: ["videoId"],
};
</script>

<style>
</style>