import { Orders } from "../components";

export default [
  {
    path: "/dashboard/orders",
    name: "dashboard-orders",
    component: Orders,
    meta: {
      title: "ORDERS",
      needsAuth: true,
      verified: true,
      roles: ["admin", "superadmin"],
      guest: false,
    },
  },
];
