<template>
  <v-select
    @change="setTestLanguage()"
    v-model="selectedTestLanguage"
    :label="$lang('language')"
    item-text="text"
    item-value="value"
    prepend-icon="mdi-translate"
    :items="selectLanguages"
  >
  </v-select>
</template>

<script>
export default {
  name: "select-test-language",
  data() {
    return {
      selectLanguages: [
        { value: "hi", text: "हिन्दी" },
        { value: "en", text: "English" },
      ],
    };
  },
  methods: {
    setTestLanguage(val) {
      console.log("language", this.selectedTestLanguage);
    },
  },
  computed: {
    selectedTestLanguage: {
      get() {
        return this.$store.getters["app/exams/selectedTestLanguage"];
      },
      set(val) {
        this.$store.dispatch("app/exams/UpdateSelectedTestLanguage", val);
      },
    },
  },
};
</script>

<style>
</style>