<template>
  <v-select
    v-model="abbr"
    :items="ident"
    item-text="title"
    item-value="abbr"
    :label="$lang('IDENT')"
  />
</template>

<script>
export default {
  name: "selector-tag-ident",
  props: ["select"],
  data() {
    return {
      ident: [
        { abbr: "questions", title: "Questions/ प्रश्न" },
        { abbr: "exams", title: "Exams/ परीक्षा" },
        { abbr: "mocktests", title: "Mock Tests/ मॉक टेस्ट" },
      ],
      abbr: null,
    };
  },
  watch: {
    abbr(val) {
      console.log("selected ident", val);
      this.$emit("selected", val);
    },
    select(abbr) {
      let idnt = this.ident.find((el) => el.abbr == abbr);

      if (idnt) {
        this.abbr = idnt.abbr;
      } else {
        this.abbr = this.ident[0].abbr;
      }
    },
  },
  created() {
    let idnt = this.ident.find((el) => el.abbr == this.select);
    if (idnt) {
      this.abbr = idnt.abbr;
    } else {
      this.abbr = this.ident[0].abbr;
    }
  },
};
</script>
