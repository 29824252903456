<template>
  <v-row no-gutters dense class="d-flex flex-column">
    <v-card-title class="ma-0 pa-0 text-center justify-center">
      <v-btn icon class="mr-2" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <div v-if="segment && segment.id" class="">
        <span v-if="segment.mocktest">{{ segment.mocktest.title }}</span>
        <span class="grey--text">&nbsp;•&nbsp;</span>
        {{ segment.title }}
      </div>

      <v-spacer></v-spacer>
      <v-select
        style="max-width: 140px"
        dense
        single-line
        v-model="colViewMode"
        :items="colViewModeItems"
        label="Column View Mode"
      />
    </v-card-title>
    <v-divider class="mb-2"></v-divider>
    <vue-editable-grid
      class="mt-2 grid"
      ref="grid"
      id="grid"
      :column-defs="columnDefs"
      :row-data="rows"
      :only-border="true"
      :enable-filters="false"
      :breakLine="false"
      :breakLineWordLimit="200"
      :breakLineEllipsis="'...'"
      :tab2Column="false"
      row-data-key="sn"
      :itemHeight="fontSize * 2"
      :style="gridStyle"
      @cell-updated="cellUpdated"
      @row-selected="rowSelected"
      @context-menu="onContextMenu"
      @contextmenu="onContextMenu"
      @keyup="onKeyUp"
    >
      <template v-slot:header>
        <div class="mb-4 mr-2">
          <v-btn icon @click="handleAddRow">
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-btn icon @click="showLatexEditor = !showLatexEditor">
            <v-icon>mdi-math-compass</v-icon>
          </v-btn>

          <v-btn v-if="selectedRow" icon @click="showPreview = !showPreview">
            <v-icon>mdi-monitor-cellphone</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:header-r>
        <v-btn
          :disabled="colViewMode != 'all'"
          :loading="loading"
          icon
          class="mb-4 mr-2"
          @click="onReload"
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
        <v-btn
          :disabled="colViewMode != 'all'"
          :loading="loading"
          small
          class="mb-4 primary white--text"
          @click="handleSaveAll"
        >
          <v-icon left>mdi-floppy</v-icon>
          Save All
        </v-btn>
      </template>
    </vue-editable-grid>
    <WysiwygEditorDialog
      :show="showWysiwygEditor"
      @opened="(trueFalse) => (showWysiwygEditor = trueFalse)"
    >
      <template v-slot:header> Edit </template>
      <template v-slot:default>
        <VueTip
          :initial="selectedCell"
          @changed="(val) => (selectedCell = val)"
          placeholder="Type here..."
          variant="default"
        />
      </template>
    </WysiwygEditorDialog>
    <QuestionTagsSelector
      :tags="tags"
      :row="selectedRow"
      :show="showQuestionTagsSelector"
      @opened="(trueFalse) => (showQuestionTagsSelector = trueFalse)"
    ></QuestionTagsSelector>
    <latex-editor-dialog
      :show="showLatexEditor"
      @closed="(val) => (showLatexEditor = val)"
      :item="selectedRow"
    />
    <question-preview-dialog
      :show="showPreview"
      @closed="(val) => (showPreview = val)"
      :item="selectedRow"
    />
  </v-row>
</template>
<script>
import { axios } from "@/plugins/axios";
import { mapGetters } from "vuex";
import WysiwygEditorDialog from "./WysiwygEditorDialog.vue";
import { toggles, subjects, options, ColumnDefs } from "./ColumnDefs";
import VueTip from "@/components/VueTip";
import QuestionPreviewDialog from "../../Questions/QuestionPreviewDialog";
import LatexEditorDialog from "../../Questions/LatexEditorDialog";
import QuestionTagsSelector from "./QuestionTagsSelector";
import "./style.css";

export default {
  name: "dashboard-prep-segments-questions-grid",
  components: {
    WysiwygEditorDialog,
    VueTip,
    QuestionTagsSelector,
    QuestionPreviewDialog,
    LatexEditorDialog,
  },
  data() {
    return {
      showPreview: null,
      showLatexEditor: null,
      showWysiwygEditor: null,
      showQuestionTagsSelector: null,
      loading: false,
      segment: null,
      rows: [],
      selectedRowIndex: -1,
      selectedColIndex: -1,
      fontSize: 14,
      grid: null,
      columnDefs: [],
      tags: [],
      colViewMode: "all",
      colViewModeItems: [
        { value: "all", text: "All" },
        { value: "hindi", text: "Hindi" },
        { value: "english", text: "English" },
        { value: "hindi-english", text: "Hindi English" },
        { value: "english-hindi", text: "English Hindi" },
        { value: "common", text: "Common Only" },
      ],
    };
  },
  async beforeCreate() {
    this.columnDefs = await ColumnDefs({
      colViewMode: this.colViewMode,
    });
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    selectedRow: {
      get() {
        return this.rows[this.selectedRowIndex];
      },
      set(val) {
        this.rows[this.selectedRowIndex] = val;
      },
    },
    selectedCell: {
      get() {
        if (this.selectedColIndex < 0 || this.selectedRowIndex < 0) {
          return null;
        }
        let columnDef = this.columnDefs[this.selectedColIndex];
        if (!columnDef) {
          return null;
        }
        if (this.rows && this.rows[this.selectedRowIndex]) {
          return this.rows[this.selectedRowIndex][columnDef.field];
        }
        return "";
      },
      set(val) {
        if (this.selectedColIndex < 0 || this.selectedRowIndex < 0) {
          return null;
        }
        let columnDef = this.columnDefs[this.selectedColIndex];
        if (this.rows && this.rows[this.selectedRowIndex]) {
          this.rows[this.selectedRowIndex][columnDef.field] = val;
        }
      },
    },
    gridStyle() {
      let mStyle = `font-size: ${this.fontSize}px;`;
      if (false) {
        // enableSpellCheck
        mStyle += `height: calc(100vh - 6rem);`;
      } else {
        mStyle += `height: calc(100vh - 10rem);`;
      }
      return mStyle;
    },
    textColumnIndex() {
      return this.columnDefs.findIndex((el) => el.field == "title");
    },
    modifiedRows() {
      return this.rows.filter((row) => row.modified);
    },
  },
  methods: {
    handleSaveAll() {
      this.onSubmit();
    },
    handleAddRow() {
      let sn = this.rows.length + 1;
      let newRow = {
        sn: sn,
        title: "",
        title_hi: "",
        tags: "",
        is_latex: false,
        active: false,
        has_options: true,
      };

      const previousRow = this.rows[this.rows.length - 1];
      if (previousRow) {
        newRow.tags = previousRow.tags;
        newRow.prep_subject_id = previousRow.prep_subject_id;
        newRow.language = previousRow.language;
        newRow.is_latex = previousRow.is_latex;
      }

      this.rows.push(newRow);

      this.selectRow(this.rows.length - 1);

      this.addCustomFields(
        this.rows[this.rows.length - 1],
        this.rows.length - 1
      );
    },
    onContextMenu(e) {
      e.$event.preventDefault();
      // CtxMenu(e);
      console.log("onContextMenu", e);
      this.selectedRowIndex = e.rowIndex;
      this.selectedColIndex = e.columnIndex;

      if (e.column.richText) {
        this.showWysiwygEditor = true;
      } else {
        this.showWysiwygEditor = false;
      }

      if (e.column.field == "tags") {
        this.showQuestionTagsSelector = true;
      } else {
        this.showQuestionTagsSelector = false;
      }
    },
    onKeyUp(e) {
      if (e && e.evt && e.evt.input) {
        // this.selectedRow.text = e.evt.input.value;
        // this.selectedRow.length = e.evt.input.value.trim().length;
      }
    },

    cellUpdated(e) {
      // console.log("cellUpdate", e.row, this.grid);
      // TODO: maybe check for previous value Createg modified to true
      e.row.modified = true;
      setTimeout(() => {
        this.updateFieldsByCustomFields(e.row);
      }, 300);
    },
    scrollToIndex(idx) {
      let rowHeight = this.fontSize * 2;
      let offset = rowHeight * idx;
      let tableDiv = document.querySelector("table.grid-table tbody");
      tableDiv.scrollTop = offset - screen.height / 3;
      // tableDiv.scrollTop = offset - rowHeight * 4;
    },
    scrollToRow(row) {
      let idx = row.sn ? row.sn - 1 : 0;
      this.scrollToIndex(idx);
    },
    selectRow(rowIdx = 0) {
      this.grid
        // .selectCell(rowIdx, 2)
        .selectCell(rowIdx, this.textColumnIndex)
        .then((rr) => {
          this.grid.focus();
        })
        .catch((err) => {
          // log.error("errrrrr", err);
        });
    },
    rowSelected(e) {
      if (!e.rowData) return;
      if (this.busy) return;

      this.clicks++;

      this.busy = true;
      // console.log("rowSelected", e);
      this.selectedRowIndex = e.rowIndex;
      this.selectedColIndex = e.colIndex;

      // console.log("rowSelected", e, e.rowIndex, e.colIndex, e.rowData);

      // if editing bottom row, will add an extra row at bottom to
      // jump to next row when cellupdated

      setTimeout(() => {
        this.busy = false;
      }, 200);
    },
    fetchTags() {
      return axios.get(`prep/tags/where/ident/questions`).then((res) => {
        this.tags = res.data.data;
        console.log("tags", this.tags);
      });
    },
    fetchItemWithQuestions() {
      this.loading = true;
      return axios
        .get(`prep/segments/${this.$route.params.id}/questions`)
        .then((res) => {
          this.segment = res.data;
          this.rows = res.data.questions;

          this.rows = this.rows.map((el, index) => {
            this.addCustomFields(el, index);
            return el;
          });

          delete this.segment.questions;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onSubmit() {
      if (this.colViewMode != "all") {
        this.colViewMode = "all";
        return;
      }
      if (
        !confirm(
          `Are you sure? \n\nThis Double check if there is any error in data.`
        )
      ) {
        return Promise.reject("CANCELED_BY_USER");
      }

      this.rows = this.rows.map((row) => {
        this.updateFieldsByCustomFields(row);
        return row;
      });
      if (this.modifiedRows.length < 1) {
        this.loading = false;
        return;
      }
      axios
        .post(`prep/segments/${this.segment.id}/questions`, {
          questions: this.modifiedRows,
        })
        .then((res) => {
          this.fetchItemWithQuestions();
        })
        .finally(() => {
          this.loading = false;
          this.fetchTags();
        });
    },
    onReload() {
      if (this.colViewMode != "all") {
        this.colViewMode = "all";
        return;
      }
      if (
        !confirm(
          `Are you sure? \n\nThis reload the questions.\n\nAll unsaved rows will be removed.`
        )
      ) {
        return Promise.reject("CANCELED_BY_USER");
      }

      this.fetchItemWithQuestions();
    },
    addCustomFields(el, index) {
      el.sn = index + 1;
      el.toggle_active = toggles[el.active];
      el.toggle_is_latex = toggles[el.is_latex];
      el.toggle_has_options = toggles[el.has_options];
      el.select_correct_option = options[el.correct_option];

      if (subjects && subjects.length > 0) {
        let subject = subjects.find((sbj) => sbj.id == el.prep_subject_id);
        if (subject) {
          el.select_prep_subject_id = subject.title;
        }
      }
    },

    updateFieldsByCustomFields(row) {
      function getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
      }
      row.active = parseInt(getKeyByValue(toggles, row.toggle_active));
      row.is_latex = parseInt(getKeyByValue(toggles, row.toggle_is_latex));
      row.has_options = parseInt(
        getKeyByValue(toggles, row.toggle_has_options)
      );

      row.correct_option = parseInt(
        getKeyByValue(options, row.select_correct_option)
      );

      if (row.select_prep_subject_id && subjects) {
        let subject = subjects.find(
          (sbj) => sbj.title == row.select_prep_subject_id
        );

        if (subject) {
          row.prep_subject_id = parseInt(subject.id);
        }
      }
    },
  },
  watch: {
    rows(val) {
      if (!this.grid) {
        this.grid = this.$refs.grid;
      }
    },
    async colViewMode(val) {
      this.columnDefs = await ColumnDefs({
        colViewMode: this.colViewMode,
      });
    },
    segment(val) {
      if (val && this.rows && subjects) {
        this.rows = this.rows.map((el, index) => {
          this.addCustomFields(el, index);
          return el;
        });
      }
    },
  },
  mounted() {
    this.grid = this.$refs.grid;
    this.fetchTags();
    this.fetchItemWithQuestions(this.$route.params.id);
  },
};
</script>

<style>
</style>