<template>
  <v-app>
    <fullscreen-wait></fullscreen-wait>
    <alert-bar></alert-bar>
    <offline-status />
    <app-drawer v-if="!printable" ref="drawer"></app-drawer>
    <app-bar
      v-if="!printable"
      :pageTitle="$route.meta.title"
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    />

    <v-main>
      <HightlightElement />
      <verify-status></verify-status>

      <v-container fluid class="mb-12">
        <router-view :key="$route.fullPath"></router-view>
        <!-- <fab-button :icon="'mdi-plus'" color="primary"></fab-button> -->
      </v-container>
    </v-main>
    <app-bottom-nav v-if="!printable" />
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar";
import AppDrawer from "./components/AppDrawer";
import AppBottomNav from "./components/AppBottomNav";
import FullscreenWait from "./components/FullscreenWait";
import AlertBar from "./components/AlertBar";
import VerifyStatus from "./components/VerifyStatus";
// import FabButton from "./components/FabButton";
import HightlightElement from "@/components/HighlightElement";
import OfflineStatus from "@/components/OfflineStatus.vue";

export default {
  name: "App",

  components: {
    AppBar,
    AppDrawer,
    AppBottomNav,
    FullscreenWait,
    AlertBar,
    VerifyStatus,
    HightlightElement,
    OfflineStatus,
    // FabButton,
  },

  data: () => ({
    drawer: false,
    printable: false,
  }),
  watch: {
    // if i put it in main.js // it works but takes time
    isDark(val) {
      this.$vuetify.theme.dark = val;
    },
  },
  computed: {},
};
</script>
<style>
/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 4px;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 2px;
  background: rgba(0, 10, 30, 0.1);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.4);
}

/* wysiwyg scrollable-x for editor toolbar */
.editr div.editr--toolbar {
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 2em;
}
.editr div.editr--toolbar::-webkit-scrollbar {
  max-height: 2px;
}

div.vue-dropzone {
  z-index: 1;
  position: absolute;
  top: -80px;
  right: 1em;
  width: 80px;
  overflow: hidden;
  background: lightgreen;
}
</style>
