<template>
  <v-row justify="center">
    <v-col class="col-12">
      <v-autocomplete
        v-model="parent"
        :items="parents"
        dense
        filleds
        item-text="title"
        item-value="id"
        :label="$lang('EXAM_GROUPS').ucWords()"
      >
        <template slot="append-outer">
          <v-btn icon @click.stop="handleRefresh">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <template slot="selection" slot-scope="data">
          <!-- HTML that describe how select should render selected parents -->
          {{ data.item.title }} -
          {{ data.item.children ? data.item.children.length : 1 }}
        </template>
        <template slot="item" slot-scope="data">
          <!-- HTML that describe how select should render parents when the select is open -->
          <span>
            {{ data.item.title }}
            <br />
            <span class="caption" style="color: grey">{{
              data.item.title_hi
            }}</span>
          </span>
          <v-spacer></v-spacer>
          <v-chip color="black" dark small>
            {{ data.item.children ? data.item.children.length : 0 }}
          </v-chip>
        </template>
      </v-autocomplete>

      <v-autocomplete
        @blur="filterChidren(parent)"
        @focus="filterChidren(parent)"
        :disabled="children.length < 1 || !parent"
        v-model="child"
        :items="children"
        dense
        item-text="title"
        item-value="id"
        :label="$lang('EXAMS')"
        clearable
      >
        <template slot="selection" slot-scope="data">
          <!-- HTML that describe how select should render selected parents -->
          {{ data.item.title }}
        </template>
        <template slot="item" slot-scope="data">
          <!-- HTML that describe how select should render parents when the select is open -->
          <span>
            {{ data.item.title }}
            <br />
            <span class="caption" style="color: grey">{{
              data.item.title_hi
            }}</span>
          </span>
          <v-spacer></v-spacer>
          <!-- <v-chip color="black" dark small>
            <v-icon>mdi-check</v-icon>
          </v-chip> -->
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";

export default {
  name: "selector-exams-autocomplete",
  props: ["itemExams"],
  data() {
    return {
      exams: [],
      parents: [],
      children: [],
      parent: null,
      child: null,
    };
  },
  watch: {
    child(id) {
      console.log("child id", id);
      id = parseInt(id);
      if (isNaN(id) || id == null || !id) return;
      // this.children =
      this.$emit("selected", id);
      setTimeout(() => {
        this.children = this.children.filter((el) => el.id != id);
        this.child = null;
      }, 300);
    },
    parent(id) {
      console.log("parent id", id);
      id = parseInt(id);
      if (isNaN(id)) return;
      this.filterChidren(id);

      this.$emit("selectedParent", id);
    },
  },
  methods: {
    filterChidren(id) {
      this.children = this.exams.filter((el) => {
        if (el.parent_id == id) {
          if (this.itemExams.find((ex) => ex.id == el.id)) {
            return false;
          }
          return true;
        }
        return false;
      });
    },
    handleRefresh() {
      this.exams = [];
      this.parents = [];
      this.child = null;
      this.parent = null;

      this.fetchExams();
    },
    fetchExams() {
      return axios.get(`prep/exams`).then((res) => {
        this.exams = res.data.data;
        this.parents = this.exams.filter(
          (el) => !el.parent_id || el.parent_id == 0
        );
      });
    },
  },
  created() {
    this.fetchExams();
  },
};
</script>