import { Reports, ReportsGetOne } from "../components";
import roles from "./roles";

export default [
  {
    path: "/dashboard/prep/reports",
    name: "dashboard-prep-reports",
    component: Reports,
    meta: {
      title: "REPORTS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/reports/:id",
    name: "dashboard-prep-reports-get",
    component: ReportsGetOne,
    meta: {
      title: "Report Details",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
];
