<template>
  <v-layout justify-center align-center>
    <v-row dense>
      <v-col v-for="(item, i) in items" :key="i" cols="12">
        <v-card
          @click="
            $router.push({
              name: 'exam',
              params: { id: item.id },
            })
          "
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">
                {{
                  $store.getters.getCurrLocale == "hi"
                    ? item.title_hi
                      ? item.title_hi
                      : item.title
                    : item.title
                }}
              </v-card-title>

              <v-card-subtitle>
                {{
                  ($store.getters.getCurrLocale == "hi"
                    ? item.description_hi
                      ? item.description_hi
                      : item.description
                    : item.description
                  ).stripTags()
                }}
              </v-card-subtitle>

              <v-card-actions>
                <v-btn class="ml-2 mt-5" label text small>
                  {{
                    $lang("HASTESTS", [
                      item.__meta__.total_mocktests.toString(),
                    ])
                  }}
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3" size="125" tile>
              <v-img :src="item.banner_square"> </v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-if="pagination.lastPage > 1"
          v-model="pagination.page"
          :length="pagination.lastPage"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
export default {
  name: "exams",
  components: {},
  data() {
    return {
      items: [],
      pagination: {
        lastPage: null,
        page: null,
        perPage: null,
        total: null,
      },
    };
  },
  computed: {},
  methods: {
    fetchItems() {
      this.$store.dispatch("app/exams/ExamsGetAll", { context: this, page: 1 });
    },
  },
  created() {
    this.fetchItems();
  },
};
</script>


