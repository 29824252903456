<template>
  <v-btn
    fab
    fixed
    bottom
    :right="left ? false : true"
    :left="left ? true : false"
    @click="$emit('clicked')"
    :class="color"
    style="bottom: 70px; z-index: 2"
  >
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "fab-button",
  props: ["icon", "color", "left"],
  data() {
    return {
      cls: null,
    };
  },
  watch: {
    color: function () {
      this.color ? this.color : "primary";
    },
  },
};
</script>