<template>
  <v-system-bar
   fixed app 
   transition="slide-y-transition"
   color="outline warning"
   class="verify-bar"
   v-if="auth.user && !auth.user.verified && $route.name!='verify-email'">
        <v-layout @click="$router.push({name: 'verify-email'})">
          <v-container>
            <v-icon>mdi-alert</v-icon>{{ $lang('ROOT:VERIFY_STATUS:MESSAGE-TEXT') }}
          </v-container>
        </v-layout>
    </v-system-bar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
        auth: 'auth/auth'
    })
  }
}
</script>
<style scoped>
.verify-bar{
    cursor: pointer;
}
</style>
