<template>
  <layout-center v-if="item && item.id">
    <v-card class="mx-auto">
      <v-card-title>
        {{ item.display_name }}
        <v-spacer></v-spacer>
        <v-avatar size="100">
          <v-img :src="item.avatar"></v-img>
        </v-avatar>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-title class="justify-center">
        {{ $lang("Notify") }} <NotifyUser :user="item" :authUser="authUser" />
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div v-for="(val, key) in item" :key="key">
          <div
            :class="$store.getters.isThemeDark ? 'yellow--text' : 'blue--text'"
          >
            {{ key.length ? $lang(key).toUpperCase() : "NA" }}
          </div>
          <div>{{ val }}</div>
        </div>
      </v-card-text>
    </v-card>
    <fab-button :icon="'mdi-plus'" color="primary" @clicked="() => {}" />
  </layout-center>
</template>

<script>
import { mapGetters } from "vuex";
import FabButton from "@/components/FabButton.vue";
import LayoutCenter from "@/layouts/LayoutCenter.vue";
import NotifyUser from "../modules/NotifyUser.vue";

export default {
  name: "dashboard-auth-user",
  components: {
    FabButton,
    LayoutCenter,
    NotifyUser,
  },
  data() {
    return {
      item: [],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  watch: {},
  methods: {
    getItem() {
      this.$store.dispatch("dashboard/auth/usersGetOne", {
        id: this.$route.params.id,
        context: this,
      });
    },
  },
  created() {
    if (this.$route.params.id) {
      this.getItem();
    }
  },
};
</script>
