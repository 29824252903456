<template>
  <v-list subheader two-line v-if="items.length > 0">
    <v-list-item
      v-for="item in items"
      :key="item.id"
      @click.stop="$emit('clicked', { clickedItem: item })"
      v-long-press="
        () => {
          $emit('update', { clickedItem: item });
        }
      "
    >
      <v-list-item-avatar v-if="item.banner_square">
        <v-avatar>
          <v-img :src="item.banner_square" alt="Avatar"> </v-img>
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="title">
          <v-btn
            :id="'highlight-' + item.id"
            @click.stop="$emit('toggleActive', { clickedItem: item })"
            icon
            :color="item.active ? 'green' : 'red'"
          >
            <v-icon>{{
              item.active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline"
            }}</v-icon>
          </v-btn>
          <v-icon
            v-if="item.children && item.children.length > 0"
            color="orange accent-4"
            >mdi-clover</v-icon
          >
          {{
            isHindi ? (item.title_hi ? item.title_hi : item.title) : item.title
          }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-chip
            @click.stop="
              () => {
                copyToClipboard(item);
              }
            "
            label
            class="mr-1 black accent-4 white--text"
            dark
            x-small
            v-if="idPrefix && item.id"
            >#{{ idPrefix }}{{ item.id }}</v-chip
          >
          <v-chip
            color="cyan darken-4 mr-1"
            dark
            label
            x-small
            v-if="item.subject"
            >{{ item.subject.title }}</v-chip
          >
          <v-chip
            color="indigo darken-4 mr-1"
            dark
            label
            x-small
            v-if="item.ident"
            >{{ item.ident }}</v-chip
          >
          <v-chip label x-small v-if="item.user">{{
            item.user.username
          }}</v-chip>
          <v-chip
            class="blue ml-1"
            label
            x-small
            v-if="item.__meta__ && item.__meta__.total_questions"
          >
            {{ item.__meta__.total_questions }}

            {{ $lang("qstn") }}
          </v-chip>
          <v-chip
            class="pink ml-1"
            label
            x-small
            v-if="item.__meta__ && item.__meta__.total_exams"
          >
            {{ item.__meta__.total_exams }}

            {{ $lang("exams") }}
          </v-chip>
          <v-chip
            class="green ml-1"
            label
            x-small
            v-if="item.__meta__ && item.__meta__.total_mocktests"
          >
            {{ item.__meta__.total_mocktests }}

            {{ $lang("mocks") }}
          </v-chip>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="mItem in menu"
              :key="mItem.id"
              @click.stop="mItem.to(item)"
            >
              <v-icon>{{ mItem.icon(item) }}</v-icon>
              {{ mItem.title }}
            </v-list-item>
            <slot v-bind:item="item" name="listItems"></slot>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </v-list-item>
  </v-list>

  <v-card v-else>
    <v-card-title class="justify-center">{{ $lang("NOITEMS") }}</v-card-title>
  </v-card>
</template>

<script>
import { copyToClipboard } from "@/helpers/utils";
export default {
  name: "list-with-avatar",
  props: ["items", "idPrefix"],
  data() {
    return {
      menu: [
        {
          id: 1,
          title: "Update",
          icon: (clickedItem) => "mdi-pencil",
          to: (clickedItem) => {
            this.$emit("update", { clickedItem });
          },
        },
        {
          id: 2,
          title: "Active",
          icon: (clickedItem) => {
            return clickedItem.active == true ? "mdi-eye" : "mdi-eye-off";
          },
          to: (clickedItem) => {
            this.$emit("toggleActive", { clickedItem });
          },
        },
        {
          id: 3,
          title: "Remove",
          icon: (clickedItem) => {
            return "mdi-delete";
          },
          to: (clickedItem) => {
            this.$emit("remove", { clickedItem });
          },
        },
      ],
    };
  },
  computed: {},
  methods: {
    copyToClipboard(item) {
      let str =
        "#" +
        this.idPrefix +
        item.id +
        " - " +
        item.title +
        " | " +
        item.title_hi;
      copyToClipboard(str);
      this.$store.dispatch("setAlert", {
        message: "Copied:--- " + str,
        icon: "mdi-clipboard-check",
        type: "pink darken-2 white--text",
      });
    },
  },
};
</script>
