<template>
  <v-card>
    <v-card-actions>
      <v-text-field
        :append-icon="'mdi-magnify'"
        type="text"
        name="search"
        :label="$lang('SEARCH')"
        hint="Enter a search term."
        v-model="term"
        @blur="clicked()"
        @keyup.enter="clicked()"
        @click:append="clicked()"
      ></v-text-field>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "search-card",
  data() {
    return {
      term: "",
    };
  },
  methods: {
    clicked() {
      this.$emit("clicked", this.term);
    },
  },
};
</script>