import { axios, setHttpToken } from "@/plugins/axios.js";
import { isEmpty } from "lodash";
import localforage from "localforage";
import bus from "@/bus";
import { locale } from "core-js";

export const getItems = ({ dispatch }, { context }) => {
  bus.$emit("showWait");
  return axios
    .get("prep/exams/", { context })
    .then((response) => {
      context.items = response.data.data;
      bus.$emit("hideWait");
      return Promise.resolve(response.data.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      return Promise.reject(error.response);
    });
};


export const resetState = ({ commit }) => {
  commit("RESET_STATE");
};
