var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"100%"}},[_c('v-card-text',[_c('notify-all')],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('search-card',{on:{"clicked":(term) => _vm.getItems(term)}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.pagination.total,"loading":_vm.loading,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.role",fn:function({ item }){return [_c('v-chip',{attrs:{"outlined":"","label":"","x-small":"","color":_vm.getColor(item.role),"dark":""}},[_vm._v(" "+_vm._s(item.role.toUpperCase())+" ")])]}},{key:"item.referee_uid",fn:function({ item }){return [_c('v-chip',{attrs:{"outlined":"","label":"","x-small":"","dark":""}},[_vm._v(" "+_vm._s(item.referee_uid ? item.referee_uid.toUpperCase() : "N/A")+" ")])]}},{key:"item.wallet",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.wallet ? "🌕" + item.wallet.vcoin : "")+" ")]),_c('span',[_vm._v(_vm._s(item.wallet ? "🌚" + item.wallet.gcoin : "")+" ")]),(
            _vm.authUser &&
            ['admin', 'superadmin'].includes(_vm.authUser.role.toLowerCase())
          )?_c('top-up-wallet',{attrs:{"user":item}}):_vm._e()]}},{key:"item.actions",fn:function({ item }){return [_c('notify-user',{attrs:{"user":item}}),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":"","color":item.banned ? 'red' : ''},on:{"click":function($event){return _vm.$store.dispatch('dashboard/auth/usersToggleBan', {
              item,
            })}}},[_c('v-icon',[_vm._v(_vm._s(item.banned ? "mdi-lock" : "mdi-lock-open"))])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$router.push({
              name: 'dashboard-auth-users-create',
              params: { id: item.id },
            })}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$store.dispatch('dashboard/auth/usersRemove', {
              item,
            })}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"item.email",fn:function({ item }){return [_vm._v(" "+_vm._s(item.display_name)+" "),(item.mobile)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(item.mobile)+" "),(item.email)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.last_logged_in",fn:function({ item }){return [(item.last_logged_in)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.last_logged_in))+" ")]):_c('span',[_vm._v(_vm._s("--"))])]}},{key:"item.verified",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","color":item.verified ? 'green' : 'red'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleVerify(item)}}},[_c('v-icon',[_vm._v(_vm._s(item.verified ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline"))])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.pagination.lastPage > 1)?_c('v-pagination',{attrs:{"length":_vm.pagination.lastPage},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1),_c('fab-button',{attrs:{"icon":'mdi-plus',"color":"primary"},on:{"clicked":function($event){return _vm.$router.push({ name: 'dashboard-auth-users-create' })}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }