<template>
  <v-autocomplete
    v-model="selected"
    :items="exams"
    item-text="title"
    item-value="id"
    deletable-chips
    small-chips
    multiple
    prepend-icon="mdi-note-text"
    :loading="loading"
    :label="$lang('EXAM')"
  ></v-autocomplete>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";

export default {
  name: "exams-list",
  props: [],
  data() {
    return {
      selected: null,
      exams: [],
      loading: false,
    };
  },
  watch: {
    selected(val) {
      let exams = this.exams
        .filter((el) => val.includes(el.id))
        .map((el) => {
          return { id: el.id, title: el.title };
        });
      this.$emit("selected", exams);
    },
  },
  methods: {
    fetchExams() {
      this.loading = true;
      this.$store
        .dispatch("dashboard/prep/ExamsGetAll", {})
        .then((res) => {
          this.exams = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.fetchExams();
  },
};
</script>