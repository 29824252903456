<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon dark v-bind="attrs" v-on="on">
        <v-icon>mdi-crop</v-icon>
      </v-btn>
    </template>
    <v-card elevation="10" class="pa-0 ma-0 brown darken-4" dark>
      <v-card-actions class="mx-1 px-0 justify-space-between actions">
        <v-icon v-tooltip="$lang('Cancel')" @click.stop="cancel"
          >mdi-close</v-icon
        >
        <v-icon v-tooltip="$lang('Restart')" @click.stop="reset"
          >mdi-reload</v-icon
        >

        <v-spacer></v-spacer>
        <v-icon v-tooltip="$lang('ChooseAnImage')" @click.stop="showFileChooser"
          >mdi-upload</v-icon
        >
        <v-icon v-tooltip="$lang('Crop')" @click.stop="cropImage"
          >mdi-crop</v-icon
        >

        <v-spacer></v-spacer>

        <v-icon v-tooltip="$lang('RotateRight')" @click.stop="rotate(90)"
          >mdi-rotate-right</v-icon
        >
        <v-icon v-tooltip="$lang('RotateLeft')" @click.stop="rotate(-90)"
          >mdi-rotate-left</v-icon
        >
        <v-icon
          v-tooltip="$lang('FlipVertical')"
          ref="flipY"
          @click.stop="flipY()"
          >mdi-flip-vertical</v-icon
        >
        <v-icon
          v-tooltip="$lang('FlipHorizontal')"
          ref="flipX"
          @click.stop="flipX()"
          >mdi-flip-horizontal</v-icon
        >
        <v-spacer></v-spacer>
        <v-icon v-tooltip="$lang('Done')" @click.stop="done">mdi-check</v-icon>
      </v-card-actions>
      <v-card-text class="ma-0 pa-1">
        <input
          ref="input"
          type="file"
          name="image"
          accept="image/*"
          @change="setImage"
        />
        <section class="cropper-area">
          <div class="img-cropper">
            <vue-cropper
              ref="cropper"
              :aspect-ratio="ratio ? ratio : null"
              :src="imgSrc"
              preview=".preview"
            />
          </div>
        </section>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  props: ["ratio", "src"],
  components: {
    VueCropper,
  },
  data() {
    return {
      dialog: null,
      imgSrc: "",
      cropImg: "",
      data: null,
      flipedX: 1,
      flipedY: 1,
    };
  },
  methods: {
    cancel() {
      this.reset();
      this.dialog = false;
    },
    done() {
      // if (!this.cropImg) {
      //   }
      this.cropImage();
      this.$emit("cropped", this.cropImg);
      this.dialog = false;
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$refs.cropper.replace(this.cropImg, false);
      this.$refs.cropper.reset();
    },
    flipX() {
      let scale = this.flipedX ? -this.flipedX : -1;
      this.$refs.cropper.scaleX(scale);
      this.flipedX = scale;
    },
    flipY() {
      let scale = this.flipedY ? -this.flipedY : -1;
      this.$refs.cropper.scaleY(scale);
      this.flipedY = scale;
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.replace(this.imgSrc, false);
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;

      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;

      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
  watch: {
    src(val) {
      if (val) {
        this.imgSrc = val;
        // this.reset();
      }
    },
  },
  mounted() {
    if (this.src) {
      this.imgSrc = this.src;
      // this.reset();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input[type="file"] {
  display: none;
}

img {
  max-width: 60vw;
  max-height: 60vh;
}
.actions button {
  margin-right: 0.4rem;
}
</style>
