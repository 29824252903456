export default {
  "ROOT:FULLSCREEN_WAIT:ALERT_MSG-TEXT": "Please wait...",
  "ROOT:FULLSCREEN_WAIT:ALERT_MSG-TEXT|LOGOUT": "Logging you out...",

  "ROOT:DRAWER:TOOLBAR_GUEST_TITLE-TEXT": "Welcome!",
  "ROOT:DRAWER:BUTTON_HOME-TEXT": "Home",
  "ROOT:DRAWER:BUTTON_GROUPS-TEXT": "Groups",
  "ROOT:DRAWER:BUTTON_EXPENSES-TEXT": "Expenses",
  "ROOT:DRAWER:BUTTON_PROFILE-TEXT": "Profile",
  "ROOT:DRAWER:BUTTON_UPDATE_PROFILE-TEXT": "Update Profile",
  "ROOT:DRAWER:BUTTON_UPDATE_EMAIL-TEXT": "Update Email",
  "ROOT:DRAWER:BUTTON_UPDATE_PASSWORD-TEXT": "Change Password",
  "ROOT:DRAWER:BUTTON_LOGIN-TEXT": "Login",
  "ROOT:DRAWER:BUTTON_LOGOUT-TEXT": "Logout",
  "ROOT:DRAWER:BUTTON_REGISTER-TEXT": "Register",

  "ROOT:VERIFY_STATUS:MESSAGE-TEXT": "Click here to verify your email address.",

  "ROOT:BOTTOM_NAV:BUTTON_HOME-TEXT": "Home",
  "ROOT:BOTTOM_NAV:BUTTON_GROUPS-TEXT": "Groups",
  "ROOT:BOTTOM_NAV:BUTTON_EXPENSES-TEXT": "Expenses",

  APP_TITLE: "Vikarn",
  INR: "₹ %s/-",
  MENU: "Menu",
  ACCOUNT: "Account",
  SETTINGS: "Settings",
  USER_PREFS: "User Preferences",
  GENERAL: "General",
  DARK_THEME: "Dark Theme",
  LIGHT_THEME: "Light Theme",
  ON: "On",
  OFF: "Off",
  SOMETHING_WENT_WRONG: "Something went wrong!",
  NO_MORE_ITEMS: "No more items.",
  NOT_CONNECTED: "You are not connected to Internet.",

  HOME: "Home",
  GROUP: "Group",
  GROUPS: "Groups",
  EXPENSE: "Expense",
  EXPENSES: "Expenses",
  TEAMS: "Teams",
  TEAM: "Team",
  MEMBER: "Member",
  MEMBERS: "Membrs",
  TOTAL: "Total",
  SUBTOTAL: "Subtotal",

  I_OWE: "I owe Rs. %s /-",
  I_NILL: "My balance is nill",
  I_GET: "I get Rs. %s /-",
  I_SPENT: "I Spent Rs. %s /-",
  OWE: "Owe Rs. %s /-",
  NILL: "Balance nill",
  GET: "Get Rs. %s /-",
  SPENT: "Spent Rs. %s /-",

  CONFIRM_DELETE: "Are you sure to delete <b style='color:red'>%s</b>?",
  YES: "Yes",
  NO: "No",
  OK: "OK",
  CANCEL: "Cancel",
  REMOVE: "Remove",
  UPDATE: "Update",
  CREATE: "Create",
  GENERATE: "Generate",
  NEW: "New",
  NEXT: "Next",
  PREVIOUS: "Previous",
  PAGE: "Page",
  DESCRIPTION: "Description",
  SUBJECT: "Subject",
  "CORRECT-OPTION": "Correct Option",
  OPTIONA: "Option (A)",
  OPTIONB: "Option (B)",
  OPTIONC: "Option (C)",
  OPTIOND: "Option (D)",
  TITLE: "Title",
  EXAMS: "Exams",
  EXAM: "Exam",
  QUESTIONS: "Questions",
  QUESTION: "Question",
  TAGS: "Tags",
  TAG: "Tag",
  SUBJECTS: "Subjects",
  SUBJECT: "Subject",
  NOTES: "Notes",
  NOTE: "Note",
  MOCKTESTS: "Mock Tests",
  MOCKTEST: "Mock Test",
  SEGMENTS: "Segments",
  SEGMENT: "Segment",
  DURATION: "Time Duration",
  EXPLANATION: "Explanation",
  NOITEMS: "No items in here",
  SEGMENTED: "Use segmented time duration?",
  ATTEMPT: "Attempt",
  START: "Start",
  "TOTAL-QUESTIONS": "Total Questions",
  MARKANDNEXT: "Mark & Next",
  UNMARK: "Unmark",
  CLEAR: "Clear",
  SUBMIT: "Submit",
  SAVEANDNEW: "Save & New",
  ACCEPTANDSTART: "Accept and Start",
  CONFIRMLEAVE: "Test has not completed yet, Leave without complition?",
  USERS: "Users",
  AUTH: "Auth",
  PREPARE: "Prepare",
  "GENERATE-MOCKTEST": "Generate Mocktest",
  REPORT: "Report",
  REPORTS: "Reports",
  JOB: "Job",
  JOBS: "Jobs",
};
