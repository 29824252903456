<template >
  <vue-mathjax
    v-if="isLatex"
    :class="'noselect ' + hasClass ? hasClass : ''"
    id="mathjaxx"
    :safe="false"
    :formula="innerHtml"
  ></vue-mathjax>
  <div
    id="mathjaxx"
    :class="'noselect ' + hasClass ? hasClass : ''"
    v-else
    v-html="innerHtml"
  ></div>
</template>

<script>
export default {
  name: "LatexHtml",
  props: ["html", "isLatex", "hasClass"],
  data() {
    return {
      innerHtml: null,
    };
  },
  watch: {
    html(val) {
      this.setHtml();
    },
  },
  methods: {
    setHtml() {
      if (
        this.html &&
        this.html != "<p></p>" &&
        // (!this.noLatex && this.html.stripTags().length > 0) &&
        this.html != undefined &&
        this.html != "undefined"
      ) {
        this.innerHtml = this.html;
      }
    },
  },
  mounted() {
    this.setHtml();
  },
};
</script>
<style>
#mathjaxx {
  padding: 0rem;
  margin: 0rem;
  overflow-x: auto;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}
#mathjaxx img {
  max-width: 95%;
  margin: 0 auto;
}
#mathjaxx table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

#mathjaxx td,
#mathjaxx th {
  border: 1px solid;
}

#mathjaxx th {
  padding-top: 1rem;
  text-align: center;
}
#mathjaxx hr {
  margin: 2rem 0;
  border: 0px;
  background: grey;
  height: 1px;
}
#mathjaxx p {
  padding: 0px;
  margin: 0px;
}
#mathjaxx br {
  content: " ";
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>