import { axios, setHttpToken } from "@/plugins/axios.js";
import { isEmpty } from "lodash";
import moment from "@/plugins/moment";
import localforage from "localforage";
import bus from "@/bus";
import { locale } from "core-js";
import store from "@/store";

function dispatchErrorAlert(error) {
  store.dispatch("apiAlert", {
    message:
      error.response.data && error.response.data.message
        ? error.response.data.message
        : error.response.status,
    code: error.response.status,
  });
}

export const getAll = ({ dispatch }, { uri, context, page }) => {
  bus.$emit("showWait");
  return axios
    .get(`prep/${uri}?page=${page}`)
    .then((response) => {
      if (context) {
        context.pagination = {
          lastPage: response.data.lastPage,
          page: response.data.page,
          perPage: response.data.perPage,
          total: response.data.total,
        };
        context.items = response.data.data;
      }
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const getOne = ({ dispatch }, { uri, id, context }) => {
  bus.$emit("showWait");
  return axios
    .get(`prep/${uri}/${id}`)
    .then((response) => {
      if (context) {
        context.item = response.data;
      }
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const getAllWhere = (
  { dispatch, commit },
  { parent_uri, parent_id, uri, context }
) => {
  bus.$emit("showWait");
  return axios
    .get(`prep/${parent_uri}/${parent_id}/${uri}`)
    .then((response) => {
      if (context) {
        context.pagination = {
          lastPage: response.data.lastPage,
          page: response.data.page,
          perPage: response.data.perPage,
          total: response.data.total,
        };
        context.items = response.data.data;
      }
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const ExamsGetAll = ({ dispatch }, { context, page }) => {
  return dispatch("getAll", { context, uri: "exams", page });
};
export const ExamsGetOne = ({ dispatch }, { context, id }) => {
  return dispatch("getOne", { context, uri: "exams", id });
};

export const SaveAttempt = ({ commit }, data) => {
  return commit("SET_ATTEMPT", data);
};

export const UpdateActiveMocktestElapsed = ({ commit }, { id, elapsed }) => {
  return commit("SET_MOCKTEST_ELAPSED", { id, elapsed });
};

export const ResetActiveMocktestElapsed = ({ commit }) => {
  return commit("RESET_MOCKTEST_ELAPSED");
};

export const RestoreActiveMocktestElapsed = ({ commit }, data) => {
  return commit("RESTORE_MOCKTEST_ELAPSED", data);
};

export const AddMocktests = ({ commit }, mocktest) => {
  if (mocktest) {
    mocktest.saved_at = moment();
  }
  return commit("ADD_MOCKTESTS", mocktest);
};

export const UpdateMocktests = ({ commit }, mocktest) => {
  if (mocktest) {
    mocktest.saved_at = moment();
  }
  return commit("UPDATE_MOCKTESTS", mocktest);
};

export const RemoveMocktests = ({ commit }, id) => {
  return commit("REMOVE_MOCKTESTS", id);
};

export const ResetMocktests = ({ commit }) => {
  return commit("RESET_MOCKTESTS");
};

export const RestoreMocktests = ({ commit }, data) => {
  return commit("RESTORE_MOCKTESTS", data);
};

export const UpdateSelectedTestLanguage = ({ commit }, value) => {
  if (!value) value = "hi";
  return commit("UPDATE_SELECTED_TEST_LANGUAGE", value);
};

export const MocktestsGetOneWithQuestions = ({ dispatch, commit }, id) => {
  bus.$emit("showWait");
  return axios
    .get(`prep/mocktests/${id}/questions?shuffle=1`)
    .then((res) => {
      console.log("MocktestsGetOneWithQuestions", res.data.data);
      dispatch("SaveAttempt", {});
      commit("SET_MOCKTEST", res.data.data);

      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatchErrorAlert(error);
      commit("SET_MOCKTEST", {});
      dispatch("SaveAttempt", {});
      return Promise.reject(err);
    })
    .finally(() => {
      bus.$emit("hideWait");
    });
};

export const MocktestsGetWhere = ({ dispatch }, { context, parent_id }) => {
  return dispatch("getAllWhere", {
    context,
    parent_uri: "exams",
    uri: "mocktests",
    parent_id,
  });
};

export const resetState = ({ commit }) => {
  commit("RESET_STATE");
};
export const resetMocktest = ({ commit }) => {
  commit("SET_MOCKTEST", {});
};
