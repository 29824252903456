<template>
  <v-dialog
    :fullscreen="fullscreen"
    scrollable
    persistent
    v-model="dialog"
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card elevation="4" tile>
      <v-card-title class="px-2 py-2 teal darken-2 white--text">
        <v-btn outlined x-small icon @click="openCheatsheet" dark class="mr-2">
          <v-icon x-small>mdi-help</v-icon>
        </v-btn>

        LaTeX
        <v-spacer></v-spacer>
        <v-btn small class="ml-1" icon dark @click="fullscreen = !fullscreen">
          <v-icon v-if="fullscreen">mdi-fullscreen-exit</v-icon>
          <v-icon v-else>mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn small class="ml-1" icon dark @click="dialog = !dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="ma-0 pa-0 px-2 mt-2">
        <vue-tip
          variant="none"
          color="orange"
          dark
          :initial="latex"
          placeholder="Type something..."
          @changed="
            (val) => {
              latex = val;
            }
          "
        />
      </v-card-text>
      <v-card-text class="white black--text py-8">
        <latex-html :html="latex" :isLatex="true" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VueTip from "@/components/VueTip";
import LatexHtml from "@/components/LatexHtml";
export default {
  name: "latex-editor-dialog",
  props: ["initial", "show"],
  components: { LatexHtml, VueTip },
  data() {
    return {
      dialog: null,
      locale: null,
      fullscreen: false,
      latex: "",
    };
  },
  watch: {
    latex(val) {
      this.$emit("changed", val);
    },
    show(val) {
      this.dialog = val;
      this.locale = this.$store.getters.getCurrLocale;
      this.latex = this.initial;
    },
    dialog(val) {
      this.$emit("closed", val);
    },
    initial(val) {
      this.latex = val;
    },
  },
  created() {
    this.locale = this.$store.getters.getCurrLocale;
    this.dialog = this.show;
  },
  methods: {
    openCheatsheet() {
      let href = "https://math.meta.stackexchange.com/q/5020";
      window.open(href, "_system");
    },

    toggleLocale() {
      if (this.locale == "hi") {
        this.locale = "en";
      } else {
        this.locale = "hi";
      }
    },
  },
};
</script>

<style>
</style>