import Vue from "vue";
import "@/helpers/prototype";
import longpress from "@/plugins/longpress";
import vuetify from "@/plugins/vuetify";
import vuetip from "@/plugins/vuetip";
import VueOnlineProp from "@/plugins/vue-online-prop";
import vueshortkey from "@/plugins/vue-shortkey";
import vtooltip from "@/plugins/vtooltip";
import mathjax from "@/plugins/mathjax";
import moment from "@/plugins/moment";
import localforage from "@/plugins/localforage";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/helpers/persist";
import VueYouTubeEmbed from "@/plugins/vue-youtube-embed";
import VueEditableGrid from "@/plugins/vue-editable-grid";
import { axios } from "@/plugins/axios";
import { locales } from "@/locales";
import { mapGetters } from "vuex";

import froala  from "@/plugins/froala";

Vue.config.productionTip = false;

// This is a global mixin, it is applied to every vue instance.
// Mixins must be instantiated *before* your call to new Vue(...)
Vue.mixin({
  data() {
    return {
      rolesAllowedSuperadmin: ["superadmin"],
      rolesAllowedAdminAndUp: ["superadmin", "admin"],
      rolesAllowedManagerAndUp: ["superadmin", "admin", "manager"],
      rolesAllowedModeratorAndUp: [
        "superadmin",
        "admin",
        "manager",
        "moderator",
      ],
      rolesAllowedTeacherAndUp: [
        "superadmin",
        "admin",
        "manager",
        "moderator",
        "teacher",
      ],
    };
  },
  watch: {},
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    allowedSuperadmin() {
      return (
        this.authUser &&
        this.authUser.role &&
        this.rolesAllowedSuperadmin.includes(this.authUser.role.toLowerCase())
      );
    },
    allowedAdminAndUp() {
      return (
        this.authUser &&
        this.authUser.role &&
        this.rolesAllowedAdminAndUp.includes(this.authUser.role.toLowerCase())
      );
    },
    allowedManagerAndUp() {
      return (
        this.authUser &&
        this.authUser.role &&
        this.rolesAllowedManagerAndUp.includes(this.authUser.role.toLowerCase())
      );
    },
    allowedModeratorAndUp() {
      return (
        this.authUser &&
        this.authUser.role &&
        this.rolesAllowedModeratorAndUp.includes(
          this.authUser.role.toLowerCase()
        )
      );
    },
    allowedTeacherAndUp() {
      return (
        this.authUser &&
        this.authUser.role &&
        this.rolesAllowedTeacherAndUp.includes(this.authUser.role.toLowerCase())
      );
    },
    isDark() {
      return store.getters.isThemeDark;
    },
    isHindi() {
      return store.getters.getCurrLocale == "hi";
    },
    isEnglish() {
      return store.getters.getCurrLocale == "en";
    },
    currLocale() {
      return store.getters.getCurrLocale;
    },
  },
});

new Vue({
  router,
  vuetip,
  store,
  vuetify,
  localforage,
  axios,
  locales,
  longpress,
  VueOnlineProp,
  mathjax,
  moment,
  VueYouTubeEmbed,
  vueshortkey,
  vtooltip,
  VueEditableGrid,
  froala,
  render: (h) => h(App),
}).$mount("#app");
