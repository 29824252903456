var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-1"},[_c('v-card-title',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),(_vm.parent && _vm.parent.id)?_c('span',[_vm._v(" "+_vm._s(_vm.isHindi ? _vm.parent.title_hi ? _vm.parent.title_hi : _vm.parent.title : _vm.parent.title)+" ")]):_c('span',[_vm._v(_vm._s(_vm.$lang("exams")))])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.pagination.total,"loading":_vm.loading,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.title",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.isHindi ? (item.title_hi ? item.title_hi : item.title) : item.title)+" ")]}},{key:"item.active",fn:function({ item }){return [_c('v-btn',{attrs:{"id":'highlight-' + item.id,"icon":"","color":item.active ? 'green' : ''},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('dashboard/prep/ExamsToggleActive', {
              item,
            })}}},[_c('v-icon',[_vm._v(_vm._s(item.active ? "mdi-eye" : "mdi-eye-off"))])],1)]}},{key:"item.children",fn:function({ item }){return [_vm._v(" "+_vm._s(item.children.length)+" "+_vm._s(_vm.$lang("exams"))+" ")]}},{key:"item.mocktests",fn:function({ item }){return [_c('v-chip',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleClickItemMocktests(item)}}},[_vm._v(_vm._s(item.mocktests.length)+" "+_vm._s(_vm.$lang("mocktests")))]),(item.children.length < 1)?_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$router.push({
              name: 'dashboard-prep-mocktests-create',
              query: {
                exam_id: item.id,
                parent_id: _vm.$route.query.parent_id,
                page: _vm.$route.query.page,
                parent_page: _vm.$route.query.parent_page,
                parent_parent_page: _vm.$route.query.parent_parent_page,
              },
            })}}},[_c('v-icon',[_vm._v("mdi-text-box-plus-outline")])],1):_vm._e()]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","color":item.featured ? 'orange' : 'gray'},on:{"click":function($event){$event.stopPropagation();return _vm.handleFeaturedToggle(item)}}},[_c('v-icon',[_vm._v(_vm._s(item.featured ? "mdi-star" : "mdi-star-outline"))])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
              name: 'dashboard-prep-exams-create',
              params: { id: item.id },
              query: { parent_page: _vm.$route.query.page },
            })}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();_vm.$store
              .dispatch('dashboard/prep/ExamsRemove', {
                item,
              })
              .then(() => {
                _vm.items = _vm.items.filter((el) => {
                  return el.id != item.id;
                });
              })}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),(_vm.allowedModeratorAndUp)?_c('fab-button',{attrs:{"icon":'mdi-plus',"color":"primary"},on:{"clicked":function($event){return _vm.$router.push({
          name: 'dashboard-prep-exams-create',
          query: {
            parent_id: _vm.$route.query.parent_id,
            page: _vm.$route.query.page,
            parent_page: _vm.$route.query.parent_page,
            parent_parent_page: _vm.$route.query.parent_parent_page,
          },
        })}}}):_vm._e(),(_vm.parent && _vm.parent.children && _vm.parent.children.length < 1)?_c('v-btn',{staticClass:"orange darken-4 white--text",style:(`bottom: 130px; z-index: 10`),attrs:{"fab":"","fixed":"","bottom":"","right":"","dark":""},on:{"click":function($event){return _vm.$router.push({
          name: 'dashboard-prep-mocktests-create',
          query: {
            exam_id: _vm.parent.id,
          },
        })}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('query-page-redirect',{attrs:{"pagination":_vm.pagination}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }