import auth from "./auth/routes";
import home from "./home/routes";
import profile from "./profile/routes";
import exams from "./exams/routes";
import notes from "./notes/routes";
import doubts from "./doubts/routes";
import analytics from "./analytics/routes";
import errors from "./errors/routes";
import dashboard from "./dashboard/routes.js";

export default [
  ...auth,
  ...home,
  ...profile,
  ...exams,
  ...notes,
  ...doubts,
  ...analytics,
  ...errors,
  ...dashboard,
];
