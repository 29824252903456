<template>
  <layout-center>
    <v-card class="mx-auto">
      <v-card-title>
        <v-btn icon @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <v-spacer></v-spacer>
        <div class="text-right">
          <div>{{ exam.title }}</div>
          <div v-if="exam.parent" class="caption">
            <v-btn
              plain
              height="1.2rem"
              class="pa-0 ma-0"
              @click="
                $router.push({
                  name: 'dashboard-prep-exam',
                  params: { id: exam.parent_id },
                })
              "
            >
              {{
                isHindi
                  ? exam.parent.title_hi
                    ? exam.parent.title_hi
                    : exam.parent.title
                  : exam.parent.title
              }}
            </v-btn>
          </div>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <list-with-avatar
        :items="items"
        @toggleActive="
          ({ clickedItem }) => {
            $store.dispatch('dashboard/prep/MocktestsToggleActive', {
              item: clickedItem,
            });
          }
        "
        @update="
          ({ clickedItem }) => {
            $router.push({
              name: 'dashboard-prep-mocktests-create',
              params: { id: clickedItem.id },
              query: {
                exam_id: exam.id,
              },
            });
          }
        "
        @clicked="
          ({ clickedItem }) => {
            $router.push({
              name: 'dashboard-prep-mocktests-segments',
              params: { id: clickedItem.id },
            });
          }
        "
      />
      <v-btn
        fab
        fixed
        bottom
        right
        @click="
          $router.push({
            name: 'dashboard-prep-mocktests-create',
            query: {
              exam_id: exam.id,
            },
          })
        "
        dark
        class="orange darken-4 white--text"
        :style="`bottom: 130px; z-index: 10`"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card>
  </layout-center>
</template>

<script>
import { axios } from "@/plugins/axios";
import { mapGetters } from "vuex";
import ListWithAvatar from "../../modules/ListWithAvatar.vue";
import LayoutCenter from "@/layouts/LayoutCenter.vue";

export default {
  name: "dashboard-prep-exams-mocktests",
  components: {
    ListWithAvatar,
    LayoutCenter,
  },
  data() {
    return {
      items: [],
      exam: {},
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
  },
  watch: {},
  methods: {
    getItems() {
      return axios.get(`prep/exams/${this.exam.id}/mocktests`).then((res) => {
        this.items = res.data.data;
      });
    },
  },
  created() {
    let id = this.$route.params.id;
    this.$store
      .dispatch("dashboard/prep/ExamsGetOne", { id })
      .then((result) => {
        this.exam = result;
        this.getItems();
      });
  },
};
</script>
