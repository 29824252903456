export default {

    "AUTH:LOGIN:TITLE-TEXT": "Login",
    "AUTH:LOGIN:CARD_TITLE-TEXT": "To continue, Login...",
    "AUTH:LOGIN:INPUT_TEXT_EMAIL-LABEL": "Email address",
    "AUTH:LOGIN:INPUT_TEXT_EMAIL-HINT": "Should be a valid email id.",
    "AUTH:LOGIN:INPUT_TEXT_PASSWORD-LABEL": "Password",
    "AUTH:LOGIN:INPUT_TEXT_PASSWORD-HINT": "Should be at least 6 characters long",
    "AUTH:LOGIN:BUTTON_FORGOT_PASSWORD-TEXT": "Forgot password?",
    "AUTH:LOGIN:BUTTON_SUBMIT-TEXT": "Login",
    "AUTH:LOGIN:BUTTON_REGISTER-TEXT": "Don't have an account?",

    "AUTH:FORGOT_PASSWORD:TITLE-TEXT": "Forgot password",
    "AUTH:FORGOT_PASSWORD:CARD_TITLE-TEXT": "Reset your password",
    "AUTH:FORGOT_PASSWORD:INPUT_TEXT_EMAIL-LABEL": "Email address",
    "AUTH:FORGOT_PASSWORD:INPUT_TEXT_EMAIL-HINT": "A valid email id needed.",
    "AUTH:FORGOT_PASSWORD:INPUT_TEXT_CODE-LABEL": "Reset Code",
    "AUTH:FORGOT_PASSWORD:INPUT_TEXT_CODE-HINT": "Check your email to get password reset code.",
    "AUTH:FORGOT_PASSWORD:INPUT_TEXT_PASSWORD-LABEL": "Password",
    "AUTH:FORGOT_PASSWORD:INPUT_TEXT_PASSWORD-HINT": "Should be at least 6 characters long",
    "AUTH:FORGOT_PASSWORD:INPUT_TEXT_PASSWORD_CONFIRM-LABEL": "Password Confirm",
    "AUTH:FORGOT_PASSWORD:INPUT_TEXT_PASSWORD_CONFIRM-HINT": "Password and Password confirm should match.",
    "AUTH:FORGOT_PASSWORD:BUTTON_SUBMIT-TEXT": "Send code",
    "AUTH:FORGOT_PASSWORD:BUTTON_SEND_AGAIN-TEXT": "Send again",
    "AUTH:FORGOT_PASSWORD:BUTTON_UPDATE-TEXT": "Change",
    "AUTH:FORGOT_PASSWORD:BUTTON_VERIFY-TEXT": "Verify",
    "AUTH:FORGOT_PASSWORD:BUTTON_REGISTER-TEXT": "Don't have an account?",
    "AUTH:FORGOT_PASSWORD:BUTTON_LOGIN-TEXT": "Login",


    "AUTH:REGISTER:TITLE-TEXT": "Register",
    "AUTH:REGISTER:CARD_TITLE-TEXT": "Create an account.",
    "AUTH:REGISTER:INPUT_TEXT_FIRSTNAME-LABEL": "First Name",
    "AUTH:REGISTER:INPUT_TEXT_FIRSTNAME-HINT":"Should be at least 2 characters long.",
    "AUTH:REGISTER:INPUT_TEXT_USERNAME-LABEL": "Username",
    "AUTH:REGISTER:INPUT_TEXT_USERNAME-HINT":"Should be at least 4 characters long.",
    "AUTH:REGISTER:INPUT_TEXT_EMAIL-LABEL": "Email address",
    "AUTH:REGISTER:INPUT_TEXT_EMAIL-HINT": "Should be a valid email id.",
    "AUTH:REGISTER:INPUT_TEXT_PASSWORD-LABEL": "Password",
    "AUTH:REGISTER:INPUT_TEXT_PASSWORD-HINT":"Should be at least 6 characters long",
    "AUTH:REGISTER:INPUT_TEXT_PASSWORD_CONFIRM-LABEL": "Password confirm",
    "AUTH:REGISTER:INPUT_TEXT_PASSWORD_CONFIRM-HINT": "Password and Password confirm should match.",
    "AUTH:REGISTER:BUTTON_SUBMIT-TEXT": "Register",
    "AUTH:REGISTER:BUTTON_LOGIN-TEXT": "Already have an account?",

    "AUTH:VERIFY_EMAIL:TITLE-TEXT": "Verify Email",
    "AUTH:VERIFY_EMAIL:CARD_TITLE-TEXT": "Verify your email",
    "AUTH:VERIFY_EMAIL:INPUT_TEXT_SALT-LABEL": "Verification Code",
    "AUTH:VERIFY_EMAIL:INPUT_TEXT_SALT-HINT": "Check your email to get code.",
    "AUTH:VERIFY_EMAIL:BUTTON_SUBMIT-TEXT": "Verify",
    "AUTH:VERIFY_EMAIL:BUTTON_SEND_AGAIN-TEXT": "Didn't got code?",

    "AUTH:UPDATE_EMAIL:TITLE-TEXT": "Update Email",
    "AUTH:UPDATE_EMAIL:CARD_TITLE-TEXT": "Change your email address..",
    "AUTH:UPDATE_EMAIL:INPUT_TEXT_EMAIL-LABEL": "New Email Address",
    "AUTH:UPDATE_EMAIL:INPUT_TEXT_EMAIL-HINT": "A valid email id needed.",
    "AUTH:UPDATE_EMAIL:INPUT_TEXT_PASSWORD-LABEL": "Current password",
    "AUTH:UPDATE_EMAIL:INPUT_TEXT_PASSWORD-HINT": "Enter your current password.",
    "AUTH:UPDATE_EMAIL:BUTTON_SUBMIT-TEXT": "Update Email",
    "AUTH:UPDATE_EMAIL:BUTTON_SECONDARY-TEXT": "Update Later",

    "AUTH:UPDATE_PASSWORD:TITLE-TEXT": "Update Password",
    "AUTH:UPDATE_PASSWORD:CARD_TITLE-TEXT": "Change your password",
    "AUTH:UPDATE_PASSWORD:INPUT_TEXT_PASSWORD-LABEL": "Current password",
    "AUTH:UPDATE_PASSWORD:INPUT_TEXT_PASSWORD-HINT": "Enter your current password.",
    "AUTH:UPDATE_PASSWORD:INPUT_TEXT_PASSWORD_NEW-LABEL": "New password",
    "AUTH:UPDATE_PASSWORD:INPUT_TEXT_PASSWORD_NEW-HINT": "Should be at least 6 characters long.",
    "AUTH:UPDATE_PASSWORD:INPUT_TEXT_PASSWORD_NEW_CONFIRM-LABEL": "New Password Confirm",
    "AUTH:UPDATE_PASSWORD:INPUT_TEXT_PASSWORD_NEW_CONFIRM-HINT": "`New Password` and `New Password Confirm` should match.",
    "AUTH:UPDATE_PASSWORD:BUTTON_SUBMIT-TEXT": "Update Password",
    "AUTH:UPDATE_PASSWORD:BUTTON_SECONDARY-TEXT": "Update Later",


    "AUTH:UPDATE_PROFILE:TITLE-TEXT": "Update Profile",
    "AUTH:UPDATE_PROFILE:CARD_TITLE-TEXT": "Update your profile..",
    "AUTH:UPDATE_PROFILE:INPUT_TEXT_FIRSTNAME-LABEL": "First Name",
    "AUTH:UPDATE_PROFILE:INPUT_TEXT_FIRSTNAME-HINT": "Should be at least 2 characters long.",
    "AUTH:UPDATE_PROFILE:INPUT_TEXT_MIDDLENAME-LABEL": "Middle Name",
    "AUTH:UPDATE_PROFILE:INPUT_TEXT_MIDDLENAME-HINT": "Should be at least 2 characters long.",
    "AUTH:UPDATE_PROFILE:INPUT_TEXT_LASTNAME-LABEL": "Last Name",
    "AUTH:UPDATE_PROFILE:INPUT_TEXT_LASTNAME-HINT": "Should be at least 2 characters long.",
    "AUTH:UPDATE_PROFILE:INPUT_TEXT_PASSWORD-LABEL": "Current password",
    "AUTH:UPDATE_PROFILE:INPUT_TEXT_PASSWORD-HINT": "Enter your current password.",
    "AUTH:UPDATE_PROFILE:BUTTON_SUBMIT-TEXT": "Update",
    "AUTH:UPDATE_PROFILE:BUTTON_SECONDARY-TEXT": "Not now?",

}