import localforage from "localforage";
import { isEmpty } from "lodash";
import { getRandomString } from "@/helpers/utils";

import { toggles, subjects, options, flags } from "./ColumnDefs";

export const SET_COL_VIEW_MODE = (state, val) => {
  state.colViewMode = val;
};

export const REFRESH = (state) => {
  console.log("refreshing....");
  state.rows.forEach((row, idx) => {
    row.sn = idx + 1;
  });
  state.rows.push({});
  state.rows.pop();
};

export const MODIFIED = (state, { row }) => {
  row.modified = true;
  row.toggle_modified = flags[row.modified];
  console.log("row modified", row.uid, row.modified);
};

export const DUPLICATE_ROW = (state, rowIndex) => {
  const clickedRow = state.rows[rowIndex];
  //let row = { ...state.rowschema };
  let row = { ...clickedRow };

  row.uid = getRandomString(15);
  row.id = null;
  row.created_at = null;
  row.updated_at = null;

  _addCustomFields(row);
  _updateFieldsByCustomFields(row);

  state.rows.splice(rowIndex + 1, 0, { ...row });
  REFRESH(state);
};
export const MOVE_ROW_ITEM = (state, { rowIndex, indexOffset }) => {
  if (rowIndex == 0 && indexOffset < 0) {
    console.log("FIRST_ROW", "can not move up.");
    return;
  }
  if (rowIndex > state.rows.length - 1 && indexOffset > 0) {
    console.log("LAST_ROW", "can not move down.");
    return;
  }
  const clickedRow = state.rows[rowIndex];
  state.rows.move(rowIndex, rowIndex + indexOffset);

  REFRESH(state);
};
export const ADD_ROW_AT_INDEX = (state, { atIndex, clickedIndex }) => {
  let row = { ...state.rowschema };
  row.uid = getRandomString(15);

  const clickedRow = state.rows[clickedIndex];
  if (clickedRow) {
    row.tags = clickedRow.tags;
    row.lang = clickedRow.lang;
    row.prep_subject_id = clickedRow.prep_subject_id;
  }

  _addCustomFields(row);
  _updateFieldsByCustomFields(row);

  state.rows.splice(atIndex, 0, { ...row });
  REFRESH(state);
};

export const ADD_ROW = (state) => {
  let row = { ...state.rowschema };

  const previousRow = state.rows[state.rows.length - 1];
  if (previousRow) {
    row.tags = previousRow.tags;
    row.lang = previousRow.lang;
    row.prep_subject_id = previousRow.prep_subject_id;
  }

  _addCustomFields(row);
  _updateFieldsByCustomFields(row);
  state.rows.push({ uid: getRandomString(15), ...row });

  // const history = { index: rowIndex, row };
  // state.historyRows.push(history);
  REFRESH(state);
};

export const REMOVE_ROW = (state, rowIndex) => {
  const row = state.rows.splice(rowIndex, 1)[0];
  const history = { index: rowIndex, row };
  state.historyRows.push(history);
  REFRESH(state);
};

export const UNDO_REMOVE_ROW = (state) => {
  const history = state.historyRows.pop();
  let idx = history ? history.index : null;
  if (!idx) return;
  if (history.index > state.rows.length - 1) {
    idx = 0;
  }
  state.rows.splice(idx, 0, history.row);
  REFRESH(state);
};

export const SET_SEGMENT = (state, segment) => {
  state.segment = segment;
};

export const SET_TAGS = (state, tags) => {
  state.tags = tags;
};

export const SET_ROWS = (state, rows) => {
  rows.forEach((row) => {
    row.uid = getRandomString(15);
    _addCustomFields(row);
    _updateFieldsByCustomFields(row);
  });
  state.rows = [...rows];
  setTimeout(() => {
    REFRESH(state);
  }, 0);
};

const _addCustomFields = (row) => {
  row.toggle_modified = flags[row.modified];
  row.toggle_active = toggles[row.active];
  row.toggle_is_latex = toggles[row.is_latex];
  row.toggle_has_options = toggles[row.has_options];
  row.select_correct_option = options[row.correct_option];

  if (subjects && subjects.length > 0) {
    let subject = subjects.find((sbj) => sbj.id == row.prep_subject_id);
    if (subject) {
      row.select_prep_subject_id = subject.title;
    }
  }
};

export const UPDATE_FIELDS_BY_CUSTOM_FIELDS = (state, row) => {
  setTimeout(() => {
    _updateFieldsByCustomFields(row);
    _updateFieldsByCustomFields(state.rows.find((el) => el.uid == row.uid));
  }, 0);
};

const _updateFieldsByCustomFields = (row) => {
  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }
  row.active = parseInt(getKeyByValue(toggles, row.toggle_active));
  row.is_latex = parseInt(getKeyByValue(toggles, row.toggle_is_latex));
  row.has_options = parseInt(getKeyByValue(toggles, row.toggle_has_options));

  row.correct_option = parseInt(
    getKeyByValue(options, row.select_correct_option)
  );

  if (row.select_prep_subject_id && subjects) {
    let subject = subjects.find(
      (sbj) => sbj.title == row.select_prep_subject_id
    );

    if (subject) {
      row.prep_subject_id = parseInt(subject.id);
    }
  }
};
