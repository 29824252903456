var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-center',[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_c('h2',[_vm._v(" "+_vm._s(_vm.$store.getters.getCurrLocale == "hi" ? _vm.mocktest.title_hi ? _vm.mocktest.title_hi : _vm.mocktest.title : _vm.mocktest.title)+" ")])],1),_c('v-divider'),_c('list-with-avatar',{attrs:{"items":_vm.items},on:{"toggleActive":({ clickedItem }) => {
          _vm.$store.dispatch('dashboard/prep/SegmentsToggleActive', {
            item: clickedItem,
          });
        },"update":({ clickedItem }) => {
          _vm.$router.push({
            name: 'dashboard-prep-segments-create',
            params: { id: clickedItem.id },
            query: {
              mocktest_id: _vm.mocktest.id,
            },
          });
        },"clicked":({ clickedItem }) => {
          _vm.$router.push({
            name: 'dashboard-grid-segment-questions',
            params: { id: clickedItem.id },
          });
        }},scopedSlots:_vm._u([{key:"listItems",fn:function({ item }){return [_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
              name: 'dashboard-grid-segment-questions',
              params: { id: item.id },
            })}}},[_c('v-icon',[_vm._v("mdi-table")]),_vm._v(" Question Grid ")],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
              name: 'dashboard-prep-segments-questions',
              params: { id: item.id },
            })}}},[_c('v-icon',[_vm._v("mdi-view-list")]),_vm._v(" Question List ")],1)]}}])}),_c('fab-button',{attrs:{"icon":'mdi-plus',"color":"primary"},on:{"clicked":function($event){return _vm.$router.push({
          name: 'dashboard-prep-segments-create',
          query: {
            mocktest_id: _vm.mocktest.id,
          },
        })}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }