import { Subjects, SubjectsCreate } from "../components";
import roles from './roles';

export default [
  {
    path: "/dashboard/prep/subjects",
    name: "dashboard-prep-subjects",
    component: Subjects,
    meta: {
      title: "SUBJECTS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/dashboard/prep/subjects/create/:id?",
    name: "dashboard-prep-subjects-create",
    component: SubjectsCreate,
    meta: {
      title: "CREATE-SUBJECTS",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
]