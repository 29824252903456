<template>
  <v-layout justify-center align-center>
    <v-flex md8 lg6>
      <form>
        <v-card>
          <v-card-title>
            <div class="title">
              {{ $lang("AUTH:REGISTER:CARD_TITLE-TEXT") }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              prepend-icon="mdi-face"
              :label="$lang('AUTH:REGISTER:INPUT_TEXT_USERNAME-LABEL')"
              :hint="$lang('AUTH:REGISTER:INPUT_TEXT_USERNAME-HINT')"
              v-model="user.username"
              type="text"
              :error-messages="errors && errors.username ? errors.username : []"
            ></v-text-field>

            <v-text-field
              prepend-icon="mdi-email"
              :label="$lang('AUTH:REGISTER:INPUT_TEXT_EMAIL-LABEL')"
              :hint="$lang('AUTH:REGISTER:INPUT_TEXT_EMAIL-HINT')"
              v-model="user.email"
              type="email"
              :error-messages="errors && errors.email ? errors.email : []"
            ></v-text-field>

            <v-text-field
              prepend-icon="mdi-lock"
              :label="$lang('AUTH:REGISTER:INPUT_TEXT_PASSWORD-LABEL')"
              :hint="$lang('AUTH:REGISTER:INPUT_TEXT_PASSWORD-HINT')"
              v-model="user.password"
              type="password"
              :error-messages="errors && errors.password ? errors.password : []"
            ></v-text-field>

            <v-text-field
              prepend-icon="mdi-lock"
              :label="$lang('AUTH:REGISTER:INPUT_TEXT_PASSWORD_CONFIRM-LABEL')"
              :hint="$lang('AUTH:REGISTER:INPUT_TEXT_PASSWORD_CONFIRM-HINT')"
              v-model="user.password_confirm"
              type="password"
              :error-messages="
                errors && errors.password_confirm ? errors.password_confirm : []
              "
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <div class="text-xs-center">
              <v-btn outlined @click="doRegister">{{
                $lang("AUTH:REGISTER:BUTTON_SUBMIT-TEXT")
              }}</v-btn>
              <v-btn text @click="$router.push({ name: 'login' })">{{
                $lang("AUTH:REGISTER:BUTTON_LOGIN-TEXT")
              }}</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </form>
    </v-flex>
  </v-layout>
</template>

<script>
import bus from "@/bus";
import { isEmpty } from "lodash";
import { mapActions } from "vuex";
import localforage from "localforage";

export default {
  name: "login",
  components: {},
  data() {
    return {
      user: {
        username: "",
        email: "",
        password: "",
        password_confirm: "",
      },
      showSettings: false,
    };
  },
  computed: {
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    ...mapActions({
      register: "auth/register",
    }),
    doRegister() {
      this.register({ user: this.user, context: this })
        .then(() => {
          this.afterSuccess();
        })
        .catch(() => {});
    },
    afterSuccess(verified = false) {
      localforage
        .getItem("intended")
        .then((intended) => {
          if (isEmpty(intended)) {
            this.$router.replace({ name: "home" });
            return;
          }

          if (verified) {
            //remove this key if user is varified
            localforage.removeItem("intended");
          }

          this.$router.replace(intended);
        })
        .catch(() => {
          this.$router.replace({ name: "home" });
        });
    },
  },
  created() {
    bus.$on("contextButtonClicked", () => {
      this.showSettings = !this.showSettings;
    });
  },
};
</script>


