export default [
  {
    path: "/dashboard/videos",
    name: "dashboard-videos",
    component: () => import("../components/Videos"),
    meta: {
      title: "VIDEOS",
      needsAuth: true,
      verified: true,
      roles: ["teacher", "moderator", "manager", "admin", "superadmin"],
      guest: false,
    },
  },
  {
    path: "/dashboard/videos/create/:id?",
    name: "dashboard-videos-create",
    component: () => import("../components/VideosCreate"),
    meta: {
      title: "VIDEOS_CREATE",
      needsAuth: true,
      verified: true,
      roles: ["teacher", "moderator", "manager", "admin", "superadmin"],
      guest: false,
    },
  },
];
