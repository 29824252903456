export default [
  {
    path: "/dashboard/banners",
    name: "dashboard-banners",
    component: () => import("../components/Banners"),
    meta: {
      title: "BANNERS",
      needsAuth: true,
      verified: true,
      roles: ["manager", "admin", "superadmin"],
      guest: false,
    },
  },
  {
    path: "/dashboard/banners/create/:id?",
    name: "dashboard-banners-create",
    component: () => import("../components/BannersCreate"),
    meta: {
      title: "BANNERS_CREATE",
      needsAuth: true,
      verified: true,
      roles: ["manager", "admin", "superadmin"],
      guest: false,
    },
  },
];
