import {
  Home,
  Pdf,
  PdfSingle,
  OneQuestionPerPage,
  Analytics,
} from "../components";

export default [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "HOME",
      needsAuth: true,
      verified: false,
      guest: false,
    },
  },
  {
    path: "/pdf/:mocktest_id",
    name: "generate-pdf",
    component: Pdf,
    meta: {
      title: "Export PDF",
      needsAuth: true,
      verified: true,
      guest: false,
    },
  },
  {
    path: "/pdf-single/:mocktest_id",
    name: "generate-pdf-single",
    component: PdfSingle,
    meta: {
      title: "Export PDF Single",
      needsAuth: true,
      verified: true,
      guest: false,
    },
  },
  {
    path: "/one/question/per/page/:mocktest_id",
    name: "one-question-per-page",
    component: OneQuestionPerPage,
    meta: {
      title: "Exam",
      needsAuth: true,
      verified: true,
      guest: false,
    },
  },
];
