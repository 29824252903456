var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('search-card',{on:{"clicked":(term) => _vm.getItems(term, this.$route.query.page)}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.pagination.total,"loading":_vm.loading,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.user_id",fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","outlined":""}},[_vm._v(" "+_vm._s(item.user.username)+" ")])]}},{key:"item.title",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$store.getters.getCurrLocale == "hi" ? item.title_hi ? item.title_hi : item.title : item.title)+" ")]}},{key:"item.prep_subject_id",fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","outlined":"","color":"primary"}},[_vm._v(_vm._s(_vm.$store.getters.getCurrLocale == "hi" ? item.subject.title_hi ? item.subject.title_hi : item.subject.title : item.subject.title))])]}},{key:"item.tags",fn:function({ item }){return _vm._l((item.tags),function(tag){return _c('v-chip',{key:tag.id,staticClass:"mx-1",attrs:{"outlined":"","label":"","x-small":""}},[_vm._v(" "+_vm._s(_vm.$store.getters.getCurrLocale == "hi" ? tag.title_hi ? tag.title_hi : tag.title : tag.title)+" ")])})}},{key:"item.active",fn:function({ item }){return [_c('v-btn',{attrs:{"id":'highlight-' + item.id,"icon":"","color":item.active ? 'green' : 'red'},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('dashboard/prep/QuestionsToggleActive', {
              item,
            })}}},[_c('v-icon',[_vm._v(_vm._s(item.active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline"))])],1)]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$router.push({
              name: 'dashboard-prep-questions-create',
              params: { id: item.id },
              query: { parent_page: _vm.$route.query.page },
            })}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":""},on:{"click":function($event){_vm.$store
              .dispatch('dashboard/prep/QuestionsRemove', {
                item,
              })
              .then(() => {
                _vm.items = _vm.items.filter((el) => {
                  return el.id != item.id;
                });
              })}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('query-page-redirect',{attrs:{"pagination":_vm.pagination}})],1),_c('fab-button',{attrs:{"icon":'mdi-plus',"color":"primary"},on:{"clicked":function($event){return _vm.$router.push({ name: 'dashboard-prep-questions-create' })}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }