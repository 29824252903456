<template>
  <v-dialog fullscreen v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small icon v-bind="attrs" v-on="on">
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="blue accent-3 white--text" dark>
        <div>{{ $lang("Notify") }} {{ $lang("User") }}</div>
        <v-spacer></v-spacer>
        <v-btn icon small @click="handleClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="caption ma-2">
        <v-chip x-small>{{ user.display_name }}</v-chip>
        <v-chip x-small class="ml-1">{{ user.email }}</v-chip>
        <v-chip x-small class="ml-1">{{ user.mobile }}</v-chip>
      </div>
      <v-divider></v-divider>
      <v-card-text>
        <v-text-field
          prepend-icon="mdi-form-textbox"
          :label="$lang('title')"
          v-model="payload.title"
        />
        <v-textarea
          prepend-icon="mdi-form-textarea"
          :label="$lang('body') + ' *'"
          :rows="2"
          v-model="payload.body"
        />
        <v-img
          v-if="!showUploader && payload.image"
          height="100"
          :src="payload.image"
          @click="handleShowUploader"
        />
        <upload-Area
          :inputIdent="`upload-input-user-${user.id}`"
          :show="showUploader"
          identity="notifications"
          @uploaded="handleUploaded"
        />
        <div class="d-flex flex-row">
          <v-checkbox
            true-value="true"
            v-model="payload.data.prominent"
            :label="$lang('prominent')"
            class="mr-2"
          ></v-checkbox>
          <v-spacer></v-spacer>
          <v-select class="ml-2" :items="types" v-model="payload.data.type" />
        </div>
        <v-text-field
          prepend-icon="mdi-link"
          :label="$lang('URI').toUpperCase()"
          placeholder="/some/route/inside/app"
          v-model="payload.data.uri"
        />
        <v-text-field
          prepend-icon="mdi-web"
          :label="$lang('URL').toUpperCase()"
          placeholder="https://app.vikarn.com/some/link"
          v-model="payload.data.url"
        />
      </v-card-text>
      <v-card-actions>
        <v-checkbox
          true-value="true"
          v-model="saveNotification"
          :label="$lang('Save Notification')"
          class="mr-2"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" outlined @click="handleSend">
          <v-icon left>mdi-send</v-icon>
          {{ $lang("Notify") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { axios } from "@/plugins/axios";
import UploadArea from "./UploadArea.vue";
export default {
  name: "notify-user",
  props: ["user"],
  components: { UploadArea },
  data() {
    return {
      showUploader: false,
      saveNotification: false,
      dialog: null,
      types: [
        { text: "Uncategorized", value: "" },        
        { text: "Info", value: "blue darken-3 white--text" },
        { text: "Warning", value: "orange darken-2 white--text" },
        { text: "Danger", value: "red darken-3 white--text" },
        { text: "Success", value: "green darken-3 white--text" },
      ],
      payload: {
        title: "",
        body: "",
        image: "",
        data: {
          prominent: true,
          type: "",
          duration: 5000,
        },
      },
    };
  },
  methods: {
    handleShowUploader() {
      this.showUploader = true;
      this.payload.image = "";
    },
    handleUploaded(url) {
      this.payload.image = url;
      this.showUploader = false;
    },
    handleSend() {
      return axios
        .post("fcm/notify", {
          user_ids: [this.user.id],
          payload: this.payload,
          saveNotification: this.saveNotification
        })
        .then((res) => {
          this.handleClose();
        });
    },
    handleClose() {
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>