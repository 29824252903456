<template>
  <v-card-actions v-if="count > 0">
    <v-btn color="red" dark @click="count--" elevation="0">
      <v-icon>mdi-minus</v-icon>
    </v-btn>
    <v-spacer></v-spacer>
    {{ $lang(label) }}: {{ count }}
    <v-spacer></v-spacer>
    <v-btn color="green" dark @click="count++" elevation="0">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-card-actions>
  <v-card-actions v-else>
    <v-btn color="green" block dark @click="count++" elevation="0">
      <v-icon left>mdi-plus</v-icon>
      {{ $lang(label) }}
    </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  name: "counter",
  props: ["value", "label"],
  data() {
    return {
      count: 0,
    };
  },
  watch: {
    count(val) {
      if (val < 0) {
        val = 0;
      } else if (val > 300) {
        val = 300;
      }
      this.count = val;
      this.$emit("changed", val);
    },
    value(val) {
      this.count = val;
    },
  },
  created() {
    if (parseInt(this.value)) {
      this.count = this.value;
    }
  },
};
</script>

<style>
</style>