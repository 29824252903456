export default (router, methodName) => {
  router["old" + methodName] = router[methodName];
  router[methodName] = async function(location) {
    return router["old" + methodName](location).catch((error) => {
      if (error.name === "NavigationDuplicated") {
        return this.currentRoute;
      }
      throw error;
    });
  };
};
