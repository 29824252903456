import prep from "./prep/locales";
import logs from "./logs/locales";
import orders from "./orders/locales";
import payments from "./payments/locales";
import products from "./products/locales";
import quotes from "./quotes/locales";
import videos from "./videos/locales";
import banners from "./banners/locales";
import feeds from "./feeds/locales";
import coupons from "./coupons/locales";
import auth from "./auth/locales";
import grid from "./grid/locales";

export default {
  en: {
    ...prep.en,
    ...logs.en,
    ...orders.en,
    ...payments.en,
    ...products.en,
    ...quotes.en,
    ...videos.en,
    ...banners.en,
    ...feeds.en,
    ...coupons.en,
    ...auth.en,
    ...grid.en,
  },
  hi: {
    ...prep.hi,
    ...logs.hi,
    ...orders.hi,
    ...payments.hi,
    ...products.hi,
    ...banners.hi,
    ...feeds.hi,
    ...videos.hi,
    ...coupons.hi,
    ...quotes.hi,
    ...auth.hi,
    ...grid.hi,
  },
};
