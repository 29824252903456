export const pushHistory = ({ state, rowIndex, colField, value }) => {
  console.log("row...", { state, rowIndex, colField });
  let row = _getRow({ state, rowIndex, colField });
  row.history[colField].push(value);
  console.log("push history", row.history);
};

export const pullHistory = ({ state, rowIndex, colField }) => {
  console.log("row....", { state, rowIndex, colField });
  let row = _getRow({ state, rowIndex, colField });
  if (!row.history[colField] || row.history[colField].length < 1) {
    console.log("NO MORE HISTORY FOR THIS ROW");
    return;
  }
  const value = row.history[colField].pop();
  row[colField] = value;
  state.rows[rowIndex] = row;
  console.log("pull history", row.history);
  return value;
};

const _getRow = ({ state, rowIndex, colField }) => {
  let row = state.rows[rowIndex];
  row.history = row.history ? row.history : [];
  row.history[colField] = row.history[colField] ? row.history[colField] : [];
  return row;
};
