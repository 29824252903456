<template>
  <div class="countdown1">
    <v-icon x-small class="mr-1" color="black">mdi-clock</v-icon>
    <div class="digit1" v-if="hms.hours">{{ hms.hours | twoDigits }}:</div>
    <div class="digit1">{{ hms.minutes | twoDigits }}:</div>
    <div class="digit1">{{ hms.seconds | twoDigits }}</div>
  </div>
</template>
<script>
export default {
  name: "display-timer-counter",
  props: ["duration", "startTime", "start", "reset"],
  emits: ["started", "stopped", "tick", "finished", "reset"],
  data() {
    return {
      timer: null,
      elapsed: 0,
    };
  },
  computed: {
    hms() {
      return this.elapsed.toString().convertToTime();
    },
  },
  watch: {
    reset() {
      this.onReset();
    },
    start(val) {
      if (val) this.onStart();
      else this.onStop();
    },
    startTime(val) {
      this.elapsed = val;
    },
  },
  methods: {
    onStart() {
      this.timer = setInterval(() => {
        this.elapsed++;
        this.$emit("tick", this.elapsed);
      }, 1000);
      this.$emit("started", {});
    },
    onStop() {
      if (!this.timer) return;
      clearInterval(this.timer);
      this.$emit("stopped", this.elapsed);
    },
    onReset() {
      this.onStop();
      this.elapsed = this.startTime;
      this.$emit("reset", {});
    },
  },
  created() {
    this.elapsed = this.startTime;
    if (this.start) {
      this.onStart();
    }
  },
  filters: {
    twoDigits(value) {
      if (value < 0) {
        return "00";
      }
      if (value.toString().length <= 1) {
        return `0${value}`;
      }
      return value;
    },
  },
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400|Roboto:100);

.countdown1 {
  display: flex;
  justify-content: center;
}

.digit1 {
  display: flex;
  flex-direction: column;
  text-align: center;
}
</style>