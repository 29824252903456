<template>
  <v-dialog v-model="showDialog" width="500">
    <v-card v-if="item">
      <v-card-title>
        <div class="title">
          {{ $lang("ADD") + "/" + $lang("CREATE") + " " + $lang("TAGS") }}
        </div>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-text-field
              :label="$lang('TITLE')"
              :hint="$lang('TITLE-HINT')"
              v-model="item.title"
              clearable
              :error-messages="errors && errors.title ? errors.title : []"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn class="mt-4" block outlined @click="doSubmit">{{
              $lang("CREATE")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text style="max-height: 400px; overflow: scroll">
        <v-chip
          @click.stop="attachExisting(itm)"
          small
          :style="'background:' + getRandomColorLuminance() + '; color: white'"
          class="ma-1"
          v-for="itm in filteredItems"
          :key="itm.id"
        >
          {{ itm.title }}
        </v-chip>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getRandomColorLuminance } from "@/helpers/utils";

export default {
  name: "new-tags-dialog",
  props: ["show", "tags", "ident"],
  emits: ["closed", "created"],
  components: {},
  data() {
    return {
      item: {
        id: null,
        title: "",
      },
      filteredItems: [],
      showDialog: null,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  watch: {
    "item.title"(val) {
      this.filterItems();
    },
    tags(tags) {
      this.filterItems();
      this.item = {};
    },
    show(val) {
      this.showDialog = val;
      this.item = {};
    },
    showDialog(val) {
      let closed = !val ? true : false;
      this.$emit("closed", closed);
    },
  },
  methods: {
    getRandomColorLuminance() {
      return getRandomColorLuminance(-0.6);
    },
    tagExists() {
      let title = this.item.title;
      return this.tags.filter((el) => {
        return el.title.toLowerCase() == title.toLowerCase();
      })[0];
    },
    attachExisting(tag) {
      this.$emit("created", tag);
      this.item = {};
    },
    filterItems() {
      let val =
        this.item && this.item.title ? this.item.title.toLowerCase() : "";
      this.filteredItems = this.tags.filter((el) => {
        return el.title.toLowerCase().includes(val);
      });
    },
    doSubmit() {
      let existing = this.tagExists();
      if (existing) {
        this.$emit("created", existing);
        this.item = {};
        this.showDialog = false;
        return Promise.resolve("ADDED_EXISTING");
      }
      // attach ident to tags
      this.item.ident = this.ident;
      return this.$store
        .dispatch("dashboard/prep/TagsCreate", {
          item: this.item,
          context: this,
        })
        .then((tag) => {
          this.$emit("created", tag);
          this.showDialog = false;
        });
    },
  },
  created() {
    this.showDialog = this.show;
  },
};
</script>
