var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.localCurrLocale == 'en')?_c('div',[_c('v-card',{class:'mb-1' + _vm.cardColor,attrs:{"tile":"","flat":""}},[(!_vm.hideTimer)?_c('question-card-timer',{attrs:{"question":_vm.question,"idx":_vm.idx,"currentQuestionId":_vm.currentQuestionId}}):_vm._e(),_c('v-card-title',{class:'' + _vm.cardColor},[_c('latex-html',{attrs:{"hasClass":"subtitle-1","isLatex":_vm.question.is_latex,"html":_vm.question.title}})],1),_c('v-divider')],1),_c('v-card',{class:'' + _vm.cardColor},[(
        _vm.question && _vm.question.description && _vm.question.description.length > 8
      )?_c('v-card-text',{class:'' + _vm.cardColor},[_c('latex-html',{attrs:{"hasClass":"body","isLatex":_vm.question.is_latex,"html":_vm.question.description}})],1):_vm._e(),_c('v-card-text',[_c('v-radio-group',{model:{value:(_vm.question.user_option),callback:function ($$v) {_vm.$set(_vm.question, "user_option", $$v)},expression:"question.user_option"}},[_c('radio-html',{attrs:{"value":"1","hasClass":"body","isLatex":"question.is_latex","html":_vm.question.option_a}}),_c('radio-html',{attrs:{"value":"2","hasClass":"body","isLatex":"question.is_latex","html":_vm.question.option_b}}),_c('radio-html',{attrs:{"value":"3","hasClass":"body","isLatex":"question.is_latex","html":_vm.question.option_c}}),_c('radio-html',{attrs:{"value":"4","hasClass":"body","isLatex":"question.is_latex","html":_vm.question.option_d}})],1)],1)],1)],1):_c('div',[_c('v-card',{class:'mb-1' + _vm.cardColor,attrs:{"tile":"","flat":""}},[(!_vm.hideTimer)?_c('question-card-timer',{attrs:{"question":_vm.question,"idx":_vm.idx,"currentQuestionId":_vm.currentQuestionId}}):_vm._e(),_c('v-card-title',{class:'' + _vm.cardColor},[_c('latex-html',{attrs:{"hasClass":"subtitle-1","isLatex":_vm.question.is_latex,"html":_vm.question.title_hi}})],1)],1),_c('v-card',{class:'' + _vm.cardColor},[(
        _vm.question &&
        _vm.question.description_hi &&
        _vm.question.description_hi.length > 8
      )?_c('v-card-text',{class:'' + _vm.cardColor},[_c('latex-html',{attrs:{"hasClass":"body","isLatex":_vm.question.is_latex,"html":_vm.question.description_hi}})],1):_vm._e(),_c('v-card-text',[_c('v-radio-group',{model:{value:(_vm.question.user_option),callback:function ($$v) {_vm.$set(_vm.question, "user_option", $$v)},expression:"question.user_option"}},[_c('radio-html',{attrs:{"value":"1","hasClass":"body","isLatex":_vm.question.is_latex,"html":_vm.question.option_a_hi}}),_c('radio-html',{attrs:{"value":"2","hasClass":"body","isLatex":_vm.question.is_latex,"html":_vm.question.option_b_hi}}),_c('radio-html',{attrs:{"value":"3","hasClass":"body","isLatex":_vm.question.is_latex,"html":_vm.question.option_c_hi}}),_c('radio-html',{attrs:{"value":"4","hasClass":"body","isLatex":_vm.question.is_latex,"html":_vm.question.option_d_hi}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }