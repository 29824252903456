<template>
  <div class="bg-page-break-landscape">
    <div class="d-flex">
      <v-btn class="mt-4" @click="isLangHindi = !isLangHindi">{{
        isLangHindi ? "hi" : "en"
      }}</v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        prefix="offset"
        placeholder="Exmaple: 1-100,5-110,7-100"
        v-model="bleedStr"
      />

      <v-spacer></v-spacer>
      <v-text-field
        style="width: 50px"
        type="number"
        prefix="Font Size"
        placeholder="Size in pixels"
        v-model="fontSize"
      />

      <v-spacer></v-spacer>
      <v-btn class="mt-4" @click="generate">Export PDF</v-btn>
    </div>
    <hr />
    <div
      class="page"
      ref="page"
      :style="`width: ${pageDimen.width}px;font-size:${fontSize}px;`"
    >
      <div ref="questionsBlock" class="questions-block">
        <div
          class="question-block"
          :style="`margin-bottom: ${
            bleed.find((el) => el.idx == i)
              ? bleed.find((el) => el.idx == i).offset
              : 0
          }px;`"
          v-for="(qstn, i) in mocktest.questions"
          :key="i"
        >
          <div class="question-title">
            <span class="bullet">{{ i + 1 }}.</span>
            <latex-html
              :isLatex="qstn.is_latex"
              :html="isLangHindi ? qstn.title_hi : qstn.title"
            />
          </div>
          <div class="options">
            <div class="option">
              <span class="bullet">A.</span>
              <latex-html
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.option_a_hi : qstn.option_a"
              />
            </div>
            <div class="option">
              <span class="bullet">B.</span>
              <latex-html
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.option_b_hi : qstn.option_b"
              />
            </div>
            <div class="option">
              <span class="bullet">C.</span>
              <latex-html
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.option_c_hi : qstn.option_c"
              />
            </div>
            <div class="option">
              <span class="bullet">D.</span>
              <latex-html
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.option_d_hi : qstn.option_d"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
// import mocktest from "@/helpers/testmodel";
import LatexHtml from "@/components/LatexHtml";
import muktaFont from "./Mukta-Regular-normal-base64";
// import './Mukta-Regular.ttf';
import html2PDF from "jspdf-html2canvas";
import { axios } from "@/plugins/axios";
import logobase64 from "./logo.base64";
export default {
  components: { LatexHtml },
  data() {
    return {
      pageDimen: {
        width: 841.89,
        height: 595.28,
      },
      headerHeight: 60,
      father: { children: [{ clientHeight: 0 }] },
      bleedStr: "",
      bleed: [],
      mocktest: {},
      fontSize: 30,
      isLangHindi: false,
    };
  },
  computed: {},
  methods: {
    stripHtml(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
    fetchMocktest() {
      let mocktestId = this.$route.params.mocktest_id;
      if (!mocktestId) {
        return;
      }
      //https://api.vikarn.com/prep/mocktests/411/questions
      return axios
        .get("prep/mocktests/" + mocktestId + "/questions")
        .then((res) => {
          this.mocktest = res.data.data;
          this.father = this.$refs.questionsBlock;
        });
    },
    async generate() {
      this.hidePageBreak = true;
      let elm = this.$refs.page;

      html2PDF(elm, {
        jsPDF: {
          unit: "px",
          format: "a4",
          orientation: "l", // landscape
          // format: [canvas.width, canvas.height]
        },
        watermark({ pdf, pageNumber, totalPageNumber }) {
          // pdf: jsPDF instance
          pdf.setTextColor("#aaa");
          pdf.text(
            pdf.internal.pageSize.width - 50,
            pdf.internal.pageSize.height - 10,
            `Page: ${pageNumber}/${totalPageNumber}`
          );
          pdf.text(30, pdf.internal.pageSize.height - 10, `vikarn.com`);
          pdf.addImage(
            logobase64,
            "PNG",
            pdf.internal.pageSize.width / 2 - 150,
            pdf.internal.pageSize.height / 2 - 100,
            300,
            200,
            undefined,
            "FAST"
          );
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: false,
          useCORS: true,
        },
        imageQuality: 1,
        imageType: "image/jpeg",
        margin: {
          top: 20,
          right: 0,
          bottom: 20,
          left: 0,
        },
        output: this.mocktest.title + ".pdf",
        init: function (pdf) {
          pdf.addFileToVFS("Mukta-Regular-normal.ttf", muktaFont);
          pdf.addFont("Mukta-Regular-normal.ttf", "Mukta-Regular", "normal");
          pdf.setFont("Mukta-Regular");
          pdf.setFontSize(10);
        },
        success: function (pdf) {
          window.open(pdf.output("bloburl"));
          setTimeout(() => {
            this.hidePageBreak = false;
          }, 200);
          // pdf.save(this.output);
        },
      });
    },
  },
  watch: {
    bleedStr(val) {
      if (!val) return;
      let strArr = val.split(",");
      strArr = strArr.map((el) => {
        el = el.trim();
        el = el.split("-");
        el = el.map((ee) => ee.trim());
        el[0] = el[0] - 1;
        el[1] = parseInt(el[1]);
        return { idx: el[0], offset: el[1] };
      });
      this.bleed = strArr;
    },
    "$refs.page"(val) {
      if (this.$refs.page) {
        this.father = this.$refs.page;
      }
    },
  },
  mounted() {
    this.fetchMocktest();
    if (this.$refs.page) {
      this.father = this.$refs.questionsBlock;
    }
  },
};
</script>

<style scoped>
.questions-block {
  flex: 1 0 100%;
}
.question-block {
  width: 100%;
  margin: 0px;
  padding: 0px;
  border: 1px solid #111;
  height: 544px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.question-block > .question-title {
  display: flex;
}
.bullet {
  font-weight: bold;
  margin-right: 5px;
}
.options {
  display: flex;
  margin-left: 10px;
  margin-top: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.option {
  flex: 1 0 50%;
  margin: 0px;
  padding: 0px;
  display: flex;
}
</style>
