<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    transition="dialog-transition"
    class="dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-filmstrip-box-multiple</v-icon>
      </v-btn>
    </template>

    <div class="menu-overlay white--text">
      <v-btn
        :disabled="showBlankOverlay"
        class="green ma-1 mt-1"
        dark
        small
        v-shortkey="{ en: ['ctrl', 'k'], hi: ['ctrl', 'l'] }"
        @shortkey="isLangHindi = !isLangHindi"
        @click="isLangHindi = !isLangHindi"
      >
        {{ isLangHindi ? "hi" : "en" }}
      </v-btn>

      <v-btn
        :disabled="showBlankOverlay"
        large
        v-shortkey="['ctrl', 'arrowleft']"
        @shortkey="goPrevious()"
        @click.stop="goPrevious()"
        class="mr-3"
        icon
        dark
        small
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-btn
        :disabled="showBlankOverlay"
        large
        v-shortkey="['ctrl', 'arrowright']"
        @shortkey="goNext()"
        @click.stop="goNext()"
        icon
        small
        dark
      >
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn
        small
        class="mt-1 mr-2"
        v-shortkey="['ctrl', 'f']"
        @shortkey="showBlankOverlay = !showBlankOverlay"
        @click.stop="showBlankOverlay = !showBlankOverlay"
        color="white"
        dark
        icon
      >
        <v-icon v-if="!showBlankOverlay">mdi-eye-outline</v-icon>
        <v-icon v-if="showBlankOverlay">mdi-eye-off</v-icon>
      </v-btn>
      <v-btn
        :disabled="showBlankOverlay"
        small
        class="mt-1 mr-2"
        v-shortkey="['ctrl', 'g']"
        @shortkey="showOptions = !showOptions"
        @click.stop="showOptions = !showOptions"
        color="white"
        dark
        icon
      >
        <v-icon v-if="!showOptions">mdi-book-open</v-icon>
        <v-icon v-if="showOptions">mdi-book-outline</v-icon>
      </v-btn>
      <v-btn
        :disabled="!showOptions || showBlankOverlay"
        small
        class="mt-1 mr-2"
        v-shortkey="['ctrl', 'h']"
        @shortkey="showCorrectOption = !showCorrectOption"
        @click.stop="showCorrectOption = !showCorrectOption"
        color="white"
        dark
        icon
      >
        <v-icon v-if="!showCorrectOption">mdi-checkbox-blank-off</v-icon>
        <v-icon v-if="showCorrectOption">mdi-checkbox-marked</v-icon>
      </v-btn>

      <v-spacer
        v-shortkey="{
          up: ['arrowup'],
          down: ['arrowdown'],
          reset: ['ctrl', '0'],
        }"
        @shortkey="modifyFontSize"
      ></v-spacer>
      <v-slider
        :disabled="showBlankOverlay"
        v-model="fontSize"
        max="70"
        min="6"
      ></v-slider>
      <v-spacer></v-spacer>
      <v-btn
        small
        class="mt-1 mr-2"
        v-shortkey.once="['esc']"
        @shortkey="dialog = false"
        @click.stop="dialog = false"
        color="red"
        dark
        icon
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <div class="page watermark" :style="`font-size:${fontSize}px;`">
      <ReportQuestionDialog
        withAnswer="true"
        v-if="qstn.id"
        class="btn-pos"
        style="left: 0; top: -5px"
        :question_id="qstn.id"
      />
      <div ref="questionsBlock" v-if="qstn && qstn.id" class="questions-block">
        <div class="question-block">
          <div class="question-title" :style="`font-size:${fontSize * 1.2}px;`">
            <span class="bullet">{{ idx + 1 }}.</span>
            <div>
              <latex-html
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.title_hi : qstn.title"
              />
              <latex-html
                v-if="qstn.description_hi || qstn.description"
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.description_hi : qstn.description"
              />
            </div>
          </div>
          <div class="options" v-if="showOptions">
            <div class="option">
              <span class="bullet">A.</span>
              <latex-html
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.option_a_hi : qstn.option_a"
              />
            </div>
            <div class="option">
              <span class="bullet">B.</span>
              <latex-html
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.option_b_hi : qstn.option_b"
              />
            </div>
            <div class="option">
              <span class="bullet">C.</span>
              <latex-html
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.option_c_hi : qstn.option_c"
              />
            </div>
            <div class="option">
              <span class="bullet">D.</span>
              <latex-html
                :isLatex="qstn.is_latex"
                :html="isLangHindi ? qstn.option_d_hi : qstn.option_d"
              />
            </div>
          </div>
          <div class="green--text" v-if="showOptions && showCorrectOption">
            Answer:
            {{
              qstn.correct_option == "1"
                ? "A"
                : qstn.correct_option == "2"
                ? "B"
                : qstn.correct_option == "3"
                ? "C"
                : qstn.correct_option == "4"
                ? "D"
                : qstn.correct_option
            }}
          </div>
        </div>
      </div>
      <div class="blank-overlay-out" v-if="showBlankOverlay">
        <div class="blank-overlay-in watermark">&nbsp;</div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import LatexHtml from "@/components/LatexHtml";
import { axios } from "@/plugins/axios";
import ReportQuestionDialog from "@/components/ReportQuestionDialog";

export default {
  props: ["mocktest_id"],
  components: { LatexHtml, ReportQuestionDialog },
  data() {
    return {
      mocktest: {},
      fontSize: 20,
      isLangHindi: false,
      dialog: false,
      idx: 0,
      showBlankOverlay: false,
      showOptions: true,
      showCorrectOption: false,
    };
  },
  computed: {
    qstn() {
      if (!this.mocktest || !this.mocktest.questions) {
        return {};
      }
      return this.mocktest.questions[this.idx];
    },
  },
  methods: {
    modifyFontSize(e) {
      switch (e.srcKey) {
        case "up":
          this.fontSize++;
          break;
        case "reset":
          this.fontSize = 20;
          break;
        case "down":
          this.fontSize--;
          break;
      }
    },
    handleKeyPress(e) {
      console.log("keyup", e);
    },
    goNext() {
      if (this.idx < this.mocktest.questions.length - 1) {
        this.idx++;
      } else {
        this.idx = 0;
      }
      console.log("this.idx", this.idx);
    },
    goPrevious() {
      if (this.idx < 1) {
        this.idx = this.mocktest.questions.length - 1;
      } else {
        this.idx--;
      }
      console.log("this.idx", this.idx);
    },
    fetchMocktest() {
      let mocktestId = this.mocktest_id
        ? this.mocktest_id
        : this.$route.params.mocktest_id;
      if (!mocktestId) {
        return;
      }
      return axios
        .get("prep/mocktests/" + mocktestId + "/questions")
        .then((res) => {
          this.mocktest = res.data.data;
          this.father = this.$refs.questionsBlock;
        });
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.fetchMocktest();
      }
    },
    show(val) {
      if (val) {
        this.fetchMocktest();
      }
    },
  },
  mounted() {
    this.fetchMocktest();
  },
};
</script>

<style scoped>
.watermark {
  background-image: url("./vikarn.text.logo.png");
  background-size: clamp(50px, 10vw, 100px);
  background-attachment: fixed;
  background-repeat: repeat;
  background-position: 50% 50%;
}

.menu-overlay {
  position: fixed;
  bottom: 0;
  background-color: #005;
  color: 111;
  z-index: 10;
  display: flex;
  flex-direction: row;
  height: 38px;
  border-top: 1px solid #ccc;
}
.page {
  background-color: white;
  color: #000;
  padding: 0px 0px 0px 0px;
  height: 100vh;
  overflow-y: auto;
}
.questions-block {
  flex: 1 0 100%;
  background-image: url("./watermark-logo.png");
  background-size: clamp(300px, 50vw, 500px);
  background-attachment: fixed;
  background-position: 50% 50%;
}
.question-block {
  border: 1px solid;
  width: 100vw;
  margin: 0px 0px 50px 0px;
  padding: 10px;
  /* border: 1px solid #111; */
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
}
.question-block > .question-title {
  display: flex;
  padding-bottom: 10px;
}
/* .question-title .bullet {
  padding: 0px 10px;
  border-right: 2px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.bullet {
  font-weight: bold;
  margin-right: 5px;
  align-items: top;
}
.options {
  padding-top: 20px;
  border-top: 1px solid;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  width: 100%;
  height: 100%;
}
.option {
  flex: 1 0 50%;
  margin: 0px;
  padding: 5px;
  display: flex;
}

.blank-overlay-out {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 1;
  position: fixed;
  top: -22px;
  left: -22px;
  margin-top: 22px;
  margin-left: 22px;
  z-index: 9;
}
.blank-overlay-in {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  background-color: white;
}
</style>
