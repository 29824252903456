<template>
  <layout-center>
    <form>
      <v-card v-if="item">
        <v-card-title>
          <div class="title">
            {{ $lang(item.id ? "UPDATE" : "NEW") + " " + $lang("SEGMENT") }}
          </div>
          <v-spacer></v-spacer>

          <banner-uploader
            v-if="item && item.id"
            :item="item"
            dispatch="dashboard/prep/SegmentsUpdateBanner"
          />
        </v-card-title>
        <v-card-subtitle v-if="mocktest && mocktest.id">
          {{ $lang("MOCKTEST") }} : {{ mocktest.title }}
        </v-card-subtitle>
        <v-divider></v-divider>

        <common-create-fields :item="item" />

        <v-card-text>
          <v-select
            v-if="!item.prep_mocktest_id"
            v-model="item.prep_mocktest_id"
            :items="mocktests"
            item-text="title"
            item-value="id"
            clearable
            :label="$lang('MOCKTEST')"
            :error-messages="
              errors && errors.prep_mocktest_id ? errors.prep_mocktest_id : []
            "
          />

          <selector-time
            :seconds="item.duration"
            @changed="(val) => (this.item.duration = val)"
          />
          <v-checkbox
            v-model="item.shuffle_questions"
            :label="`Shuffle questions of this segment.`"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <div class="text-xs-center">
            <v-btn outlined @click="doSubmit(false)">{{
              $lang(item.id ? "UPDATE" : "CREATE")
            }}</v-btn>
            <v-btn v-if="item.id" class="mr-1" outlined @click="doSubmit(true)"
              >{{ $lang("SAVEANDNEW") }}
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              $router.push({
                name: item.prep_mocktest_id
                  ? 'dashboard-prep-mocktests-segments'
                  : 'dashboard-prep-segments',
                params: { id: item.prep_mocktest_id },
              })
            "
            >{{ $lang("CANCEL") }}
          </v-btn>
          <v-btn v-if="item.id" color="red" outlined @click="remove()">
            <v-icon>mdi-delete</v-icon> {{ $lang("REMOVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </layout-center>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutCenter from "@/layouts/LayoutCenter.vue";
import BannerUploader from "@/components/BannerUploader.vue";
import SelectorTime from "../../modules/SelectorTime.vue";
import CommonCreateFields from "../../modules/CommonCreateFields.vue";

export default {
  name: "dashboard-prep-segments-create",
  components: {
    LayoutCenter,
    BannerUploader,
    SelectorTime,
    CommonCreateFields,
  },
  data() {
    return {
      item: {
        id: null,
        title: "",
        description: "",
        language: null,
      },
      mocktests: [],
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    mocktest() {
      if (this.item.prep_mocktest_id) {
        return this.mocktests.find((el) => el.id == this.item.prep_mocktest_id);
      } else {
        return {};
      }
    },
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    remove() {
      this.$store
        .dispatch("dashboard/prep/SegmentsRemove", {
          item: this.item,
          context: this,
        })
        .then(() => {
          this.$router.replace({
            name: this.item.prep_mocktest_id
              ? "dashboard-prep-mocktests-segments"
              : "dashboard-prep-segments",
            params: { id: this.item.prep_mocktest_id },
          });
        });
    },
    fetchMocktests() {
      this.$store.dispatch("dashboard/prep/MocktestsGetAll", {}).then((res) => {
        // fill Mocktests array
        this.mocktests = res.data;
      });
    },
    fetchItem(id) {
      this.item.id = this.$route.params.id;
      this.$store
        .dispatch("dashboard/prep/SegmentsGetOne", {
          id: this.item.id,
          context: this,
        })
        .then(() => {
          this.fetchMocktests();
        })
        .catch((err) => {
          console.log(err);
          this.$router.replace({ name: "dashboard-prep-segments" });
        });
    },
    doSubmit(isNew) {
      if (this.$route.params.id) {
        this.$store
          .dispatch("dashboard/prep/SegmentsUpdate", {
            item: this.item,
            context: this,
          })
          .then(() => {
            let conf = {};

            if (isNew) {
              console.log("isNew", isNew);
              console.log(
                "this.item.prep_mocktest_id",
                this.item.prep_mocktest_id
              );
              conf.name = "dashboard-prep-segments-create";
              conf.query = { mocktest_id: this.item.prep_mocktest_id };
            } else {
              if (this.item.prep_mocktest_id) {
                conf.name = "dashboard-prep-mocktests-segments";
              } else {
                conf.name = "dashboard-prep-segments";
              }
              conf.params = { id: this.item.prep_mocktest_id };
            }
            this.$router.replace(conf);
          });
      } else {
        this.$store
          .dispatch("dashboard/prep/SegmentsCreate", {
            item: this.item,
            context: this,
          })
          .then((data) => {
            this.$router.replace({
              name: "dashboard-prep-segments-create",
              params: { id: data.id },
              query: { mocktest_id: this.item.prep_mocktest_id },
            });
          });
      }
    },
  },
  watch: {
    "$route.params.id"(id) {
      this.fetchItem(id);
    },
    "$route.query.mocktest_id"(_id) {
      _id = parseInt(_id) ? parseInt(_id) : null;
      this.item.prep_mocktest_id = _id;
      this.item.prep_mocktest_id = _id;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchItem(this.$route.params.id);
    }

    if (this.$route.query.mocktest_id) {
      let _id = parseInt(this.$route.query.mocktest_id)
        ? parseInt(this.$route.query.mocktest_id)
        : null;
      this.item.prep_mocktest_id = _id;
      this.item.prep_mocktest_id = _id;
    }

    this.fetchMocktests();
  },
};
</script>
