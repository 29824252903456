var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-center',[_c('v-card',{staticClass:"mb-1"},[_c('v-card-title',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){if (_vm.$route.query.parent_id) {
            _vm.$router.push({
              name: 'dashboard-prep-exam',
              params: {
                id: _vm.$route.query.parent_id,
              },
            });
          } else {
            _vm.$router.push({
              name: 'dashboard-prep-exams',
            });
          }}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.isHindi ? (_vm.item.title_hi ? _vm.item.title_hi : _vm.item.title) : _vm.item.title)+" ")],1)],1),_c('v-card',{staticClass:"mx-auto"},[_c('list-with-avatar',{attrs:{"idPrefix":"XM","items":_vm.items},on:{"clicked":({ clickedItem }) => {
          _vm.$router.push({
            name: 'dashboard-prep-exams-mocktests',
            params: { id: clickedItem.id },
            query: { parent_id: _vm.$route.params.id },
          });
        },"update":({ clickedItem }) => {
          _vm.$router.push({
            name: 'dashboard-prep-exams-create',
            params: { id: clickedItem.id },
            query: { parent_id: _vm.$route.params.id },
          });
        },"toggleActive":({ clickedItem }) => {
          _vm.$store.dispatch('dashboard/prep/ExamsToggleActive', {
            item: clickedItem,
            query: { parent_id: _vm.$route.params.id },
          });
        }}}),(_vm.allowedModeratorAndUp)?_c('fab-button',{attrs:{"icon":'mdi-plus',"color":"primary"},on:{"clicked":function($event){return _vm.$router.push({
          name: 'dashboard-prep-exams-create',
          query: { parent_id: _vm.$route.params.id },
        })}}}):_vm._e()],1),(_vm.item && _vm.item.__meta__ && _vm.item.__meta__.total_mocktests > 0)?_c('v-card',{staticClass:"mt-2"},[_c('v-card-text',{staticClass:"primary text-center justify-center"},[_vm._v(" You Should remove mock tests from here ")]),_c('exams-mocktests')],1):_vm._e(),(_vm.pagination.lastPage)?_c('v-pagination',{attrs:{"length":_vm.pagination.lastPage},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }