export const user = (state) => {
    return state.user
}
export const profile = (state) => {
    return state.user.profile;
}

export const authenticated = (state) => {
    return state.authenticated
}

export const auth = (state) => {
    return state
}

export const me = (state) => {
    return state.user
}