<template>
  <span>
    <v-dialog v-model="dialog" persistent width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn x-small icon v-bind="attrs" v-on="on">
          <v-icon>mdi-wallet-plus</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          {{ $lang("WalletTopup").toUpperCase() }}
          <v-spacer></v-spacer>
          <v-btn icon @click="handleClose"> <v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="yellow darken-2"
        ></v-progress-linear>
        <v-divider></v-divider>
        <v-card-text class="body">
          <div>{{ $lang("name") }} : {{ user.display_name }}</div>
          <div>{{ $lang("mobile") }} : {{ user.mobile }}</div>
          <div>{{ $lang("email") }} : {{ user.email }}</div>
          <div>{{ $lang("vCoin") }} : {{ user.wallet.vcoin }}</div>
          <div>{{ $lang("gCoin") }} : {{ user.wallet.gcoin }}</div>
        </v-card-text>
        <v-card-actions>
          <v-text-field
            v-model="gcoin"
            label="gCoins"
            x-large
            type="number"
            hint="Enter gCoin value between 0 to 300"
            class="mr-4"
          ></v-text-field>
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="primary"
            large
            class="px-10"
            @click="submitForTopup"
          >
            {{ $lang("topup") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import { axios } from "@/plugins/axios";
export default {
  props: ["user"],
  data() {
    return {
      dialog: false,
      loading: false,
      gcoin: null,
    };
  },
  methods: {
    handleClose() {
      this.dialog = false;
      this.$emit("closed", { user_id: this.user.id, gcoin: this.gcoin });
    },
    submitForTopup() {
      this.loading = true;
      return axios
        .post("payments/wallet/topup", {
          user_id: this.user.id,
          gcoin: this.gcoin,
        })
        .then((res) => {
          console.log(res);
          this.$emit("success", {
            user_id: this.user.id,
            gcoin: this.gcoin,
            data: res.data,
          });
          if (res.data.gcoin) {
            this.user.wallet.gcoin = parseInt(res.data.gcoin);
          }
          this.gcoin = null;
        })
        .catch((err) => {
          console.log(err);
          this.$emit("failed", {
            user_id: this.user.id,
            gcoin: this.gcoin,
            data: err.response.data,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
