<template>
  <v-dialog v-model="dialog" width="600">
    <v-card tile>
      <v-card-title>
        <slot name="header">Edit</slot>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = !dialog">
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="area-scrollable ma-0 pa-0">
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["show"],
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    show(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit("opened", val);
    },
  },
};
</script>

<style >
.area-scrollable .ProseMirror {
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.tiptap-vuetify-editor__toolbar {
  border-bottom: 1px solid #ccc;
}
</style>