<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <v-list>
        <v-list-item>
          <v-list-item-avatar v-if="auth.authenticated">
            <drawer-avatar-img></drawer-avatar-img>
          </v-list-item-avatar>

          <v-list-item-content v-if="auth.authenticated">
            <v-list-item-title class="title">{{
              auth.user.profile.display_name
            }}</v-list-item-title>
            <v-list-item-subtitle>{{ auth.user.email }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-content v-if="!auth.authenticated">
            <v-list-item-title>Guest</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
              @click.stop="
                drawer = false;
                showSettings = true;
              "
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider v-if="isAllowedAuth"></v-divider>

      <v-list nav dense subheader v-if="auth.authenticated">
        <v-subheader v-if="isAllowedAuth">{{ $lang("AUTH") }}</v-subheader>
        <v-list-item-group
          v-model="selected_menu_manage_users"
          color="primary"
          v-if="isAllowedAuth"
        >
          <v-list-item
            v-for="item in menu_manage_users"
            :key="item.icon"
            @click="item.to"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-divider v-if="isAllowedFinance"></v-divider>

        <v-subheader v-if="isAllowedFinance">{{ $lang("Store") }}</v-subheader>
        <v-list-item-group
          v-model="selected_menu_finance"
          color="primary"
          v-if="isAllowedFinance"
        >
          <v-list-item
            v-for="item in menu_finance"
            :key="item.icon"
            @click="item.to"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-divider v-if="isAllowedAdmin"></v-divider>
        <v-subheader v-if="isAllowedAdmin">{{ $lang("Admin") }}</v-subheader>
        <v-list-item-group
          v-model="selected_menu_manage_logs"
          color="primary"
          v-if="isAllowedAdmin"
        >
          <v-list-item
            v-for="item in menu_manage_logs"
            :key="item.icon"
            @click="item.to"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider v-if="isAllowedPrep"></v-divider>
        <v-subheader v-if="isAllowedPrep">{{ $lang("PREPARE") }}</v-subheader>
        <v-list-item-group
          v-model="selected_menu_manage"
          color="primary"
          v-if="isAllowedPrep"
        >
          <v-list-item
            v-for="item in menu_manage"
            :key="item.icon"
            @click="item.to"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-list nav dense subheader>
        <v-divider></v-divider>
        <v-subheader> {{ $lang("ACCOUNT") }}</v-subheader>
        <v-list-item-group
          v-model="selected_menu_user"
          color="primary"
          v-if="auth.authenticated"
        >
          <v-list-item
            v-for="item in menu_user"
            :key="item.icon"
            @click="item.to"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-item-group v-model="selected_menu_guest" color="primary" v-else>
          <v-list-item
            v-for="item in menu_guest"
            :key="item.icon"
            @click="item.to"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <settings-dialoge
      :showDialog="showSettings"
      @closed="showSettings = false"
    ></settings-dialoge>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SettingsDialoge from "@/components/SettingsDialoge";
import DrawerAvatarImg from "@/components/DrawerAvatarImg";
import bus from "@/bus";
export default {
  components: {
    SettingsDialoge,
    DrawerAvatarImg,
  },
  data() {
    return {
      drawer: null,
      showSettings: false,
      selected_menu_guest: null,
      selected_menu_user: null,
      selected_menu_manage: null,
      selected_menu_manage_users: null,
      selected_menu_manage_logs: null,
      selected_menu_finance: null,
      menu_manage_users: [
        {
          icon: "mdi-account-supervisor",
          text: this.$lang("USERS"),
          to: () => this.routeTo("dashboard-auth-users"),
        },
      ],
      menu_finance: [
        {
          icon: "mdi-cart",
          text: this.$lang("Orders"),
          to: () => this.routeTo("dashboard-orders"),
        },

        {
          icon: "mdi-cash-multiple",
          text: this.$lang("Payments"),
          to: () => this.routeTo("dashboard-payments"),
        },
        {
          icon: "mdi-cart-variant",
          text: this.$lang("Products"),
          to: () => this.routeTo("dashboard-products"),
        },
        {
          icon: "mdi-ticket-percent",
          text: this.$lang("Coupons"),
          to: () => this.routeTo("dashboard-coupons"),
        },
        {
          icon: "mdi-image-area",
          text: this.$lang("Banners"),
          to: () => this.routeTo("dashboard-banners"),
        },
      ],
      menu_manage_logs: [
        {
          icon: "mdi-notebook-multiple",
          text: this.$lang("LOGS"),
          to: () => this.routeTo("dashboard-logs"),
        },
      ],
      menu_manage: [
        {
          icon: "mdi-note-text",
          text: this.$lang("EXAMS"),
          to: () => this.routeTo("dashboard-prep-exams"),
        },
        {
          icon: "mdi-clipboard-edit",
          text: this.$lang("MOCKTESTS"),
          to: () => this.routeTo("dashboard-prep-mocktests"),
        },

        // // NOTE:: Dynamicly added/ removed from authUser watcher, check watch
        // {
        //   icon: "mdi-bus",
        //   text: this.$lang("GENERATE-MOCKTEST"),
        //   to: () => this.routeTo("dashboard-prep-mocktests-generate"),
        // },

        {
          icon: "mdi-beaker-question",
          text: this.$lang("QUESTIONS"),
          to: () => this.routeTo("dashboard-prep-questions"),
        },
        {
          icon: "mdi-play-circle",
          text: this.$lang("VIDEOS"),
          to: () => this.routeTo("dashboard-videos"),
        },
         {
          icon: "mdi-chart-timeline",
          text: this.$lang("FEEDS"),
          to: () => this.routeTo("dashboard-feeds"),
        },
        /*
        {
          icon: "mdi-hexagon-slice-2",
          text: this.$lang("SEGMENTS"),
          to: () => this.routeTo("dashboard-prep-segments"),
        },
        */
        {
          icon: "mdi-tag-multiple",
          text: this.$lang("TAGS"),
          to: () => this.routeTo("dashboard-prep-tags"),
        },
        {
          icon: "mdi-ballot-outline",
          text: this.$lang("SUBJECTS"),
          to: () => this.routeTo("dashboard-prep-subjects"),
        },
        {
          icon: "mdi-book-open",
          text: this.$lang("NOTES"),
          to: () => this.routeTo("dashboard-prep-notes"),
        },
        {
          icon: "mdi-briefcase-account",
          text: this.$lang("JOBS"),
          to: () => this.routeTo("dashboard-prep-jobs"),
        },
        {
          icon: "mdi-check",
          text: this.$lang("ATTEMPTS"),
          to: () => this.routeTo("dashboard-prep-attempts"),
        },
        {
          icon: "mdi-alert-circle",
          text: this.$lang("Reports"),
          to: () => this.routeTo("dashboard-prep-reports"),
        },
        {
          icon: "mdi-comment-quote",
          text: this.$lang("Quotes"),
          to: () => this.routeTo("dashboard-quotes"),
        },
      ],
      menu_guest: [
        {
          icon: "mdi-lock",
          text: this.$lang("ROOT:DRAWER:BUTTON_LOGIN-TEXT"),
          to: () => this.routeTo("login"),
        },
        {
          icon: "mdi-account",
          text: this.$lang("ROOT:DRAWER:BUTTON_REGISTER-TEXT"),
          to: () => this.routeTo("register"),
        },
      ],
      menu_user: [
        {
          icon: "mdi-account-box",
          text: this.$lang("ROOT:DRAWER:BUTTON_UPDATE_PROFILE-TEXT"),
          to: () => this.routeTo("update-profile"),
        },
        {
          icon: "mdi-email",
          text: this.$lang("ROOT:DRAWER:BUTTON_UPDATE_EMAIL-TEXT"),
          to: () => this.routeTo("update-email"),
        },
        {
          icon: "mdi-lock",
          text: this.$lang("ROOT:DRAWER:BUTTON_UPDATE_PASSWORD-TEXT"),
          to: () => this.routeTo("update-password"),
        },
        {
          icon: "mdi-logout",
          text: this.$lang("ROOT:DRAWER:BUTTON_LOGOUT-TEXT"),
          to: () => this.routeTo("logout"), //this.signout
        },
      ],
    };
  },

  watch: {
    showDrawer: function () {
      this.drawer = this.showDrawer;
    },
    drawer: function () {
      if (this.drawer == false) this.$emit("drawerFalse");
    },
    authUser(val) {
      if (this.allowedManagerAndUp) {
        this.menu_manage.push({
          icon: "mdi-bus",
          text: this.$lang("GENERATE-MOCKTEST"),
          to: () => this.routeTo("dashboard-prep-mocktests-generate"),
        });
      } else {
        this.menu_manage = this.menu_manage.filter((el) => {
          return el.icon != "mdi-bus";
        });
      }
    },
  },
  props: ["showDrawer"],
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      authUser: "auth/user",
    }),
    isAllowedPrep() {
      let allowed = ["moderator", "manager", "teacher", "admin", "superadmin"];
      if (this.auth.user && allowed.includes(this.auth.user.role)) {
        return true;
      } else {
        return false;
      }
    },
    isAllowedAuth() {
      let allowed = ["admin", "superadmin"];
      if (this.auth.user && allowed.includes(this.auth.user.role)) {
        return true;
      } else {
        return false;
      }
    },
    isAllowedFinance() {
      let allowed = ["admin", "superadmin"];
      if (this.auth.user && allowed.includes(this.auth.user.role)) {
        return true;
      } else {
        return false;
      }
    },
    isAllowedAdmin() {
      let allowed = ["admin", "superadmin"];
      if (this.auth.user && allowed.includes(this.auth.user.role)) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    routeTo(to) {
      this.$router.push({ name: to });
    },
    // signout() {
    //   this.logout().then(() => {
    //     this.$router.replace({ name: "login" });
    //   });
    //   this.drawer = false;
    // },
  },
  beforeCreate() {
    bus.$on("hideAppDrawer", () => {
      this.drawer = false;
    });
    bus.$on("showAppDrawer", () => {
      this.drawer = true;
    });
  },
};
</script>

