import Vue from "vue";
import Vuex from "vuex";
import dashboard from "../app/dashboard/store/index.js";
import auth from "../app/auth/store";
import app from "../app/store";

Vue.use(Vuex);

import state from "./state";
import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    app,
    auth,
    dashboard,
  },
});
