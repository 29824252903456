<template>
  <layout-center>
    <form>
      <v-card v-if="item">
        <v-card-title>
          <div class="title">
            {{ $lang(item.id ? "UPDATE" : "NEW") + " " + $lang("JOB") }}
          </div>
          <v-spacer></v-spacer>

          <banner-uploader
            v-if="item && item.id"
            :item="item"
            dispatch="dashboard/prep/JobsUpdateBanner"
          />
        </v-card-title>
        <v-divider></v-divider>

        <common-create-fields :item="item" variant="default" />

        <v-card-actions>
          <div class="text-xs-center">
            <v-btn outlined @click="doSubmit">{{
              $lang(item.id ? "UPDATE" : "CREATE")
            }}</v-btn>
            <v-btn
              text
              @click="$router.push({ name: 'dashboard-prep-jobs' })"
              >{{ $lang("CANCEL") }}</v-btn
            >
          </div>
          <v-spacer></v-spacer>
          <v-btn v-if="item.id" color="red" outlined @click="remove()">
            <v-icon>mdi-delete</v-icon> {{ $lang("REMOVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </layout-center>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutCenter from "@/layouts/LayoutCenter.vue";
import BannerUploader from "@/components/BannerUploader.vue";
import CommonCreateFields from "../../modules/CommonCreateFields.vue";

export default {
  name: "dashboard-prep-jobs-create",
  components: {
    LayoutCenter,
    BannerUploader,
    CommonCreateFields,
  },
  data() {
    return {
      item: {
        id: null,
        title: "",
        description: "",
        language: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  watch: {},
  methods: {
    remove() {
      this.$store
        .dispatch("dashboard/prep/JobsRemove", {
          item: this.item,
          context: this,
        })
        .then(() => {
          this.$router.replace({ name: "dashboard-prep-jobs" });
        });
    },
    fetchItem(id) {
      this.item.id = this.$route.params.id;
      this.$store
        .dispatch("dashboard/prep/JobsGetOne", {
          id: this.item.id,
          context: this,
        })
        .catch(() => {
          this.$router.replace({ name: "dashboard-prep-jobs" });
        });
    },
    doSubmit() {
      if (this.$route.params.id) {
        this.$store.dispatch("dashboard/prep/JobsUpdate", {
          item: this.item,
          context: this,
        });
      } else {
        this.$store
          .dispatch("dashboard/prep/JobsCreate", {
            item: this.item,
            context: this,
          })
          .then((data) => {
            this.$router.replace({
              name: "dashboard-prep-jobs-create",
              params: { id: data.id },
            });
          });
      }
    },
  },
  watch: {
    "$route.params.id"(id) {
      this.fetchItem(id);
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchItem(this.$route.params.id);
    }
  },
};
</script>
