<template>
  <v-list subheader two-line v-if="items && items.length > 0">
    <v-list-item
      v-for="item in items"
      :key="item.id"
      @click.stop="$emit('clicked', { clickedItem: item })"
      v-long-press="
        () => {
          $emit('update', { clickedItem: item });
        }
      "
    >
      <v-list-item-content>
        <div>
          <v-btn
            :id="'highlight-' + item.id"
            @click.stop="$emit('toggleActive', { clickedItem: item })"
            icon
            :color="item.active ? 'green' : 'red'"
          >
            <v-icon>{{
              item.active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline"
            }}</v-icon>
          </v-btn>
          {{
            $store.getters.getCurrLocale == "hi"
              ? item.title_hi
                ? item.title_hi
                : item.title
              : item.title
          }}
        </div>

        <v-list-item-subtitle>
          <v-chip label x-small v-if="item.user">{{
            item.user.username
          }}</v-chip>
          <v-chip
            @click.stop="
              () => {
                copyToClipboard(`#QE${item.id} -- ${item.title} ${
                  item.description ? '\r\n' + item.description : ''
                }
(A). ${item.option_a}
(B). ${item.option_b}
(C). ${item.option_c}
(D). ${item.option_d}

#QE${item.id} -- ${item.title_hi} ${
                  item.description_hi ? '\r\n' + item.description_hi : ''
                }
(अ). ${item.option_a_hi}
(ब). ${item.option_b_hi}
(स). ${item.option_c_hi}
(द). ${item.option_d_hi}


`);
              }
            "
            label
            class="mr-1 black accent-4 white--text"
            x-small
            v-if="idPrefix && item.id"
            >Copy
          </v-chip>
          <v-chip
            @click.stop="
              () => {
                copyToClipboard('#' + idPrefix + ' - Explanation / स्पष्टीकरण');
              }
            "
            label
            class="mr-1 black accent-4 white--text"
            x-small
            v-if="idPrefix && item.id"
            >#{{ idPrefix }}{{ item.id }}</v-chip
          >
          <v-chip label x-small v-if="item.subject">{{
            item.subject.title
          }}</v-chip>
        </v-list-item-subtitle>
        <div style="border-bottom: 1px dashed; margin-top: 5px"></div>
      </v-list-item-content>
      <v-list-item-action>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="mItem in menu"
              :key="mItem.id"
              @click="mItem.to(item)"
            >
              <v-icon>{{ mItem.icon(item) }}</v-icon>
              {{ mItem.title }}
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </v-list-item>
  </v-list>

  <v-card v-else>
    <v-card-title class="justify-center">{{ $lang("NOITEMS") }}</v-card-title>
  </v-card>
</template>

<script>
import { copyToClipboard } from "@/helpers/utils";
export default {
  name: "list-item-details",
  props: ["items", "idPrefix"],
  data() {
    return {
      menu: [
        {
          id: 1,
          title: "Update",
          icon: (clickedItem) => "mdi-pencil",
          to: (clickedItem) => {
            this.$emit("update", { clickedItem });
          },
        },
        {
          id: 2,
          title: "Active",
          icon: (clickedItem) => {
            return clickedItem.active == true ? "mdi-eye" : "mdi-eye-off";
          },
          to: (clickedItem) => {
            this.$emit("toggleActive", { clickedItem });
          },
        },
        {
          id: 3,
          title: "Remove",
          icon: (clickedItem) => {
            return "mdi-delete";
          },
          to: (clickedItem) => {
            this.$emit("remove", { clickedItem });
          },
        },
      ],
    };
  },
  methods: {
    copyToClipboard(str) {
      copyToClipboard(str.replace(/^(\s*\r\n){2,}/, "\r\n"));
      this.$store.dispatch("setAlert", {
        message: "Copied--" + str,
        icon: "mdi-clipboard-check",
        type: "pink darken-2 white--text",
      });
    },
  },
};
</script>
