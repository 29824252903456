export const getCurrentRoute = (state) => {
  return state.currRoute;
};

export const getCurrLocale = (state) => {
  return state.currLocale;
};

export const isThemeDark = (state) => {
  return state.isThemeDark;
};

export const getThemeAccentColor = (state) => {
  return state.isThemeDark ? "blue" : "yellow";
};

export const getAlert = (state) => {
  return state.alert;
};

export const getFormErrors = (state) => {
  return state.formErrors;
};
