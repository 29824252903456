import state from "./state";
import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

import prep from "../prep/store";
import auth from "../auth/store";
import grid from "../grid/store";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    prep,
    auth,
    grid,
  },
};
