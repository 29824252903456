var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-center',[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_c('div',{staticClass:"text-right"},[_c('div',[_vm._v(_vm._s(_vm.exam.title))]),(_vm.exam.parent)?_c('div',{staticClass:"caption"},[_c('v-btn',{staticClass:"pa-0 ma-0",attrs:{"plain":"","height":"1.2rem"},on:{"click":function($event){return _vm.$router.push({
                name: 'dashboard-prep-exam',
                params: { id: _vm.exam.parent_id },
              })}}},[_vm._v(" "+_vm._s(_vm.isHindi ? _vm.exam.parent.title_hi ? _vm.exam.parent.title_hi : _vm.exam.parent.title : _vm.exam.parent.title)+" ")])],1):_vm._e()])],1),_c('v-divider'),_c('list-with-avatar',{attrs:{"items":_vm.items},on:{"toggleActive":({ clickedItem }) => {
          _vm.$store.dispatch('dashboard/prep/MocktestsToggleActive', {
            item: clickedItem,
          });
        },"update":({ clickedItem }) => {
          _vm.$router.push({
            name: 'dashboard-prep-mocktests-create',
            params: { id: clickedItem.id },
            query: {
              exam_id: _vm.exam.id,
            },
          });
        },"clicked":({ clickedItem }) => {
          _vm.$router.push({
            name: 'dashboard-prep-mocktests-segments',
            params: { id: clickedItem.id },
          });
        }}}),_c('v-btn',{staticClass:"orange darken-4 white--text",style:(`bottom: 130px; z-index: 10`),attrs:{"fab":"","fixed":"","bottom":"","right":"","dark":""},on:{"click":function($event){return _vm.$router.push({
          name: 'dashboard-prep-mocktests-create',
          query: {
            exam_id: _vm.exam.id,
          },
        })}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }