<template>
  <form enctype="multipart/form-data" @submit.prevent="doUpload()">
    <div class="image-box">
      <v-avatar>
        <img
          v-if="item && item.banner_square"
          :src="imagePreview ? imagePreview : item.banner_square"
          alt="Banner"
          @click="openUpload"
        />
        <v-icon v-else large @click="openUpload">mdi-image</v-icon>
      </v-avatar>
    </div>
    <input
      name="banner"
      type="file"
      id="banner"
      @change="updatePreview"
      style="display: none"
    />
  </form>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "banner-uploader",
  props: ["item", "dispatch"],
  data() {
    return {
      imagePreview: null,
    };
  },
  computed: {
    ...mapGetters({
      //auth: "auth/auth",
    }),
  },
  created() {},
  methods: {
    openUpload() {
      document.getElementById("banner").click();
    },

    updatePreview(e) {
      var reader,
        files = e.target.files;
      if (files.length === 0) {
        console.log("Empty");
      }
      reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      if (files[0]) reader.readAsDataURL(files[0]);
      this.doUpload();
    },

    doUpload() {
      this.$store.dispatch(this.dispatch, {
        files: document.querySelector("#banner"),
        context: this,
      });
    },
  },
};
</script>
<style scoped>
img {
  -webkit-transition: -webkit-transform 1s; /* Safari */
  transition: transform 1s;
}

img:hover {
  -webkit-transform: rotate(360deg); /* Safari */
  transform: rotate(360deg);
  cursor: pointer;
}
</style>
