<template>
  <v-layout justify-center align-center>
    <v-flex md8>
      <v-card v-if="authUser" class="pa-14">
        <v-card-title class="justify-center"
          >{{ `${$lang("welcome")}! ${authUser.display_name}` }}.</v-card-title
        >
      </v-card>

      <StatsDailyReports v-if="allowedManagerAndUp" />
      <v-card class="mt-2" v-if="allowedManagerAndUp">
        <v-card-actions>
          <v-btn
            @click="
              () => {
                $router.push({ name: 'analytics' });
              }
            "
            outlined
            tile
            text
            block
            >{{ $lang("View Analytics").ucWords() }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { axios } from "@/plugins/axios";
import StatsDailyReports from "../../analytics/modules/StatsDailyReports";
import { mapGetters } from "vuex";
export default {
  name: "home",
  components: {
    StatsDailyReports,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>