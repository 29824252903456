import { axios, setHttpToken } from "@/plugins/axios.js";
import { isEmpty } from "lodash";
import localforage from "localforage";
import bus from "@/bus";
import { locale } from "core-js";
import store from "@/store";

function isValidRole(role) {
  let roles = [
    "admin",
    "member",
    "manager",
    "moderator",
    "student",
    "teacher",
    "parent",
  ];
  return roles.includes(role);
}

function dispatchErrorAlert(error) {
  store.dispatch("apiAlert", {
    message:
      error.response.data && error.response.data.message
        ? error.response.data.message
        : error.response.status,
    code: error.response.status,
  });
}

export const usersCreate = ({ dispatch }, { item, context }) => {
  bus.$emit("showWait");
  return axios
    .post(`auth/signup`, item)
    .then((response) => {
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const usersRemove = ({ dispatch }, { item, context }) => {
  if (!confirm(`Are you sure to delete ${item.email}?`)) {
    return Promise.reject("CANCELED_BY_USER");
  }
  bus.$emit("showWait");
  return axios
    .post(`auth/remove/user/${item.id}`)
    .then((response) => {
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const getUsers = (
  { dispatch },
  { context, page, perPage, orderby, where }
) => {
  bus.$emit("showWait");
  return axios
    .get(
      `/auth/users?page=${page}&limit=${perPage}&orderby=${orderby}&where=${where}`
    )
    .then((response) => {
      if (context) {
        context.pagination = {
          lastPage: response.data.lastPage,
          page: response.data.page,
          perPage: response.data.perPage,
          total: response.data.total,
        };
        context.items = response.data.data.filter((el) => {
          return el.role !== "superadmin";
        });
      }
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const usersGetOne = ({ dispatch }, { id, context }) => {
  bus.$emit("showWait");
  return axios
    .get(`/auth/users/${id}`)
    .then((response) => {
      if (context) {
        context.item = response.data;
      }
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const usersToggleVerify = ({ dispatch }, { item, context }) => {
  bus.$emit("showWait");
  return axios
    .get(`/auth/toggle/user/verify/${item.id}`)
    .then((response) => {
      item.verified = response.data.verified;
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const usersAssignRole = ({ dispatch }, { item, role, context }) => {
  if (!isValidRole(role)) {
    return Promise.reject("INVALID_ROLE");
  }

  bus.$emit("showWait");
  return axios
    .post(`/auth/assign/role`, { userId: item.id, role })
    .then((response) => {
      item.role = response.data.role;
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const usersToggleBan = ({ dispatch }, { item, context }) => {
  bus.$emit("showWait");
  return axios
    .get(`/auth/toggle/user/ban/${item.id}`)
    .then((response) => {
      item.banned = response.data.banned;
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      bus.$emit("hideWait");
      dispatchErrorAlert(error);
      return Promise.reject(error.response);
    });
};

export const resetState = ({ commit }) => {
  commit("SET_AUTHENTICATED", false);
  commit("SET_USER", null);
  commit("SET_TOKEN", null);
};
