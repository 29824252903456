import prep from "./prep/routes";
import logs from "./logs/routes";
import orders from "./orders/routes";
import payments from "./payments/routes";
import products from "./products/routes";
import quotes from "./quotes/routes";
import coupons from "./coupons/routes";
import videos from "./videos/routes";
import banners from "./banners/routes";
import feeds from "./feeds/routes";
import auth from "./auth/routes";
import grid from "./grid/routes";

export default [
  ...prep,
  ...logs,
  ...orders,
  ...payments,
  ...products,
  ...quotes,
  ...coupons,
  ...banners,
  ...feeds,
  ...videos,
  ...auth,
  ...grid,
];
