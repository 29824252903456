<template>
  <v-layout justify-center align-center>
    <v-flex md8 lg6>
      <form>
        <v-card v-if="user">
          <v-card-title>
            <div class="title">
              {{ $lang("AUTH:UPDATE_PROFILE:CARD_TITLE-TEXT") }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              :label="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_FIRSTNAME-LABEL')"
              :hint="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_FIRSTNAME-HINT')"
              v-model="profile.first_name"
              type="text"
              :error-messages="
                errors && errors.firstname ? errors.firstname : []
              "
            ></v-text-field>

            <!-- <v-text-field
							:label="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_MIDDLENAME-LABEL')"
							:hint="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_MIDDLENAME-HINT')"
							v-model="profile.middle_name"
							type="text"
							:error-messages="errors && errors.middle_name ? errors.middle_name : []"
							></v-text-field> -->

            <v-text-field
              :label="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_LASTNAME-LABEL')"
              :hint="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_LASTNAME-HINT')"
              v-model="profile.last_name"
              type="text"
              :error-messages="
                errors && errors.last_name ? errors.last_name : []
              "
            ></v-text-field>

            <vue-tip
            variant="minimal"
            :initial="profile.about" 
            @changed="(val)=>{ profile.about = val; }" />

            <v-text-field
              :label="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_PASSWORD-LABEL')"
              :hint="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_PASSWORD-HINT')"
              v-model="password"
              type="password"
              :error-messages="errors && errors.password ? errors.password : []"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <div class="text-xs-center">
              <v-btn outlined @click="doUpdateProfile">{{
                $lang("AUTH:UPDATE_PROFILE:BUTTON_SUBMIT-TEXT")
              }}</v-btn>
              <v-btn text @click="$router.push({ name: 'home' })">{{
                $lang("AUTH:UPDATE_PROFILE:BUTTON_SECONDARY-TEXT")
              }}</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </form>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueTip from "@/components/VueTip"
export default {
  name: "update-profile",
  components: {
    VueTip
  },
  data() {
    return {
      profile: {},
      password: "",
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      user: "auth/user",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  watch: {
    user(value) {
      this.profile = value.profile;
    },
  },
  mounted() {
    if (this.user) this.profile = this.user.profile;
  },
  methods: {
    ...mapActions({
      updateProfile: "auth/updateProfile",
    }),
    doUpdateProfile() {
      if (!this.profile) return;
      this.updateProfile({
        profile: this.profile,
        password: this.password,
        context: this,
      })
        .then((res) => {})
        .catch((err) => {});
    },
  },
};
</script>
