<template>
  <v-row>
    <v-col cols="12">
      <search-card
        @clicked="(term) => getItems(term, this.$route.query.page)"
      />
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="pagination.total"
        :loading="loading"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:item.user_id="{ item }">
          <v-chip small outlined>
            {{ item.user.username }}
          </v-chip>
        </template>

        <template v-slot:item.title="{ item }">
          {{
            $store.getters.getCurrLocale == "hi"
              ? item.title_hi
                ? item.title_hi
                : item.title
              : item.title
          }}
        </template>

        <template v-slot:item.prep_subject_id="{ item }">
          <v-chip small outlined color="primary">{{
            $store.getters.getCurrLocale == "hi"
              ? item.subject.title_hi
                ? item.subject.title_hi
                : item.subject.title
              : item.subject.title
          }}</v-chip>
        </template>
        <template v-slot:item.tags="{ item }">
          <v-chip
            class="mx-1"
            outlined
            label
            x-small
            v-for="tag in item.tags"
            :key="tag.id"
          >
            {{
              $store.getters.getCurrLocale == "hi"
                ? tag.title_hi
                  ? tag.title_hi
                  : tag.title
                : tag.title
            }}
          </v-chip>
        </template>

        <template v-slot:item.active="{ item }">
          <v-btn
            :id="'highlight-' + item.id"
            @click.stop="
              $store.dispatch('dashboard/prep/QuestionsToggleActive', {
                item,
              })
            "
            icon
            :color="item.active ? 'green' : 'red'"
          >
            <v-icon>{{
              item.active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline"
            }}</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            class="mx-1"
            small
            icon
            @click="
              $router.push({
                name: 'dashboard-prep-questions-create',
                params: { id: item.id },
                query: { parent_page: $route.query.page },
              })
            "
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class="mx-1"
            small
            icon
            @click="
              $store
                .dispatch('dashboard/prep/QuestionsRemove', {
                  item,
                })
                .then(() => {
                  items = items.filter((el) => {
                    return el.id != item.id;
                  });
                })
            "
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12">
      <query-page-redirect :pagination="pagination" />
      <!-- <v-pagination
        v-model="pagination.page"
        :length="pagination.lastPage"
      ></v-pagination> -->
    </v-col>
    <fab-button
      :icon="'mdi-plus'"
      color="primary"
      @clicked="$router.push({ name: 'dashboard-prep-questions-create' })"
    />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import FabButton from "@/components/FabButton.vue";
import { axios } from "@/plugins/axios";
import SearchCard from "../../modules/SearchCard.vue";
import QueryPageRedirect from "@/components/QueryPageRedirect.vue";
import bus from "@/bus";

export default {
  name: "dashboard-prep-questions",
  components: {
    FabButton,
    SearchCard,
    QueryPageRedirect,
  },
  data() {
    return {
      items: [],
      options: {},
      loading: false,
      where: "",
      headers: [
        { text: "Active", value: "active", sortable: true },
        { text: "Title", value: "title", sortable: true },
        { text: "Subject", value: "prep_subject_id", sortable: true },
        { text: "User", value: "user_id", sortable: true },
        { text: "Tags", value: "tags", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pagination: {
        lastPage: null,
        page: 1,
        perPage: null,
        total: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
  },
  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
    "pagination.page"(page) {
      if (page > 0) {
        //this.getItems();
      }
    },
  },
  methods: {
    getItems(term = "", currPage) {
      if (!currPage) {
        currPage = this.$route.query.page;
      }
      this.where = term;
      this.loading = true;
      const { sortBy, sortDesc } = this.options;
      const ascDesc = sortDesc && sortDesc[0] ? "desc" : "asc";

      return axios
        .get(`/prep/questions`, {
          params: {
            page: currPage ? currPage : 1,
            orderby:
              sortBy && sortBy.length > 0 ? `${sortBy[0]}|${ascDesc}` : ``,
            where: this.where,
            perPage: 20,
          },
        })
        .then((res) => {
          this.pagination = {
            lastPage: res.data.lastPage,
            page: res.data.page,
            perPage: res.data.perPage,
            total: res.data.total,
          };
          this.items = res.data.data;
          return Promise.resolve(res.data);
        })
        .catch((error) => {
          return Promise.reject(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    // this.getItems("", this.$route.query.page);
  },
  updated() {
    bus.$emit("highlightElement");
  },
};
</script>
