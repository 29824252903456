<template>
  <v-card flat v-if="dialog" class="uploader">
    <div ref="area" class="upload-area">
      <img v-if="data" :src="data" />
      <img v-if="url && !data" :src="url" />
      <div v-if="!data && !url">CLick to Upload/ paste here</div>
    </div>
    <input
      :name="inputIdent"
      type="file"
      :id="inputIdent"
      style="display: none"
      accept="image/*"
      @change="updatePreview"
    />
    <v-card-actions v-if="data">
      <image-cropper @cropped="handleEdited" :src="data ? data : url" />
      <v-spacer></v-spacer>
      <v-btn text outlined dark :loading="loading" @click="doUpload">
        <v-icon>mdi-upload</v-icon>
        {{ $lang("Upload") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import bus from "@/bus";
import UploadBase64 from "@/helpers/UploadBase64.js";
import ImageCropper from "@/components/ImageCropper";
export default {
  props: [
    "show",
    "submitProp",
    "inputIdent",
    "identity",
    "width",
    "height",
    "title",
    "findFase",
  ],
  data() {
    return {
      loading: false,
      data: null,
      url: null,
      dialog: true,
    };
  },
  components: {
    ImageCropper,
  },
  computed: {
    
  },
  mounted() {
    this.$refs.area.onclick = this.onClick;
    this.$refs.area.onpaste = this.onPaste;
  },
  created() {
    this.dialog = this.show ? this.show : true;
  },
  watch: {
    data(val) {
      this.$emit("imageAdded", val ? true : false);
    },
    show(val) {
      this.dialog = val;
      if (val == true) {
        this.url = null;
        this.data = null;
        this.dialog = true;
        this.$emit("imageAdded", false);
      }
    },
    dialog(val) {
      this.$emit("shown", val);
    },
    submitProp(val) {
      if (val == true && this.data) {
        this.doUpload();
      }
    },
    dialog(val) {
      this.$emit("closed", val);
    },
  },
  methods: {
    handleEdited(croppedData) {
      this.data = croppedData;
    },
    blobToDataURL(blob, callback) {
      if (!blob) return;

      var a = new FileReader();
      a.onload = function (e) {
        callback(e.target.result);
      };
      a.readAsDataURL(blob);
    },
    openUpload() {
      document.getElementById(this.inputIdent).click();
    },
    onClick() {
      console.log("clicked...");
      if (this.data || this.url) {
        this.url = null;
        this.data = null;
      } else {
        this.openUpload();
      }
    },
    updatePreview(e) {
      var files = e.target.files;
      if (files.length === 0) {
        console.log("file not selected");
      }

      this.blobToDataURL(files[0], (res) => {
        this.data = res;
        this.$emit("imageAdded", true);
      });
    },
    onPaste(e) {
      console.log("pasted...");

      let file = e.clipboardData.items[0].getAsFile();
      if (file) {
        e.preventDefault();
        e.stopPropagation();
        this.blobToDataURL(file, (res) => {
          this.url = null;
          this.data = res;
          this.$emit("imageAdded", true);
        });
      }
    },

    doUpload() {
      if (!this.data) {
        console.log("Choose an image to upload.");
        return;
      }
      bus.$emit("showWait", "Uploading...");
      this.loading = true;
      return UploadBase64(this.data, "media/upload", {
        identity: this.identity ? this.identity : null,
        width: this.width ? this.width : null,
        height: this.height ? this.height : null,
        title: this.title ? this.title : null,
        findFase: this.findFase ? this.findFase : null,
      })
        .then((res) => {
          this.$emit("uploaded", res.url);
          this.$emit("select-file", {
            src: res.url,
            alt: "",
          });
          this.url = res.url;
          this.data = null;
          this.dialog = false;
          this.$emit("imageAdded", false);
        })
        .catch((err) => {
          console.error(err);
          this.url = null;
        })
        .finally(() => {
          bus.$emit("hideWait");
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.uploader {
  background: rgb(80, 80, 80);
  cursor: pointer;
}
.upload-area {
  width: 100%;
  height: 180px;
  color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid darkgray;
}
img {
  object-fit: cover;
}
</style>