<template>
  <div>
    <v-row dense class="text-center justify-center">
      <v-col cols="12">
        <v-card v-if="item && item.id > 0">
          <v-card-title>
            <v-btn icon @click="$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon></v-btn
            >
            <v-spacer></v-spacer>
            <span>{{
              isHindi
                ? item.title_hi
                  ? item.title_hi
                  : item.title
                : item.title
            }}</span>
            <v-avatar class="ml-2" tile>
              <v-img :src="item.banner_square"> </v-img>
            </v-avatar>
          </v-card-title>
          <v-divider></v-divider>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense class="text-center justify-center">
      <v-col v-for="item in children" :key="item.id" cols="6" md="4">
        <v-card
          @click="$router.push({ name: 'exam', params: { id: item.id } })"
          outlined
          elevation="0"
          style="overflow: hidden"
          height="150"
          class="d-flex flex-column justify-space-around"
        >
          <v-card-text class="ma-0 pa-0 pt-1 text-center">
            <v-badge
              top
              right
              dot
              :color="item.active ? 'green' : 'red'"
              offset-x="30"
              offset-y="-20"
            ></v-badge>
            <v-avatar size="70" class="justify-self-cent">
              <v-img
                :src="
                  item.banner_square
                    ? item.banner_square
                    : 'https://images.unsplash.com/photo-1456513080510-7bf3a84b82f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&h=400&q=80'
                "
              />
            </v-avatar>
          </v-card-text>
          <v-card-text class="ma-0 pa-0 pt-1 text-center">
            <div
              :class="'subtitle-1 ' + (isDark ? 'white--text' : 'black--text')"
            >
              {{
                isHindi
                  ? item.title_hi
                    ? item.title_hi
                    : item.title
                  : item.title
              }}
            </div>
            <div class="caption">
              <span class="mr-1" v-for="child in item.children" :key="child.id">
                {{
                  isHindi
                    ? child.title_hi
                      ? child.title_hi
                      : child.title
                    : child.title
                }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider
      v-if="items && items.length > 0 && children && children.length > 0"
      class="mt-5"
    ></v-divider>
    <v-row dense class="text-center justify-center">
      <v-col cols="12" v-if="item && item.id && (!items || items.length > 0)">
        <v-card-title class="mb-0 pb-0 justify-center">
          {{ isHindi ? "उपलब्ध परीक्षा टेस्ट्स" : "AVAILABLE EXAM TESTS" }}
        </v-card-title>
        <v-card-text class="mt-0 pt-0 text-center">
          {{
            isHindi
              ? "कृपया नीचे से कोई भी परीक्षा टेस्ट चुनें।"
              : "Please choose any exam test from below."
          }}
        </v-card-text>
      </v-col>
      <v-col v-for="mocktest in items" :key="mocktest.id" cols="12" md="6">
        <v-expansion-panels focusable>
          <v-expansion-panel class="mb-1">
            <v-expansion-panel-header expand-icon="">
              <div>
                <div class="subtitle-1 mb-1">
                  <v-badge
                    top
                    right
                    dot
                    :color="item.active ? 'green' : 'red'"
                    offset-x="30"
                    offset-y="-10"
                  ></v-badge>

                  <v-badge
                    v-if="!mocktest.owned"
                    top
                    :color="
                      mocktest.vcoin ? 'orange darken-2' : 'grey darken-1'
                    "
                    :content="
                      mocktest.vcoin < 1 ? 'free' : '🌕 ' + mocktest.vcoin
                    "
                    offset-x="30"
                    offset-y="-20"
                  ></v-badge>
                  <v-badge
                    v-if="mocktest.owned"
                    top
                    :color="'green darken-2'"
                    :content="'✔️ owned'"
                    offset-x="30"
                    offset-y="-20"
                  ></v-badge>
                  <v-badge
                    v-if="mocktest.elapsed"
                    bordered
                    bottom
                    color="orange accent-4"
                    dot
                    offset-x="10"
                    offset-y="10"
                  ></v-badge>
                  {{
                    isHindi
                      ? mocktest.title_hi
                        ? mocktest.title_hi
                        : mocktest.title
                      : mocktest.title
                  }}
                </div>
                <div class="caption grey--text">
                  <v-btn
                    class="grey--text"
                    style="margin-left: -0.4rem"
                    x-small
                    text
                  >
                    <v-icon left>mdi-calendar</v-icon>
                    {{ mocktest.created_at | formatDateOnly }}
                  </v-btn>
                  <v-btn class="brown--text" x-small text>
                    <v-icon left>mdi-clock</v-icon>
                    {{ calculateDuration(mocktest) }}
                  </v-btn>
                </div>
              </div>
              <v-spacer></v-spacer>
              {{
                mocktest.segments.reduce(
                  (prev, curr) => prev + curr.__meta__.questions_count,
                  0
                )
              }}

              {{ $lang("qstn") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list dense style="margin: 0 -1.5rem">
                <v-list-item v-for="seg in mocktest.segments" :key="seg.id">
                  {{
                    isHindi
                      ? seg.title_hi
                        ? seg.title_hi
                        : seg.title
                      : seg.title
                  }}
                  <v-spacer></v-spacer>
                  {{ seg.__meta__.questions_count }}
                  {{ $lang("qstn") }}
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item
                  v-if="false"
                  class="caption"
                  style="padding: 0 -1.5rem"
                >
                  <v-icon left>mdi-face</v-icon>
                  {{ $lang("Instructor") }}:
                  <v-spacer></v-spacer>
                  <span text @click.stop="">
                    {{ mocktest.user.username.toUpperCase() }}
                  </span>
                </v-list-item>
              </v-list>
              <v-card-actions
                style="margin: 0 -1.5rem"
                class="text-center justify-center"
              >
                <!-- <purchase-dialog
                  :mocktest="mocktest"
                  v-if="mocktest.vcoin > 0 && !mocktest.owned"
                /> -->
                <v-btn
                  @click="
                    $router.push({
                      name: 'exams-mocktest-preview',
                      params: { id: mocktest.id },
                    })
                  "
                  :class="
                    mocktest.elapsed ? 'orange darken-3' : 'blue darken-3'
                  "
                  text
                  dark
                >
                  {{ $lang(mocktest.elapsed ? "Resume" : "Start") }}
                  <span v-if="mocktest.elapsed">
                    ({{ mocktest.elapsed.toString().fancyTime() }})
                  </span>
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  @click="
                    $router.push({
                      name: 'generate-pdf',
                      params: { mocktest_id: mocktest.id },
                    })
                  "
                  color="red"
                >
                  pdf
                </v-btn>
                <one-question-per-page :mocktest_id="mocktest.id" />
                <v-btn
                  @click="
                    $router.push({
                      name: 'dashboard-prep-mocktests-create',
                      params: { id: mocktest.id },
                    })
                  "
                  color="primary"
                  icon
                >
                  <v-icon> mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  outlined
                  @click="
                    $router.push({
                      name: 'dashboard-prep-mocktests-segments',
                      params: { id: mocktest.id },
                    })
                  "
                  color="primary"
                  >{{ $lang("SEG") }}
                </v-btn>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12">
        <query-page-redirect :pagination="pagination" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { axios } from "@/plugins/axios";
import { mapGetters } from "vuex";
import QueryPageRedirect from "@/components/QueryPageRedirect";
// import PurchaseDialog from "./PurchaseDialog";
import OneQuestionPerPage from "@/app/home/components/OneQuestionPerPage.vue";

export default {
  name: "exam",
  components: {
    QueryPageRedirect,
    // PurchaseDialog,
    OneQuestionPerPage,
  },
  data() {
    return {
      children: null,
      item: {},
      items: [],
      pagination: {},
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      authUser: "auth/user",
      mocktests: "app/exams/mocktests",
    }),
  },
  methods: {
    calculateDuration(mocktest) {
      let duration = 0;
      if (mocktest.duration > 0) {
        duration = mocktest.duration;
      } else {
        duration = mocktest.segments.reduce((pre, cur) => {
          return pre + cur.duration;
        }, 0);
      }
      return duration.toString().fancyTime();
    },
    countQuestions(items) {
      let total = 0;
      items.forEach((item) => {
        total += item.segments.reduce(
          (prev, curr) => prev + curr.__meta__.questions_count,
          0
        );
      });
      return total;
    },
  },
  created() {
    this.$store
      .dispatch("app/exams/ExamsGetOne", {
        context: this,
        id: this.$route.params.id,
      })
      .then(() => {
        return axios
          .get(`prep/exams/where/parent_id/${this.$route.params.id}`)
          .then((res) => {
            this.children = res.data.data;
          })
          .catch((err) => {
            console.log("children error", err);
          });
      })
      .then(() => {
        // return axios
        //   // .get(`prep/exams/${this.$route.params.id}/mocktests/`)
        //   // .then((res) => {})
        //   // .catch((err) => {});
        this.$store
          .dispatch("app/exams/MocktestsGetWhere", {
            context: this,
            parent_id: this.item.id,
          })
          .then(() => {
            setTimeout(() => {
              console.log("this.mocktests in localstorage", this.mocktests);

              this.items = this.items.map((el) => {
                let mts = this.mocktests.find(
                  (mt) => mt.id == el.id && mt.elapsed > 0
                );

                if (mts) {
                  el.elapsed = mts.elapsed;
                }

                return el;
              });
            }, 500);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        this.$route.go(-1);
      });
  },
};
</script>
<style scoped>
</style>

