var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.user && _vm.user.id)?_c('v-row',[_c('v-col',{staticClass:"d-flex my-4",attrs:{"cols":"12"}},[_vm._v(" 🌕"+_vm._s(_vm.user.wallet ? _vm.user.wallet.coins : "0")+" coins "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","small":"","outlined":"","text":""},on:{"click":() => {
            _vm.$router.push({
              name: 'products',
            });
          }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" buy more")],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"justify-center",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.user)?_c('v-card',{staticClass:"mt-14 text-center"},[_c('v-avatar',{staticClass:"rounded-circle elevation-6 mt-n12 d-inline-block",attrs:{"size":"128"}},[_c('drawer-avatar-img',{staticStyle:{"cursor":"pointer"}})],1),_c('v-card-text',{staticClass:"text-center"},[_c('h6',{staticClass:"text-h6 mb-2 text--secondary"},[_vm._v(" "+_vm._s(_vm.user.role.ucAll())+" ")]),_c('h4',{staticClass:"text-h4 mb-3 text--primary"},[_vm._v(" "+_vm._s(_vm.user.profile.display_name)+" ")]),_c('p',{staticClass:"text--secondary",domProps:{"innerHTML":_vm._s(_vm.user.profile.about)}}),_c('v-btn',{staticClass:"mr-0",attrs:{"color":"primary","min-width":"100","rounded":""},on:{"click":() => {
                _vm.$router.push({ name: 'update-profile' });
              }}},[_vm._v(" Update ")])],1),_c('v-card-text',[_c('update-username')],1)],1):_vm._e()],1)],1),_c('v-row',{staticClass:"justify-center",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('continue-attempts')],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('my-attempts')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }