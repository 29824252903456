import { axios } from "@/plugins/axios";

//**dataURL to blob**
function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

//**blob to dataURL**
function blobToDataURL(blob, callback) {
  var a = new FileReader();
  a.onload = function(e) {
    callback(e.target.result);
  };
  a.readAsDataURL(blob);
}

// helper function: generate a new file from base64 String
const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1; // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime });
};

export default (
  base64Data,
  destinationURL = "media/upload",
  {
    identity = null,
    width = null, //400,
    height = null,
    title = null,
    findFase = false,
  }
) => {
  // generate file from base64 string
  // const file = dataURLtoFile("data:image/png;base64,iVBORw0KGgoAAAANSUhEU...");
  const file = dataURLtoFile(base64Data);

  // put file into form data
  const data = new FormData();
  data.append("file", file, file.name);
  if (identity) {
    data.append("identity", identity);
  }
  if (width) {
    data.append("width", width);
  }
  if (height) {
    data.append("height", height);
  }
  if (title) {
    data.append("title", title);
  }
  if (findFase) {
    data.append("findFase", findFase);
  }

  // now upload
  return axios
    .post(destinationURL, data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
      console.log(response.data);
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err.response);
    });
};
