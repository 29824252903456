// import { axios } from "@/plugins/axios";

// fetch these from server if required
// fetching may coast some extra processing time
const avaiableRoles = [
  "member",
  "student",
  "parent",
  "teacher",
  "manager",
  "moderator",
  "admin",
  "superamdin",
];

export default ({ route, user }) => {
  // check if route has rules
  if (!route.meta || !route.meta.roles) {
    return true;
  }
  // return false if user has not roles
  if (!user || !user.role) {
    return false;
  }

  // return true if user is super admin
  // remove this, set individual route roles instead
  // if (user.role.toLowerCase() == "superadmin") {
  //   return true;
  // }

  const pageRoles = route.meta.roles; // ["student", "teacher"];

  // check if page has valid roles
  let pageHasValidRoles =
    pageRoles.filter((role) => {
      return avaiableRoles.includes(role.toLowerCase());
    }).length > 0;

  // return false if does not have valid rules
  if (!pageHasValidRoles) {
    return false;
  }

  return (
    pageRoles.filter((role) => {
      return role.toLowerCase() == user.role.toLowerCase();
    }).length > 0
  );
};
