<template>
  <div>
    <v-row v-if="user && user.id">
      <v-col cols="12" class="d-flex my-4">
        🌕{{ user.wallet ? user.wallet.coins : "0" }} coins
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          @click="
            () => {
              $router.push({
                name: 'products',
              });
            }
          "
          small
          outlined
          text
        >
          <v-icon left>mdi-plus</v-icon> buy more</v-btn
        >
      </v-col>
    </v-row>

    <v-row dense class="justify-center">
      <v-col cols="12" md="8">
        <v-card class="mt-14 text-center" v-if="user">
          <v-avatar
            size="128"
            class="rounded-circle elevation-6 mt-n12 d-inline-block"
          >
            <drawer-avatar-img style="cursor: pointer" />
          </v-avatar>
          <v-card-text class="text-center">
            <h6 class="text-h6 mb-2 text--secondary">
              {{ user.role.ucAll() }}
            </h6>

            <h4 class="text-h4 mb-3 text--primary">
              {{ user.profile.display_name }}
            </h4>

            <p class="text--secondary" v-html="user.profile.about"></p>

            <v-btn
              class="mr-0"
              color="primary"
              min-width="100"
              rounded
              @click="
                () => {
                  $router.push({ name: 'update-profile' });
                }
              "
            >
              Update
            </v-btn>
          </v-card-text>
          <v-card-text>
            <update-username />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="justify-center">
      <v-col cols="12" md="6">
        <continue-attempts />
      </v-col>
      <v-col cols="12" md="6">
        <my-attempts />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContinueAttempts from "./ContinueAttempts.vue";
import MyAttempts from "./MyAttempts.vue";
import DrawerAvatarImg from "@/components/DrawerAvatarImg";
import UpdateUsername from "@/app/auth/modules/UpdateUsername";
export default {
  name: "profile",
  components: { UpdateUsername, MyAttempts, ContinueAttempts, DrawerAvatarImg },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {},
  created() {},
};
</script>
