<template>
  <v-layout justify-center align-center>
    <v-flex lg8>
      <v-card v-if="auth.user" class="pa-3s">
        <v-card-title class="headline text-uppercase">
          <v-btn
            @click="
              () => {
                $router.push({ name: 'home' });
              }
            "
            icon
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          {{ $lang("Analytics") }}
        </v-card-title>
      </v-card>
      <StatsDailyUsers />
      <StatsDailyAttempts />
      <StatsDailyMocktest />
      <StatsDailyQuestion />
      <StatsDailyExam />
      <StatsDailyDoubts />
      <StatsDailyReports />
      <StatsDeviceActivity
        :limit="9"
        title="Device Activity (Daily)"
        format="DD MMM YYYY"
        label_format="DMMM"
      />
      <StatsDeviceActivity
        :limit="7"
        title="Device Activity (Hourly)"
        format="HH"
        label_format="hhA"
        :sort_callback="handleSort"
      />
      <StatsDailyLogs
        :limit="8"
        title="Activity Logs (Hourly)"
        format="HH"
        label_format="hhA"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { axios } from "@/plugins/axios";
import StatsDailyUsers from "../modules/StatsDailyUsers";
import StatsDailyReports from "../modules/StatsDailyReports";
import StatsDailyAttempts from "../modules/StatsDailyAttempts";
import StatsDailyLogs from "../modules/StatsDailyLogs";
import StatsDailyDoubts from "../modules/StatsDailyDoubts";
import StatsDeviceActivity from "../modules/StatsDeviceActivity";
import StatsDailyMocktest from "../modules/StatsDailyMocktest";
import StatsDailyExam from "../modules/StatsDailyExam";
import StatsDailyQuestion from "../modules/StatsDailyQuestion";
import { mapGetters } from "vuex";
export default {
  name: "analytics",
  components: {
    StatsDailyReports,
    StatsDailyUsers,
    StatsDailyAttempts,
    StatsDailyDoubts,
    StatsDailyLogs,
    StatsDailyMocktest,
    StatsDailyExam,
    StatsDailyQuestion,
    StatsDeviceActivity,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
  },
  watch: {},
  methods: {
    handleSort(grouped) {
      grouped.sort((a, b) => {
        return parseInt(a.key) < parseInt(b.key)
          ? -1
          : parseInt(b.key) < parseInt(a.key)
          ? 1
          : 0;
      });
      return grouped;
    },
  },
  created() {},
};
</script>