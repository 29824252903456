<template>
  <div v-if="localCurrLocale == 'en'">
    <v-card :class="'mb-1' + cardColor" tile flat>
      <question-card-timer
        v-if="!hideTimer"
        :question="question"
        :idx="idx"
        :currentQuestionId="currentQuestionId"
      />
      <v-card-title :class="'' + cardColor">
        <latex-html
          hasClass="subtitle-1"
          :isLatex="question.is_latex"
          :html="question.title"
        />
      </v-card-title>
      <v-divider></v-divider>
    </v-card>
    <v-card :class="'' + cardColor">
      <v-card-text
        :class="'' + cardColor"
        v-if="
          question && question.description && question.description.length > 8
        "
      >
        <latex-html
          hasClass="body"
          :isLatex="question.is_latex"
          :html="question.description"
        />
      </v-card-text>
      <v-card-text>
        <v-radio-group v-model="question.user_option">
          <radio-html
            value="1"
            hasClass="body"
            isLatex="question.is_latex"
            :html="question.option_a"
          />
          <radio-html
            value="2"
            hasClass="body"
            isLatex="question.is_latex"
            :html="question.option_b"
          />
          <radio-html
            value="3"
            hasClass="body"
            isLatex="question.is_latex"
            :html="question.option_c"
          />
          <radio-html
            value="4"
            hasClass="body"
            isLatex="question.is_latex"
            :html="question.option_d"
          />
        </v-radio-group>
      </v-card-text>
    </v-card>
  </div>
  <div v-else>
    <v-card :class="'mb-1' + cardColor" tile flat>
      <question-card-timer
        v-if="!hideTimer"
        :question="question"
        :idx="idx"
        :currentQuestionId="currentQuestionId"
      />
      <v-card-title :class="'' + cardColor">
        <latex-html
          hasClass="subtitle-1"
          :isLatex="question.is_latex"
          :html="question.title_hi"
        />
      </v-card-title>
    </v-card>
    <v-card :class="'' + cardColor">
      <v-card-text
        :class="'' + cardColor"
        v-if="
          question &&
          question.description_hi &&
          question.description_hi.length > 8
        "
      >
        <latex-html
          hasClass="body"
          :isLatex="question.is_latex"
          :html="question.description_hi"
        />
      </v-card-text>
      <v-card-text>
        <v-radio-group v-model="question.user_option">
          <radio-html
            value="1"
            hasClass="body"
            :isLatex="question.is_latex"
            :html="question.option_a_hi"
          />
          <radio-html
            value="2"
            hasClass="body"
            :isLatex="question.is_latex"
            :html="question.option_b_hi"
          />
          <radio-html
            value="3"
            hasClass="body"
            :isLatex="question.is_latex"
            :html="question.option_c_hi"
          />
          <radio-html
            value="4"
            hasClass="body"
            :isLatex="question.is_latex"
            :html="question.option_d_hi"
          />
        </v-radio-group>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RadioHtml from "@/components/RadioHtml";
import LatexHtml from "@/components/LatexHtml";
import QuestionCardTimer from "@/components/QuestionCardTimer";

export default {
  name: "question-card",
  props: [
    "cardColor",
    "question",
    "idx",
    "currentQuestionId",
    "hideTimer",
    "useGlobalLocale",
    "locale",
  ],
  components: {
    RadioHtml,
    LatexHtml,
    QuestionCardTimer,
  },
  computed: {
    localCurrLocale() {
      if (this.locale) {
        return this.locale;
      } else {
        if (this.useGlobalLocale) {
          return this.$store.getters.getCurrLocale;
        } else {
          return this.$store.getters["app/exams/selectedTestLanguage"];
        }
      }
    },
  },
};
</script>
