<template>
  <v-row>
    <v-col cols="12">
      <v-card class="mb-1">
        <v-card-title>
          <v-btn icon @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <span v-if="parent && parent.id">
            {{
              isHindi
                ? parent.title_hi
                  ? parent.title_hi
                  : parent.title
                : parent.title
            }}
          </span>
          <span v-else>{{ $lang("exams") }}</span>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="pagination.total"
        :loading="loading"
        class="elevation-1"
        hide-default-footer
        @click:row="handleRowClick"
      >
        <template v-slot:item.title="{ item }">
          {{
            isHindi ? (item.title_hi ? item.title_hi : item.title) : item.title
          }}
        </template>
        <template v-slot:item.active="{ item }">
          <v-btn
            :id="'highlight-' + item.id"
            @click.stop="
              $store.dispatch('dashboard/prep/ExamsToggleActive', {
                item,
              })
            "
            icon
            :color="item.active ? 'green' : ''"
          >
            <v-icon>{{ item.active ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.children="{ item }">
          {{ item.children.length }} {{ $lang("exams") }}
        </template>
        <template v-slot:item.mocktests="{ item }">
          <v-chip small @click.stop="handleClickItemMocktests(item)"
            >{{ item.mocktests.length }} {{ $lang("mocktests") }}</v-chip
          >
          <v-btn
            class="ml-2"
            v-if="item.children.length < 1"
            @click="
              $router.push({
                name: 'dashboard-prep-mocktests-create',
                query: {
                  exam_id: item.id,
                  parent_id: $route.query.parent_id,
                  page: $route.query.page,
                  parent_page: $route.query.parent_page,
                  parent_parent_page: $route.query.parent_parent_page,
                },
              })
            "
            icon
            small
          >
            <v-icon>mdi-text-box-plus-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.actions="{ item }">
          
           <v-btn
            @click.stop="handleFeaturedToggle(item)"
              icon
              :color="item.featured ? 'orange' : 'gray'"
            >
              <v-icon>{{
                item.featured ? "mdi-star" : "mdi-star-outline"
              }}</v-icon>
            </v-btn>

          <v-btn
            class="mx-1"
            small
            icon
            @click.stop="
              $router.push({
                name: 'dashboard-prep-exams-create',
                params: { id: item.id },
                query: { parent_page: $route.query.page },
              })
            "
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            class="mx-1"
            small
            icon
            @click.stop="
              $store
                .dispatch('dashboard/prep/ExamsRemove', {
                  item,
                })
                .then(() => {
                  items = items.filter((el) => {
                    return el.id != item.id;
                  });
                })
            "
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <fab-button
        v-if="allowedModeratorAndUp"
        :icon="'mdi-plus'"
        color="primary"
        @clicked="
          $router.push({
            name: 'dashboard-prep-exams-create',
            query: {
              parent_id: $route.query.parent_id,
              page: $route.query.page,
              parent_page: $route.query.parent_page,
              parent_parent_page: $route.query.parent_parent_page,
            },
          })
        "
      />

      <v-btn
        v-if="parent && parent.children && parent.children.length < 1"
        fab
        fixed
        bottom
        right
        @click="
          $router.push({
            name: 'dashboard-prep-mocktests-create',
            query: {
              exam_id: parent.id,
            },
          })
        "
        dark
        class="orange darken-4 white--text"
        :style="`bottom: 130px; z-index: 10`"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-col>
    <query-page-redirect :pagination="pagination" />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import FabButton from "@/components/FabButton.vue";
import { axios } from "@/plugins/axios";
import QueryPageRedirect from "@/components/QueryPageRedirect.vue";
export default {
  name: "dashboard-prep-exams",
  components: {
    FabButton,
    QueryPageRedirect,
  },
  data() {
    return {
      loading: false,
      items: [],
      parent: {},
      options: {},
      headers: [
        { text: "Active", value: "active", sortable: true },
        { text: "Title", value: "title", sortable: true },
        { text: "Children", value: "children", sortable: false },
        { text: "Mocks", value: "mocktests", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pagination: {
        lastPage: null,
        page: 1,
        perPage: null,
        total: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
    "pagination.page"(page) {
      if (page > 0) {
        //this.getItems();
      }
    },
    "$route.query.parent_id"(id) {
      this.getParent();
      console.log("watch -- $route.query.parent_id", id);
    },
  },
  methods: {
    handleClickItemMocktests(item) {
      this.$router.push({
        name: "dashboard-prep-exams-mocktests",
        params: { id: item.id },
        query: {
          parent_id: this.$route.query.parent_id,
          parent_page: this.$route.query.page,
          parent_parent_page: this.$route.query.parent_page,
        },
      });
    },
    handleRowClick(item) {
      this.$router.push({
        name: "dashboard-prep-exams",
        query: {
          page: 1,
          parent_page: this.$route.query.page,
          parent_parent_page: this.$route.query.parent_page,
          parent_id: item.id,
        },
      });
    },
    getParent() {
      const parent_id = this.$route.query.parent_id
        ? this.$route.query.parent_id
        : null;

      if (!parent_id) return;

      return axios
        .get(`/prep/exams/${parent_id}`)
        .then((res) => {
          this.parent = res.data;
          return Promise.resolve(res.data);
        })
        .catch((error) => {
          return Promise.reject(error.response);
        });
    },

    handleFeaturedToggle(item) {
      //bus.$emit("showWait");
      this.loading = true;
      return axios
        .get(`/prep/exams/featured/${item.id}`)
        .then((res) => {
          item.featured = !item.featured;
          return Promise.resolve(res.data);
        })
        .catch((error) => {
          return Promise.reject(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getItems(currPage) {
      if (!currPage) {
        currPage = this.$route.query.page;
      }

      this.loading = true;
      const { sortBy, sortDesc } = this.options;
      const ascDesc = sortDesc && sortDesc[0] ? "desc" : "asc";
      const parent_id = this.$route.query.parent_id
        ? this.$route.query.parent_id
        : null;
      return axios
        .get(`/prep/exams/root`, {
          params: {
            page: currPage ? currPage : 1,
            orderby:
              sortBy && sortBy.length > 0 ? `${sortBy[0]}|${ascDesc}` : ``,
            parent_id,
            perPage: 20,
          },
        })
        .then((res) => {
          this.pagination = {
            lastPage: res.data.lastPage,
            page: res.data.page,
            perPage: res.data.perPage,
            total: res.data.total,
          };
          this.items = res.data.data;
          return Promise.resolve(res.data);
        })
        .catch((error) => {
          return Promise.reject(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getParent();
  },
};
</script>
