<template>
  <v-dialog
    v-if="mocktest && mocktest.id"
    v-model="dialog"
    fullscreen
    persistent
    :overlay="true"
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card tile>
      <v-toolbar color="indigo" dark flat>
        <v-btn @click="dialog = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          isHindi ? mocktest.title_hi : mocktest.title
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <TranslateExamButton />
        <v-btn color="yellow" @click="showAnswers = !showAnswers" icon>
          <v-icon v-if="showAnswers">mdi-format-list-checks</v-icon>
          <v-icon v-else>mdi-format-list-text</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs v-model="segmentTab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab
              @click="questionTab = 0"
              v-for="(segment, segmentIndex) in mocktest.segments"
              :key="segmentIndex"
            >
              {{ isHindi ? segment.title_hi : segment.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items
        style="min-height: 100vh"
        touchless
        v-touch="{
          left: () => swipeLeft(),
          right: () => swipeRight(),
        }"
        v-model="segmentTab"
      >
        <v-tab-item
          v-for="(segment, segmentIndex) in mocktest.segments"
          :key="segmentIndex"
        >
          <v-tabs
            center-active
            grow
            show-arrows
            v-model="questionTab"
            align-with-title
          >
            <v-tabs-slider color="orange"></v-tabs-slider>
            <v-tab
              v-for="(qstn, questionIndex) in segment.questions"
              :key="questionIndex"
            >
              <v-icon :color="getBadge(qstn).color" small>
                {{ getBadge(qstn).icon }}
              </v-icon>
              {{ questionIndex + 1 }}
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-row dense class="justify-center align-center">
            <v-col cols="12" md="6">
              <v-card
                tile
                outlined
                class="pa-2 ma-2"
                v-if="question && question.id"
              >
                <div class="btn-pos-left" v-if="question.elapsed > 0">
                  {{ isHindi ? "समय" : "Time" }}:
                  {{ question.elapsed.toString().fancyTime() }}
                </div>
                <report-question-dialog
                  class="btn-pos"
                  :withAnswer="true"
                  :question_id="question.id"
                />
                <v-divider class="mt-6"></v-divider>
                <v-card-title>
                  <latex-html
                    :isLatex="question.is_latex"
                    :html="isHindi ? question.title_hi : question.title"
                  />
                </v-card-title>

                <v-card-text>
                  <latex-html
                    :isLatex="question.is_latex"
                    :html="
                      isHindi ? question.description_hi : question.description
                    "
                  />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row class="optn-row">
                        <v-col cols="2">
                          <v-chip :class="getColor(question, '1')">A</v-chip>
                        </v-col>
                        <v-col cols="10">
                          <latex-html
                            :isLatex="question.is_latex"
                            :html="
                              isHindi ? question.option_a_hi : question.option_a
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-row class="optn-row">
                        <v-col cols="2">
                          <v-chip :class="getColor(question, '2')">B</v-chip>
                        </v-col>
                        <v-col cols="10">
                          <latex-html
                            :isLatex="question.is_latex"
                            :html="
                              isHindi ? question.option_b_hi : question.option_b
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-row class="optn-row">
                        <v-col cols="2">
                          <v-chip :class="getColor(question, '3')">C</v-chip>
                        </v-col>
                        <v-col cols="10">
                          <latex-html
                            :isLatex="question.is_latex"
                            :html="
                              isHindi ? question.option_c_hi : question.option_c
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-row class="optn-row">
                        <v-col cols="2">
                          <v-chip :class="getColor(question, '4')">D</v-chip>
                        </v-col>
                        <v-col cols="10">
                          <latex-html
                            :isLatex="question.is_latex"
                            :html="
                              isHindi ? question.option_d_hi : question.option_d
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card class="pa-2" v-if="question && question.id">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" v-if="question && question.id">
                      <v-card-text v-if="showAnswers">
                        <latex-html
                          :isLatex="question.is_latex"
                          :html="
                            isHindi
                              ? question.explanation_hi
                              : question.explanation
                          "
                        />
                      </v-card-text>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        showAnswers &&
                        question &&
                        question.explanation_video &&
                        question.explanation_video.length == 11
                      "
                    >
                      <v-card-text>
                        <question-explanation-video
                          :videoId="question.explanation_video"
                          class="mt-4"
                        />
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import bus from "@/bus";
import { axios } from "@/plugins/axios";
import LatexHtml from "@/components/LatexHtml";
import TranslateExamButton from "../../modules/TranslateExamButton";
import QuestionExplanationVideo from "@/components/QuestionExplanationVideo";
import ReportQuestionDialog from "@/components/ReportQuestionDialog";

export default {
  props: ["mocktest", "show"],
  components: {
    LatexHtml,
    TranslateExamButton,
    QuestionExplanationVideo,
    ReportQuestionDialog,
  },
  data() {
    return {
      dialog: false,
      segmentTab: null,
      questionTab: null,
      question: null,
      showAnswers: true,
    };
  },
  computed: {
    isHindi() {
      return this.$store.getters["app/exams/selectedTestLanguage"] == "hi";
      //return this.$store.getters.getCurrLocale == "hi";
    },
  },
  watch: {
    questionTab(val) {
      this.fetchQuestion();
    },
    segmentTab(val) {
      this.fetchQuestion();
    },
    dialog(val) {
      this.$emit("closed", val);
    },
    show(val) {
      this.dialog = val;
    },
  },
  methods: {
    getBadge(qtn) {
      if (!qtn || !this.showAnswers) {
        return {
          color: "",
          icon: "",
        };
      }

      let color = " ";
      let icon = "";
      if (!qtn.user_option) {
        color = "white black--text";
        icon = "mdi-square-rounded-outline";
      } else if (qtn.user_option == qtn.correct_option) {
        color = "green white--text";
        icon = "mdi-check-bold";
      } else if (qtn.user_option != qtn.correct_option) {
        color = "red white--text";
        icon = "mdi-close-thick";
      } else {
        color = "";
        icon = "";
      }
      return {
        color,
        icon,
      };
    },
    swipeRight() {
      this.goPrevious();
    },
    swipeLeft() {
      this.goNext();
    },
    goNext() {
      let segments = this.mocktest.segments;
      let questions = segments[this.segmentTab].questions;
      if (this.questionTab < questions.length - 1) {
        // can go next question
        this.questionTab++;
      } else {
        // cant go next question
        if (this.segmentTab < segments.length - 1) {
          // can go next segment
          this.segmentTab++;
          this.questionTab = 0;
        } else {
          // cant go next segment
          // this.segmentTab = 0;
          //this.questionTab = 0;
        }
      }
    },
    goPrevious() {
      let segments = this.mocktest.segments;
      if (this.questionTab > 0) {
        // can go previous question
        this.questionTab--;
      } else {
        // cant go previous question
        if (this.segmentTab > 0) {
          // can go previous segment
          this.segmentTab--;
          let questions = segments[this.segmentTab].questions;
          this.questionTab = questions.length - 1;
        } else {
          // cant go previous segment
          // this.segmentTab = segments.length - 1;
        }
      }
    },
    getColor(qtn, numberStr) {
      let cls = " ";
      if (!this.showAnswers) {
        return cls;
      }
      if (qtn.user_option == numberStr) {
        if (qtn.user_option != qtn.correct_option) {
          cls += "red";
        } else {
          cls += "green";
        }
      } else {
        if (qtn.correct_option == numberStr) {
          cls += "blue";
        }
      }
      console.log("color cls", cls);

      return cls;
    },
    fetchQuestion() {
      if (this.segmentTab == null || this.questionTab == null) {
        return;
      }
      let segment = this.mocktest.segments[this.segmentTab];
      let question = segment.questions[this.questionTab];
      this.getQuestion(question);
    },
    getQuestion(qtn) {
      bus.$emit("showWait");
      this.question = null;
      return axios
        .get("prep/questions/" + qtn.id)
        .then((res) => {
          console.log("res.data", res.data);
          this.question = res.data;
          this.question.elapsed = qtn.elapsed ? qtn.elapsed : 0;
          this.question.user_option = qtn.user_option;
          return Promise.resolve(this.question);
        })
        .catch((err) => {
          console.log("error.response", error.response);
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
  },
};
</script>
<style>
.btn-pos {
  position: absolute;
  top: 0.1rem;
  right: 1rem;
}
.btn-pos-left {
  position: absolute;
  top: 0.3rem;
  left: 1rem;
}
</style>