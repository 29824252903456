import { NotFound } from "../components";

export default [
  {
    path: "*",
    component: NotFound,
    meta: {
      title: "ERRORS:NOT_FOUND:TITLE-TEXT",
    },
  },
];
